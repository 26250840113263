import React from 'react';
export const RUPEES = <>&#x20b9;</>
export const RUPEES_SYMBOL = '₹'

export const BULLET = <>&#x2022;</>
export const BULLET_SYMBOL = '•'

export const truncate = (string, size = 25) => {
    if (!string) return ''
    if (string.length > size) {
        return `${string.substring(0, size - 1)}...`;
    }
    return string;

}

export const capitalize = (string) => {
    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const UpperCase = (string) => {
    if (!string) return ''
    return string.replace(/\b\w/g, l => l.toUpperCase());
}


export const objToQuery = (params) => {
    return Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
}
export const randomString = (length = 10) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export const arrayIntersection = (arr1 = [], arr2 = []) => {
    return arr1.filter(value => arr2.includes(value))
}

export const convertStrToKeys = (str, separator) => {
    //remove double space in string
    let keys = str.split("  ").join(' ')
    keys = keys.split(separator ? separator : ' ')
    // capatize all words
    keys = keys.map(key => key.charAt(0).toUpperCase() + key.slice(1))
    // trim all keys
    return keys.map(key => key.trim());
}


// let r = (Math.random() + 1).toString(36).substring(7);
// console.log("random", r);


export const formatNumber = (no = '') => {

    if (no.startsWith('0161'))
        return `0161-${no.slice(4, 7)}-${no.slice(7, 11)}`

    if (no.startsWith('161'))
        return `0161-${no.slice(3, 7)}-${no.slice(7, 11)}`

    if (no.startsWith(91))
        return `${no.slice(0, 2)}-${no.slice(2, 7)}-${no.slice(7, 12)}`

    if (no.length === 10)
        return `${no.slice(0, 3)}-${no.slice(3, 6)}-${no.slice(6, 10)}`

    return no

}

export const formatAmount = (amount) => {
    if (!amount) return 0
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const getPercentage = (value, total) => {
    const val = (value / total) * 100
    return Math.round(val * 100) / 100
}
