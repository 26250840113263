import { Button } from 'native-base';

export const typesList = [
    { label: 'Age Input', value: 'age' },
    // { label: 'Age Input(1 Selection)', value: 'age-1' },
    { label: 'Size Input', value: 'size' },
    // { label: 'Size Input(1 Selection)', value: 'size-1' },
    { label: 'Color Input', value: 'color' },
    // { label: 'Color Input(1 Selection)', value: 'color-1' },
    { label: 'Select Input', value: 'select' },
    { label: 'Autocomplete Input', value: 'autocomplete' },
    // { label: 'Text Input', value: 'text' },
    // { label: '1 Selection', value: 'S-1' },
    // { label: '2 Selection', value: 'S-2' },
    // { label: '3 Selection', value: 'S-3' },
    // { label: '4 Selection', value: 'S-4' },
    // { label: '5 Selection', value: 'S-5' },
    // { label: '30 Selection', value: 'S-30' },
]

export const columns = (navigate) => [
    {
        name: 'Name',
        selector: (row) => row.title,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row) => row.active ? "Active" : "Inactive",
        sortable: true,
    },
    {
        name: 'Required',
        selector: (row) => row.required ? "Yes" : "No",
        sortable: true,
    },
    {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
    },
    {
        name: 'Keys',
        selector: (row) => row.keys?.length,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/product-filters/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]