import React from 'react'
import { VStack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import { getOfferTemplateList } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { columns } from './static';

const OfferTemplateList = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'OfferTemplateList', service: getOfferTemplateList, })
    if (isLoading) return <Loading />

    return (
        <VStack>
            <CompHeader title='Offer Template List'
                buttonTitle='Create New List'
                onPress={() => navigate(`/admin/offerTemplateList/create`)} />
            <DataTable tableData={{ columns: columns(navigate), data, }} />
        </VStack>
    )
}
export default OfferTemplateList