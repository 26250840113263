import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useParams } from 'react-router-dom';
import useFetch from '../../hook/useFetch';
import { getOnboardersById } from '../../apis/admin';
import Loading from '../../commons/Loading';
import CreateOnboarders from './CreateOnboarders';

const EditOnboader = () => {
    const { id } = useParams()
    const { data, isLoading } = useFetch({
        name: `Onboader_${id}`,
        service: getOnboardersById,
        id
    })

    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2' w={'100%'} >
            {data && <CreateOnboarders data={data} />}
        </VStack>
    )
}

export default EditOnboader