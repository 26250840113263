import React from 'react'
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import L from "leaflet";
import { Marker, Popup } from 'react-leaflet'


const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    // iconAnchor: null,
    // popupAnchor: null,
    // shadowUrl: iconShadow,
    // shadowSize: new L.Point(100, 25),
    shadowAnchor: null,
    iconSize: new L.Point(15, 25),
    // className: 'leaflet-div-icon'
});


const Markers = ({ positions = [{ position: [31.487735, 74.822192], label: 'demo' }] }) => {
    return (
        <>
            {positions.map((item, index) =>
                <Marker key={`${item.label}-${index}`} position={item.position} icon={icon}>
                    <Popup>{item.label}</Popup>
                </Marker>
            )}
        </>
    )
}

export default Markers