import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getURLQuery } from '../helper/url';
import useNavigateSearch from './useNavigateSearch';
import { selectConverter } from '../helper/converter';

export const dataExtractor = (data) => {
    if (data) {
        if (data?.data) {
            if (data?.data?.result) {
                return data?.data?.result
            }
            return data?.data
        }
        return data
    }
    return []
}

const useFetch = ({
    name = '',
    service,
    conditionalRefetch = [],
    props = {},
    id,
    params = {},
    dataOnly,
    condition = true,
    onSuccess,
    target, pagination,
    converterLabel,
    getExcel = false,
    sort = false,
}) => {

    const navigateSearch = useNavigateSearch()
    const { search, pathname } = useLocation()
    const _params = getURLQuery(search)

    if (getExcel) {
        params = { ...params, get_excel: getExcel }
    }

    const [page, setPage] = useState(null)
    const [limit, setLimit] = useState(null)
    const [q, setQ] = useState(null)

    useEffect(() => {
        if (!pagination) return
        const { page = 1, limit = 10, q } = getURLQuery(search)
        setPage(page)
        setLimit(limit)
        setQ(q)
    }, [search])


    if (pagination) {
        params = { page, limit, q, ...params, }
        conditionalRefetch = [...conditionalRefetch, page, limit, q]
    }

    useEffect(() => {
        if (!pagination || !page || !limit) return
        navigateSearch(pathname, { ..._params, page, limit })
    }, [page, limit, q])


    let { isLoading, refetch, data, error, isSuccess, isRefetching } = useQuery(
        [name, conditionalRefetch],
        () => service({ id, params, ...props, }),
        { enabled: condition, refetchOnWindowFocus: 'always', cacheTime: 0 }
    )

    useEffect(() => {
        if (isSuccess && onSuccess) onSuccess(data)
    }, [isSuccess])

    data = dataExtractor(data)

    if (target)
        data = data?.[target]

    if (dataOnly && converterLabel) {
        return selectConverter({
            list: data?.data || data,
            label: converterLabel,
            sort
        })
    }

    if (dataOnly) return data?.data || data

    return {
        refetch, setPage, setLimit,
        isLoading, data, error,
        isSuccess, page, limit, isRefetching
    }

}

export default useFetch