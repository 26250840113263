import React from 'react'
import useFetch from '../../../hook/useFetch'
import { getAnalytics } from '../../../apis/admin'
import { VStack, HStack, Progress, Text } from '@chakra-ui/react'

const OverAll = ({ range }) => {

    const { data, isLoading } = useFetch({
        name: 'allAnalytics',
        service: getAnalytics,
        params: {
            date1: range[0],
            date2: range[1]
        },
        conditionalRefetch: [range]
    })


    const list = [
        ['Stores', data?.stores?.length],
        ['Malls', data?.malls?.length],
        ['Offers', data?.offers?.length],
        ['Users', data?.users?.length],
        ['Styles', data?.styles?.length],
        ['Products', data?.products?.length],
        ['Shops Auth', data?.shopsAuth?.length],
        ['Brands', data?.brands?.length],
    ]

    return (
        <VStack w={'100%'} alignItems={'flex-start'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>Overall</Text>
            {list.map(([key, value]) => (
                <HStack key={key} w={'100%'}>
                    <Text w={140} fontWeight={'bold'}>{key}({value})</Text>
                    <Text fontWeight={'bold'}>:</Text>
                    <Progress w={'100%'} h={10} value={value} />
                </HStack>
            ))}
        </VStack>
    )
}

export default OverAll