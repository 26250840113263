import React from 'react'
import { Box } from 'native-base'
import { useNavigate, useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from '../Users/static'
import { getUserSubscriptionUsers } from '../../apis/admin'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'

const UsersSubscriptions = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({ setvice: getUserSubscriptionUsers, name: 'getUserSubscriptionUsers', id })

    return (
        <Box>
            <CompHeader title='Subscriptions Users' backButton />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading }} />
        </Box>
    )
}

export default UsersSubscriptions