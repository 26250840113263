import React from 'react'
import { Spacer } from 'native-base'
import useFetch from '../../../hook/useFetch'
import { colors } from '../../../theme/colors'
import ProductGenderCard from './ProductGenderCard'
import { getgender, getwears } from '../../../apis/admin'
import { Divider, HStack, Text, VStack } from '@chakra-ui/react'

const contProps = { borderWidth: 0, w: '100%', bg: colors.light, alignItems: 'flex-start', p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const ProductWears = ({ data, refetch }) => {
    const genders = useFetch({ service: getgender, name: 'genders', dataOnly: true })
    const wears = useFetch({ service: getwears, name: 'wears', dataOnly: true })
    const [editWear, setEditWear] = React.useState(false)

    return (
        <VStack {...contProps} position='relative'>
            <HStack w='100%'>
                <Text fontSize={'xl'} fontWeight={'bold'} >{data.name} Wear</Text>
                <Spacer />
                <Text color={colors.primary} cursor={'pointer'}
                    onClick={() => setEditWear(!editWear)} >
                    {editWear ? 'Done' : 'Edit Wear'}
                </Text>
            </HStack>
            <VStack alignItems={'flex-start'} divider={<Divider />} w='100%'>
                {genders.map(item =>
                    <ProductGenderCard
                        key={item._id}
                        activeWears={data?.wears?.wears.find(({ gender }) => gender._id === item._id)?.wears || []}
                        gender={item}
                        refetch={refetch}
                        wears={wears}
                        editWear={editWear}
                    />
                )}
            </VStack>
        </VStack>
    )
}

export default ProductWears