import { VStack } from '@chakra-ui/react'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import SendIntroMessageToUser from './SendIntroMessageToUser'
import SendOffersToStore from './SendOffersToStore'
import SendBannerToPhoneNumber from './SendBannerToPhoneNumber'

const WAMessage = () => {
    return (
        <VStack w="100%" alignItems={'flex-start'}>

            <CompHeader title={'WhatsApp Message'}
                buttonTitle='Logs'
                buttonLink={'/admin/wa_messages_logs'}
            />

            <SendBannerToPhoneNumber />

            <SendIntroMessageToUser />


            {/* <SendOffersToStore /> */}

        </VStack>
    )
}

export default WAMessage