import React, { useState } from 'react'
import { Checkbox, HStack, Text, VStack } from 'native-base'
import { Input, SimpleGrid, } from '@chakra-ui/react';
import { colors } from '../../../theme/colors';

const RulesSelectionRelationCard = ({ data, form }) => {
    const updateState = (id, value) => form.setValue(id, value)
    const [search, setSearch] = useState('')
    const { relations } = form.getValues()
    const { title, _id } = data.title

    return (
        <VStack bg={colors.inputBg} p='4' borderRadius={'lg'} mb='4' space={2}>

            <Text fontWeight={'semibold'} fontSize={'md'}>{title}</Text>

            <Input placeholder='Search key' value={search} bg={'white'}
                borderWidth={0} outline={0}
                outlineColor={'transparent'} onChange={e => setSearch(e.target.value)} />

            <VStack space='2' w={'100%'} >
                <HStack w={'100%'} space='2' >
                    <Checkbox.Group
                        value={relations.find(item => item.title === _id)?.keys}
                        onChange={(e) => {
                            const index = relations.findIndex(item => item.title === _id)
                            if (index === -1) {
                                relations.push({ title: _id, keys: e })
                            } else {
                                relations[index].keys = e
                            }

                            if (relations[index].keys.length === 0)
                                relations.splice(index, 1)

                            updateState('relations', relations)
                        }}

                    >
                        <SimpleGrid columns={[3, 6]} spacing='2' >
                            {data.keys.filter(({ label }) => label.toLowerCase().startsWith(search.toLowerCase())).map(key =>
                                <Checkbox mb='1' w='130' key={key._id}
                                    _text={{ fontSize: 'sm' }} value={key._id}>
                                    {key.label}
                                </Checkbox>
                            )}
                        </SimpleGrid>
                    </Checkbox.Group>
                </HStack>
            </VStack>
        </VStack>
    )
}

export default RulesSelectionRelationCard