import { RangeSlider, RangeSliderFilledTrack, RangeSliderMark, RangeSliderThumb, RangeSliderTrack, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { Box, Text, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { createRange } from '../../../helper/operations';
import { getURLQuery } from '../../../helper/url';
import useNavigateSearch from '../../../hook/useNavigateSearch';
import Maps from './Maps';

const defaultValues = { _lat: [0, 1], _long: [0, 1], lat: [0, 1], long: [0, 1], center: [0, 1], }

const Ranger = ({ data, isLoading, limit, setLimit }) => {
    const { search, pathname } = useLocation()
    const navigate = useNavigateSearch()
    const query = getURLQuery(search)
    const { fullscreen } = query

    const form = useForm({ defaultValues: { ...defaultValues, ...query } })
    const setValue = (name, value) => form.setValue(name, value)
    const state = form.getValues()
    const { _lat, _long, lat, long, center, } = state

    useEffect(() => {
        const _query = {
            ...query,
            // lat: lat.join(','),
            // long: long.join(','),
            // center: center.join(','),
            limit,
        }
        navigate(pathname, _query)
    }, [state])

    useEffect(() => {
        if (!data?.data) return
        let _data = data.data.map(item => { return { position: item.geo_location.coordinates, label: item.store_name } })
        const lats = _data.map(item => item.position[0]).filter(item => item !== undefined)
        const longs = _data.map(item => item.position[1]).filter(item => item !== undefined)
        const minLat = Math.min(...lats)
        const maxLat = Math.max(...lats)
        const minLong = Math.min(...longs)
        const maxLong = Math.max(...longs)
        const lat = [minLat, maxLat]
        const long = [minLong, maxLong]
        const center = [(lat[0] + lat[1]) / 2, (long[0] + long[1]) / 2]

        form.reset({ center, lat, long, _lat: lat, _long: long, })
    }, [data])

    const positions = (() => {
        if (!data?.data) return
        let _data = data.data.map(item => {
            return {
                position: item.geo_location.coordinates,
                label: item.store_name
            }
        })

        _data = _data.filter(item => {
            const _lat = item.position[0]
            const _long = item.position[1]
            return _lat >= lat[0] && _lat <= lat[1] && _long >= long[0] && _long <= long[1]
        })

        return _data
    })()

    return (
        <VStack>
            <VStack space='3' p='8' my='2' bg='white' borderRadius={'lg'}>
                <Text>Total Stores on map: {positions?.length}</Text>
                <VStack space='3'>
                    <Text fontWeight={'semibold'} fontSize='md'>Latitude ({lat[0]},{lat[1]})</Text>
                    <RangeSlider aria-label={['min', 'max']}
                        min={_lat[0]} max={_lat[1]}
                        defaultValue={[0, 0]}
                        value={lat}
                        step={(_lat[1] - _lat[0]) / 100}
                        onChange={(val) => setValue('lat', val)}
                    >
                        {createRange(_lat[0], _lat[1], 5).map(item =>
                            <RangeSliderMark value={item} key={item} mt='1' ml='-2.5' fontSize='sm'>
                                {item.toString().substr(0, 4)}
                            </RangeSliderMark>
                        )}
                        <RangeSliderTrack>
                            <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb index={0} />
                        <RangeSliderThumb index={1} />
                    </RangeSlider>
                </VStack>

                <VStack space='3'>
                    <Text fontWeight={'semibold'} fontSize='md'>Longitude ({long[0]},{long[1]})</Text>
                    <RangeSlider aria-label={['min', 'max']}
                        min={_long[0]}
                        max={_long[1]}
                        step={(_long[1] - _long[0]) / 100}
                        defaultValue={[0, 0]} value={long}
                        onChange={(val) => setValue('long', val)}
                    >
                        {createRange(_long[0], _long[1], 5).map(item =>
                            <RangeSliderMark value={item} key={item} mt='1' ml='-2.5' fontSize='sm'>
                                {item.toString().substr(0, 4)}
                            </RangeSliderMark>
                        )}
                        <RangeSliderTrack>
                            <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb index={0} />
                        <RangeSliderThumb index={1} />
                    </RangeSlider>
                </VStack>

                <VStack space='3'>
                    <Text fontWeight={'semibold'} fontSize='md'>Store Count ({limit})</Text>
                    <Slider
                        _disabled={{ cursor: 'not-allowed' }}
                        disabled={!isLoading}
                        value={limit}
                        min={0}
                        max={data?.total}
                        onChange={setLimit}
                        aria-label='slider-ex-1' defaultValue={100} >
                        {createRange(0, data.total, 5).map(item =>
                            <SliderMark value={item} key={item} mt='1' ml='-2.5' fontSize='sm'>
                                {item.toString().substr(0, 4)}
                            </SliderMark>
                        )}
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>

                </VStack>
            </VStack>

            {(fullscreen ?
                <Box bg='white' position='fixed' top='0' left='0' right='0' bottom='0' zIndex='100'>
                    <Box position='absolute' top='0' left='0' right='0' bottom='0' zIndex='100'>
                        <Maps positions={positions} center={center} />
                    </Box>
                </Box>
                :
                <Box>
                    <Maps positions={positions} center={center} />
                </Box>
            )}
        </VStack>
    )
}

export default Ranger