import React from 'react'
import { useEffect } from 'react'
import { colors } from '../../theme/colors'
import { getNavByType } from '../Routes/LinkItem'
import { useNavigate, useParams } from 'react-router-dom'
import { HStack, Icon, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/authSlice'

const LinkTypes = () => {
    const { type } = useParams()
    const profile = useSelector(selectProfile)
    const nav = getNavByType({ type, profile })
    const navigate = useNavigate()

    useEffect(() => {
        if (nav.length === 1) navigate(nav[0].link)
    }, [nav])

    return (
        <VStack w='100%' alignItems='center' justifyContent={'center'} h={'70vh'} p={2} space={2}>
            <SimpleGrid columns={[1, 4]} spacing='2' mb='2' w='100%'>
                {nav.map(({ name, link, icon }) => (
                    <HStack key={link}
                        onClick={() => navigate(link)}
                        _hover={{ bg: colors.border, color: colors.primary }}
                        transition={'all 0.3s ease'}
                        cursor={'pointer'}
                        borderColor={colors.secondary}
                        p={4} borderWidth={2} borderRadius={'lg'}
                    >
                        <Icon mr="4" fontSize="16" as={icon} _groupHover={{ color: 'white', }} />
                        <Text fontSize='md' fontWeight={'semibold'}
                            textAlign={'center'} letterSpacing={'wide'}
                            color={link === `/${type}` ? 'primary' : 'secondary'} >
                            {name}
                        </Text>
                    </HStack>
                ))}
            </SimpleGrid>
        </VStack>
    )
}

export default LinkTypes