import { Button } from "native-base";
import { formatDateTimes } from "../../utils/moment";
import { VStack } from "@chakra-ui/react";
// import Loading from "../../commons/Loading";

const getNumber = (number) => {
    number = number.trim().replace(/\s/g, '');
    if (number.length === 10) {
        return `+91${number}`;
    }
    if (number.startsWith('91'))
        return `+${number}`;
    return number;
}


const shareWithWhatsApp = (number) => {
    // const text = 'https://offingo.s3.ap-south-1.amazonaws.com/OffingoSharePosterVertical.jpg'
    // const url = `https://api.whatsapp.com/send?phone=${getNumber(number)}&text=${text}`;
    const url = `https://api.whatsapp.com/send?phone=${getNumber(number)}&text=https://offn.in/offingo`;
    window.open(url, "_blank", "noreferrer");
};


export const columns = ({ navigate, setCall, setCoupon, SendBannerToPhoneNumber }) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/users/edit/${row?._id}`)}>
            Details
        </Button>,
        sortable: false,
    },
    {
        name: 'Journey',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/users/journey/${row?._id}`)}>
            Journey
        </Button>,
        sortable: false,
    },
    {
        name: 'Call',
        button: true,
        cell: (row) =>
            <VStack spacing={0}>
                <Button size='sm' variant={'link'} onPress={() => setCall(row)} p={0}>
                    Calls
                </Button>
                <Button size='sm' variant={'link'} onPress={() => setCoupon(row)} p={0}>
                    Assign Coupon
                </Button>
                <Button size='sm' variant={'link'} onPress={() => shareWithWhatsApp(row?.phone_number)} p={0}>
                    Open Chat
                </Button>
            </VStack>,
        sortable: false,
    },
    // {
    //     name: 'Send Banner',
    //     button: true,
    //     cell: (row) =>
    //         <Button size='sm' variant={'link'} onPress={() => SendBannerToPhoneNumber.submit({ phone_number: row?.phone_number })}>
    //             via Api
    //         </Button>,
    //     sortable: false,
    // },
    {
        name: 'Name',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Phone No.',
        selector: (row) => row?.phone_number,
        sortable: true,
    },
    {
        name: 'Joined',
        selector: (row) => formatDateTimes(row?.createdAt, 5),
        sortable: true,
    },
    {
        name: 'Platform',
        selector: (row) => row?.device_tokens?.map(({ platform }) => platform).join(', '),
        sortable: true,
    },
    {
        name: 'Last Activity',
        selector: (row) => formatDateTimes(row?.updatedAt, 8),
        sortable: true,
    },
    // {
    //     name: 'Subscription',
    //     selector: (row) => row?.subscription?.sub_id?.name || "Free",
    //     sortable: true,
    // },
    {
        name: 'City/State',
        selector: (row) => `${row?.city?.name}/${row?.state?.name}`.replace('undefined', '-').replace('undefined', '-'),
        sortable: true,
    },
    // {
    //     name: 'Role',
    //     selector: (row) => row?.role,
    //     sortable: true,
    // },
    {
        name: 'Gender',
        selector: (row) => row?.gender?.type,
        sortable: true,
    },
    {
        name: 'DOB',
        selector: (row) => formatDateTimes(row?.date_of_birth, 3),
        sortable: true,
    },
]

