import React from 'react'
import { columns } from './static'
import { VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getSesssionOtps } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const Otps = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getSesssionOtps',
        service: getSesssionOtps,
    })

    return (
        <VStack>
            <CompHeader title="Otps" />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading }} pagination={false} />
        </VStack>
    )
}


export default Otps