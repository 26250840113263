import React from 'react'
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Button, HStack, Spacer, Text } from '@chakra-ui/react'

const CompHeader = ({
    title = '',
    buttonTitle = '',
    onPress,
    backButton,
    renderRight,
    buttonLink,
    show = true,
    mb = '5'
}) => {
    const navigate = useNavigate()

    return (
        show && <HStack space='5' alignItems={'center'} mb={mb}
            justifyContent={'space-between'} w={'100%'} >
            {backButton &&
                <HStack space='2' alignItems={'center'} onClick={() => navigate(-1)}>
                    <IoMdArrowRoundBack />
                </HStack>
            }

            <Text fontSize={'md'} fontWeight='semibold' flex={1}>
                {title}
            </Text>

            {renderRight ||
                (onPress || buttonLink ?
                    <>
                        <Spacer />
                        <Button
                            size='sm'
                            onClick={() => {
                                if (onPress) onPress()
                                if (buttonLink) navigate(buttonLink)
                            }}>
                            {buttonTitle}
                        </Button>
                    </> : <></>
                )
            }
        </HStack>
    )
}

export default CompHeader