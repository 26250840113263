import { Text, VStack } from 'native-base'
import React from 'react'
import { createLoginCode, unlinkStoreAuth } from '../../../apis/store'
import { storeRoles } from '../../../components/PreRegister/static/register'
import EditInputCard from './EditInputCard'
import { colors } from '../../../theme/colors'
import useSubmit from '../../../hook/useSubmit'
import { Button, HStack } from '@chakra-ui/react'
import StoreAuthCalls from './StoreAuthCalls/StoreAuthCalls';

import Model from '../../../components/compo/UI/Model'

const StoreAuthCard = ({
    data: item, index, form, form2, store
}) => {

    const [code, setCode] = React.useState(null)
    const [calls, setCalls] = React.useState(false)

    const { submit: unlink, isLoading: isLoading2 } = useSubmit({
        service: unlinkStoreAuth, id: store._id,
    })

    const { submit: loginCode, isLoading: isLoading3 } = useSubmit({
        service: createLoginCode,
        onSuccess: (data) => setCode(data)
    })

    const list_basic = [
        ['Name', 'name'],
        ['Phone Number', 'phone_number'],
        ['Role', 'role', storeRoles?.map(([value, label]) => ({ label, value }))],
        ['Verified', 'verified', [true, false].map(value => ({ label: value.toString(), value }))],
    ]

    return (
        <VStack space='2' borderRadius={'lg'} borderWidth={'1'} borderColor={colors.border} p='2'>
            {list_basic.map(([label, target, options]) =>
                <EditInputCard
                    key={`${target}${index}`}
                    label={label}
                    target={`auth[${index}][${target}]`}
                    form={form}
                    form2={form2}
                    options={options}
                />
            )}

            <HStack alignItems={'center'} >
                <Button w='50' size='sm' colorScheme={'green'} isLoading2={isLoading3}
                    onClick={() => setCalls(true)}>
                    Calls
                </Button>
                <Button w='50' bg='red.500' size='sm' colorScheme={'red'}
                    onClick={() => {
                        if (window.prompt('Are you sure you want to unlink this store? Type "y" to confirm.') === 'y')
                            unlink({ store_id: store._id, auth_id: item._id });
                    }}
                    isLoading2={isLoading2}
                >
                    Unlink {item.name} from This Store
                </Button>
                <Button w='50' size='sm' colorScheme={'yellow'} isLoading2={isLoading3}
                    onClick={() => loginCode({ phone_number: form.watch(`auth[${index}].phone_number`) })}>
                    Get Login Code
                </Button>

                {code?.code && <Text bg='yellow.500' colorScheme={'yellow'} p={2} borderRadius={'md'}
                    fontWeight={'bold'} fontSize={'sm'} >
                    00000-{code?.code?.split('00000')[1]}
                </Text>}
            </HStack>
            {calls && <Model size='full' title={'Calls'} show={calls} close={() => setCalls(false)}
                body={<StoreAuthCalls auth={item} store={store} close={() => setCalls(false)} />}
            />}
            {/* {LOGIN_AS_STORE_OWNER && <LoginToDashboard data={_} />} */}
        </VStack>
    )
}

export default StoreAuthCard