import React, { useState } from 'react'
import { Checkbox, HStack, VStack } from 'native-base'
import { Input, SimpleGrid, } from '@chakra-ui/react';
import { colors } from '../../../theme/colors';

const RulesSelectionExceptCard = ({ data, form }) => {
    const updateState = (id, value) => form.setValue(id, value)
    const [search, setSearch] = useState('')
    const { except, hides } = form.getValues()
    const { title, _id } = data.title

    return (
        <VStack bg={colors.input} p='4' borderRadius={'lg'} mb='4' space={2}>

            <HStack>
                <Checkbox mb='1' w='200'
                    _text={{ fontSize: 'md', fontWeight: 'bold' }}
                    value={_id}
                    isChecked={hides.includes(_id)}
                    onChange={e => {
                        const _hides = e ? [...hides, _id] : hides.filter(item => item !== _id)
                        updateState('hides', _hides)
                        const _except = except.filter(item => item.title !== _id)
                        updateState('except', _except)
                    }}
                >{title}</Checkbox>
            </HStack>

            <Input placeholder='Search key' value={search} bg={'white'}
                borderWidth={0} outline={0}
                outlineColor={'transparent'} onChange={e => setSearch(e.target.value)} />

            <VStack space='2' w={'100%'} >
                <HStack w={'100%'} space='2'
                    opacity={hides.includes(_id) ? '.4' : 1}
                    pointerEvents={hides.includes(_id) ? 'none' : 'auto'}
                >
                    <Checkbox.Group

                        value={except.find(item => item.title === _id)?.keys || []}
                        onChange={(e) => {
                            const _except = except
                            const index = _except.findIndex(item => item.title === _id)
                            if (index === -1) _except.push({ title: _id, keys: e })
                            else _except[index].keys = e

                            // remove empty except
                            if (_except[index].keys.length === 0) _except.splice(index, 1)

                            updateState(`except`, _except)
                        }}>
                        <SimpleGrid columns={[3, 6]} spacing='2' >
                            {data.keys.filter(({ label }) => label.toLowerCase().startsWith(search.toLowerCase())).map(key =>
                                <Checkbox mb='1' w='130' key={key._id}
                                    _text={{ fontSize: 'sm' }} value={key._id}>
                                    {key.label}</Checkbox>
                            )}
                        </SimpleGrid>
                    </Checkbox.Group>
                </HStack>
            </VStack>
        </VStack>
    )
}

export default RulesSelectionExceptCard