import { Button } from "native-base"
import { Text, VStack } from "@chakra-ui/react"
import { formatDateTimes } from "../../utils/moment"
import { getDistancefromLocal } from "../../helper/coords"

export const columns = () => [
    {
        name: 'Actions',
        button: true,
        cell: (row) =>
            <VStack>
                <Button size='sm' variant={'link'}
                    onPress={() => window.open(`/admin/restaurants/edit/${row?._id}`, '_blank')}>
                    Edit
                </Button>
                <Button size='sm' variant={'link'}
                    onPress={() => window.open(`/admin/food_coupons/create?restaurant_id=${row?._id}&restaurant_name=${row?.name}`, '_blank')}>
                    Create Food Coupon
                </Button>
            </VStack>
        ,
        sortable: false,
    },
    {
        name: 'Date Registered',
        selector: (row) => formatDateTimes(row?.createdAt, 4),
        sortable: true,
    },
    {
        name: 'City/State',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Text>
                    {row?.address.city?.name},
                </Text>
                <Text>
                    {row?.address.state?.name}
                </Text>
                <Text>
                    ({getDistancefromLocal(row?.geo_location.coordinates[0], row?.geo_location.coordinates[1])}Km)
                </Text>
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row?.name,
        sortable: true,
    },
    {
        name: 'Auths',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'}>
                <Text>
                    {row?.manager_name}
                </Text>
                <Text>
                    {row?.phone_number}
                </Text>
            </VStack>,
        sortable: false,
    },
    {
        name: 'Coupons',
        selector: (row) => `${row?.coupons?.[0]?.name || ''}`,
        sortable: true,
    },
    {
        name: 'Address',
        width: '200px',
        selector: (row) => row?.address?.address,
        sortable: true,
    },
    {
        name: 'Verified',
        selector: (row) => row?.verified ? 'Yes' : 'No',
    },
] 