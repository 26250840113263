import { Button } from "native-base";
import { getAppLinks } from "../../helper/applink";
import { formatDateTimes } from "../../utils/moment";
import { capitalize } from "../../helper/stringOperations";

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/reviewStore/${row._id}`)}
        >
            Review</Button>,
        sortable: false,
    },
    {
        name: 'Date Registered',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Registered By Name',
        selector: (row) => row?.createdBy === 'bot' ? "Bot" : `${row?.registered_by?.name}(${row?.registered_by?.register_by?.role?.split('STORE')[1]})`,
        sortable: true,
    },
    {
        name: 'Registered By P.Number',
        selector: (row) => row?.registered_by?.phone_number,
        sortable: true,
        hide: 'lg',
        reorder: true,
    },
    {
        name: 'Store Name',
        selector: (row) => row.store_name,
        sortable: true,
    },
    {
        name: 'City, State',
        selector: (row) => `${row.store_address.city?.name}, ${row.store_address.state?.name}`,
        sortable: true,
    },
    {
        name: 'Pre-Register',
        selector: (row) => row.pre_register ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Claimed',
        selector: (row) => row.claimed ? 'Yes' : 'No',
        sortable: true,
    },
    // {
    //     name: 'App Link',
    //     button: true,
    //     cell: (row) => <Button
    //         color='blue.400'
    //         size='sm' variant={'link'}
    //         onPress={() => ['exclusive', 'non-branded'].includes(row.type) ? getAppLinks(`/store/${row._id}`) : getAppLinks(`/mutliBrandStore/${row._id}`)
    //         } >
    //         copy</Button>,
    //     sortable: false,
    // },
] 