import { formatDateTimes, getDayDiff } from "../../utils/moment";
import { Button } from '@chakra-ui/react';
import { IMAGE_BASE_URL } from '../../helper/variables';
import { Image } from '@chakra-ui/react';

export const columns = (navigate) => [
    {
        name: 'Logo',
        cell: (row) => <Image resizeMode='contain' borderRadius={'lg'} cursor='pointer' src={IMAGE_BASE_URL + row.images[0]} h='50' w='50' />,
        sortable: true,
    },
    {
        name: 'Active',
        selector: (row) => row.active ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Expired',
        selector: (row) => {
            const now = new Date()
            const end = new Date(row.end_date)
            return end < now ? 'Yes' : 'No'
        },
        sortable: true,
    },
    {
        name: 'Requested',
        selector: (row) => row.is_requested ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Requested By',
        selector: (row) => row.is_requested ? `${row.requested_by?.name}(${row.requested_by?.role})` : '--',
        sortable: true,
    },
    {
        name: 'Requested Phone Number',
        selector: (row) => row.requested_by?.phone_number,
        sortable: true,
    },
    {
        name: 'Reach',
        selector: (row) => row.seen?.length,
        sortable: true,
    },
    {
        name: 'Clicked',
        selector: (row) => row?.clicked?.length,
        sortable: true,
    },
    {
        name: 'name',
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: 'Link',
        selector: (row) => row.link,
        sortable: true,
    },
    {
        name: 'From',
        selector: (row) => formatDateTimes(row.start_date, 3),
        sortable: true,
    },
    {
        name: 'To',
        selector: (row) => formatDateTimes(row.end_date, 3),
        sortable: true,
    },
    {
        name: 'Duration',
        selector: (row) => `${getDayDiff(new Date(row.start_date), new Date(row.end_date))} Days`,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onClick={() => navigate(`/admin/ads/edit/${row._id}`)}
        >
            Edit</Button>,
        sortable: false,
    },
]