import { formatDateTimes } from '../../../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Mod',
        selector: (row) => row?.mod?.name,
        sortable: true,
    },
    {
        name: 'Auth Name',
        selector: (row) => row?.mod?.name,
        sortable: true,
    },
    {
        name: 'Purpose',
        selector: (row) => row?.purpose,
        sortable: true,
    },
    {
        name: 'Comment',
        selector: (row) => row?.comment,
        sortable: true,
    },
    {
        name: 'Review',
        selector: (row) => row?.review,
        sortable: true,
    },
    {
        name: 'Call Time',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
] 