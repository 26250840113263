import { Box, Checkbox, HStack, Text, Textarea, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Input } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import Model from './UI/Model';

const StringDivider = ({ value, onChange }) => {

    const [str, setStr] = useState('')
    const [separator, setSeparator] = useState('')
    const [show, setShow] = useState(false)

    const [props, setprops] = useState({
        sort: true,
        removeDuplicate: true,
        capatize: true,
        removeEmpty: true,
        small: true,
        trim: true,
        removeDoubleSpace: true,
        removeSpaceBeforeAndAfterSeparator: true,
    })

    const convertStrToKeys = () => {
        //remove double space in str
        let keys = str.replace(/\s+/g, ' ')
        // remove space at start and end
        keys = keys.trim()
        // if not separator is provided
        let _separator = separator || ' '
        // remove space before and after separator
        keys = keys.replace(new RegExp(`\\s*${_separator}\\s*`, 'g'), _separator)
        // split str by separator
        keys = keys.split(_separator)
        // add to existing keys
        keys = [...value.filter(key => typeof key === 'string'), ...keys]

        // trim keys
        if (props.trim)
            keys = keys.map(key => key.trim())
        // capatize all words
        if (props.capatize) {
            // small all words
            keys = keys.map(key => key.toLowerCase())
            keys = keys.map(key => key.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))))
        }
        // arrange in ascending order
        if (props.sort)
            keys = keys.sort()
        // remove empty string
        if (props.removeEmpty)
            keys = keys.filter(key => key !== '')
        // remove duplicate
        if (props.removeDuplicate)
            keys = [...new Set(keys)]
        onChange(keys)
        setShow(false)
        setStr('')
        setSeparator('')
    }

    const formatKeyForUser = (key) => {
        // camel case to space
        key = key.replace(/([A-Z])/g, ' $1')
        // remove first space
        key = key.replace(/^./, key[0].toUpperCase())
        return key
    }

    const disabled = ['removeEmpty', 'small', 'trim', 'removeSpaceBeforeAndAfterSeparator', 'removeDoubleSpace']

    return (
        <>
            <Button
                size='sm' onClick={() => setShow(!show)}
                variant='solid' colorScheme={'facebook'}
            >
                Add Multiple Keys
            </Button>
            <Model
                title={'Add multiple keys'}
                show={show}
                close={() => setShow(false)}
                body={
                    <VStack alignItems={'flex-start'} bg='white' space='2' p='2' borderRadius={'md'} >
                        <Box alignItems="center" w="100%">
                            <Textarea
                                rows={15}
                                variant='filled' borderColor='gray.300'
                                value={str} placeholder='Add here'
                                onChange={e => setStr(e.target.value)}
                            />
                        </Box>
                        <Input variant='filled' borderColor='gray.300' value={separator}
                            placeholder='Separator(space, comma, etc)'
                            onChange={e => setSeparator(e.target.value)}
                        />

                        <VStack alignItems={'flex-start'}>
                            <Text color='yellow.500'>Options</Text>
                            {Object.keys(props).map(key => (
                                <HStack>
                                    <Checkbox
                                        disabled={disabled.includes(key)}
                                        isChecked={props[key]}
                                        onChange={e => setprops({ ...props, [key]: e.target.checked })}
                                    />
                                    <Text>{formatKeyForUser(key)}</Text>
                                </HStack>
                            ))}
                        </VStack>
                        <Text color='yellow.500'>Review data after adding</Text>
                        <Button
                            disabled={str === ''}
                            size={'sm'}
                            onClick={convertStrToKeys}>Add</Button>
                    </VStack>
                }
            />
        </>
    )
}

export default StringDivider