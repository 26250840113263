import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { getRedeemCouponReward, } from '../../apis/admin'


const RedeemCouponReward = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({
        name: 'getRedeemCouponReward',
        service: getRedeemCouponReward,
        pagination: true
    })
    console.log('data', data)

    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title="Redeem Coupon Reward" />
            <DataTable title={`${data?.total || ''} Redeem Coupon Reward`}
                tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default RedeemCouponReward

