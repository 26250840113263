import { Button, Stack, VStack } from 'native-base'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllStoreAuth } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { storeRoles } from '../../components/PreRegister/static/register'
import useFetch from '../../hook/useFetch'
import StoreTabs from '../Stores/StoreTabs'
import { columns } from './static'

const StoreAuthorities = () => {
    const navigate = useNavigate()
    const [role, setRole] = useState('')

    const { data, isLoading } = useFetch({
        name: 'getAllStoreAuth',
        service: getAllStoreAuth,
        pagination: true,
        params: { role },
        conditionalRefetch: [role]
    })

    return (
        <VStack>
            <CompHeader title='Stores' buttonTitle='New Store' onPress={() => navigate('/admin/pre-register')} />
            <StoreTabs as='authorities' />
            <Stack mb="2.5" mt="1.5" space={2}
                direction={{ base: "column", md: "row" }}
                mx={{ base: "auto", md: "0" }} >
                {storeRoles.map(([id, name]) =>
                    <Button size="sm" key={id}
                        variant={role === id ? 'solid' : 'outline'}
                        onPress={() => setRole(id)} >
                        {name}
                    </Button>
                )}
            </Stack>
            <DataTable tableData={{
                columns: columns(navigate), data,
                isLoading, pagination: true
            }} />
        </VStack>
    )
}

export default StoreAuthorities