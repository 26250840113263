import React from 'react'
import { VStack } from 'native-base'
import { Button } from '@chakra-ui/react';

const PreRegisterSession = () => {
    const { location: { hostname } } = window

    const url = (() => {
        const pathname = '/store/register?mode=preregister'
        return hostname === 'localhost' ?
            `http://localhost:5001${pathname}`
            :
            `https://app.offingo.com${pathname}`;
    })()

    return (
        <VStack>
            <Button onClick={() => window.open(url, '_blank')}
                variant='solid' maxW={200} colorScheme='red' >
                Pre-Register
            </Button>
        </VStack>
    )
}

export default PreRegisterSession