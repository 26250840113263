import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";

const getProfile = () => {
    const token = localStorage.getItem('token');
    if (!token) return { token: null, profile: null };

    const decodedJwt = (() => {
        try {
            return jwt_decode(token)
        }
        catch (e) {
            console.error(e)
            localStorage.clear()
            return { token: null, profile: null };
        }
    }
    )();

    if (!decodedJwt.exp || decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear()
        return { token: null, profile: null };
    }

    return {
        token,
        profile: JSON.parse(localStorage.getItem("profile"))
    }
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: { ...getProfile() },
    reducers: {
        login: (state, action) => {
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('profile', JSON.stringify(action.payload.profile));
            window.location.reload()
            state = { ...action.payload };
        },
        setProfile: (state, action) => {
            localStorage.setItem('profile', JSON.stringify(action.payload));
            state.profile = action.payload;
        },
        logout: (state) => {
            state = { token: null, role: null, id: null };
            localStorage.removeItem('token');
            localStorage.removeItem('profile');
            window.location.reload();
        },
    },
});

export const { login, logout, setProfile } = authSlice.actions;

export const selectToken = state => state.auth.token;
export const selectProfile = state => state.auth.profile;

export default authSlice.reducer;