import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { getCoupons } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import useParamManager from '../../hook/useParamManager'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'
import ExcelDownload from '../../components/ExcelDownload/ExcelDownload'

const Coupons = () => {
    const navigate = useNavigate()

    const { params, ParamSelector } = useParamManager({
        defaultParams: {
            paid: false,
            active: true,
            public: true,
            lottery: false,
            expired: false,
            today: false,
        },
        list: [
            { title: 'Active', id: 'active' },
            { title: 'Public', id: 'public' },
            { title: 'Paid', id: 'paid' },
            { title: 'Lottery', id: 'lottery' },
            { title: 'Expired', id: 'expired' },
            { title: 'Today', id: 'today' },
        ]
    })

    const { data, isLoading } = useFetch({
        name: 'getCoupons',
        service: getCoupons,
        params,
        conditionalRefetch: [params],
        pagination: true,
    })

    return (
        <VStack alignItems={'flex-start'} w="100%">
            <CompHeader title={'Coupons'} buttonTitle={'Create Coupons'}
                onPress={() => navigate('/admin/coupons/create')}
            />
            <ParamSelector />
            <ExcelDownload service={getCoupons} params={params} />
            <DataTable
                title={`${data?.total || ''} Coupons`}
                tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Coupons