import { Button } from 'native-base';
import { formatDateTimes } from '../../utils/moment';
import QRCode from "react-qr-code";
import { Text } from '@chakra-ui/react';

export const columns = (navigate) => [
    {
        name: 'QR',
        button: true,
        cell: (row) =>
            <QRCode
                id={row.qr}
                value={row.qr}
                onClick={() => navigate(`/admin/qr/edit/${row._id}`)}
                size={100}
                style={{ margin: 'auto', padding: 10 }}
            />
        ,
        sortable: false,
    },
    {
        name: 'Code',
        selector: (row) => row.code,
        sortable: true,
        width: '200px'
    },
    // {
    //     name: 'Store',
    //     selector: (row) => row.store?.store_name,
    //     sortable: true,
    //     width: '200px'
    // },
    {
        name: 'Link',
        selector: (row) => row.link,
        sortable: true,
        width: '200px'
    },
    {
        name: 'Short Link',
        selector: (row) => row?.qr,
        sortable: true,
        width: '200px'
    },
    {
        name: 'Active',
        button: true,
        cell: (row) => <Text
            color={row.active ? 'green.500' : 'red.500'}
        >
            {row.active ? 'Active' : 'Inactive'}
        </Text>,
        sortable: true,
    },
    {
        name: 'Updated at',
        selector: (row) => formatDateTimes(row.updatedAt, 4),
        sortable: true,
        width: '200px'
    },
    {
        name: 'Created at',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
        width: '200px'
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/qr/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]