import React from 'react'
import { Button, Text, VStack } from '@chakra-ui/react';
import Model from '../../../components/compo/UI/Model';
import StoreProductSelectionModel from './StoreProductSelectionModel';
import { colors } from '../../../theme/colors';

const StoreProductSelection = () => {
    const [open, setOpen] = React.useState(false)

    return (
        <VStack space='2' alignItems={'flex-start'}
            borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
            <Text fontSize={'md'} fontWeight='600'>Store Products</Text>

            <Button onClick={() => setOpen(true)} color='white' colorScheme="cyan" size="md" w={'50%'} >
                Update Store Products
            </Button>

            {open && <Model title='Update Store Products'
                size={'full'}
                close={() => setOpen(false)}
                body={<StoreProductSelectionModel close={() => setOpen(false)} />}
            />}
        </VStack>
    )
}

export default StoreProductSelection