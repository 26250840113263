import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IMAGE_BASE_URL, contProps } from '../../../helper/variables'
import { useNavigate } from 'react-router-dom'
import { Button, HStack, Input, Spacer, Text, VStack } from '@chakra-ui/react'
import { multipledataformater } from '../../../helper/multipledataformater'
import { createMarketArea, deleteMarketAreasById, editMarketAreasById, editMarketById } from '../../../apis/admin'
import SelectField from '../../../commons/SelectField'
import { InputField } from '../../../commons/InputField'
import useSubmit from '../../../hook/useSubmit'
import AreaImages from './AreaImages'

const defaultValues = {
    name: '', images: [''],
    geo_location: { coordinates: {} },
    status: 'Active', phone_number: '',
}
const staticData = { status: [["Active", "Verified"], ["Pending", "Not-Verified"]] }


const AddMarketArea = ({ data, market_id, refetch, close }) => {
    if (data) {
        data = {
            ...data,
            geo_location: {
                coordinates: {
                    latitude: data?.geo_location?.coordinates?.[0],
                    longitude: data?.geo_location?.coordinates?.[1],
                }
            }
        }
    }

    const form = useForm({ defaultValues: data || defaultValues })
    const [images, setImages] = useState(null)
    const state = form.watch()

    const navigate = useNavigate()

    React.useEffect(() => {
        if (!data) return
        setImages(images ? images : (data ? `${IMAGE_BASE_URL}${data.image}` : images))
    }, [data])

    const { submit, isLoading } = useSubmit({
        service: data ? editMarketAreasById : createMarketArea,
        id: data?._id,
        props: { market_id },
        onSuccess: () => {
            refetch()
            close()
        },
    })

    const Delete = useSubmit({
        service: deleteMarketAreasById,
        id: data?._id,
        props: { market_id },
        onSuccess: () => {
            refetch()
            close()
        },
        alert: true,
    })

    const list = [
        { title: 'Area id', id: '_id', disabled: true, show: !!data },
        { title: 'Area Name', id: 'name', autoCapatilize: true },
        { title: 'Phone Number', id: 'phone_number' },
        {
            title: 'Google Maps Coords', Comp:
                <VStack w="100%" alignItems="flex-start" >
                    <Text fontSize="sm" color="gray.500" >
                        Enter the coordinates in the format: latitude,longitude
                    </Text>
                    <Input
                        placeholder='latitude,longitude'
                        onChange={(e) => {
                            form.setValue('geo_location.coordinates', { latitude: e.target.value.split(',')[0], longitude: e.target.value.split(',')[1] })
                        }}
                    />
                </VStack>
        },
        { title: 'Latitude', id: 'geo_location.coordinates.latitude' },
        { title: 'Longtitude', id: 'geo_location.coordinates.longitude' },
        { title: 'Status', id: 'status', list: staticData.status.map(([value, label]) => ({ label, value })) },
    ]

    const save = () => {
        const state = { ...form.getValues() }

        const formdata = multipledataformater({
            state,
            images: state.images.filter(image => typeof image !== 'string' && image !== '')
        })

        submit(formdata)
    }

    return (
        <VStack {...contProps} px={6}>

            <AreaImages form={form} />

            {list.map((item, index) => {
                if (item?.Comp) {
                    return item.Comp
                }
                const Comp = item?.Comp || item.list ? SelectField : InputField
                return <Comp tw={120} key={index} form={form} {...item} />
            })}

            <HStack>
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={save}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update Market' : "Add Market"}</Button>
                <Spacer />
                {data && <Button m='auto'
                    disabled variant={'solid'}
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Market
                </Button>}
            </HStack>
        </VStack>
    )
}

export default AddMarketArea