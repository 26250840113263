import React from 'react'
import useFetch from '../../hook/useFetch'
import { VStack } from '@chakra-ui/react'
import { getuserJourney } from '../../apis/admin'
import { useNavigate, useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import usePaginationNav from '../../hook/usePaginationNav'
import Loading from '../../commons/Loading'
import JourneyCard from './JourneyCard'

const UserJourney = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: `getuserJourney_${id}`,
        service: getuserJourney,
        id
    })

    const journey = data?.journey
    const { currentData, Nav } = usePaginationNav({ data: journey, item_per_page: 100, defaultPage: 1 })

    if (isLoading) return <Loading />

    return (
        <VStack w={'100%'} alignItems={'flex-start'} p={4} spacing={4}>
            <CompHeader title='User Journey' backButton />
            <Nav />
            <VStack w='100%' spacing={0}>
                {currentData?.map((item, index) =>
                    <JourneyCard key={index} data={item} index={index} />
                )}
            </VStack>
            <Nav />
        </VStack>
    )
}

export default UserJourney