import { Button } from "native-base";
import { getAppLinks } from "../../../helper/applink";
import { formatDateTimes } from "../../../utils/moment";
import { capitalize } from "../../../helper/stringOperations";
import { Text } from "@chakra-ui/react";

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/reviewStore/${row?.store?._id}`)}
        >
            Review</Button>,
        sortable: false,
    },
    {
        name: 'Name',
        selector: (row) => row?.store?.store_name,
        sortable: true,
    },
    {
        name: 'Type',
        selector: (row) => row?.store?.type,
        sortable: true,
    },
    {
        name: 'Date Registered',
        selector: (row) => formatDateTimes(row?.store?.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Actions',
        width: '250px',
        button: true,
        cell: (row) => <Text flex={1}>
            <Text as='span' color={row?.store?.verified ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Verified-{row?.store?.verified ? 'Y' : 'N'}/
            </Text>
            <Text as='span' color={row?.store?.claimed ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Claimed-{row?.store?.claimed ? 'Y' : 'N'}/
            </Text>
            <Text as='span' color={row?.store?.pre_register ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Pre-Register-{row?.store?.pre_register ? 'Y' : 'N'}
            </Text>
        </Text>,
        sortable: false,
    },
    {
        name: 'Points',
        selector: (row) => row?.store?.points,
        sortable: true,
    },
    // {
    //     name: 'State',
    //     selector: (row) => row?.store?.store_address.state?.name,
    //     sortable: true,
    // },
    // {
    //     name: 'City',
    //     selector: (row) => row?.store?.store_address.city?.name,
    //     sortable: true,
    // },
    // {
    //     name: 'category',
    //     selector: (row) => row?.store?.type,
    //     sortable: true,
    // },
    // {
    //     name: 'Chain',
    //     selector: (row) => row?.store?.store_address.chain?.name ? `${row?.store?.store_address.chain?.name}(${row?.store?.store_address.chain.type === 'Primary' ? "Lg" : "Sm"})` : '--',
    //     sortable: true,
    // },
] 