import React from 'react'
import { Box, HStack, Text, VStack } from 'native-base'
import { useForm } from 'react-hook-form'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { editglobalvars, getglobalvars } from '../../apis/admin'
import EditInputCard from '../Stores/ReviewStore/EditInputCard'
import GlobalVarUpdater from './GlobalVarUpdater'

const list_basic = [
    // ['label','target','options']
    ['CBS'],
    ['Enquiry validity(days)', 'ENQUIRY_VALIDITY_IN_DAYS'],
    ['Stores per enquiry', 'STORES_PER_ENQUIRY'],

    // ['Cron'],
    // ['Default exp', 'DEFAULT_CRON_EXP'],
    // ['First Round exp', 'FIRST_CRON_EXP'],
    // ['Second Round exp', 'SECOUND_CRON_EXP'],

    // ['Miles'],
    // ['First round miles', 'FIRST_ROUND_MILES'],
    // ['Second round miles', 'SECOND_ROUND_MILES'],
    // ['Third round miles', 'THIRD_ROUND_MILES'],
    // ['Max round miles', 'MAX_ROUND_MILES'],
    // ['Max rounds', 'MAX_ROUND'],

    ['Response'],
    ['Response Threshold', 'RESPONSE_THRESHOLD'],
    ['Max Response Threshold', 'MAX_RESPONSE_THRESHOLD'],

];

const GlobalVars = () => {
    const form = useForm({ mode: 'onBlur' })
    const form2 = useForm({ mode: 'onBlur' })

    useFetch({
        name: 'getglobalvars',
        service: getglobalvars,
        onSuccess: (data) => {
            if (!data) return;
            for (const [id, value] of Object.entries(data)) {
                form.setValue(id, value, { shouldValidate: true, shouldDirty: true })
                form2.setValue(id, value, { shouldValidate: true, shouldDirty: true })
            }
        }
    })

    const { submit } = useSubmit({
        service: editglobalvars,
    })

    const save = () => submit({ ...form2.getValues() })

    return (
        <VStack space='4'>
            <CompHeader title='Global Variables'
                buttonTitle='Save' onPress={save} mb='0' />
            <HStack space='4' flexWrap={'wrap'}>
                {list_basic.map(([label, target, options, hide]) =>
                    !hide ?
                        target ?
                            <Box py='2' w='250'>
                                <EditInputCard key={target} label={label}
                                    target={target} form={form}
                                    form2={form2} options={options} />
                            </Box>
                            :
                            <Text mt='4' w='full' fontSize={'md'} fontWeight='semibold'>{label}</Text>
                        : null
                )}
            </HStack>
            <GlobalVarUpdater type={'age'} />
            <GlobalVarUpdater type={'size'} />
        </VStack>
    )
}

export default GlobalVars