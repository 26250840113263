import React, { useEffect, useMemo } from 'react'
import { Button, HStack, Text, VStack } from 'native-base'
import { InputField } from '../../commons/InputField'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { editTitleKeysById, getTitleKeysById, deleteTitleKeysById } from '../../apis/admin'
import useSubmit from '../../hook/useSubmit'
import SelectField from '../../commons/SelectField'
import { colors } from '../../theme/colors'
import StringDivider from '../../commons/StringDivider'
import { typesList } from './static'
import AlertAction from '../../commons/UI/AlertAction'
import { Input } from '@chakra-ui/react'

const EditTitleKey = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [show, setShow] = React.useState(false)

    const form = useForm({ defaultValues: { title: '', keys: [], active: '', required: '', type: '' } })
    const { keys, title } = form.getValues()

    const { data, isLoading } = useFetch({ name: 'titleKeysByID', service: getTitleKeysById, id })
    const { submit } = useSubmit({ service: editTitleKeysById, id, onSuccess: () => navigate(-1) })
    const Delete = useSubmit({ service: deleteTitleKeysById, id, onSuccess: () => navigate(-1) })

    useEffect(() => {
        if (!(data?.title && data?.keys)) {
            return;
        }
        form.setValue('title', data.title)
        form.setValue('active', data.active?.toString())
        form.setValue('required', data.required?.toString())
        form.setValue('type', data.type)
        form.setValue('keys', data.keys)
    }, [data])

    useMemo(() => { }, [form.watch()])

    const save = () => {
        const state = form.getValues()
        const keys = state.keys.filter(key => key?._id || key !== '')
        const updateKeys = keys?.filter(key => key?.new_label)?.map(key => ({ _id: key._id, label: key.new_label }))
        const newKeys = keys?.filter(key => typeof key === 'string')
        const removedKeys = keys.filter(key => key?.deleted)?.filter(key => key?._id)?.map(key => key._id)

        const res = {
            updateKeys,
            removedKeys,
            newKeys,
            title: state.title,
            active: state.active,
            required: state.required,
            type: state.type
        }

        submit(res)
    }

    const remove = () => { Delete.submit() }

    if (isLoading) return <Loading />

    return (
        <VStack space='4'>
            <CompHeader title='Edit Title Key' backButton />

            <VStack w='800' space='2'>
                <HStack alignItems={'center'} space='2'>
                    <InputField form={form} title='Title' id='title' />
                </HStack>

                <SelectField form={form} list={typesList} title='Type' id='type' />
                <SelectField form={form} list={[true, false].map(value => ({ label: value.toString(), value: value.toString() }))} title='Required' id='required' />

                <StringDivider
                    value={keys}
                    onChange={val => {
                        const keys = [
                            ...form.watch('keys').filter(key => key?._id),
                            ...val
                        ]
                        form.setValue('keys', keys)
                    }} />

                <Text fontSize={'md'} fontWeight={'semibold'}>Keys</Text>

                <VStack space='2'>
                    {form.getValues().keys?.map((item, index) => (
                        <HStack space='2' alignItems={'center'} key={index}>
                            <InputField disabled={item?._id}
                                form={form} title={`Key ${index + 1}`}
                                RenderInput={
                                    <VStack flex={1} w='100%'>
                                        <Input flex={1} w='100%'
                                            py={2}
                                            borderWidth={0}
                                            outline={0}
                                            bg={item?.deleted ? "gray" : 'white'}
                                            disabled={item?.deleted}
                                            value={item?.new_label || item?.label || item}
                                            onChange={e => {
                                                const { keys } = form.getValues()
                                                if (item?._id) {
                                                    if (e.target.value === keys[index]?.label) {
                                                        delete keys[index].new_label
                                                    } else {
                                                        keys[index].new_label = e.target.value
                                                    }
                                                }
                                                else
                                                    keys[index] = e.target.value
                                                form.setValue('keys', keys)
                                            }}
                                        />
                                        {keys[index].new_label ?
                                            <HStack alignItems={'center'} space={4}>
                                                <Text>
                                                    Last Value :{keys[index].label}
                                                </Text>
                                                <Text
                                                    color={colors.info}
                                                    onPress={
                                                        () => {
                                                            const { keys } = form.getValues()
                                                            delete keys[index].new_label
                                                            form.setValue('keys', keys)
                                                        }
                                                    }
                                                >
                                                    reset
                                                </Text>
                                            </HStack> : <></>
                                        }
                                    </VStack>
                                }
                            />
                            <Button variant={'solid'} borderRadius='md'
                                bg='red.400' alignItems={'center'}
                                h='6' justifyContent={'center'}
                                onPress={() => {
                                    const { keys } = form.getValues()
                                    if (item?._id) {
                                        keys[index].deleted = item?.deleted ? false : true
                                    } else {
                                        keys.splice(index, 1)
                                    }
                                    form.setValue('keys', keys)
                                }}>
                                <Text color='white'>
                                    {item?._id ? (item?.deleted ? 'Undo' : 'Delete') : 'Remove'}
                                </Text>
                            </Button>
                        </HStack>
                    ))}
                    <Button w='fit-content' alignSelf={'flex-end'}
                        size='sm' variant={'link'}
                        onPress={() => {
                            const { keys } = form.getValues()
                            keys.push('')
                            form.setValue('keys', keys)
                        }}>
                        Add More
                    </Button>
                </VStack>

                <HStack space={'4'}>
                    <Button w={'150'} alignSelf={'center'} size='sm'
                        onPress={save}>update</Button>

                    <Button w={'150'} alignSelf={'center'} size='sm'
                        bg={'red.400'}
                        onPress={() => setShow(true)}>Delete</Button>

                    <AlertAction
                        ok={remove}
                        show={show}
                        close={() => setShow(false)}
                        // isLoading={isLoading}
                        title={'Are you sure you want to remove this Filter?'}
                        okText={'Remove'}
                        confirmText={title}
                    />
                </HStack>
            </VStack>

        </VStack>
    )
}

export default EditTitleKey