import React, { useRef, useState } from 'react'
import { Box, Button, CheckIcon, HStack, Select, Spacer, Stack, Text, VStack } from 'native-base'
import { useForm } from 'react-hook-form'
import { InputField } from '../../commons/InputField'
import { MdCancel } from 'react-icons/md'
import { IMAGE_BASE_URL } from '../../helper/variables'
import box from "./box.svg";
import { FiCamera } from 'react-icons/fi'
import imageCompression from 'browser-image-compression'
import { useNavigate, useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { deletegender, editgenderbyid, getgenderbyid } from '../../apis/admin'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import { colors } from '../../theme/colors'

const compressionOptions = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true, }

const EditGender = () => {
    const form = useForm({ defaultValues: { type: '', active: 'active', image: null, order: '' } })
    const { id } = useParams()

    useFetch({
        name: `gender_${id}`,
        service: getgenderbyid,
        id,
        onSuccess: (data) => {
            form.setValue('order', data.order)
            form.setValue('type', data.type)
            form.setValue('active', data.active ? 'active' : 'passive')
            setImage(`${IMAGE_BASE_URL}${data.logo}`)
        }
    })

    const [image, setImage] = useState(null)
    const navigate = useNavigate()

    const ref = useRef(null);
    const uploadImage = async (e) => {
        const { files } = e.target;
        let _file = null
        const image = files[0]
        await imageCompression(image, compressionOptions)
            .then(function (_res) { _file = new File([_res], _res.name) })
            .catch(function (error) { console.error(error.message) })
        form.setValue('image', _file)
        const url = URL.createObjectURL(_file);
        setImage(url)
    };

    const removeImage = () => {
        setImage(null)
        ref.current.click()
        form.setValue('image', null)
    }


    const { submit, isLoading } = useSubmit({
        service: editgenderbyid,
        onSuccess: () => navigate(-1),
        id
    })

    const Delete = useSubmit({
        service: deletegender,
        id,
        onSuccess: () => navigate(-1),
        alert: true,
    })


    const create = () => {
        const state = { ...form.getValues() }
        state.active = state.active === 'active'

        const formdata = new FormData();

        formdata.append("type", state.type)
        formdata.append("active", state.active)
        formdata.append("images", state.image)
        formdata.append("order", state.order)

        submit(formdata)
    }

    return (
        <VStack space='4' w='100%' maxW='400'>
            <CompHeader title='Edit Gender' backButton />

            <VStack space='2'>

                <InputField form={form} title={'Label'} autoCapatilize id={`type`} />
                <InputField form={form} title={'Order'} autoCapatilize id={`order`} />

                <HStack alignItems={'center'} space='2'>
                    <Text fontWeight='600' w='100'>Status</Text>
                    <Text fontWeight='600' >:</Text>
                    <Select selectedValue={form.watch('active')} minWidth="200"
                        accessibilityLabel="Choose Gender" placeholder="Select Status" _selectedItem={{
                            bg: "teal.600",
                            endIcon: <CheckIcon size="5" />
                        }} mt={1} onValueChange={val => form.setValue('active', val)}>
                        <Select.Item label={"Active"} value={'active'} key={'active'} />
                        <Select.Item label={'Passive'} value={'passive'} key={'passive'} />
                    </Select>
                </HStack>

                {image
                    ?
                    <Box
                        style={{
                            position: "relative",
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            height: "200px",
                            width: "250px",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <MdCancel
                            onClick={removeImage}
                            style={{ position: "absolute", right: 10, top: 10 }}
                        />
                    </Box>
                    :
                    <>
                        <input
                            type="file"
                            onChange={(e) => {
                                uploadImage(e);
                                e.target.value = null;
                            }}
                            ref={ref}
                            style={{ display: "none" }}
                        />
                        <Stack
                            cursor={"pointer"}
                            onClick={() => ref.current.click()}
                            h={"150px"}
                            w={"150px"}
                            alignItems="center"
                            justifyContent={"center"}
                            backgroundImage={`url(${box})`}
                            backgroundSize={"cover"}
                        >
                            <FiCamera color={colors.secondary} />
                            <Text color={colors.secondary} fontWeight={500}>
                                Upload Images
                            </Text>
                        </Stack>
                    </>
                }
                <HStack alignItems={'center'}>
                    <Button size='sm' isLoading={isLoading} onPress={create} >
                        Update
                    </Button>
                    <Spacer />
                    <Button w='200' m='auto'
                        colorScheme={'red'}
                        disabled
                        isLoading={Delete.isLoading}
                        onPress={Delete.submit} size='sm' >
                        Delete
                    </Button>

                </HStack>


            </VStack>

        </VStack>
    )
}

export default EditGender