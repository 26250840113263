import FilterCard from './FilterCard'
import { useForm } from 'react-hook-form'
import useFetch from '../../hook/useFetch'
import { Button } from '@chakra-ui/react';
import { colors } from '../../theme/colors'
import Loading from '../../commons/Loading'
import useSubmit from '../../hook/useSubmit'
import { useParams, useSearchParams } from 'react-router-dom'
import { SimpleGrid } from '@chakra-ui/react'
import { Divider, Text, VStack } from 'native-base'
import { filtersToCloudDS, filtersToLocalDS } from './action'
import CompHeader from '../../commons/CompHeader/CompHeader'
import React, { useEffect, useMemo, useState } from 'react'
import { editProductFiltersById, getParametricByProduct, getProductById, getProductFiltersById, getProductsWearsById, getTitleKeys, } from '../../apis/admin'

const EditProductFilter = ({ _gender, _wear, _id }) => {
    let { id, name } = useParams()
    if (!id) id = _id
    const [searchParams] = useSearchParams()
    if (!name)
        name = searchParams.get('name')
    const form = useForm({ defaultValues: { keys: [], gender: _gender || '', wear: _wear || '' } })
    const { gender, wear, keys } = form.getValues()
    const [selected, setSelected] = useState(null)

    const product = useFetch({ name: 'getProduct', service: getProductById, id, })
    const parametric = useFetch({ name: 'getParametricByProduct', service: getParametricByProduct, id, })
    const filters = useFetch({ name: 'getTitleKeys', service: getTitleKeys, dataOnly: true, params: { limit: 100 } })

    const wears = useFetch({
        name: 'getWears', id, service: getProductsWearsById,
        params: { populate: 'true' }, dataOnly: true
    })

    const genders = wears.map(({ gender }) => gender)
    const { data, isLoading } = useFetch({ name: `product_filters_${id}`, service: getProductFiltersById, id })
    const MapProduct = useSubmit({ service: editProductFiltersById, id, })

    useEffect(() => {
        if (isLoading) return
        form.setValue('keys', filtersToLocalDS(data, wear))
        if (!selected) setSelected(filters?.[0]?._id)
    }, [isLoading, filters, wear])

    const save = () => {
        MapProduct.submit({ filters: filtersToCloudDS(keys), wear })
    }

    const activedoc = data.find(item => item.wear === wear && item.gender === gender)
    useEffect(() => { !_gender && form.setValue('wear', '') }, [gender])

    const Compo = useMemo(() => {
        return <VStack space={'2'} divider={<Divider />} >
            {filters?.filter(item => selected === item._id).map(filter =>
                <FilterCard form={form} data={filter} key={filter._id}
                    genders={genders} refetchParametric={parametric.refetch}
                    parametric={parametric.data.find(item => item.titleKey === filter._id)}
                    product={product} wears={wears}
                />
            )}
        </VStack>
    }, [form.watch(), selected])

    if (isLoading) return <Loading />

    return (
        <VStack w={`100%`} space='1'>
            <CompHeader show={!_gender} title={`Filter Selection: ${name}`} backButton />
            {!_gender &&
                <>
                    <Text fontSize={'md'} fontWeight='semibold'>Genders</Text>
                    <SimpleGrid columns={[3, 8]} spacing='2'>
                        {genders?.map(({ type, _id }) =>
                            <Button variant={gender === _id ? 'solid' : 'outline'}
                                colorScheme={'blue'} onClick={() => form.setValue('gender', _id)}
                            > {type}
                            </Button>
                        )}
                    </SimpleGrid>
                </>
            }

            {(!_wear && gender) &&
                <>
                    <Text fontSize={'md'} fontWeight='semibold'>Wears</Text>
                    <SimpleGrid columns={[3, 7]} spacing='2'>
                        {wears?.find(item => item.gender._id === gender)?.wears.map(item =>
                            <Button
                                variant={wear === item._id ? 'solid' : 'outline'}
                                colorScheme={'blue'} onClick={() => form.setValue('wear', item._id)}>
                                {item.wear}
                            </Button>
                        )}
                    </SimpleGrid>
                    <Text color={colors.error} fontWeight='bold'>Save Before changing wears</Text>
                </>
            }

            {gender && wear &&
                <>
                    <Text fontSize={'md'} fontWeight='semibold'>Filters</Text>
                    <SimpleGrid columns={[3, 7]} spacing='2' mb='2'>
                        {filters?.map(filter => {
                            const doc = activedoc?.filters?.find(({ title }) => title === filter._id)?.keys?.length
                            return <Button
                                variant={selected === filter._id ? 'solid' : 'outline'}
                                colorScheme={doc ? 'green' : "blue"}
                                onClick={() => selected === filter._id ?
                                    setSelected(selected.find(item => item !== filter._id))
                                    : setSelected(filter._id)}>
                                {filter.title}{doc ? `(${doc})` : ''}
                            </Button>
                        })}
                    </SimpleGrid>
                    {Compo}
                </>
            }

            <Button mt='2' w={200} variant={'solid'}
                colorScheme='cyan' onClick={save}
                textColor='white'
                size='sm' isLoading={MapProduct.isLoading}
            >
                Save
            </Button>
        </VStack>
    )
}

export default EditProductFilter