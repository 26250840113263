import React from 'react'
import { Divider, VStack } from '@chakra-ui/react'
import InitialCall from './InitialCall';
import AllCalls from './AllCalls';

const UserCalls = ({ user, onSuccess }) => {
    const [refresh, setRefresh] = React.useState(false)
    return (
        <VStack alignItems={'flex-start'}>
            <InitialCall user={user} setRefresh={setRefresh} onSuccess={onSuccess} />
            <Divider borderWidth={2} />
            <AllCalls user={user} refresh={refresh} />
        </VStack>
    )
}

export default UserCalls