import { VStack } from 'native-base'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getbrands } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import useFetch from '../../hook/useFetch'
import { columns } from './static'

const Brands = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'brands', service: getbrands, pagination: true })

    return (
        <VStack>
            <CompHeader title='Brands'
                buttonTitle='Create New Brand'
                onPress={() => navigate(`/admin/brands/create`)} />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Brands