
import { extendTheme as chakraExtendTheme } from "@chakra-ui/react";
import { extendTheme } from "native-base";

export const chakraTheme = chakraExtendTheme({
    fonts: {
        heading: "Signika Negative",
        body: "Signika Negative",
    },
    colors: {
        red: {
            100: "#FE3D3E",
            900: "#FE3D3E",
        },
    },
    Element: {
        HStack: {
            baseStyle: {
                alignItems: 'flex-start'
            }
        },
        VStack: {
            baseStyle: {
                alignItems: 'flex-start'
            },
        },
    }
});

export const nativeTheme = extendTheme({
    fonts: {
        heading: "Signika Negative",
        body: "Signika Negative",
    },
});

export const reactTostify = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    rtl: false,
    limit: 3,
    draggable: false,
    pauseOnHover: false,
    closeOnClick: true,
    pauseOnFocusLoss: true,
}