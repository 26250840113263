import { HStack, Spacer, VStack } from 'native-base'
import { createchains, deleteChain, editchainsbyid } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader';
import React, { useEffect, useRef, useState } from 'react'
import { IMAGE_BASE_URL } from '../../helper/variables'
import { InputField } from '../../commons/InputField'
import SelectField from '../../commons/SelectField'
import { useNavigate } from 'react-router-dom'
import { getCity, getStates } from '../../apis/misc'
import useSubmit from '../../hook/useSubmit'
import { useForm } from 'react-hook-form'
import useFetch from '../../hook/useFetch';
import CropArea from '../../components/compo/CropArea/CropArea.js';
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from '@chakra-ui/react';
import LinkedStoresList from '../../components/LinkedStoresList/LinkedStoresList';

const defaultValues = { name: '', logo: '', company: '', status: '', type: '', state: '', city: "" }
const staticData = { status: ["Active", "Passive", "Pending"], type: ["Primary", "Secondary"] }

const CreateChain = ({ data }) => {
    const form = useForm({ defaultValues })
    const [image, setImage] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (!data) return
        Object.keys(data).forEach(key => {
            form.setValue(key, data[key])
            setImage(`${IMAGE_BASE_URL}${data.logo}`)
        })
    }, [data])

    const { submit, isLoading } = useSubmit({
        service: data ? editchainsbyid : createchains,
        id: data?._id,
        onSuccess: () => navigate(-1),
    })

    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, })

    const cities = useFetch({
        name: `cities_${form.getValues()?.state}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: form.getValues()?.state,
        id: form.getValues()?.state,
        condition: !!form.getValues()?.state
    })

    const Delete = useSubmit({
        service: deleteChain,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const add = () => {
        const formdata = new FormData();
        const state = { ...form.getValues() }
        Object.keys(state).map((obj) => {
            if (Array.isArray(state[obj])) {
                state[obj].map((item) => {
                    if (typeof item === "object") {
                        return formdata.append(obj, JSON.stringify(item));
                    }
                    formdata.append(obj, item);
                });
            } else if (!Array.isArray(state[obj]) && typeof state[obj] === "object") {
                formdata.append(obj, JSON.stringify(state[obj]));
            }
            if (typeof state[obj] === "string") {
                formdata.append(obj, state[obj]);
            }
        });
        formdata.append("images", state.logo);
        submit(formdata)
    }

    return (
        <VStack p='3' space='2' w={'100%'} >
            {!data && <CompHeader title='Create New Chain' backButton />}

            <CropArea
                // circularCrop 
                defaultImage={image}
                removeImage={() => { setImage(null); form.setValue('logo', null) }}
                setImage={(file) => form.setValue('logo', file)}
            />

            <InputField form={form} title='Chain Name' id='name' autoCapatilize />
            <InputField form={form} title='Company' id='company' />
            <SelectField form={form} list={staticData.status.map(item => ({ label: item, value: item }))} title='Status' id='status' />
            <SelectField form={form} list={staticData.type.map(item => ({ label: item, value: item }))} title='Type' id='type' />

            {form.getValues().type === 'Secondary' &&
                <>
                    <SelectField form={form} title='State' id='state'
                        list={states.map(state => ({ label: state.name, value: state._id }))}
                    />
                    <SelectField form={form} title='City' id='city'
                        list={cities.map(state => ({ label: state.name, value: state._id }))}
                    />
                </>
            }
            {data && <LinkedStoresList data={data?.stores} type='chain' />}

            <HStack alignItems={'center'} mt='2'>
                <Button backgroundColor={'blue.400'}
                    onClick={add} size='sm' isLoading={isLoading}>
                    {data ? 'Update Chain' : 'Create Chain'}
                </Button>
                <Spacer />
                {data && <Button m='auto'
                    disabled={data?.stores?.length > 0}
                    variant={'solid'}
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Chain
                </Button>}
            </HStack>
        </VStack>
    )
}

export default CreateChain