import { io } from 'socket.io-client'
import { baseurl } from '../apis/url'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectProfile } from '../redux/authSlice'

const useSocket = () => {
    const user = useSelector(selectProfile)

    useEffect(() => {
        const socket = io(baseurl.split('/api/v1')[0])
        const join_offingo = { app: "admin_panel", user: user?._id }
        socket.emit('join', join_offingo)
    }, [])

    return <></>
}

export default useSocket 