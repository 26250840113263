import { Button, Image } from 'native-base';
import { IMAGE_BASE_URL } from '../../helper/variables';
export const columns = (navigate) => [
    {
        name: 'Order',
        selector: (row) => row.order,
        sortable: true,
    },
    {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
    },
    {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
    },
    {
        name: 'Logo',
        // selector: (row) => row.type,
        cell: (row) => <Image cursor='pointer' src={IMAGE_BASE_URL + row.logo} h='50' w='50' />,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/genders/edit/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
]