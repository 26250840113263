import React from 'react'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../../hook/useFetch'
import Loading from '../../../commons/Loading'
import AddMarket from '../AddMarket/AddMarket'
import { useParams } from 'react-router-dom';
import { getMarketById } from '../../../apis/admin'
import CompHeader from '../../../commons/CompHeader/CompHeader'

const EditMarket = () => {
    const { id } = useParams()
    const { data, isLoading, refetch } = useFetch({
        name: `market_${id}`,
        service: getMarketById,
        id
    })

    if (isLoading) return <Loading />
    console.log(data)
    return (
        <VStack p='3' space='2' w={'100%'} >
            <CompHeader title='Edit Mall' backButton />
            {data && <AddMarket refetch={refetch} data={{
                ...data,
                is_top: data?.is_top ? 'true' : 'false',
            }} />}
        </VStack>
    )
}

export default EditMarket