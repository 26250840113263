import { HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { Box } from '@chakra-ui/react';

const Tile = ({ label, value = '', color, titleWidth = '150', fw = 'bold', autoHide, valueComponent, ...other }) => {
    const hex = color ? value?.split('#')?.[1] : null

    if (typeof value === 'number' && value === 0) value = '0'

    return autoHide && !value ? null :
        <HStack alignItems='center' {...other} >
            <Text fontSize="md" fontWeight={fw} w={titleWidth}>{label}</Text>
            <Text fontSize="md" fontWeight={fw} >:</Text>
            {valueComponent ||
                <Text fontSize="md" fontWeight='500' ml='2'>{value}</Text>}
            {hex && <Box h='5' w='5' borderRadius='full' bg={`#${hex}`}></Box>}
        </HStack>
}

export default Tile