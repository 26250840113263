import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import SendMessageToUsers from './SendMessageToUsers'
import SendMessageToStores from './SendMessageToStores'

const Services = () => {
    const [active, setActive] = useState(0)


    const list = [
        {
            title: "Send Message to Users",
            Comp: <SendMessageToUsers />
        },
        {
            title: "Send Message to Stores",
            Comp: <SendMessageToStores />
        }
    ]

    return (
        <Accordion defaultIndex={active} onChange={(index) => setActive(index)} allowMultiple
            width='100%' flex={1} alignItems={'flex-start'} >
            {list.map((item, index) => {
                return (
                    <AccordionItem bg={'white'} key={index} width='100%' flex={1} alignItems={'flex-start'} >
                        <h2>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left' >
                                    <Text fontSize="lg" fontWeight="bold" color="black">
                                        {item.title}
                                    </Text>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            {item.Comp}
                        </AccordionPanel>
                    </AccordionItem>
                )
            })
            }

        </Accordion>
    )
}

export default Services