import { HStack, Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../helper/url'
import { colors } from '../../theme/colors'

const list = [
    ['All', '/admin/CBS', 'all'],
    ['Active', '/admin/CBS?status=active', 'active'],
    ['Pause', '/admin/CBS?status=pause', 'pause'],
    ['Closed', '/admin/CBS?status=closed', 'closed'],
    ['Failed', '/admin/CBS?status=failed', 'failed'],
]

const CBSTabs = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const status = getURLQuery(search, 'status') || 'all';

    return (
        <HStack alignItems='center' justifyContent='space-between' mb='5'>
            <HStack alignItems='center' justifyContent='space-between' space='4'>
                {list.map(([name, link, active]) =>
                    <Text key={link} fontWeight='600' fontSize='lg'
                        color={status === active ? colors.dark : colors.secondary}
                        onPress={() => navigate(link)}>{name}</Text>
                )}
            </HStack>
            {/* <Button size='sm' background='blue.400' onPress={() => navigate('/admin/pre-register')}> Add New Store </Button> */}
        </HStack>
    )
}

export default CBSTabs