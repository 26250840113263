import React, { useEffect } from 'react'
import { Text, VStack } from 'native-base'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteuser, getuserbyid } from '../../apis/admin'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import CompHeader from '../../commons/CompHeader/CompHeader';
import SelectField from '../../commons/SelectField'
import { InputField } from '../../commons/InputField'
import { useForm } from 'react-hook-form'
import { formatDateTimes } from '../../utils/moment'
import { Button } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit'

const EditUsers = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: `getuserbyid_${id}`,
        service: getuserbyid,
        id
    })

    const DELETE = useSubmit({
        name: 'deleteuser',
        service: deleteuser,
        id,
        onSuccess: () => navigate(-1)
    })

    const form = useForm({})

    useEffect(() => {
        if (data) form.reset(data)
    }, [data])

    const removeUser = () => {
        DELETE.submit(id)
    }

    const list = [
        { title: "Joined", id: 'createdAt', disabled: true, valueDresser: (val) => formatDateTimes(val, 5) },
        { title: "Name", id: 'name', disabled: true },
        { title: "Platform", id: 'device_tokens', disabled: true, valueDresser: (val) => val?.map(({ platform }) => platform).join(', ') },
        { title: "Last Activity", id: 'updatedAt', disabled: true },
        { title: "Subscription", id: 'subscription.sub_id.name', disabled: true },
        { title: "State", id: 'state.name', disabled: true },
        { title: "City", id: 'city.name', disabled: true },
        { title: "Gender", id: 'gender.type', disabled: true },
        { title: "DOB", id: 'date_of_birth', disabled: true, valueDresser: (val) => formatDateTimes(val, 5) },
        { title: "Phone No.", id: 'phone_number', disabled: true },
        { title: "Actions", id: '', disabled: true }
    ]

    return (
        <VStack p='3' space='2' w={'100%'} maxW={500}>
            <CompHeader title='User' backButton />
            {isLoading ? <Loading /> :
                <VStack space={2}>
                    {list.map((item, index) => {
                        const Comp = item.list ? SelectField : InputField
                        return <Comp tw={90} key={index} form={form} {...item} />
                    })}

                    <Button colorScheme='red' mt={10}
                        onClick={removeUser}
                        isLoading={DELETE.isLoading}
                    >
                        Remove user
                    </Button>
                </VStack>
            }
        </VStack>
    )
}

export default EditUsers