import { getbrands, getchains, getmallsByCity } from '../../apis/admin';
import { Checkbox, Text, VStack } from '@chakra-ui/react';
import { getCity, getStates } from '../../apis/misc';
import SelectField from '../../commons/SelectField';
import React, { useEffect } from 'react';
import useFetch from '../../hook/useFetch';
import { offerTypes, offerTypesChain, storeTypes } from './static';

const FilterSelection = ({ form, store_id }) => {
    const state = { ...form.getValues() }
    const { type, city, pre_register, claimed } = state

    const brands = useFetch({ name: `brands_${store_id}`, service: getbrands, dataOnly: true, converterLabel: 'brand', sort: true, params: { limit: 0, store_id } })
    const chains = useFetch({ name: 'chains', service: getchains, dataOnly: true, converterLabel: 'name', params: { limit: 0 } })
    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name', params: { limit: 0 } })

    const state_id = state?.state?.[0]?.value
    const cities = useFetch({
        name: `cities_${state_id}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: state_id,
        id: state_id,
        condition: !!state_id,
        converterLabel: 'name'
    })

    const city_id = city?.[0]?.value
    const malls = useFetch({
        name: `malls_${city_id}`,
        service: getmallsByCity, dataOnly: true,
        conditionalRefetch: city_id,
        id: city_id,
        condition: !!city_id,
        converterLabel: 'mall_name'
    })

    useEffect(() => {
        if (type === 'exclusive') {
            form.setValue('chain', '')
            form.setValue('offer_type', '')
        }
        if (city?.length > 1) form.setValue('mall', '')
        if (state.state?.length > 1) {
            form.setValue('city', '')
            form.setValue('mall', '')
        }
    }, [form.watch('type'), form.watch('state'), form.watch('city')])

    const list = [
        { visiblity: true, title: 'Brand', id: 'brand', list: brands },
        { visiblity: store_id ? false : true, title: 'Store Type', id: 'type', list: storeTypes },
        { visiblity: store_id ? false : true, title: 'State', id: 'state', list: states, multi: true },
        { visiblity: store_id ? false : state.state?.length === 1, title: 'City', id: 'city', list: cities, multi: true },
        { visiblity: store_id ? false : city?.length === 1, title: 'Mall', id: 'mall', list: malls },
        { visiblity: store_id ? false : type, title: 'Offer Type', id: 'offer_type', list: type === 'exclusive' ? offerTypes : offerTypesChain },
        { visiblity: store_id ? false : type && type !== 'exclusive', title: 'Chain', id: 'chain', list: chains },
    ]

    return (
        <VStack w='100%' alignItems={'flex-start'}>
            {list.map(item => item.visiblity &&
                <SelectField tw={100} w='100%' form={form} {...item} />
            )}
            {store_id ?
                <></> : <>
                    <Checkbox isChecked={claimed} onChange={() => form.setValue('claimed', !claimed)}>
                        <Text>Apply on Claimed/Registered Stores</Text>
                    </Checkbox>
                    <Checkbox isChecked={pre_register} onChange={() => form.setValue('pre_register', !pre_register)}>
                        <Text>Apply on Pre-Register(but not claimed) Stores</Text>
                    </Checkbox>
                </>
            }
        </VStack>
    )
}

export default FilterSelection