import React from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { formatDateTimes } from '../../../utils/moment'
import { useParams } from 'react-router-dom'

const ModCallCard2 = ({ data, index }) => {
    const { id } = useParams()
    return (
        <VStack w='100%' bg='white' p={2} borderRadius={10}
            alignItems={'flex-start'} borderWidth={2} borderColor={'gray.200'} >
            <HStack>
                <Text>
                    {index + 1}.
                </Text>
                <HStack>
                    <Text fontWeight={'semibold'}>
                        Name:
                    </Text>
                    <Text>
                        {data?.user?.name}
                    </Text>
                </HStack>
                <HStack>
                    <Text fontWeight={'semibold'}>
                        Phone Number:
                    </Text>
                    <Text>
                        {data?.user?.phone_number}
                    </Text>
                </HStack>
            </HStack>
            <VStack w='100%' bg={'gray.100'} p={1} alignItems={'flex-start'} borderRadius={'lg'} >
                <HStack w='100%' justifyContent={'space-between'}>
                    <HStack flex={1}>
                        <Text fontWeight={'semibold'}>
                            Purpose:
                        </Text>
                        <Text>
                            {data?.purpose || '--'}
                        </Text>
                    </HStack>
                    <HStack flex={1}>
                        <Text fontWeight={'semibold'}>
                            Comment:
                        </Text>
                        <Text>
                            {data?.comment || '--'}
                        </Text>
                    </HStack>
                    <HStack flex={1}>
                        <Text fontWeight={'semibold'}>
                            Date/Time:
                        </Text>
                        <Text>
                            {formatDateTimes(data?.createdAt, 2)}/ {formatDateTimes(data?.createdAt, 9)}
                        </Text>
                    </HStack>
                </HStack>
                <HStack>
                    <Text fontWeight={'semibold'}>
                        Review:
                    </Text>
                    <Text>
                        {data?.review || '--'}
                    </Text>
                </HStack>

            </VStack>


        </VStack>
    )
}

export default ModCallCard2