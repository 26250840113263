import React, { useEffect } from 'react'
import useFetch from '../../hook/useFetch'
import { getCity, getStates } from '../../apis/misc'
import SelectField from '../../commons/SelectField'
import { InputField } from '../../commons/InputField'
import { Checkbox, Text, VStack } from '@chakra-ui/react'
import { getbrands, getchains, getmallsByCity } from '../../apis/admin'
import { offerTypes, offerTypesChain, storeTypes } from '../AdminOffers/static'

const FilterSelection = ({ form }) => {
    const state = { ...form.getValues() }
    const { type, city, pre_register, claimed } = state

    const brands = useFetch({ name: `brands`, service: getbrands, dataOnly: true, converterLabel: 'brand', sort: true, params: { limit: 0 } })
    const chains = useFetch({ name: 'chains', service: getchains, dataOnly: true, converterLabel: 'name', params: { limit: 0 } })
    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name', params: { limit: 0 } })

    const state_id = state?.state?.[0]?.value
    const cities = useFetch({
        name: `cities_${state_id}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: state_id,
        id: state_id,
        condition: !!state_id,
        converterLabel: 'name'
    })

    const city_id = city?.[0]?.value
    const malls = useFetch({
        name: `malls_${city_id}`,
        service: getmallsByCity, dataOnly: true,
        conditionalRefetch: city_id,
        id: city_id,
        condition: !!city_id,
        converterLabel: 'mall_name'
    })

    useEffect(() => {
        if (type === 'exclusive') {
            form.setValue('chain', '')
            form.setValue('offer_type', '')
        }
        if (city?.length > 1) form.setValue('mall', '')
        if (state.state?.length > 1) {
            form.setValue('city', '')
            form.setValue('mall', '')
        }
    }, [form.watch('type'), form.watch('state'), form.watch('city')])

    const list = [
        { visiblity: true, title: 'Brand', id: 'brand', list: brands },
        { visiblity: true, title: 'Store Type', id: 'type', list: storeTypes },
        { visiblity: true, title: 'State', id: 'state', list: states, multi: true },
        { visiblity: state.state?.length === 1, title: 'City', id: 'city', list: cities, multi: true },
        { visiblity: city?.length === 1, title: 'Mall', id: 'mall', list: malls },
        { visiblity: type, title: 'Offer Type', id: 'offer_type', list: type === 'exclusive' ? offerTypes : offerTypesChain },
        { visiblity: type && type !== 'exclusive', title: 'Chain', id: 'chain', list: chains },
    ]

    return (
        <VStack w='100%' space='2' alignItems={'flex-start'}>

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return item.visiblity &&
                    <Comp tw={200} w='100%' key={index} form={form} {...item} />
            })}

            <Checkbox isChecked={claimed} onChange={() => form.setValue('claimed', !claimed)}>
                <Text>Claimed/Registered Stores</Text>
            </Checkbox>
            <Checkbox isChecked={pre_register} onChange={() => form.setValue('pre_register', !pre_register)}>
                <Text>Pre-Register(but not claimed) Stores</Text>
            </Checkbox>
        </VStack>
    )
}

export default FilterSelection