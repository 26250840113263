import { Button, Input, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import useSubmit from '../../hook/useSubmit'
import { sendIntroMessageToUser } from '../../apis/admin'

const SendIntroMessageToUser = () => {

    const [number, setNumber] = React.useState('')

    const { submit, isLoading } = useSubmit({
        service: sendIntroMessageToUser,
        name: 'sendIntroMessageToUser',
    })


    return (
        <VStack w="100%" alignItems={'flex-start'} maxW={'500px'}
            bg={'white'} p={'4'} borderRadius={'lg'}
        >

            <Text fontSize={'md'} fontWeight='semibold' >
                Send Intro Message to User
            </Text>

            <Text fontSize={'sm'} fontWeight='normal' color={'gray.500'} mt={'2'} mb={'4'}>
                🎉 Introducing Offingo! 🛍️ Your ultimate Digital Apparel Shopping Assistant! 📱

                👗 Say goodbye to endless browsing! With Offingo, find the best ongoing discounts on your favorite brands effortlessly!

                📍 Locate nearby stores with the hottest deals before you even step out the door! 🚪

                💬 Just tell Offingo what you're looking for, and we'll do the legwork for you! No more aimless wandering for discounts!

                🛒 Shop smarter, save more with Offingo! Download now and experience the joy of hassle-free shopping! 🌟
            </Text>

            <Input placeholder="Enter Number(10-digit)" type="number" maxLength={10}
                value={number} onChange={(e) => setNumber(e.target.value)} />


            <Button
                onClick={() => submit({ number })}
                isLoading={isLoading}
            >
                send
            </Button>


        </VStack>
    )
}

export default SendIntroMessageToUser