import React from 'react'
import { columns } from './static'
import { Text, VStack } from '@chakra-ui/react'
import { getTimeWiseApiCalls } from '../ApiStats/ApiStats'
import DataTable from '../../../commons/DataTable/DataTable'
import { getPastHour, getPastMinute, getPastSec } from '../../../utils/moment'

const ApiStatsUrlWise = ({ data }) => {

    const _urls = [...new Set([...data.map((item) => item.url)])]
        .map(url => {
            url = url?.split('/api/v1/')?.[1]?.split('?')?.[0] || url
            url = url?.split('/')
            const new_url = []
            url?.forEach((item) => {
                if (item.length === 24) {
                    return
                }
                new_url.push(item)
            })
            return new_url.join('/')
        })

    const urls = [...new Set(_urls)]

    const list = urls.map((url) => {
        const url_data = data.filter((item) => item.url.includes(url))

        const last_10_sec = getTimeWiseApiCalls({ start: getPastSec(10), data: url_data, })
        const last_30_sec = getTimeWiseApiCalls({ start: getPastSec(30), data: url_data, })
        const last_min = getTimeWiseApiCalls({ start: getPastMinute(1), data: url_data })
        const last_10_min = getTimeWiseApiCalls({ start: getPastMinute(10), data: url_data })
        const last_30_min = getTimeWiseApiCalls({ start: getPastMinute(30), data: url_data })
        const last_one_hour = getTimeWiseApiCalls({ start: getPastHour(1), data: url_data })
        const last_two_hour = getTimeWiseApiCalls({ start: getPastHour(2), data: url_data })
        const last_six_hour = getTimeWiseApiCalls({ start: getPastHour(6), data: url_data })
        const last_12_hour = getTimeWiseApiCalls({ start: getPastHour(12), data: url_data })
        const last_24_hours = getTimeWiseApiCalls({ start: getPastHour(24), data: url_data })

        return {
            last_10_sec_api_calls: last_10_sec.api_calls,
            last_30_sec_api_calls: last_30_sec.api_calls,
            last_min_api_calls: last_min.api_calls,
            last_10_min_api_calls: last_10_min.api_calls,
            last_30_min_api_calls: last_30_min.api_calls,
            last_one_hour_api_calls: last_one_hour.api_calls,
            last_two_hour_api_calls: last_two_hour.api_calls,
            last_six_hour_api_calls: last_six_hour.api_calls,
            last_12_hour_api_calls: last_12_hour.api_calls,
            last_24_hours_api_calls: last_24_hours.api_calls,
            url,
            last_10_sec,
            last_30_sec,
            last_min,
            last_10_min,
            last_30_min,
            last_one_hour,
            last_two_hour,
            last_six_hour,
            last_12_hour,
            last_24_hours,
        }
    })
        .sort((a, b) => b.last_24_hours_api_calls - a.last_24_hours_api_calls)
        .sort((a, b) => b.last_12_hour_api_calls - a.last_12_hour_api_calls)
        .sort((a, b) => b.last_six_hour_api_calls - a.last_six_hour_api_calls)
        .sort((a, b) => b.last_two_hour_api_calls - a.last_two_hour_api_calls)
        .sort((a, b) => b.last_one_hour_api_calls - a.last_one_hour_api_calls)
        .sort((a, b) => b.last_30_min_api_calls - a.last_30_min_api_calls)
        .sort((a, b) => b.last_10_min_api_calls - a.last_10_min_api_calls)
        .sort((a, b) => b.last_min_api_calls - a.last_min_api_calls)
        .sort((a, b) => b.last_30_sec_api_calls - a.last_30_sec_api_calls)
        .sort((a, b) => b.last_10_sec_api_calls - a.last_10_sec_api_calls)


    return (
        <VStack alignItems={'flex-start'} spacing={0} w={'100%'} >
            <Text fontSize={'2xl'} fontWeight={'bold'}>Api Calls Stats</Text>
            <DataTable tableData={{ columns: columns(), data: list }} showSearch={false} pagination={false} />
        </VStack>

    )
}

export default ApiStatsUrlWise