import { HStack, Text, Box } from '@chakra-ui/react'
import { CheckIcon, Spinner, Select, } from 'native-base'
import React from 'react'
import ReactSelect from 'react-select'
import { colors } from '../theme/colors'

const SelectField = ({
    multi, form, list, w = "100%", title, disabled, desc, showDesc = true,
    value, tw = 150, placeholder, id, loading, onChange, body, isMulti = true,
    advReactSelect, reactSelect
}) => {

    const createValueList = (() => {
        value = value || form.watch(id)

        if (!(body || multi || reactSelect)) return

        if (!advReactSelect) {
            return value
        }

        if (isMulti) {
            return value.map((val) => ({
                label: list.find(item => item.value === val)?.label,
                value: val
            }))
        } else {
            return {
                label: list.find(item => item.value === value)?.label,
                value
            }
        }

    })();

    return (
        <HStack w={w} alignItems={'flex-start'} space='1'
            opacity={disabled ? 0.8 : 1}
            pointerEvents={disabled ? 'none' : 'auto'}
        >
            {title &&
                <HStack alignItems={'center'} mt='2.5'>
                    <Text fontWeight='600' w={tw}>{title}</Text>
                    <Text fontWeight='600' >:</Text>
                </HStack>
            }
            {body || multi || reactSelect ?
                <Box w='100%'>
                    <ReactSelect
                        isDisabled={disabled}
                        value={advReactSelect ? createValueList : value || form.watch(id)}
                        onChange={(val) => onChange ? onChange(val) : form.setValue(id, val)}
                        styles={{ width: '100%', flex: 1 }}
                        id="state" variant={"filled"}
                        placeholder={placeholder || title}
                        // value={offer.state}
                        size="lg" isMulti={isMulti}
                        options={list}
                    />
                </Box>
                :
                <Box flex='1' w='100%' alignItems={'flex-start'} >
                    <Select
                        selectedValue={value || form.watch(id)}
                        defaultValue={value || form.watch(id)}
                        bg={'white'}
                        flex='1'
                        // w={'100%'}
                        variant='filled'
                        disabled={disabled}
                        accessibilityLabel={placeholder || title}
                        placeholder={placeholder || title}
                        _selectedItem={{ bg: "teal.600", endIcon: loading && (!value ? <CheckIcon size="5" /> : <Spinner />) }}
                        mt={1}
                        onValueChange={val => onChange ? onChange(val) : form.setValue(id, val)}>
                        <Select.Item label={'None'} value={''} />
                        {list.map(({ label, value }) =>
                            <Select.Item label={label} value={value} key={value} />
                        )}
                    </Select>
                    {(desc && showDesc) && <Text fontWeight={'500'} fontSize='sm' color={colors.secondary}>{desc}</Text>}
                </Box>}
        </HStack>
    )
}

export default SelectField