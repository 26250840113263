
const app_actions = [
    ['all marketing store enquiry store edit restaurants coupon', 'redeem-coupon-reward', 'Redeem Coupon Reward'],
    ['all marketing store enquiry store edit restaurants coupon', 'food_coupons', 'Food Coupons'],
    ['all marketing store enquiry store edit restaurants coupon', 'food_coupons/create', 'Create Food Coupons'],
    ['all marketing store enquiry store edit restaurants coupon', 'food_coupons/edit', 'Edit Food Coupons'],

    ['all marketing store enquiry store edit restaurants', 'restaurants', 'Restaurants'],
    ['all marketing store enquiry store edit restaurants', 'restaurants/create', 'Create Restaurants'],
    ['all marketing store enquiry store edit restaurants', 'restaurants/edit', 'Edit Restaurants'],

    ['all marketing store enquiry store edit otps', 'otps', 'OTPS'],
    ['all marketing store enquiry store edit AdminAlerts', 'admin_alerts', 'AdminAlerts'],
    ['all marketing store enquiry store edit store', 'create_new_store', 'CreateNewStore'],
    ['all marketing store enquiry store edit stats', 'app_reviews', 'App Reviews'],
    ['all marketing store enquiry store edit calls', 'calls', 'Calls'],
    ['all marketing store enquiry store edit coupons whatsapp coupons', 'grabbed_coupons', 'Grabbed Coupons'],
    ['all marketing store enquiry store edit coupons whatsapp', 'link_types', 'Link Types'],
    ['marketing store enquiry store edit coupons whatsapp', 'whatsapp_conversations', 'WhatsappConversations'],
    ['marketing store enquiry store edit coupons whatsapp', 'whatsapp', 'Whatsapp'],
    ['marketing store enquiry store edit coupons', 'user_calling_my_batches', 'My User Calling Batches'],
    ['marketing store enquiry store edit coupons', 'user_calling_batches', 'User Calling Batches'],
    ['marketing store enquiry store edit coupons', 'user_calling/assign', 'Assign User Calling'],
    ['marketing store enquiry store edit coupons', 'store_on_map', 'Store on Map'],
    ['marketing store enquiry store edit coupons', 'user_src', 'User Source'],
    ['marketing store enquiry store edit coupons', 'analytics/user_analyst', 'User Analyst'],
    ['marketing store enquiry store edit coupons', 'wa_share', 'WAShare'],
    ['marketing store enquiry store edit coupons', 'sponsor_reels/edit', 'Edit Sponsor Reel'],
    ['marketing store enquiry store edit coupons', 'sponsor_reels/create', 'Add Sponsor Reel'],
    ['marketing store enquiry store edit coupons', 'sponsor_reels', 'Sponsor Reels'],
    ['marketing store enquiry store edit coupons', 'ref_link/create', 'Create Ref Links'],
    ['marketing store enquiry store edit coupons', 'ref_link', 'Ref Links'],
    ['marketing store enquiry store edit coupons', 'wa_messages_logs', 'WA Message Logs'],
    ['marketing store enquiry store edit coupons', 'store_dump', 'StoreDump'],
    ['marketing store enquiry store edit coupons', 'wa_messages', 'WA Message'],
    ['marketing store enquiry store edit coupons advance_search_store', 'advance_search_store', 'Advance Search Store'],
    ['marketing store enquiry store edit coupons', 'coupons/all_coupon_data', 'All coupon Data'],
    ['marketing store enquiry store edit coupons', 'coupons/edit', 'Edit Coupons'],
    ['marketing store enquiry store coupons', 'coupons/create', 'Create Coupons'],
    ['marketing store enquiry store coupons', 'coupons', 'Coupons'],
    ['marketing store enquiry store analysis', 'api_calls/graph', 'Api Calls'],
    ['marketing store enquiry store analysis', 'conn', 'Connections'],
    ['marketing store enquiry store Stories', 'kpi', 'KPI'],
    ['marketing store enquiry store Stories', 'stories', 'Stories'],
    ['marketing store enquiry store', 'store_stats', 'Store Stats'],
    ['marketing store enquiry', 'arrange_meeting', 'Arrange Meeting'],
    ['marketing store enquiry', 'arrange_meeting/create', 'Create Arrange Meeting'],
    ['marketing store enquiry', 'arrange_meeting/edit', 'Edit Arrange Meeting'],
    ['marketing store enquiry', 'store_with_no_offers', 'View store with no offers'],
    ['marketing store enquiry', 'ava_enquires', 'View Ava Enquires'],
    ['marketing store enquiry', 'ava_enquires/edit', 'Edit Ava Enquires'],
    ['marketing store', 'store_verification_pending', 'Edit shops'],
    ['marketing shops', 'shops', 'Edit shops'],
    ['marketing shops', 'app_updates', 'View App Updates'],
    ['marketing research view admin edit', 'markets/edit', 'Edit Markets'],
    ['marketing research view admin edit', 'markets/create', 'create Markets'],
    ['marketing research view admin edit', 'markets', 'Markets'],
    ['marketing research view admin edit', 'link_shortener/edit', 'Edit Link Shortener'],
    ['marketing research view admin edit', 'link_shortener/create', 'create Link Shortener'],
    ['marketing research view admin edit', 'link_shortener', 'Link Shortener'],
    ['marketing research view admin edit', 'qr/edit', 'Edit qr'],
    ['marketing research view admin edit', 'qr/create', 'create qr'],
    ['marketing research view admin edit', 'qr', 'qr'],
    ['marketing research view admin edit', '/product/tree', 'product tree'],
    ['marketing research view admin edit', 'product/thumbnails', 'product thumbnails'],
    ['marketing research view admin edit', 'storeuploads', 'storeuploads'],
    ['marketing research view admin edit', 'onboarders/create', 'create onboarder'],
    ['marketing research view admin edit', 'onboarders/edit', 'edit onboarder'],
    ['marketing research view admin', 'onboarders', 'onboarders'],
    ['admin', 'superactions', 'Super Actions'],
    ['admin', 'analytics', 'Analytics'],
    ['marketing research view admin', 'ads', 'Advertisement'],
    ['marketing research view edit admin', 'ads/edit', 'Edit Advertisement'],
    ['marketing research create admin', 'ads/create', 'Create Advertisement'],
    ['marketing research view admin', 'search', 'Search functionality'],
    ['marketing research view admin', 'CBS', 'View CBS'],
    ['marketing research view edit admin', 'globals/vars', 'View/Edit Global Vars'],
    ['marketing research create admin', 'notifications', 'Create Notifications'],
    ['marketing research view admin', 'subscriptions/store', 'View Store Subscriptions'],
    ['marketing research create admin', 'subscriptions/store/new', 'Create Store Subscriptions'],
    ['marketing research edit admin', 'subscriptions/store/edit', 'Edit Store Subscriptions'],
    ['marketing research view admin', 'subscriptions/user', 'View User Subscriptions'],
    ['marketing research create admin', 'subscriptions/user/new', 'Create User Subscriptions'],
    ['marketing research edit admin', 'subscriptions/user/edit', 'Edit User Subscriptions'],
    ['marketing research view admin', 'bot-activities', 'View Bot Activities'],
    ['marketing research view admin', 'payments', 'View Payments'],
    ['marketing research view admin', 'mods', 'View Mods'],
    ['marketing research create admin', 'mods/create', 'Create Mods'],
    ['marketing research edit admin', 'mods/edit', 'Edit Mods'],
    ['marketing research edit admin', 'mods/calls', ' Mod calls'],
    ['marketing research view admin user', 'users/journey', 'View User Journey'],
    ['marketing research view admin user', 'users', 'View Users'],
    ['marketing research edit admin user', 'users/edit', 'Edit Users'],
    ['marketing research edit admin', 'reviewStore', 'Review Store'],
    ['marketing research view admin', 'stores', 'View Stores'],
    ['marketing research view admin', 'stores/authorities', 'View Authorities'],
    ['marketing research view admin', 'stores/noAuthStores', 'View No Auth Stores'],
    ['marketing research view admin', 'stores/mapline', 'View Stores Map'],
    ['marketing research create admin', 'pre-register', 'Store Pre-Register'],
    ['marketing research view admin', 'offers', 'View Offers'],
    ['marketing research edit admin', 'system_offers/created', 'Admin Offer list'],
    ['marketing research edit admin', 'system_offers/created/edit', 'Edit Admin Created Offers'],
    ['marketing research edit admin', 'system_offers/suggested/edit', 'Edit Admin Suggested Offers'],
    ['marketing research create admin', 'system_offers/create', 'Create Admin Offers'],
    ['marketing research view admin', 'system_offers', 'View admin Offers'],
    ['marketing research create admin', 'create-offers', 'Create Offers'],
    ['marketing research edit admin', 'offers/edit', 'Edit Offers'],
    ['marketing research view admin', 'offerTemplateList', 'View Offer Templates List'],
    ['marketing research create admin', 'offerTemplateList/create', 'Create Offer Templates List'],
    ['marketing research edit admin', 'offerTemplateList/edit', 'Edit Offer Templates'],
    ['marketing research view admin', 'offerTemplate', 'View Offer Template'],
    ['marketing research create admin', 'offerTemplate/create', 'Create Offer Template'],
    ['marketing research edit admin', 'offerTemplate/edit', 'Edit Offer Template'],
    ['marketing research view admin', 'brands', 'View Brands'],
    ['marketing research create admin', 'brands/create', 'Create Brands'],
    ['marketing research edit admin', 'brands/edit', 'Edit Brands'],
    ['marketing research view admin', 'malls', 'View Malls'],
    ['marketing research create admin', 'malls/create', 'Create Malls'],
    ['marketing research edit admin', 'malls/edit', 'Edit Malls'],
    ['marketing research view admin', 'chains', 'View Chains'],
    ['marketing research create admin', 'chains/create', 'Create Chains'],
    ['marketing research edit admin', 'chains/edit', 'Edit Chains'],
    ['marketing research view admin', 'wear', 'View Wear'],
    ['marketing research create admin', 'wear/create', 'Create Wear'],
    ['marketing research edit admin', 'wear/edit', 'Edit Wear'],
    ['marketing research edit admin', 'clothingTypes', 'Edit Clothing Types'],
    ['marketing research view admin', 'product-filters', 'View Product Filters'],
    ['marketing research create admin', 'product-filters/create', 'Create Product Filters'],
    ['marketing research edit admin', 'product-filters/edit', 'Edit Product Filters'],
    ['marketing research view admin', 'product', 'View Products'],
    ['marketing research create admin', 'product/create', 'Create Products'],
    ['marketing research edit admin', 'product/edit', 'Edit Products'],
    ['marketing research edit admin', 'product/filter/edit', 'Edit Product Filters'],
    ['marketing research edit admin', 'product/wear/edit', 'Edit Product Clothing Types'],
    ['marketing research view admin', 'genders', 'View Genders'],
    ['marketing research create admin', 'genders/create', 'Create Genders'],
    ['marketing research edit admin', 'genders/edit', 'Edit Genders'],
    ['marketing research edit admin', 'flags', 'Feedback'],
    ['marketing research edit admin', 'flags/details', 'Feedback Details'],
    ['marketing research edit admin', 'LOGIN_AS_STORE_OWNER', 'Login as Store Owner'],
]

export const getAppActions = (filters = []) => {
    if (filters.length) {
        return app_actions.filter(item => {
            let match = false
            filters.forEach(filter => {
                if (item[0].includes(filter)) match = true
            })
            return match
        })
    }
    return app_actions
}