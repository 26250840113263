import React from 'react'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import useFetch from '../../../hook/useFetch'
import { getUserSearch } from '../../../apis/admin'
import Loading from '../../../commons/Loading'
import { useNavigate } from 'react-router-dom'
import { columns } from './static'
import { columns as columns2 } from './static2'
import DataTable from '../../../commons/DataTable/DataTable'
import CompHeader from '../../../commons/CompHeader/CompHeader'

const UserSearchs = () => {
    const navigate = useNavigate()
    const [type, setType] = React.useState(1)

    const { data, isLoading } = useFetch({
        name: 'getUserSearch',
        service: getUserSearch,
    })

    return (
        <VStack width='100%' alignItems='flex-start'>

            <CompHeader title={`User Search (${data?.search_list?.length || 0})`} backButton />

            <HStack width='100%' justifyContent='flex-start' borderBottomWidth={2} p={2} >
                <Button onClick={() => setType(1)} colorScheme={type === 1 ? 'blue' : 'gray'}>
                    Number Wise
                </Button>
                <Button onClick={() => setType(2)} colorScheme={type === 2 ? 'blue' : 'gray'}>
                    User Wise
                </Button>
            </HStack>

            {type === 1 ?
                <DataTable tableData={{
                    columns: columns(navigate),
                    data: data?.search_list || [],
                    isLoading
                }} pagination={false} />
                :
                <DataTable tableData={{
                    columns: columns2(navigate),
                    data: data?.user_wise.filter(item => item?.user?.phone_number) || [],
                    isLoading
                }} pagination={false} />
            }

        </VStack>
    )
}

export default UserSearchs