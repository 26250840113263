import { HStack, Text } from '@chakra-ui/react'
import React from 'react'

const Tabs = ({
    params,
    setParams
}) => {

    const actions = [
        { name: 'Online', value: { online: true }, key: 'online' },
        { name: 'Joined Today', value: { joined_today: true }, key: 'joined_today' },
        { name: 'Active Today(Not Joined Today)', value: { active_today: true }, key: 'active_today' },
        { name: 'Android Users', value: { android: true, ios: false }, key: 'android' },
        { name: 'iOS Users', value: { ios: true, android: false }, key: 'ios' },
        { name: 'Notification On', value: { notification_on: true, }, key: 'notification_on' },
        { name: 'Location On', value: { location_on: true, }, key: 'location_on' },
        { name: 'Men', value: { men: true, women: false }, key: 'men' },
        { name: 'Women', value: { men: false, women: true }, key: 'women' },
    ]

    return (
        <HStack bg='white'>
            {actions.map(action => (
                <HStack key={action.key} p={2} cursor='pointer'
                    onClick={() => {
                        if (params[action.key]) {
                            setParams({ ...params, [action.key]: false })
                        } else {
                            setParams({ ...params, ...action.value })
                        }
                    }}
                    fontWeight={params[action.key] ? 'bold' : 'normal'}
                    borderBottom={params[action.key] ? '2px solid #000' : '2px solid transparent'}
                >
                    <Text>
                        {action.name}
                    </Text>
                </HStack>
            ))
            }
        </HStack>
    )
}

export default Tabs