import React from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'

const RulesSummary = ({ form, data, parentObj, childObj }) => {
    const { titles, keys, except, hides, relations } = form.watch()

    const key = !!childObj?.label
    const actionName = childObj?.label || parentObj?.title?.title

    const allTitles = data?.map(({ title }) => title)
    const allKeys = data.flatMap(({ keys, title }) => keys.map(key => ({ ...key, title })))

    const titleLabels = allTitles?.filter(({ _id }) => titles?.includes(_id))
    const keyLabels = allKeys?.filter(({ _id }) => keys?.includes(_id))

    const populateExcept = (except) => {
        return except?.map(({ title, keys }) => {
            const titleObj = allTitles?.find(({ _id }) => _id === title)
            const keyObjs = allKeys?.filter(({ _id }) => keys?.includes(_id))
            return { title: titleObj, keys: keyObjs }
        })
    }

    const exceptLabels = populateExcept(except)

    const hidesLabels = allTitles?.filter(({ _id }) => hides?.includes(_id))

    const relationLabels = relations?.map(({ title, keys }) => ({
        title: allTitles?.find(({ _id }) => _id === title),
        keys: keys?.map(key => allKeys?.find(({ _id }) => _id === key))
    }))

    return (
        (titleLabels.length > 0 || keyLabels?.length > 0 || exceptLabels?.length > 0 || relationLabels?.length > 0) &&


        <VStack w={'100%'} alignItems={'flex-start'} bg='white' p='4' borderRadius={'lg'} mb='4' space={2}>
            <Text fontSize={'xl'} fontWeight={'bold'}>Rule Summary</Text>

            {(titleLabels.length > 0 || keyLabels?.length > 0) && <>
                {key ?
                    <Text> Values <Text as='span' fontWeight={'bold'}> `{actionName}` </Text> will hide from filter <Text as='span' fontWeight={'bold'}> `{parentObj?.title?.title}` </Text></Text>
                    :
                    <Text> Filter <Text as='span' fontWeight={'bold'}> `{actionName}`</Text> will hide </Text>
                }


                {titleLabels.length > 0 &&
                    <>
                        <Text>
                            when any value from following filters will be selected:
                        </Text>
                        {titleLabels.map(({ title }, index) =>
                            <Text fontWeight={'bold'} color={'blue.500'}>
                                {index + 1} - {title}
                            </Text>
                        )}

                        {keyLabels?.length > 0 && <Text as='span' fontWeight={'bold'}> OR </Text>}
                    </>
                }
                {keyLabels?.length > 0 &&
                    <>
                        <Text>
                            when following selection exists:
                        </Text>
                        {keyLabels.map(({ label, title }, index) =>
                            <Text fontWeight={'bold'} color={'blue.500'}>
                                {index + 1} - {label}(value) from {title.title}(filter)
                            </Text>
                        )}
                    </>
                }

            </>}

            {except?.length > 0 &&
                <VStack mt={5} alignItems={'flex-start'} borderTopWidth={1} w='100%' pt={2}>
                    <Text fontSize={'xl'} fontWeight={'bold'}>Except Rules</Text>
                    {key ?
                        <Text>When <Text as='span' fontWeight={'bold'}> `{actionName}` </Text> Value is selected from <Text as='span' fontWeight={'bold'}> `{parentObj?.title?.title}` </Text> filter</Text>
                        :
                        <Text>When any value from <Text as='span' fontWeight={'bold'}> `{actionName}`</Text>  Filter is selected </Text>
                    }

                    {exceptLabels?.map(({ title, keys }, index) =>
                        <HStack alignItems={'flex-start'}>
                            <Text fontWeight={'bold'} color={'blue.500'}>
                                {index + 1} - {' '}
                                {title.title} {' '}
                                will show {keys.length} {keys.length > 1 ? 'values' : 'value'}
                            </Text>
                        </HStack>
                    )}
                </VStack>
            }

            {hidesLabels?.length > 0 &&
                <VStack mt={5} alignItems={'flex-start'} borderTopWidth={1} w='100%' pt={2}>
                    <Text fontSize={'xl'} fontWeight={'bold'}>Hide Rules</Text>
                    {key ?
                        <Text>When <Text as='span' fontWeight={'bold'}> `{actionName}` </Text> Value is selected from <Text as='span' fontWeight={'bold'}> `{parentObj?.title?.title}` </Text> filter</Text>
                        :
                        <Text>When any value from <Text as='span' fontWeight={'bold'}> `{actionName}`</Text> Filter is selected </Text>
                    }
                    {hidesLabels?.map(({ title }, index) =>
                        <HStack alignItems={'flex-start'}>
                            <Text fontWeight={'bold'} color={'blue.500'}>
                                {index + 1} - {' '}
                                {title} {' '}
                                will hide
                            </Text>
                        </HStack>
                    )}
                </VStack>
            }

            {relationLabels?.length > 0 &&
                <VStack mt={5} alignItems={'flex-start'} borderTopWidth={1} w='100%' pt={2}>
                    <Text fontSize={'xl'} fontWeight={'bold'}>Relation Rules</Text>
                    {key ?
                        <Text>When <Text as='span' fontWeight={'bold'}> `{actionName}` </Text> Value is selected from <Text as='span' fontWeight={'bold'}> `{parentObj?.title?.title}` </Text> filter</Text>
                        :
                        <Text>When any value from <Text as='span' fontWeight={'bold'}> `{actionName}`</Text> Filter is selected </Text>
                    }
                    {relationLabels?.map(({ title, keys }, index) =>
                        <HStack alignItems={'flex-start'}>
                            <Text fontWeight={'bold'} color={'blue.500'}>
                                {index + 1} - {' '}
                                {keys.map(({ label }) => label).join(', ')} {' '}
                                from {' '}
                                {title?.title} {' '}
                                will Autoselect
                            </Text>
                        </HStack>
                    )}
                </VStack>
            }
        </VStack>
    )
}

export default RulesSummary