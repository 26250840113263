import React from 'react';
import NavItem from './NavItem';
import { useSelector } from 'react-redux';
import { colors } from '../../theme/colors';
import { selectProfile } from '../../redux/authSlice';
import { LinkTypes } from '../../Admin Panel/Routes/LinkItem';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, CloseButton, Flex, useColorModeValue, Text } from '@chakra-ui/react';

const SidebarContent = ({ onClose, ...rest }) => {
  const navigate = useNavigate()
  const profile = useSelector(selectProfile)
  const pathHistory = JSON.parse(localStorage.getItem('pathHistory')) || []
  const location = useLocation()
  const [search, setSearch] = React.useState('')

  return (
    <Box transition="3s ease" borderRight="1px"
      bg={useColorModeValue('white', 'gray.900')}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed" h="full" overflowY='scroll' {...rest}>
      <Flex h="12" alignItems="center" mx="8" justifyContent="space-between">
        <Text onClick={() => navigate('/')} fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          OFFINGO
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      <Box mx="4" mt="2" mb="2">
        <Box bg={useColorModeValue('white', 'gray.700')} mt="2" shadow="lg" rounded="lg" borderWidth="1px" borderColor={useColorModeValue('gray.200', 'gray.700')} >
          <Flex alignItems="center" justifyContent="space-between" px="0" py="2" borderBottomWidth="1px" borderColor={useColorModeValue('gray.200', 'gray.700')} >
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="outline-none w-full"
              style={{
                border: 'none', padding: '0 10px', height: '100%', fontSize: '16px',
                outline: 'none', color: colors.secondary, fontWeight: 600
              }}
            />
          </Flex>
        </Box>
      </Box>


      {LinkTypes(profile, pathHistory, 'links')
        .filter(({ name, nav }) => search ?
          name.toLowerCase().startsWith(search.toLowerCase())
          ||
          nav.some(type => type.toLowerCase().startsWith(search.toLowerCase()))
          : true
        )
        .map(({ link, name, icon = <></>, links }) => {
          const active = location.pathname.includes(link) || links.includes(location.pathname)
          return (
            <Link to={link} key={link}
              onClick={() => {
                pathHistory.unshift(name)
                // localStorage.setItem('pathHistory', JSON.stringify(pathHistory))
              }}
            >
              <NavItem key={name} link={link} icon={icon} active={active}>
                <Text fontWeight={600} color={active ? colors.primary : colors.secondary}
                  _hover={{ bg: colors.info, color: 'white', }} >
                  {name[0]}
                  <Text as='span' color={active ? colors.primary : colors.secondary} fontWeight={400}
                    _hover={{ bg: colors.info, color: 'white', }} >
                    {name.slice(1)}
                  </Text>
                </Text>
              </NavItem>
            </Link>
          )
        })}
    </Box>
  );
}


export default SidebarContent