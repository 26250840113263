import React from 'react'
import { Divider, VStack } from '@chakra-ui/react'
import InitialCall from './InitialCall';
import AllCalls from './AllCalls';

const StoreAuthCalls = ({ store, auth, close }) => {
    const [refresh, setRefresh] = React.useState(false)
    return (
        <VStack alignItems={'flex-start'}>
            <InitialCall store={store} auth={auth} setRefresh={setRefresh} />
            <Divider borderWidth={2} />
            <AllCalls store={store} auth={auth} refresh={refresh} />
        </VStack>
    )
}

export default StoreAuthCalls