import { Box, VStack } from '@chakra-ui/react'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CustomLoader = () => {
    return (
        <VStack w='100%' minH='60vh' >
            <Box width='100%' px='4' pt='4' >
                <Skeleton count={14} height={30} style={{ marginBottom: 5 }} />
            </Box>
        </VStack>
    )
}

export default CustomLoader