import React from 'react'
import { useForm } from 'react-hook-form'
import useFetch from '../../../../hook/useFetch'
import { Button, Checkbox, CheckboxGroup, HStack, VStack } from '@chakra-ui/react'
import SelectField from '../../../../commons/SelectField'
import { InputField } from '../../../../commons/InputField'
import { getgender, updateMultipleOffers } from '../../../../apis/admin'
import useSubmit from '../../../../hook/useSubmit'

const ChangeMultipleOffers = ({ selection, refetch, close, }) => {

    const form = useForm({ mode: 'onBlur' })
    const state = { ...form.getValues() }
    const { offer_applicable } = state


    const genders = useFetch({ name: 'getgenders', service: getgender, dataOnly: true, converterLabel: 'type' })

    const { submit, isLoading: submitting } = useSubmit({
        service: updateMultipleOffers,
        onSuccess: (data) => {
            refetch()
            close()
        }
    })

    const list = [
        {
            title: 'Description',
            placeholder: 'Description (Leave empty to keep same)',
            id: 'description',
        },
        {
            title: 'Offer Applicable',
            body: <CheckboxGroup value={offer_applicable} colorScheme='red'
                onChange={(e) => form.setValue('offer_applicable', e)} >
                <HStack alignItems={'center'} space='2'>
                    {genders.map(({ label, value }) => <Checkbox value={value} >{label}</Checkbox>)}
                </HStack>
            </CheckboxGroup>
        }
    ]

    const list2 = [
        { title: 'Start Date', id: 'start_date', date: true },
        { title: 'End Date', id: 'end_date', date: true },
    ]

    const save = () => {
        const state = { ...form.getValues() }
        Object.keys(state).forEach((key) => {
            if (!state[key] || (Array.isArray(state[key]) && state[key].length === 0)) {
                delete state[key]
            }
        })
        submit({ ...state, selection })
    }

    return (
        <VStack space='3' w='100%' alignItems={'flex-start'} p={5}>

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}

            <HStack alignItems={'center'} space='2'>
                {list2.map((item, index) => {
                    const Comp = item.list ? SelectField : InputField
                    return <Comp tw={90} key={index} form={form} {...item} />
                })}
            </HStack>

            <Button colorScheme='blue' onClick={save} isLoading={submitting} >
                Update
            </Button>
        </VStack>
    )
}

export default ChangeMultipleOffers