import React, { useState } from 'react';
import { Input, Stack, Button, Tag, TagLabel, TagCloseButton, HStack, Spacer } from '@chakra-ui/react';
import { contProps } from '../../helper/variables';
import { Text } from 'native-base';

const KeywordInput = ({ tags = [], onKeywordsChange }) => {
    const [keyword, setKeyword] = useState('');

    const handleAddKeyword = () => {
        const keywords = keyword.split(',').map((keyword) => keyword.trim());
        const newKeywords = [...tags, ...keywords];
        onKeywordsChange(newKeywords);
        setKeyword('');
    };

    const handleInputChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleRemoveKeyword = (index) => {
        const updatedKeywords = [...tags];
        updatedKeywords.splice(index, 1);
        onKeywordsChange(updatedKeywords);
    };

    return (
        <Stack spacing={4} {...contProps} >
            <Text fontSize="xl" fontWeight="bold" color="gray.600" >
                Add Tags
            </Text>
            <Stack direction="row" spacing={2}>
                {tags?.map((keyword, index) => (
                    <Tag key={index} size="md" variant="solid" colorScheme="teal">
                        <TagLabel>{keyword}</TagLabel>
                        <TagCloseButton onClick={() => handleRemoveKeyword(index)} />
                    </Tag>
                ))}
            </Stack>
            <Input placeholder="Enter keywords separated by commas"
                value={keyword} onChange={handleInputChange}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        handleAddKeyword();
                    }
                }}
            />
            <HStack alignItems="center" w={'100%'}>
                <Button onClick={handleAddKeyword} colorScheme="blue">
                    Add Keywords
                </Button>
                <Button onClick={() => { onKeywordsChange([]); }} colorScheme="red">
                    Clear Tags
                </Button>
                <Spacer />
                <Button onClick={() => {
                    navigator.clipboard.writeText(tags.join(', '))
                }} colorScheme="gray">
                    Copy Tags
                </Button>
            </HStack>
        </Stack>
    );
};

export default KeywordInput;
