import { VStack } from 'native-base'
import React, { } from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useParams } from 'react-router-dom';
import AddOfferTemplate from './AddOfferTemplate';
import { getoffertemplatebyid } from '../../apis/admin';
import useFetch from '../../hook/useFetch';
import Loading from '../../commons/Loading';

const EditOfferTemplate = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `getoffertemplatebyid_${id}`,
        service: getoffertemplatebyid,
        props: { id },
    })

    return (
        <VStack p='3' space='2' w={'100%'} maxW={500}>
            <CompHeader title='Edit offer Template' backButton />
            {isLoading ? <Loading /> :
                <AddOfferTemplate data={data} />}
        </VStack>
    )
}

export default EditOfferTemplate