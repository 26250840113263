import React from 'react'
import { VStack } from 'native-base'
import { HStack, Text } from '@chakra-ui/react'
import useFetch from '../../../hook/useFetch'
import Loading from '../../../commons/Loading'
import { getStoreVersionHistory } from '../../../apis/store'
import { useParams } from 'react-router-dom'
import { formatDateTimes } from '../../../utils/moment'
import { colors } from '../../../theme/colors'

const StoreVersionHistory = ({ form, form2 }) => {
    const { id } = useParams();
    const { data, isLoading } = useFetch({ name: 'getStoreVersionHistory', service: getStoreVersionHistory, id })


    if (isLoading) return <Loading />

    return (
        <VStack space={2} borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
            <Text fontWeight={'semibold'} fontSize='lg' color='gray.600' >
                Store Version History
            </Text>

            <VStack>
                {data.map((item, index) =>
                    <HStack px={4} py={1} key={index} justifyContent={'space-between'}
                        _hover={{ bg: 'gray.50' }}
                        borderWidth={item.current ? 2 : 1}
                        borderColor={item.current ? "green.500" : 'gray.200'} space={4} >
                        <Text color='gray.400' w={10}>
                            {index + 1} :-
                        </Text>
                        <Text flex={1}>
                            {formatDateTimes(item[item.current ? 'updatedAt' : 'createdAt'], 5)}
                        </Text>
                        <Text flex={1} color='teal.500' fontWeight={'semibold'} cursor='pointer' >
                            {item.current ? 'Active' : ''}
                        </Text>
                        <Text flex={1}>
                            <Text as='span' color={item.verified ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                                Verified-{item.verified ? 'Y' : 'N'}/
                            </Text>
                            <Text as='span' color={item.claimed ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                                Claimed-{item.claimed ? 'Y' : 'N'}/
                            </Text>
                            <Text as='span' color={item.pre_register ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                                Pre-Register-{item.pre_register ? 'Y' : 'N'}
                            </Text>
                        </Text>

                        <Text color='teal.500' fontWeight={'semibold'} cursor='pointer'
                            onClick={() => {
                                form.reset({ ...item, viewOnly: true })
                                form2.reset({ ...item, default: item, viewOnly: false })
                            }}
                        >
                            Edit
                        </Text>

                    </HStack>
                )}

            </VStack>
        </VStack>
    )
}

export default StoreVersionHistory