import moment from "moment"

export const getDiff = (timestamp, deadline) => {
    if (!deadline) return
    const a = moment(timestamp);
    const b = moment(deadline);
    return b.diff(a);
}

const small_date_time = 'DD MMM YY, h:mm a'
const long_date_time = 'dddd, MMMM Do YYYY, h:mm:ss a'
// const long_date = 'dddd, MMMM Do YYYY, h:mm:ss a'
const small_date = 'MMM DD, YYYY'
const small_time = 'hh:mm a'
// const date_time2 = 'hh:mma, ddmm'

const getFormat = (x) => {
    switch (x) {
        case 1:
            return 'hh:mmA, DD MMM'
        case 2:
            return 'DD MMM'
        case 3:
            return 'DD MMM YYYY'
        case 4:
            return 'DD MMM YYYY | hh:mmA'
        case 5:
            return 'DD MMM YYYY | hh:mm:ssA'
        case 6:
            return 'hh:mm:ssA | DD MMM YYYY'
        case 7:
            return 'hh:mm:ssA | DD MMM YYYY'
        case 8:
            return 'DD MMM | hh:mmA'
        case 9:
            return 'hh:mmA'
        case 10:
            return 'hh:mm:ssA'
        default:
            return small_date
    }
}


// export const formatDateTimes = (value) => {
//     formatDateTimes()
// }
export const formatDateTimes = (timestamp, formatNumber) => {
    if (!timestamp) return "--";
    return moment(timestamp).utcOffset("+05:30").format(getFormat(formatNumber));
}
export const formatSmallTime = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment(timestamp).utcOffset("+05:30").format(small_time);
}
export const formatDateTime = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment(timestamp).utcOffset("+05:30").format(long_date_time);
}

export const formatDateTimeSmall = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment(timestamp).utcOffset("+05:30").format(small_date_time);
}

export const formatDateSmall = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment(timestamp).utcOffset("+05:30").format(small_date);
}

export const formatUnixDateTime = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment.unix(timestamp).utcOffset("+05:30").format(long_date_time);
}

export const formatUnixDateTimeSmall = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment.unix(timestamp).utcOffset("+05:30").format(small_date_time);
}

export const formatUnixDateSmall = (timestamp) => {
    if (!timestamp) return "Timestamp not Found";
    return moment.unix(timestamp).utcOffset("+05:30").format(small_date);
}

export const getHourDiff = (s, e) => {
    if (!e) return
    const a = moment(s);
    const b = moment(e);
    return b.diff(a, 'hour');
}
export const getMinuteDiff = (s, e) => {
    if (!e) return
    const a = moment(s);
    const b = moment(e);
    return b.diff(a, 'minute');
}

export const getSecDiff = (s, e) => {
    if (!e) return
    const a = moment(s);
    const b = moment(e);
    return b.diff(a, 'second');
}

export const getDayDiff = (s, e) => {
    if (!e) return
    const a = moment(s);
    const b = moment(e);
    return b.diff(a, 'day');
}

export const getHourDiffUnix = (timestamp, deadline) => {
    if (!deadline) return
    const a = moment.unix(timestamp);
    const b = moment.unix(deadline);
    const hours = b.diff(a, 'hours')
    if (hours === 0) {
        const mins = b.diff(a, 'minutes')
        if (mins === 0) {
            return `${b.diff(a, 'seconds')} seconds`;
        }
        return `${mins} minutes`
    }
    return `${hours} hours`
}

export const fromNow = (time) => {
    if (!time) return
    return moment(time).utcOffset("+05:30").fromNow();
}

export const fromNowUnix = (time) => {
    if (!time) return
    return moment.unix(time).utcOffset("+05:30").fromNow();
}

export const getPastDate = (days) => {
    const date = new Date()
    date.setDate(date.getDate() - days)
    return date
}

export const getPastHour = (hours) => {
    const date = new Date()
    date.setHours(date.getHours() - hours)
    return date
}

export const getPastMinute = (minutes) => {
    const date = new Date()
    date.setMinutes(date.getMinutes() - minutes)
    return date
}

export const getPastSec = (sec) => {
    const date = new Date()
    date.setSeconds(date.getSeconds() - sec)
    return date
}



export const getTimeListWithIntervals = ({ start, end, interval = 60_000 }) => {
    if (end && end > new Date()) end = new Date()

    if (!start) {
        start = new Date()
        start.setHours(0, 0, 0, 0)
    }

    if (!end) {
        end = new Date()
        // end.setHours(23, 59, 59, 999)
    }

    let time = start.getTime()
    const times = []
    while (time <= end.getTime()) {
        times.push(time)
        time += interval
    }
    return times
}


// seconds to MM:ss

export const secondsToMMSS = (seconds) => {
    return moment.utc(seconds * 1000).format('mm:ss')
}


export const formatTimestamp = (timestamp, type) => {
    if (!timestamp) return "--";

    if (timestamp.toString().length === 13)
        timestamp /= 1000
    const date = moment.unix(timestamp)
    return formatDateTimes(date, type)
}


// convert seconds to mm:ss
export const formatSeconds = (seconds) => {
    return moment.utc(seconds * 1000).format('mm:ss')
}