import { Text } from '@chakra-ui/react';
import { formatDateTimes, fromNow } from '../../../utils/moment';
import QRCode from "react-qr-code";

export const columns = (navigate) => [
    {
        name: 'QR',
        button: true,
        cell: (row) =>
            <QRCode
                id={row.qr}
                value={row.qr}
                // onClick={() => navigate(`/admin/qr/edit/${row._id}`)}
                size={100}
                style={{ margin: 'auto', padding: 10 }}
            />
        ,
        sortable: false,
    },
    {
        name: 'Serial No',
        selector: (row) => row?.serial_no,
        sortable: true,
    },
    {
        name: 'Grabbed',
        selector: (row) => row?.user ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Coupon',
        selector: (row) => `${row?.coupon?.name}(${row?.coupon?.code})`,
        sortable: true,
    },
    {
        name: 'Valid Till',
        selector: (row) => `${formatDateTimes(row?.coupon?.expiry, 2)}(${fromNow(row?.coupon?.expiry)})`,
        sortable: true,
    },
    {
        name: 'Redeemed',
        cell: (row) => <Text color={row?.redeemed ? 'green.500' : 'red.500'} > {row?.redeemed ? 'Yes' : 'No'} </Text>,
        sortable: true,
    },
    {
        name: 'Redeemed At',
        selector: (row) => row?.redeemed ? `${formatDateTimes(row?.redeemed_at, 4)}(${fromNow(row?.redeemed_at)})` : 'Not Redeemed',
        sortable: true,
    },
    {
        name: 'Assigned At',
        selector: (row) => row?.user ? `${formatDateTimes(row?.assigned_at, 4)}(${fromNow(row?.assigned_at)})` : '--',
        sortable: true,
    },
] 