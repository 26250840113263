import { Box, Button, HStack, Input, Spacer, Text, VStack } from 'native-base'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { addOfferTemplateList, deleteOfferTemplateListById, editOfferTemplateListById } from '../../apis/admin'
import { InputField } from '../../commons/InputField'
import useSubmit from '../../hook/useSubmit'
import { colors } from '../../theme/colors'
import { useNavigate } from 'react-router-dom';
import { convertStrToKeys } from '../../helper/stringOperations';

const defaultValues = { name: '', options: ['', '', ''] }

const AddOfferTemplateList = ({ data }) => {
    const form = useForm({ defaultValues: data ? data : defaultValues })
    const [str, setStr] = useState('')
    const [separator, setSeparator] = useState('')
    const state = { ...form.getValues() }
    const navigate = useNavigate()

    const { submit, isLoading } = useSubmit({
        service: data ? editOfferTemplateListById : addOfferTemplateList,
        id: data ? data._id : null,
        onSuccess: () => navigate(-1)
    })

    const Delete = useSubmit({
        service: deleteOfferTemplateListById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    useMemo(() => { }, [form.watch()])

    const save = () => {
        const value = { ...state, options: state.options.filter(item => item !== '') }
        submit(value)
    }

    return (
        <VStack>
            <VStack space="2" p="3"  >
                <VStack space="2" p="3" w={'100%'} maxW={500}>
                    <InputField form={form} title='List Name' id='name' />
                    <VStack space='2' borderWidth={'1'} p='2' borderRadius={'md'} borderColor={colors.border}>
                        <Text>Add multiple keys</Text>
                        <Box alignItems="center" w="100%">
                            <Input w={'100%'} flex='1' variant='filled' borderColor='gray.300'
                                value={str} placeholder='Add here' onChange={e => setStr(e.target.value)}
                            />
                        </Box>
                        <Input
                            flex='1' variant='filled'
                            borderColor='gray.300'
                            value={separator}
                            placeholder='Separator(space, comma, etc)'
                            onChange={e => setSeparator(e.target.value)}
                        />
                        {/* waring */}
                        <Text color='yellow.500'>Review all keys after adding</Text>
                        <Button
                            disabled={str === ''}
                            size={'sm'}
                            onPress={() => {
                                form.setValue(`options`, convertStrToKeys(str, separator))
                                setStr('')
                            }}>Add to list</Button>
                    </VStack>
                </VStack>

                <HStack space='2' my='2' flexWrap={'wrap'} >
                    {state.options?.map((item, i) => (
                        <HStack m='2' space='2' alignItems={'center'}>
                            <InputField form={form} title={`Option ${i + 1}`}
                                autoCapatilize id={`options[${i}]`} />
                            <Button
                                variant={'solid'} borderRadius='md' h='6'
                                w={'6'} bg='red.400' alignItems={'center'}
                                justifyContent={'center'}
                                onPress={() => {
                                    const keys = state.options
                                    keys.splice(i, 1)
                                    form.setValue(`options`, keys)
                                }}>
                                <Text color='white'>-</Text>
                            </Button>
                        </HStack>
                    ))}
                </HStack>
                <Button
                    w='fit-content' alignSelf={'flex-end'}
                    size='sm' variant={'link'}
                    onPress={() => {
                        const keys = state.options
                        keys.push('')
                        form.setValue(`options`, keys)
                    }}>
                    Add More
                </Button>
                <HStack alignItems={'center'} >
                    <Button isLoading={isLoading} w='200'
                        backgroundColor={data ? 'green.900' : 'blue.400'}
                        size='sm'
                        onPress={save}
                    >{data ? 'Update Template List' : "Add Template List"}</Button>
                    <Spacer />
                    {data && <Button w='200' m='auto'
                        colorScheme={'red'}
                        isLoading={Delete.isLoading}
                        onPress={Delete.submit} size='sm' >
                        Delete Template List
                    </Button>}
                </HStack>
            </VStack>
        </VStack>
    )
}

export default AddOfferTemplateList