import { Button, Box, HStack, Pressable, Text, VStack } from 'native-base'
import React, { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { editProductsWearsById, getClothingTypesByGenders, getgender, getProductsWearsById, getwears } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import { colors } from '../../theme/colors'
import { CheckboxGroup, SimpleGrid } from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/react';

const EditProductWear = () => {
    const { id, name } = useParams()
    const [gender, setGender] = useState(null)
    const [selected, setSelected] = useState([])
    const navigate = useNavigate()

    const genders = useFetch({ service: getgender, name: 'genders', dataOnly: true })
    const wears = useFetch({
        service: getClothingTypesByGenders,
        name: 'getClothingTypesByGenders',
        dataOnly: true,
        id: gender,
        condition: !!gender,
        conditionalRefetch: [gender],
    })

    const { isLoading } = useFetch({
        name: `ClothingTypes_${id}`,
        service: getProductsWearsById,
        id,
        onSuccess: (data) => setSelected(data)
    })

    useEffect(() => {
        if (isLoading || gender) return
        setGender(genders[0]?._id)
    }, [genders, isLoading])

    const Save = useSubmit({ service: editProductsWearsById, id, onSuccess: () => navigate(-1) })

    const CheckBoxCompo = useMemo(() => {
        if (!gender || isLoading) return <Loading />
        const i = selected.findIndex(item => item.gender === gender)
        if (i === -1) {
            setSelected([...selected, { gender, wears: [] }])
            return
        }

        return (
            <CheckboxGroup value={selected?.[i]?.wears} colorScheme='red'
                onChange={e => {
                    const _selected = [...selected]
                    _selected[i].wears = e
                    setSelected(_selected)
                }}
            > <SimpleGrid columns={[2, 8]} spacing='1'>
                    {wears?.map(item =>
                        <Checkbox key={item._id} value={item._id} > {item.wear} </Checkbox>
                    )}
                </SimpleGrid>
            </CheckboxGroup>
        )
    }, [selected, gender, wears])


    if (isLoading) return <Loading />

    return (
        <VStack>
            <CompHeader title={`Wear Selection: ${name}`} backButton />
            <VStack>
                <HStack space='4'>
                    {genders?.map(item =>
                        <Pressable key={item._id} onPress={() => setGender(item._id)}>
                            <Box bg={item._id === gender ? colors.primary : colors.border}
                                p='2' px='4'
                                borderRadius={'lg'} >
                                <Text color={item._id === gender ? colors.light : colors.dark}>{item.type}</Text>
                            </Box>
                        </Pressable>
                    )}
                </HStack>

                <VStack space='2' p='4' bg={colors.light} h={500} overflowY='scroll' my='4' borderRadius={'lg'}>
                    {CheckBoxCompo}
                </VStack>

                <Button w='150' colorScheme={'red'} size='sm'
                    onPress={() => Save.submit(selected)}
                    isLoading={Save.isLoading}
                > Save </Button>
            </VStack>
        </VStack>
    )
}

export default EditProductWear