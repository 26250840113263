import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import SelectField from '../../commons/SelectField'
import { InputField } from '../../commons/InputField'
import { Button, HStack } from '@chakra-ui/react'
import { createQR, editQRById } from '../../apis/admin'
import useSubmit from '../../hook/useSubmit'
import useFetch from '../../hook/useFetch'
import { getstores } from '../../apis/store'
import { formatDateTimes } from '../../utils/moment'
import QRCode from "qrcode.react";

const defaultValues = { store: '', link: '', active: 'true', }

const CreateQr = ({ data }) => {
    if (data) {
        data = {
            ...data,
            active: data?.active?.toString(),
            store: data?.store ? { label: `${data?.store?.store_name} || ${data?.store?.store_address?.address} || ${formatDateTimes(data?.store?.createdAt, 4)}`, value: data?.store?._id } : null
        }
    }

    const form = useForm({ defaultValues: data || defaultValues })
    const navigate = useNavigate()

    const stores = useFetch({
        name: 'getstores',
        service: getstores,
        params: { limit: 0 },
        dataOnly: true,
    })

    const list = [
        {
            title: `Store (${stores?.length})`, id: 'store', list: stores?.map(item =>
            ({
                label: `${item.store_name} || ${item?.store_address?.address} || ${formatDateTimes(item.createdAt, 4)}`,
                value: item._id,
            })),
            reactSelect: true,
            isMulti: false
        },
        { title: 'Link', id: 'link', disabled: form.watch('store')?.value },
        { title: 'Code', id: 'code', disabled: true, show: !!data },
        { title: 'Short Link', id: 'short_link', disabled: true },
        { title: 'Active', id: 'active', list: [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }] },
    ]

    const { submit, isLoading } = useSubmit({
        service: data ? editQRById : createQR,
        id: data?._id,
        onSuccess: (res) => data ? navigate(`/admin/qr`) : navigate(`/admin/qr/edit/${res._id}`),
    })

    const save = () => {
        const state = form.getValues()
        submit({
            ...state,
            store: state.store?.value,
        })
    }

    const link = data?.short_link

    const downloadQR = () => {
        const canvas = document.getElementById(link);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${data?.code}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <VStack w={'100%'} alignItems={'flex-start'} bg='white' p={4} borderRadius={8} boxShadow='md' space={5}>
            <CompHeader title={data ? "Edit Qr" : 'Create QR'} backButton />

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={120} key={index} form={form} {...item} />
            })}

            {data ?
                <VStack alignItems={'flex-start'}>
                    <QRCode id={link} value={link} size={290} level={"H"} includeMargin={true} />
                    <Button w='100' variant='solid' onClick={downloadQR}>Download QR</Button>
                </VStack>
                : null}

            <HStack>
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={save}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update QR' : "Create QR"}</Button>
            </HStack>
        </VStack>

    )
}

export default CreateQr