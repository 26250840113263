import React from 'react'
import DatePicker from 'react-datepicker';
import { colors } from '../theme/colors';
import { getDayDiff, getPastDate } from '../utils/moment';
import { HStack, Box, Input, VStack, Text, Button } from '@chakra-ui/react';

const DateRangeSelector = ({
    range = [getPastDate(30), new Date()],
    setRange = () => { }
}) => {

    const shortHands = [
        {
            label: 'Today',
            range: [new Date(), new Date()]
        },
        {
            label: 'Yesterday',
            range: [getPastDate(1), getPastDate(1)]
        },
        {
            label: 'Last 2 Days',
            range: [getPastDate(2), new Date()]
        },
        {
            label: 'Last 3 Days',
            range: [getPastDate(3), new Date()]
        },
        {
            label: 'Last 7 Days',
            range: [getPastDate(7), new Date()]
        },
        {
            label: 'Last 30 Days',
            range: [getPastDate(30), new Date()]
        },
        {
            label: 'Last 90 Days',
            range: [getPastDate(90), new Date()]
        },
        {
            label: '6 Months',
            range: [getPastDate(180), new Date()]
        },
        {
            label: '1 Year',
            range: [getPastDate(365), new Date()]
        },
    ]

    return (
        <VStack w={'100%'} alignItems={'flex-start'} p={4} bg={'white'} borderRadius={8} boxShadow={'md'}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
                Range ( {getDayDiff(range[0], range[1])} days )
            </Text>

            <HStack w='100%' alignItems={'center'} justifyContent={'space-between'}>
                {shortHands.map(({ label, range: dateRange }) =>
                    <Button key={label} size='sm' onClick={() => setRange(dateRange)}>
                        {label}
                    </Button>
                )}
            </HStack>

            <HStack w='100%' alignItems={'center'} >
                <Box zIndex='1' flex={1}>
                    <Text fontWeight={'semibold'}>From</Text>
                    <DatePicker
                        selected={range[0]}
                        onChange={(date) => setRange([date, range[1]])}
                        maxDate={range[1]}
                        dateFormat={'dd/MM/yyyy'}
                        customInput={
                            <Input
                                variant={"outline"}
                                size="lg"
                                id="offer"
                                placeholder="From"
                                bg={colors.inputBg}
                            />
                        }
                    />
                </Box>
                <Box zIndex='1' flex={1}>
                    <Text fontWeight={'semibold'}>To</Text>
                    <DatePicker
                        dropdownMode
                        minDate={range[0]}
                        maxDate={new Date()}
                        selected={range[1]}
                        dateFormat={'dd/MM/yyyy'}
                        onChange={(date) => setRange([range[0], date])}
                        customInput={
                            <Input
                                variant={"outline"}
                                size="lg"
                                id="offer"
                                placeholder="From"
                                bg={colors.inputBg}
                            />
                        }
                    />
                </Box>
            </HStack>
        </VStack>
    )
}

export default DateRangeSelector