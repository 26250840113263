import { Text, VStack } from 'native-base'
import React from 'react'
import { useParams } from 'react-router-dom'
import { getpaymentbyid } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import Tile from '../../commons/Tile'
import useFetch from '../../hook/useFetch'
import { colors } from '../../theme/colors'
import { formatDateTimes } from '../../utils/moment'

const contProps = { borderWidth: 0, bg: colors.light, p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const PaymentsDetails = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `payment_${id}`,
        service: getpaymentbyid,
        props: { id },
    })

    if (isLoading) return <Loading />

    return (
        <VStack>
            <CompHeader title='Payment Details' backButton />
            <VStack space='4'>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2' > Payment Details</Text>
                    <Tile fw='500' label='Payment ID' value={data?.razorpay?.payment_id} />
                    <Tile fw='500' label='Order ID' value={data.order_id} />
                    <Tile fw='500' label='Amount' value={data?.amount / 100} />
                    <Tile fw='500' label='Status' value={data.status} />
                    <Tile fw='500' label='Method' value={data?.razorpay?.method} />
                </VStack>

                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'> Payee Details</Text>
                    <Tile fw='500' label='ID' value={data.user._id} />
                    <Tile fw='500' label='Name' value={data.user.name} />
                    <Tile fw='500' label='Phone Number' value={data.user.phone_number} />
                    <Tile fw='500' label='Role' value={data.user.role} />
                    <Tile fw='500' label='gender' value={data.user.gender.type} />
                    <Tile fw='500' label='State' value={data.user.state.name} />
                    <Tile fw='500' label='City' value={data.user?.city?.name} />
                </VStack>


                {/* acquirer_data */}
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'> Acquirer Details</Text>
                    <Tile fw='500' label='RRN' value={data?.razorpay?.acquirer_data?.rrn} />
                    <Tile fw='500' label='UPI Transaction ID' value={data?.razorpay?.acquirer_data?.upi_transaction_id} />
                </VStack>

                {/* payee_details */}
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'> Other Details</Text>
                    <Tile fw='500' label='Card Id' value={data?.razorpay?.payee_details?.card_id} autoHide />
                    <Tile fw='500' label='Bank' value={data?.razorpay?.payee_details?.bank} autoHide />
                    <Tile fw='500' label='Wallet' value={data?.razorpay?.payee_details?.wallet} autoHide />
                    <Tile fw='500' label='vpa' value={data?.razorpay?.payee_details?.vpa} autoHide />
                    <Tile fw='500' label='Email' value={data?.razorpay?.payee_details?.email} autoHide />
                    <Tile fw='500' label='Contact' value={data?.razorpay?.payee_details?.contact} autoHide />
                </VStack>

                {/* Dates */}
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'> Dates </Text>
                    <Tile fw='500' label='Initiated' value={formatDateTimes(data?.createdAt, 5)} />
                    <Tile fw='500' label='Completed' value={data.status === 'pending' ? data.status : formatDateTimes(data?.updatedAt, 5)} />
                </VStack>

                {/* Plans Details */}
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'> Plans Details </Text>
                    <Tile fw='500' label='Code' value={data.sub_id?.code} />
                    <Tile fw='500' label='Name' value={data.sub_id?.name} />
                    <Tile fw='500' label='Duration' value={`${data.sub_id?.duration} days`} />
                    <Tile fw='500' label='Price' value={data.sub_id?.price} />
                </VStack>

            </VStack>
        </VStack>
    )
}

export default PaymentsDetails