import React, { } from 'react'
import { VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader';
import { useParams } from 'react-router-dom'
import { getchainsbyid } from '../../apis/admin'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading';
import CreateChain from './CreateChain'

const EditChain = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `chain_${id}`,
        service: getchainsbyid,
        id
    })

    return (
        <VStack p='3' space='2' w={'100%'} >
            <CompHeader title='Edit Chain' backButton />
            {isLoading ? <Loading /> :
                <CreateChain data={data} />
            }
        </VStack>
    )
}

export default EditChain