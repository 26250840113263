import Tile from '../../../commons/Tile'
import React, { useEffect } from 'react'
import useFetch from '../../../hook/useFetch'
import SelectField from '../../../commons/SelectField'
import { formatDateTimes } from '../../../utils/moment'
import { InputField } from '../../../commons/InputField'
import { getAppEvents, getRestaurants } from '../../../apis/admin'
import { HStack, Text, Textarea, VStack } from '@chakra-ui/react'
import { RUPEES_SYMBOL, formatAmount } from '../../../helper/stringOperations'
// import ProductAutocomplete from './ProductAutocomplete'

const couponTypes = [
    { label: 'Percentage', value: 'per' },
    { label: 'Flat', value: 'flat' },
    { label: 'Gift/item Based', value: 'gift_based' },
    { label: 'Other', value: 'other' },
]

const FoodCouponForm = ({ data, form, restaurant_id, restaurant_name }) => {

    const value = form.watch()

    const stores = useFetch({
        name: 'getstores',
        service: getRestaurants,
        params: { limit: 0 },
        condition: !restaurant_id,
        dataOnly: true,
    })

    const appEvents = useFetch({ service: getAppEvents, dataOnly: true, name: 'getAppEvents', params: { limit: 0 } })

    const list = [
        { title: 'Coupon id', id: '_id', disabled: true, show: !!data },
        { title: 'Code', id: 'code' },
        { title: 'Name', id: 'name' },
        ...(!restaurant_id ? [{
            title: `Restaurant (${stores?.length})`, id: 'store', list: stores?.map(item =>
            ({
                label: `${item.name} || ${item?.address?.address} || ${formatDateTimes(item.createdAt, 4)}`,
                value: item._id,
            })),
            reactSelect: true,
            isMulti: false,
            disabled: !!data,
        }] :
            [{ title: 'Restaurant', id: 'other_store', defaultValue: restaurant_name, disabled: true }]
        ),
        { title: "Type", id: "type", list: couponTypes },
        { title: 'Item Name', id: 'item_name', show: value.type === 'gift_based' },
        { title: 'Discount', id: 'discount', type: 'number', show: value.type !== 'gift_based' },
        { title: 'Max Redeems', id: 'max_redeems', type: 'number', disabled: !!data },
        { title: 'Active', id: 'active', list: ['true', 'false'].map(item => ({ label: item.toString(), value: item })) },
        { title: 'Lottery', id: 'lottery', list: ['true', 'false'].map(item => ({ label: item.toString(), value: item })), show: value.type !== 'gift_based' },
        { title: 'Min Lottery Discount', id: 'constraints.min_lottery_discount', type: 'number', disabled: value.lottery !== 'true', show: value.lottery === 'true' || value.lottery === true },
        { title: 'Max Lottery Discount', id: 'constraints.max_lottery_discount', type: 'number', disabled: value.lottery !== 'true', show: value.lottery === 'true' || value.lottery === true },
        { title: 'Public', id: 'public', list: ['true', 'false'].map(item => ({ label: item.toString(), value: item })) },
        { title: 'Paid', id: 'paid', list: ['true', 'false'].map(item => ({ label: item.toString(), value: item })) },
        { title: 'Price', id: 'price', type: 'number', disabled: value.paid !== 'true', show: value.paid === 'true' || value.paid === true },
        { title: "App Events", id: "app_event", list: appEvents.map(item => ({ label: item.title, value: item._id })), reactSelect: true, isMulti: true },
        // ...(
        //     value?.products?.map((item, i) => ({
        //         title: `Product: ${item.label}`,
        //         id: `typed_products[${i}]`,
        //         defaultValue: item.label
        //     }))
        // )
    ]

    const list2 = [
        { title: 'Start Date', id: 'start_date', date: true },
        { title: 'End Date', id: 'expiry', date: true },
    ]

    const constraints = [
        { title: 'Min Amount', id: 'constraints.min_amount', type: 'number' },
        { title: 'Max Discount', id: 'constraints.max_discount', type: 'number' },
        { title: 'Min Items', id: 'constraints.min_items', type: 'number' },
        // { title: 'Max Amount', id: 'constraints.max_amount', type: 'number' },
        // { title: 'Max Items', id: 'constraints.max_items', type: 'number' },
        // { title: 'Styles', id: 'constraints.styles', type: 'number' },
    ]

    const estBudget = (() => {
        const value = form.watch()

        const n = value?.max_redeems
        const discount = value?.discount
        const type = value?.type
        const min_amt = value?.constraints?.min_amount
        const max_dis = value?.constraints?.max_discount

        const overall_min_sales = min_amt ? min_amt * n : 0

        const overall_min_dis = (() => {

            if (!n || !discount || !type) return 0

            if (type === 'per') return overall_min_sales * discount / 100

            return n * discount

        })();

        return {
            overall_min_dis,
            overall_min_sales,
        }
    })()

    const stats = [
        { label: 'Number of Coupons', value: value.max_redeems },
        { label: 'Min Sales', value: estBudget?.overall_min_sales },
        { label: 'Discount', value: estBudget?.overall_min_dis },
    ]

    const termsFromConstraints = () => {
        const value = form.watch()

        const min_amt = value?.constraints?.min_amount
        const min_items = value?.constraints?.min_items
        const max_dis = value?.constraints?.max_discount

        const terms = []

        if (min_amt) terms.push(`Shop for ${RUPEES_SYMBOL}${min_amt} to get discount.(Auto Applied)`)
        if (max_dis) terms.push(`Maximum discount is upto ${RUPEES_SYMBOL}${max_dis}.(Auto Applied)`)
        if (min_items) terms.push(`Only Valid for ${min_items} items.(Auto Applied)`)

        const _terms = form.watch('terms')
            ?.split('\n')
            ?.map(item => item.trim())
            ?.filter(item => item)
            ?.filter(item => !item?.includes('(Auto Applied)')) || []

        const auto_applied_terms = terms?.map(item => item?.trim()).filter(item => item)

        const new_terms = [
            ..._terms,
            ...auto_applied_terms
        ].join('\n')

        form.setValue('terms', new_terms)
    }

    useEffect(() => {
        if (!data)
            termsFromConstraints()
    }, [
        form.watch('constraints.min_amount'),
        form.watch('constraints.min_items'),
        form.watch('constraints.max_discount'),
    ])

    return (
        <>
            <VStack alignItems={'flex-start'} w="100%">
                {list.map((item, index) => {
                    const Comp = item.list ? SelectField : InputField
                    return <Comp tw={120} key={index} form={form} {...item} />
                })}
            </VStack>

            {/* <ProductAutocomplete /> */}

            <HStack w="100%" justifyContent="space-between">
                {list2.map((item, index) => {
                    const Comp = item.list ? SelectField : InputField
                    return <Comp tw={120} key={index} form={form} {...item} />
                })}
            </HStack>

            <HStack alignItems={'flex-start'} w="100%">
                <HStack alignItems={'center'} mt='4.6'>
                    <Text fontWeight='600' w={120}>Terms</Text>
                    <Text fontWeight='600'>:</Text>
                </HStack>
                <Textarea rows={5} variant='filled' borderColor='white' bg='white'
                    value={form.watch('terms')} placeholder='Terms'
                    _focus={{ borderColor: 'gray.200', bg: 'white' }}
                    _hover={{ borderColor: 'white', bg: 'white' }}
                    onChange={e => form.setValue('terms', e.target.value)}
                />
            </HStack>

            <Text fontWeight='bold' fontSize='md' pt={10} w="100%" color='blue.500' mb={4}
                cursor='pointer' onClick={() => window.open(`/admin/reviewStore/${form.watch('restaurant').value}`, '_blank')} >
                Visit - {form.watch('restaurant')?.label}
            </Text>

            <HStack alignItems={'flex-start'} spacing={10} w="100%">
                <VStack alignItems={'flex-start'} w="100%" spacing={2} maxW={500}>
                    <Text w="100%" pt="10" fontWeight="bold" fontSize="xl" >
                        Constraints
                    </Text>
                    {constraints.map((item, index) => {
                        const Comp = item.list ? SelectField : InputField
                        return <Comp tw={120} key={index} form={form} {...item} />
                    })}
                </VStack>

                <VStack alignItems={'flex-start'} w="100%" spacing={2} maxW={500}>
                    <Text w="100%" pt="10" fontWeight="bold" fontSize="xl" >
                        Stats
                    </Text>
                    {stats.map((item) =>
                        <Tile key={item.label} label={item.label} value={formatAmount(item.value)} />
                    )}
                </VStack>
            </HStack>
        </>
    )
}

export default FoodCouponForm