import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { dataExtractor } from './useFetch';

const useSubmit = ({
    service = () => { },
    dataOnly = false,
    id,
    onSuccess = () => { },
    successToast = true,
    props = {},
    condition = true,
    conditionalRefetch,
    alert = false,
    alertMessage = 'Are you sure?',
}) => {

    let { mutate, isLoading, isSuccess, error, data } = useMutation(data => service({
        id,
        ...props,
        data: data || props?.data,
    }))

    useEffect(() => {
        if (isSuccess && onSuccess) onSuccess(data)
        if (error) { toast.error(error?.response?.data?.error?.[1] || error?.message) }
        if (isSuccess && successToast) { toast.success('Success') }
    }, [error, isSuccess])

    data = dataExtractor(data)

    if (dataOnly) return data

    return {
        submit: mutate,
        data,
        isLoading,
        isSuccess,
        error
    }
}

export default useSubmit