import { formatDateTimes, formatTimestamp } from "../../utils/moment";
import { formatNumber } from "../../helper/stringOperations";
import { HStack, Spacer, Text, VStack } from "@chakra-ui/react";

export const columns = (navigate, setCall) => [
    {
        name: 'Message type',
        selector: (row) => row?.auth ? 'Store' : row?.user ? 'User' : 'Unknown',
        sortable: true,
    },
    {
        name: 'From',
        cell: (row) =>
            row?.auth ?
                <VStack alignItems={"flex-start"}
                    onClick={() => window.open(`/admin/reviewStore/${row.auth.store_id}`)}
                    cursor='pointer'>
                    <Text fontWeight={'bold'}>
                        Store:{row?.auth?.store_name}
                    </Text>
                    <Text fontWeight={'bold'}>
                        Auth:{row?.auth?.name}
                    </Text>
                    <Text>
                        {row?.phone_number}
                    </Text>
                </VStack>
                :
                row?.user ?
                    <VStack alignItems={"flex-start"}
                        onClick={() => window.open(`/admin/users/edit/${row.user._id}`)}
                        cursor='pointer'>
                        <Text fontWeight={'bold'}>
                            {row?.user?.name}
                        </Text>
                        <Text>
                            {row?.phone_number}
                        </Text>
                    </VStack>
                    :
                    <VStack alignItems={"flex-start"} cursor='pointer'>
                        <Text fontWeight={'bold'}>
                            {row?.user?.name}
                        </Text>
                        <Text>
                            {row?.phone_number}
                        </Text>
                    </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Message',
        width: '50%',
        cell: (row) =>
            <VStack alignItems={"flex-start"} w="100%">
                {row?.messages?.reverse()?.map(({ message }, index) =>
                    <HStack alignItems={"flex-start"} borderTopWidth={index === 0 ? 0 : 1} w={"100%"}>
                        <Text>
                            {index + 1}.
                        </Text>
                        <VStack flex={1} alignItems={'flex-start'}>
                            {message?.type === 'text' ?
                                <Text cursor='pointer'>
                                    {message?.text?.body}
                                </Text> :
                                message?.type === 'image' ?
                                    <Text>
                                        {`<!-- Image -->`}
                                    </Text>
                                    :
                                    <Text>
                                        {JSON.stringify(message)}
                                    </Text>}
                        </VStack>
                        <Text color={'gray.500'}>
                            {formatTimestamp(message?.timestamp, 5)}
                        </Text>
                    </HStack>
                )}
            </VStack>
        ,
        sortable: true,
    }
]

