import { toast } from 'react-hot-toast';

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Link Copied!')
}
export const getAppLinks = (append) => {
    const baseURL = 'http://offingo.com';
    const link = `${baseURL}${append}`

    copyToClipboard(link)
    return link;
}

