import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { getStories } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const Stories = () => {
    const navigate = useNavigate()
    // const { search } = useLocation();
    // const verified = getURLQuery(search, 'verified') || 'all';

    const { data, isLoading } = useFetch({
        name: 'getStories',
        service: getStories,
        pagination: true,
        // params: { verified },
        // conditionalRefetch: [verified],
    })
    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title='Stories' backButton />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default Stories