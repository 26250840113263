import React from 'react'
import { columns } from './static'
import { VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { getOnboarders } from '../../apis/admin'

const Onboarders = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getOnboarders',
        service: getOnboarders,
        pagination: true,
    })

    return (
        <VStack>
            <CompHeader
                title="Onboarders"
                buttonTitle='Add Onboarder'
                buttonLink='/admin/onboarders/create'
            />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Onboarders