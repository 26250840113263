import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast';
import CustomLoader from './CustomLoader';
import DT from 'react-data-table-component'
import { SearchIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import { getURLQuery } from '../../helper/url';
import { Input, Spacer } from '@chakra-ui/react';
import { textHighlighter } from '../../helper/highlighters';
import useNavigateSearch from '../../hook/useNavigateSearch';
import 'react-data-table-component-extensions/dist/index.css';
import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';

const Conventer = (columns, q) => {
    return columns.map((column) => {
        if (column?.selector) {
            return {
                ...column,
                cell: (row) => {
                    let label = column?.selector(row) || '--'
                    const isLink = label && typeof label === 'string' ? label?.includes('http') : false
                    label = label?.toString()
                    return <Tooltip hasArrow position={'top'} label={label}>
                        <Text onClick={() => {
                            navigator.clipboard.writeText(label)
                            if (isLink) window.open(label, '_blank')
                            toast('Copied to clipboard', { icon: '📋' })
                        }}
                            color={isLink ? 'blue.500' : 'inherit'}
                            cursor={isLink ? 'pointer' : 'inherit'}
                        >
                            {label.toLowerCase().includes(q?.toLowerCase()) ? textHighlighter({ text: label, highlight: [q] }) : label}
                        </Text>
                    </Tooltip>
                },
            };
        }
        return column
    })
}

const DataTable = ({ selectableRows, onSelectedRowsChange, tableData = {}, title = '', pagination = true, showSearch = true, forceLimit, ...props }) => {
    // const profile = useSelector(selectProfile)
    // const admin = profile?.role?.toLowerCase() === 'admin'
    const navigateSearch = useNavigateSearch();
    const [query, setQuery] = React.useState('')

    const { search, pathname } = useLocation()
    const params = getURLQuery(search)
    const { page = 1, q } = params
    const limit = forceLimit || params.limit

    useEffect(() => {
        if (q && !query) setQuery(q)
    }, [q])

    const changePage = (page) => {
        if (page == '0') page = 1
        navigateSearch(pathname, { ...params, page })
    }

    const changeRowsPerPage = (limit) => {
        navigateSearch(pathname, { ...params, limit, page: 1 })
    }

    const _tableData = {
        columns: Conventer(tableData.columns, q),
        data: tableData.data?.data || tableData.data,
    }

    const limits = [...new Set([parseInt(limit), 10, 20, 30, 40, 50, 100])]

    return (
        <Box bg='white' minH={'70vh'} w={'100%'}>
            {showSearch && <HStack p='2' >
                <Spacer />
                <Input maxW={300} placeholder="Search..." value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            navigateSearch(pathname, { ...params, q: query })
                        }
                    }}
                />
                <Button onClick={() => navigateSearch(pathname, { ...params, q: query })}>
                    <SearchIcon />
                </Button>
            </HStack>}
            <DT title={title}
                {..._tableData}
                {...props}
                selectableRows={selectableRows}
                onSelectedRowsChange={onSelectedRowsChange}
                highlightOnHover
                pagination={pagination}
                progressPending={tableData.isLoading}
                paginationServer={tableData.pagination}
                paginationTotalRows={tableData.data.total}
                paginationDefaultPage={page}
                progressComponent={<CustomLoader />}
                onChangePage={changePage}
                paginationServerOptions={{ persistSelectedOnPageChange: true }}
                paginationPerPage={limit}
                paginationRowsPerPageOptions={limits}
                onChangeRowsPerPage={changeRowsPerPage}
            />
        </Box>
    )
}

export default DataTable