import React from 'react'
import { Text, VStack } from 'native-base'
import Tile from '../../commons/Tile'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { colors } from '../../theme/colors'
import { getAvaEnqById } from '../../apis/admin'
import { useNavigate, useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { Button, HStack, Image, Spacer } from '@chakra-ui/react'
import { IMAGE_BASE_URL } from '../../helper/variables'

const contProps = { borderWidth: 0, bg: colors.light, p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const EditAvaEnquires = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading, refetch } = useFetch({
        service: getAvaEnqById,
        name: `getAvaEnqById-${id}`,
        id
    })

    if (isLoading) return <Loading />

    return (
        <VStack >
            <CompHeader title={'Edit Available Enquires'} />
            <VStack space='4'>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Enquiry</Text>
                    <Tile fw='500' label='ID' value={data?._id} />
                    <Tile fw='500' label='Brand' value={data?.brand_id?.brand || 'N/A'} />
                    <Tile fw='500' label='Status' value={data?.status} />
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Users</Text>
                    {data?.user?.map((user, i) => (
                        <HStack key={i} borderBottomWidth={1} >
                            <Tile titleWidth={5} fw='500' label='Sr' value={i + 1} mr={10} />
                            <Tile flex={1} titleWidth={50} fw='500' label='ID' value={user._id} />
                            <Tile flex={1} titleWidth={50} fw='500' label='Name' value={user?.name} />
                            <Tile flex={1} titleWidth={120} fw='500' label='Phone Number' value={user?.phone_number} />
                        </HStack>
                    ))}
                </VStack>

                <VStack {...contProps}>

                    <HStack alignItems={'center'} justifyContent={'space-between'} >
                        <VStack>
                            <Text fontWeight='600' mb='2'>Store </Text>
                            <Tile fw='500' label='ID' value={data?.store._id} />
                            <Tile fw='500' label='Name' value={data?.store.store_name} />
                        </VStack>
                        <Button onClick={() => navigate(`/admin/reviewStore/${data?.store._id}`)} >
                            Go to Store Page
                        </Button>
                        <Image h='100' w='200' resizeMode='contain'
                            borderRadius={'lg'}
                            transition={'all .2s ease-in-out'}
                            _hover={{ cursor: 'pointer', transform: 'scale(1.4)' }}
                            src={`${IMAGE_BASE_URL}${data?.store.store_images?.[0]}`} />
                    </HStack>

                    <VStack >
                        <Text fontWeight='600' mb='2'>Store Auths</Text>
                        {data.auths.map((auth, i) =>
                            <HStack key={i} borderBottomWidth={1} >
                                <Tile titleWidth={5} fw='500' label='Sr' value={i + 1} mr={10} />
                                <Tile flex={1} titleWidth={50} fw='500' label='Role' value={auth.role} />
                                <Tile flex={1} titleWidth={50} fw='500' label='Name' value={auth?.name} />
                                <Tile flex={1} titleWidth={120} fw='500' label='Phone Number' value={auth?.phone_number} />
                            </HStack>
                        )}
                    </VStack>

                </VStack>

                <VStack {...contProps}>
                    <HStack space='4' justifyContent={'space-between'} alignItems={'flex-start'} >
                        <VStack>
                            <Text fontWeight='600' mb='2'>Style</Text>
                            {data?.style?._id ?
                                <>
                                    <Tile fw='500' label='ID' value={data?.style?._id} />
                                    <Tile fw='500' label='Name' value={data?.style?.name} />
                                    <Tile fw='500' label='Brand' value={data?.style?.brand?.brand || 'N/A'} />
                                    <Tile fw='500' label='Display Name' value={data?.style?.display_name} />
                                    <Tile fw='500' label='Product' value={data?.style?.product?.name} />
                                    <Tile fw='500' label='Wear' value={data?.style?.wear?.wear} />
                                    <Tile fw='500' label='Gender' value={data?.style?.gender?.type} />
                                    <Tile fw='500' label='Price' value={data?.style?.price} />
                                    <Tile fw='500' label='Style code' value={data?.style?.product_id || 'N/A'} />
                                </>
                                :
                                <>
                                    <Text>No Style Found</Text>
                                </>
                            }
                        </VStack>
                        <Image
                            _hover={{ cursor: 'pointer', transform: 'scale(1.1)' }}
                            transition={'all .2s ease-in-out'}
                            borderRadius={'lg'}
                            src={`${IMAGE_BASE_URL}${data?.style?.images?.[0]}`} w={'200px'} h={'400px'} />
                    </HStack>
                </VStack>

            </VStack>

        </VStack>
    )
}

export default EditAvaEnquires

