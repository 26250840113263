import { Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { IMAGE_BASE_URL } from '../../../helper/variables'

const OfferCard = ({ item }) => {
    return (
        <VStack space='2' w={'fit-content'} bg='#cfcfcf4D'
            py='2' px='2' borderRadius='md' >
            <Text textAlign={'center'} fontSize='sm'
                fontWeight='normal'>{item.offer}</Text>
        </VStack>
    )
}

export default OfferCard