import React from 'react'
import { VStack } from 'native-base'
import useFetch from '../../../hook/useFetch'
import { getchains, verifyChain } from '../../../apis/admin'
import { Button, HStack, Spacer, Text } from '@chakra-ui/react'
import SelectField from '../../../commons/SelectField'
import useSubmit from '../../../hook/useSubmit'

const StoreChain = ({ form, viewOnly }) => {
    const [refresh, setRefresh] = React.useState(false)
    const _refetch = () => setRefresh(!refresh)
    const state = form.watch()
    const { store_address: { chain } } = state

    const chains = useFetch({
        name: 'chains',
        service: getchains,
        dataOnly: true,
        params: { limit: 0 },
        conditionalRefetch: [refresh]
    })

    const isVerified = chains.find(({ _id }) => _id === form.watch('store_address.chain'))?.status === 'Active'

    const { submit, isLoading } = useSubmit({
        service: verifyChain,
        id: form.watch('store_address.chain'),
        onSuccess: _refetch
    })

    return (
        <VStack space={3} w='100%' alignItems='flex-start'>

            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' > Chain </Text>
                {chain && (isVerified ?
                    <Text color='green.600' fontWeight={'semibold'} fontSize='md'>Verified</Text>
                    :
                    <Text color='red.600' fontWeight={'semibold'} fontSize='md'>Not Verified</Text>
                )}
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('store_address.chain', form.watch('default.store_address.chain'))
                        }}>
                        Reset chain
                    </Text>}
            </HStack>

            <SelectField form={form} value={chain}
                list={chains.map(({ name: label, _id: value, status, type }) => ({ value, label: `${label} ${type === 'Primary' ? "(L)" : "(S)"} ${status !== 'Active' ? "- Not Verified" : ""}` }))}
                onChange={(value) => {
                    form.setValue('store_address.chain', value)
                }}
                disabled={viewOnly} placeholder='Select Chain'
            />

            {!isVerified && form.watch('store_address.chain') && (
                <Button w='100%' alignSelf={'flex-end'} isLoading={isLoading}
                    variant='solid' colorScheme='red' disabled={viewOnly}
                    onClick={() => {
                        submit({
                            chain: form.watch('store_address.chain'),
                            store: form.watch('default._id'),
                            state: form.watch('store_address.state'),
                            city: form.watch('store_address.city'),
                        })
                    }} >
                    Verify this Chain - {chains.find(({ _id }) => _id === form.watch('store_address.chain'))?.name}
                </Button>
            )}

        </VStack>
    )
}

export default StoreChain