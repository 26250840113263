import { Button } from "native-base";
export const columns = (navigate) => [
    {
        name: 'Code',
        selector: (row) => row.code,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Perks',
        selector: (row) => row.perks.join(', '),
        sortable: true,
        width: '30%',
    },
    {
        name: 'Price',
        selector: (row) => `₹ ${row.price}`,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Duration',
        selector: (row) => `${row.duration} days`,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Status',
        selector: (row) => row.status,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Active users',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/subscriptions/store/users/${row._id}`)}>
            Users</Button>,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/subscriptions/store/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]


export const staticData = {
    status: ["Active", "Inactive"].map(item => ({ label: item, value: item })),
    yes_no: ["Yes", "No"].map(item => ({ label: item, value: (item === 'Yes').toString() })),
}


export const properties = [
    {
        label: 'Product upload Count', value: 'product_upload_count',
        desc: 'Product upload count',
    },
    {
        label: 'Landing Page', value: 'landing_page', list: staticData.yes_no,
        desc: 'Landing page for store',
    },
    {
        label: 'Variants Upload Per Enquiry', value: 'variants_upload_per_enquiry', list: null,
        desc: 'Variants upload per enquiry in reponse of CBS enquiry',
    },
    {
        label: 'Varaint upload Count per brand', value: 'variants_upload_count_per_brand',
        desc: 'Product upload count per brand',
    },
    {
        label: 'Enquiry Directly', value: 'enquiry_directly', list: staticData.yes_no,
        desc: 'Enquiry directly',
    },
    {
        label: 'First Priority To Enquiry', value: 'first_priority_to_enquiry', list: staticData.yes_no,
        desc: 'First priority to enquiry over non-premium stores',
    },
    {
        label: 'More Time To Respond', value: 'more_time_to_respond', list: staticData.yes_no,
        desc: 'More time to respond on enquiry',
    },
    {
        label: 'Product Promotion', value: 'product_promotion', list: staticData.yes_no,
        desc: 'Product promotion on product page',
    },
]