import React, { useEffect } from 'react'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import { IMAGE_BASE_URL } from '../../helper/variables'
import { editProductThumbnail } from '../../apis/admin'
import CropArea from '../../components/compo/CropArea/CropArea'
import { multipledataformater } from '../../helper/multipledataformater'
import useSubmit from '../../hook/useSubmit'

const ProductThumnailCard = ({ gender, product_id, product }) => {
    const defaultImage = product?.thumbnails?.find(i => i.gender === gender._id)?.images?.[0]

    const [image, setImages] = React.useState(defaultImage ? `${IMAGE_BASE_URL}${defaultImage}` : '')

    const { submit, isLoading } = useSubmit({
        service: editProductThumbnail,
        id: product_id,
        onSuccess: () => { }
    })

    const save = () => {
        const formData = multipledataformater({
            state: { product_id, gender_id: gender._id, },
            images: [image]
        })

        submit(formData)
    }

    const remove = () => {
        const formData = multipledataformater({
            state: { product_id, gender_id: gender._id, },
            images: [],
            remove: true
        })
        submit(formData)
        setImages(null)
    }

    useEffect(() => {
        if (!defaultImage) return
        setImages(defaultImage ? `${IMAGE_BASE_URL}${defaultImage}` : '')
    }, [defaultImage])

    return (
        <VStack w={'100%'} alignItems={'flex-start'} mb={10}>
            <Text fontSize={'lg'} fontWeight={'bold'}>
                Gender: {gender.type}
            </Text>

            <CropArea aspect={1}
                {...image && { defaultImage: image }}
                removeImage={() => {
                    setImages(null)
                }}
                setImage={(file) => {
                    if (!file) return
                    setImages(file)
                }}
            />

            <HStack w='100%' spacing={2} alignItems='center' mt={4} >
                <Button onClick={save} isLoading={isLoading} variant='solid' colorScheme='blue'
                    size='sm'
                    disabled={!image || (typeof image === 'string' && image?.includes(IMAGE_BASE_URL))}
                >
                    Save {gender.type}
                </Button>
                {defaultImage &&
                    <Button onClick={remove} isLoading={isLoading} variant='solid' colorScheme='red' size='sm' >
                        remove {gender.type}
                    </Button>
                }
            </HStack>
        </VStack>
    )
}

export default ProductThumnailCard