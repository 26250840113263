import React, { useEffect } from 'react'
import useFetch from '../../hook/useFetch'
import { HStack, StackDivider, Text, VStack } from '@chakra-ui/react'
import { getCurrentStats } from '../../apis/admin'
import Tile from '../../commons/Tile'
import Loading from '../../commons/Loading'

const LiveSection = () => {

    const { data, isLoading, refetch } = useFetch({
        name: 'getCurrentStats',
        service: getCurrentStats,
    })
    /*  */
    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    if (isLoading) return <Loading />

    return (
        <VStack bg="white" borderRadius={'lg'}
            shadow={'md'} p={4} alignItems={'flex-start'}>

            <Text fontWeight={'bold'} fontSize={'xl'} color={'gray.500'}>
                Live
            </Text>

            <HStack spacing={4} w={'100%'} alignItems={'flex-start'}
                divider={<StackDivider borderColor="gray.200" />}
            >
                <VStack alignItems={'flex-start'}>
                    <Text fontWeight={'bold'} fontSize={'md'} >
                        Offingo
                    </Text>
                    <Tile label={'Live Now'} value={data?.offingo?.live} />
                    <Tile label={'Active in Last hour'} value={data?.offingo?.live_in_last_hour} />
                </VStack>

                <VStack alignItems={'flex-start'}>
                    <Text fontWeight={'bold'} fontSize={'md'} >
                        Offingo Business
                    </Text>
                    <Tile label={'Live Now'} value={data?.offingo_business?.live} />
                    <Tile label={'Active in Last hour'} value={data?.offingo_business?.live_in_last_hour} />
                </VStack>

            </HStack>




        </VStack>
    )
}

export default LiveSection