import React from 'react'
import { formatDateTimes } from '../../utils/moment'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit'
import { recoverStore } from '../../apis/admin'

const StoreDumpCard = ({ data, refetch }) => {

    const { submit, isLoading } = useSubmit({
        service: recoverStore,
        id: data._id,
        onSuccess: refetch,
    })

    const recover = () => {
        if (window.confirm('Are you sure you want to recover this store?')) {
            submit({})
        }
    }

    return (
        <HStack w="100%" alignItems={'flex-start'} borderRadius={4} p={4} bg="white" boxShadow="md" >

            <VStack alignItems={'flex-start'} flex={1}>
                <Text fontSize="lg" fontWeight={'semibold'} >
                    {data.store.store_name}
                    <Text as='span' fontSize="sm" color='gray.400' ml={2} >
                        Deleted At - {formatDateTimes(data.createdAt, 5)}
                    </Text>
                </Text>
                <Text>
                    {data.store.store_address.address}
                </Text>
            </VStack>

            <VStack alignItems={'flex-start'} flex={1} spacing={0}>
                <Text flex={1}>
                    {data.store.verified &&
                        <Text as='span' color={data.store.verified ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                            Verified {' '}
                        </Text>
                    }
                    {data.store.claimed &&
                        <Text as='span' color={data.store.claimed ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                            || Claimed
                        </Text>
                    }
                    {data.store.pre_register &&
                        <Text as='span' color={'red.500'} fontWeight={'semibold'} >
                            But Pre-Register
                        </Text>
                    }
                </Text>
            </VStack>


            <Button onClick={recover}
                isLoading={isLoading}
            >
                Recover
            </Button>

        </HStack>
    )
}

export default StoreDumpCard