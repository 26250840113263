import { Button, Image } from 'native-base';
import { getAppLinks } from '../../helper/applink';
import { IMAGE_BASE_URL } from '../../helper/variables';
import { truncate } from '../../helper/stringOperations';

export const columns = (navigate) => [
    {
        name: 'Logo',
        cell: (row) => <Image resizeMode='contain' borderRadius={'lg'} cursor='pointer' src={IMAGE_BASE_URL + row.image} h='50' w='50' />,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row.mall_name,
        sortable: true,
    },
    {
        name: 'Phone Number',
        selector: (row) => row?.phone_number,
        sortable: true,
    },
    {
        name: 'State',
        selector: (row) => row.mall_address?.state?.name,
        sortable: true,
    },
    {
        name: 'City',
        selector: (row) => row.mall_address?.city?.name,
        sortable: true,
    },
    {
        name: 'Address',
        selector: (row) => truncate(row.mall_address.address, 15),
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row) => row.status === 'Active' ? 'Verified' : 'Not-Verified',
        sortable: true,
    },
    {
        name: 'Stores',
        selector: (row) => row.stores.length,
        sortable: true,
    },
    // {
    //     name: 'App Link',
    //     button: true,
    //     cell: (row) => <Button
    //         color='blue.400'
    //         size='sm' variant={'link'}
    //         onPress={() => getAppLinks(`/mall/${row._id}`)}>
    //         copy</Button>,
    //     sortable: false,
    // },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/malls/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]