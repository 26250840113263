import { Button } from 'native-base';

export const columns = (navigate) => [
    {
        name: 'Reports',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/onboarders/reports/${row._id}`)}>
            View
        </Button>,
        sortable: false,
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        width: '200px'
    },
    {
        name: 'Phone Number',
        selector: (row) => row.phone_number,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/onboarders/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]