import React from 'react'
import { columns } from './static';
import { Text, VStack } from '@chakra-ui/react'
import useFetch from '../../../../hook/useFetch'
import { getCallRecord } from '../../../../apis/store'
import DataTable from '../../../../commons/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';


const getRecords = (data) => {
    if (!data) return []
    const r = data?.data?.map((item, i) => item.records).flat() || []
    // sort by created at
    return r?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

}

const AllCalls = ({ auth, refresh }) => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getCallRecord,
        id: auth._id,
        conditionalRefetch: [refresh],
    })

    const records = getRecords(data)
    return (
        <VStack w='100%' space='2' px={4} alignItems={'flex-start'}>
            <Text fontSize={'md'} fontWeight='600'>
                All Calls
            </Text>

            <DataTable tableData={{
                columns: columns(navigate),
                data: records,
                pagination: false,
                isLoading
            }}
                pagination={false}
            />

        </VStack>
    )
}

export default AllCalls