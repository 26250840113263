import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, HStack, VStack } from 'native-base'
import { InputField } from '../../commons/InputField';
import { useForm } from 'react-hook-form';
import SelectField from '../../commons/SelectField';
import { createoffertemplate, editoffertemplatebyid, getOfferTemplateList, deleteoffertemplatebyid } from '../../apis/admin';
import useFetch from '../../hook/useFetch';
import useSubmit from '../../hook/useSubmit';

const defaultValues = { template: '', example: '', effectiveDiscount: '', value: [], }

const types = [
    { label: 'Integer', value: 'integer' },
    { label: 'String', value: 'string' },
    { label: 'List', value: 'list' },
]

export default function AddOfferTemplate({ data }) {
    const navigate = useNavigate()
    const form = useForm({ defaultValues: data ? data : defaultValues })

    const List = useFetch({ name: 'template_lists', service: getOfferTemplateList, })

    const state = { ...form.getValues() }
    const { template } = state

    const { submit, isLoading } = useSubmit({
        service: data ? editoffertemplatebyid : createoffertemplate,
        id: data?._id,
        onSuccess: () => navigate(-1)
    })

    const Delete = useSubmit({
        service: deleteoffertemplatebyid,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    useEffect(() => {
        form.setValue('value', [...template.split(' ').map((item, i) => {
            if (item !== '_') return
            const _value = state?.value?.find(_item => parseInt(_item?.value) === parseInt(item))
            return {
                value: i,
                type: _value?.type || 'string',
                ...(_value?.type === 'list' && { list: _value?.list?._id || _value?.list || "" })
            }
        }),].filter(item => !!item))
    }, [template])

    // useEffect(() => {
    //     // set example
    //     let exp = ''




    //     form.setValue('example',exp)
    // }, [template, state.value])

    useMemo(() => { }, [form.watch()])

    return (
        <VStack space='3' bg='white' p='4' borderRadius={'lg'}>
            <InputField form={form} title='Template' id='template' />
            <InputField form={form} title='value' id='value' disabled valueDresser={item => item.map(({ value }) => value).join(', ')} />
            {state?.value?.map((item, i) =>
                <VStack key={i}>
                    <SelectField form={form} list={types} title={`Datatype for ${item.value}`} id={`value[${i}].type`} value={state?.value?.[i]?.type} />
                    {state.value?.[i].type === 'list' &&
                        <SelectField form={form} list={List.data.map(item => ({ label: item.name, value: item._id }))} title={`Select List ${item.value}`}
                            id={`value[${i}].list`} />
                    }
                </VStack>
            )}
            <InputField form={form} title='Example' id='example' />
            <InputField form={form} title='Effective Discount' id='effectiveDiscount' />
            <HStack>
                <Button w='200' m='auto'
                    isLoading={isLoading}
                    onPress={() => submit(state)} size='sm' >
                    {data ? 'Update Template' : 'Create Template'}
                </Button>
                {data && <Button w='200' m='auto'
                    colorScheme={'red'}
                    isLoading={Delete.isLoading}
                    onPress={Delete.submit} size='sm' >
                    Delete Template
                </Button>}
            </HStack>
        </VStack>
    );
}

