import React from 'react'
import toast from 'react-hot-toast'
import { colors } from '../../theme/colors'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { formatNumber } from '../../helper/stringOperations'

const StoreAuthCard = ({ data }) => {

    return (
        <VStack w='100%' space='2' alignItems={'flex-start'}
            bg='white' p='4' borderRadius={'lg'} boxShadow={'lg'} >
            <HStack w='100%' spacing={2}
                alignContent={'flex-start'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <VStack alignItems={'flex-start'} flex={1}>
                    <Text fontWeight={'bold'} fontSize={'lg'} cursor={'pointer'}
                        _hover={{ color: colors.primary, textDecoration: 'underline' }}
                        onClick={() => window.open(`/admin/reviewStore/${data._id}`, '_blank')}
                    >{data.store_name}</Text>
                    <Text color={colors.secondary} >{data.store_address.address}</Text>
                </VStack>

                <VStack alignItems={'flex-start'} flex={1}>
                    <Text fontWeight={'bold'} fontSize={'lg'}>Contacts</Text>

                    <VStack>
                        {data?.auths?.map((item, index) => (
                            <HStack p={1} borderRadius={'lg'} >
                                <Text>{index + 1}. {item.name}/{item.role}/
                                    <Text as='span' fontWeight={'bold'} fontSize={'lg'} cursor={'pointer'}
                                        _hover={{ color: colors.primary, textDecoration: 'underline' }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(item.phone_number)
                                            toast('Copied to clipboard', { icon: '📋' })
                                        }}
                                    >
                                        {formatNumber(item.phone_number)}
                                    </Text>
                                </Text>
                            </HStack>
                        ))}
                    </VStack>
                </VStack>
            </HStack>
        </VStack>
    )
}

export default StoreAuthCard