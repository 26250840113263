import { HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { formatDateTimes } from '../../utils/moment'
import Tile from '../../commons/Tile'
import RemoveStore from '../Stores/ReviewStore/RemoveStore'

const DupliStoreCard = ({ data, refetch }) => {
    return (
        <HStack w="100%" alignItems={'flex-start'} borderRadius={4} p={4} bg="white" boxShadow="md"
            borderColor={data.claimed ? 'green.500' : data.pre_register ? 'red.500' : 'gray.200'}
            borderWidth={data.claimed ? 1 : 2} >

            <VStack alignItems={'flex-start'} flex={1}>
                <Text fontSize="lg" fontWeight={'semibold'} >
                    {data.store_name}
                    <Text as='span' fontSize="sm" color='gray.400' ml={2} >
                        {formatDateTimes(data.createdAt, 5)}
                    </Text>
                </Text>
                <Text>
                    {data.store_address.address}
                </Text>
            </VStack>

            <VStack alignItems={'flex-start'} flex={1} spacing={0}>
                <Text flex={1}>
                    {data.verified &&
                        <Text as='span' color={data.verified ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                            Verified {' '}
                        </Text>
                    }
                    {data.claimed &&
                        <Text as='span' color={data.claimed ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                            || Claimed
                        </Text>
                    }
                    {data.pre_register &&
                        <Text as='span' color={'red.500'} fontWeight={'semibold'} >
                            But Pre-Register
                        </Text>
                    }
                    {data.store_address?.mall_id?.mall_name ?
                        <Tile titleWidth='20' label={'Mall'} value={data.store_address?.mall_id?.mall_name} />
                        : null}
                    <Tile titleWidth='20' label={'City/State'} value={`${data.store_address.city.name}/${data.store_address.state.name}`} />
                </Text>
            </VStack>

            {data.verified && data.claimed ? <></> :
                <RemoveStore store={data} simple onSuccess={refetch} />
            }

        </HStack>
    )
}

export default DupliStoreCard