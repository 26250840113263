import { Button } from 'native-base';
import { formatTimestamp } from '../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Phone Number',
        selector: (row) => row.phone_number,
        sortable: true,
    },
    {
        name: 'Otp',
        selector: (row) => row.otp,
        sortable: true,
        width: '200px'
    },
    {
        name: 'Time',
        selector: (row) => formatTimestamp(row.date, 10),
        sortable: true,
        width: '200px'
    },
]