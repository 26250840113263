import React from 'react'
import Autosuggest from 'react-autosuggest';
import { theme } from 'react-autosuggest';
import useFetch from '../../hook/useFetch';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import useNavigateSearch from '../../hook/useNavigateSearch';
import { getURLQuery } from '../../helper/url';


const SuggestionInput = () => {
    const { search, pathname } = useLocation()
    const params = getURLQuery(search)
    const navigate = useNavigateSearch()
    const [value, setValue] = React.useState('')
    const [suggestions, setSuggestions] = React.useState([])

    const { data, isLoading } = useFetch({
        service: '',
    })

    const onChange = (_, { newValue }) => setValue(newValue)

    const fetchSuggestions = ({ value }) => {
        setSuggestions([
            // { label: 'Apple' },
            // { label: 'Banana' },
            // { label: 'Orange' },
            // { label: 'Pineapple' },
            // { label: 'Strawberry' },
            // { label: 'Watermelon' },
            // { label: 'Grapes' },
            // { label: 'Mango' },
            // { label: 'Papaya' },
            // { label: 'Peach' },

        ])
    }

    const renderSuggestion = (suggestion) => {
        return (
            <VStack w='full' p='2' alignItems='flex-start'>
                <Text>{suggestion.label}</Text>
            </VStack>

        )

    }

    return (
        <Box my='2' w='full'>
            <Autosuggest
                theme={theme}
                suggestions={suggestions}
                onSuggestionsFetchRequested={fetchSuggestions}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onChange={onChange}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={({ containerProps, children }) => {

                    return (
                        suggestions.length ? <VStack borderWidth={1} shadow='2xl'
                            mt='2'
                            borderColor={'#0000004D'}
                            borderRadius='lg'
                            w='full' bg='#ffffff4D'
                            alignItems={'flex-start'}
                            position={'absolute'}
                            zIndex={100}
                            {...containerProps}
                        >
                            {children}
                        </VStack> : <></>
                    )
                }}
                inputProps={{
                    placeholder: 'Search...',
                    // value: value,
                    value: params.q || '',
                    onChange: onChange,
                    onChange: (e) => navigate(pathname, { ...params, q: e.target.value }),
                    style: {
                        width: '100%',
                        padding: '10px',
                        borderRadius: '5px',
                    }

                }}
            />

        </Box>
    )
}

export default SuggestionInput