import FilterCard from './FilterCard'
import { useForm } from 'react-hook-form'
import { Text, VStack } from 'native-base'
import { Button } from '@chakra-ui/react';
import useFetch from '../../../hook/useFetch'
import { SimpleGrid } from '@chakra-ui/react'
import { colors } from '../../../theme/colors';
import Loading from '../../../commons/Loading'
import useSubmit from '../../../hook/useSubmit'
import React, { useEffect, useMemo, useState } from 'react'
import { filtersToCloudDS, filtersToLocalDS } from './../action'
import { editProductFiltersById, getParametricByProduct, getProductById, getProductFiltersById, getProductsWearsById, getTitleKeys, } from '../../../apis/admin'

const contProps = { borderWidth: 0, w: '100%', bg: colors.light, alignItems: 'flex-start', p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const EditProductFilter = ({ gender, wear, id, title }) => {

    const form = useForm({ defaultValues: { keys: [], gender, wear } })
    const { keys } = form.getValues()
    const [selected, setSelected] = useState(null)
    const [addRules, setAddRules] = useState(false)

    useEffect(() => {
        if (gender) form.setValue('gender', gender)
        if (wear) form.setValue('wear', wear)

    }, [gender, wear])

    const product = useFetch({ name: 'getProduct', service: getProductById, id, })
    const parametric = useFetch({ name: 'getParametricByProduct', service: getParametricByProduct, id, })
    const filters = useFetch({ name: 'getTitleKeys', service: getTitleKeys, dataOnly: true, params: { limit: 0 } })

    const wears = useFetch({
        name: 'getWears', id, service: getProductsWearsById,
        params: { populate: 'true' }, dataOnly: true
    })

    const genders = wears.map(({ gender }) => gender)
    const { data, isLoading, refetch } = useFetch({ name: `product_filters_${id}`, service: getProductFiltersById, id })
    const MapProduct = useSubmit({ service: editProductFiltersById, id, onSuccess: refetch })

    useEffect(() => {
        if (isLoading) return
        form.setValue('keys', filtersToLocalDS(data, wear))
        if (!selected) setSelected(filters?.[0]?._id)
    }, [isLoading, filters, wear])

    const save = () => MapProduct.submit({ filters: filtersToCloudDS(keys), wear })
    const activedoc = data.find(item => item.wear === wear && item.gender === gender)

    const Compo = useMemo(() => {
        const filter = filters?.find(item => selected === item._id)
        if (!filter) return null
        return (
            <FilterCard form={form} data={filter} key={filter._id}
                genders={genders} refetchParametric={parametric.refetch}
                parametric={parametric.data.find(item =>
                    item.titleKey === filter._id &&
                    item.wear === wear &&
                    item.gender === gender
                )}
                product={product} wears={wears}
                addRules={addRules} setAddRules={setAddRules}
            />
        )
    }, [form.watch(), selected])

    if (isLoading) return <Loading />

    const filterActiveDocList = filters.map(filter => ({
        activedocs: activedoc?.filters?.find(({ title }) => title === filter._id)?.keys?.length || 0,
        ...filter,
    })).sort((a, b) => b.activedocs - a.activedocs)

    return (
        <VStack {...contProps} position='relative' >
            <Text fontWeight={'semibold'} fontSize='2xl'>
                {title}
            </Text>

            <Text fontSize={'md'} fontWeight='semibold'>Filters</Text>
            <SimpleGrid columns={[3, 7]} spacing='2' mb='2'>
                {filterActiveDocList
                    ?.filter(filter => addRules ? filter?.activedocs : true)
                    ?.map(filter => {
                        return <Button
                            variant={selected === filter._id ? 'solid' : 'outline'}
                            colorScheme={filter.activedocs ? 'green' : "blue"}
                            onClick={() => selected === filter._id ?
                                setSelected(selected.find(item => item !== filter._id))
                                : setSelected(filter._id)}>
                            {filter.title}{filter?.activedocs ? `(${filter?.activedocs})` : ''}
                        </Button>
                    })}
            </SimpleGrid>

            {Compo}

            <Button mt='2' w={200} variant={'solid'}
                colorScheme='cyan' onClick={save}
                textColor='white'
                size='sm' isLoading={MapProduct.isLoading}
            >
                Save
            </Button>
        </VStack>
    )
}

export default EditProductFilter
