import { Box, Image } from "@chakra-ui/react";
import { formatDateTimes } from "../../../../utils/moment";
import { IMAGE_BASE_URL } from "../../../../helper/variables";

export const columns = (navigate) => [
    {
        name: 'Logo',
        cell: (row) => <Box alignItems={'center'} justifyContent={'center'} >
            <Image resizeMode='contain' p={2} rounded={'2xl'} borderWidth={1}
                cursor='pointer' src={IMAGE_BASE_URL + row?.images?.[0]} h='200' w='100' />
        </Box>,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: 'Store',
        selector: (row) => row?.store?.store_name,
        sortable: true,
    },
    {
        name: 'brand',
        selector: (row) => row?.brand?.brand,
        sortable: true,
    },
    {
        name: 'CreatedAt',
        selector: (row) => formatDateTimes(row.createdAt, 3),
        sortable: true,
    },

] 