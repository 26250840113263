import { Button, Text } from "native-base";
import { getAppLinks } from "../../helper/applink";
import { formatDateTimes } from "../../utils/moment";

export const columns = (navigate) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/CBS/${row._id}`)}>
            Details</Button>,
        sortable: false,
    },
    {
        name: 'Type',
        button: true,
        cell: (row) =>
            <Text
                color={row?.product_id ? 'black' : 'gray.300'}
            >
                {row?.product_id ? 'Style Code' : 'Specific'}
            </Text>
        ,
        sortable: true
    },

    {
        name: 'Responses',
        selector: (row) => row.responses?.length,
        sortable: true,
    },
    {
        name: 'Rounds',
        selector: (row) => row.round,
        sortable: true,
    },
    {
        name: 'user',
        selector: (row) => row.user?.name || row.user,
        sortable: true,
    },
    {
        name: 'Store Matches',
        selector: (row) => row.store_matches.flat().length,
        sortable: true,
    },
    {
        name: 'Sent to',
        selector: (row) => row.store.length,
        sortable: true,
    },
    {
        name: 'Brand',
        button: true,
        cell: (row) => <Text
            color={row.brand?.brand ? 'black' : 'gray.300'}
        >{row.brand?.brand ? row.brand.brand : 'N/A'}</Text>,
        sortable: true,
    },
    {
        name: 'Filters',
        selector: (row) => row.keys?.length,
        sortable: true,
    },
    {
        name: 'Product',
        selector: (row) => row.product?.name,
        sortable: true,
    },
    {
        name: 'Last update',
        selector: (row) => formatDateTimes(row.updatedAt, 4),
        sortable: true,
    },
    {
        name: 'Started',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
] 