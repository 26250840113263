import React, { useMemo } from 'react'
import { Box, HStack, Text, VStack } from 'native-base'
import { InputField } from '../../commons/InputField'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import SelectField from '../../commons/SelectField'
import { addTitleKeys } from '../../apis/admin'
import useSubmit from '../../hook/useSubmit'
import StringDivider from '../../commons/StringDivider';
import { Button } from '@chakra-ui/react';
import { typesList } from './static'

const defaultValues = {
    title: '',
    keys: new Array(3).fill(''),
    active: 'true',
    required: 'false',
    type: 'select',
}

const CreateNewTitleKey = ({ data }) => {
    const form = useForm({ defaultValues: data || defaultValues })
    const navigate = useNavigate()
    const { keys, title } = form.getValues()

    useMemo(() => { }, [form.watch()])
    const { submit } = useSubmit({ service: addTitleKeys, onSuccess: () => navigate(-1) })

    const save = () => {
        const state = form.getValues()
        state.keys = state.keys.filter(key => key !== '')
        submit(state)
    }

    return (
        <VStack space='4'>
            <CompHeader title='Create New Title Key' backButton />

            <VStack space='2' w='400'>
                <InputField form={form} title='Title' id='title' autoCapatilize />
                <SelectField form={form} list={[true, false].map(value => ({ label: value.toString(), value: value.toString() }))} title='Required' id='required' />
                <SelectField form={form} list={typesList} title='Type' id='type' />

                <StringDivider value={keys} onChange={val => form.setValue('keys', val)} />

                <VStack space='2'>
                    {form.getValues().keys?.map((item, index) => (
                        <HStack space='2' alignItems={'center'}>
                            <InputField form={form} title={`Key ${index + 1}`}
                                autoCapatilize id={`keys[${index}]`} />
                            <Button
                                variant={'solid'} borderRadius='md' h='6'
                                w={'6'} bg='red.400' alignItems={'center'}
                                justifyContent={'center'}
                                onClick={() => {
                                    const { keys } = form.getValues()
                                    keys.splice(index, 1)
                                    form.setValue('keys', keys)
                                }}>
                                <Text color='white'>-</Text>
                            </Button>
                        </HStack>
                    ))}

                    <Button
                        w='fit-content' alignSelf={'flex-end'}
                        size='sm' variant={'link'}
                        onClick={() => {
                            const { keys } = form.getValues()
                            keys.push('')
                            form.setValue('keys', keys)
                        }}>
                        Add More
                    </Button>
                </VStack>

                <Button w={'150'} mt='10'
                    variant={'solid'}
                    colorScheme={'blue'}
                    disabled={keys.length === 0 || title === ''}
                    alignSelf={'center'} size='sm'
                    onClick={save}>Submit</Button>
            </VStack>

        </VStack>
    )
}

export default CreateNewTitleKey