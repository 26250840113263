import { HStack, Spacer, Text, VStack } from 'native-base'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { getstoreauthority, updateStoreAuths } from '../../../apis/store'
import useFetch from '../../../hook/useFetch'
import { colors } from '../../../theme/colors'
import useSubmit from '../../../hook/useSubmit'
import { Button } from '@chakra-ui/react'
import StoreAuthCard from './StoreAuthCard'
import Model from '../../../components/compo/UI/Model'
import LinkStoreAuth from './LinkStoreAuth';


const StoreAuthories = ({ store, auth }) => {
    const form = useForm({ mode: 'onBlur' });
    const form2 = useForm({ mode: 'onBlur' });

    const [linkAuth, setLinkAuth] = React.useState(false)

    const { submit, isLoading } = useSubmit({ service: updateStoreAuths, id: store._id })

    let { data, refetch } = useFetch({
        name: "store_authority",
        service: getstoreauthority,

        props: { id: store._id }
    })

    if (auth) data = auth

    useEffect(() => {
        if (!data) return;
        form.setValue("auth", data)
        form2.setValue("auth", data)
    }, [data]);

    const save = () => submit({ ...form2.getValues() })

    return useMemo(() => {
        return (
            <VStack space='2' borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
                <HStack alignItems={'center'} justifyContent={'space-between'} >
                    <Text fontSize={'md'} fontWeight='600'>Store Authorities</Text>
                    <Button bg='red.500' size='sm' colorScheme={'red'} onClick={() => setLinkAuth(true)}>
                        Link Auth
                    </Button>
                </HStack>

                {data?.map((item, index) =>
                    <StoreAuthCard data={item} key={index} index={index} form={form} form2={form2} store={store} />
                )}

                <Button mt='2' w='150' alignSelf={'flex-start'} bg='red.500' size='sm'
                    colorScheme={'red'} onClick={save} isLoading={isLoading} >
                    Save Store Auths
                </Button>

                <Model title={'Link Store Auth'} show={linkAuth}
                    close={() => setLinkAuth(false)}
                    body={<LinkStoreAuth store={store} refetch={refetch} close={() => setLinkAuth(false)} />}
                />
            </VStack>
        )
    }, [form.watch()])
}

export default StoreAuthories