import { Button } from "@chakra-ui/react";
import { getAppLinks } from "../../helper/applink";
import { formatDateTimes } from "../../utils/moment";
import { capitalize, formatNumber } from "../../helper/stringOperations";
import { Image } from "@chakra-ui/react";
import { IMAGE_BASE_URL } from '../../helper/variables';

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onClick={() => navigate(`/admin/sponsor_reels/edit/${row._id}`)}
        >
            Edit</Button>,
        sortable: false,
    },
    {
        name: 'Thumbnail',
        button: true,
        cell: (row) => <Image
            size='sm'
            src={`${IMAGE_BASE_URL}${row?.thumbnail}`}
        // alt={row?.store.store_name}
        />
        ,
        sortable: false,
    },
    {
        name: 'Views/Shares/Likes',
        selector: (row) => `${row.views}/${row.shares}/${row.likes}`,
        sortable: true,
    },
    {
        name: 'Link Clicks',
        selector: (row) => `${row.link_clicks}`,
        sortable: true,
    },
    {
        name: 'Show',
        selector: (row) => row.show ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Created At',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    // {
    //     name: 'Uploaded by',
    //     selector: (row) => `${row?.user.name}(${formatNumber(row?.user.phone_number)})`,
    //     sortable: true,
    // },
] 