import { HStack, Button } from '@chakra-ui/react'
import React, { memo } from 'react'

const useParamManager = ({
    defaultParams = {},
    list = [],
    single,
}) => {
    const [params, setParams] = React.useState(defaultParams)

    const ParamSelector = memo(() => {
        return (
            <HStack w={'100%'} alignItems={'center'} spacing={2} bg={'white'} p={4}
                borderRadius={10} borderWidth={2} borderColor={'gray.200'} >
                {list.map(({ title, id, off, on }, index) => {
                    const handleClick = () => {
                        if (single) {
                            setParams({ [id]: !params[id], })
                            return
                        }
                        setParams({ ...params, [id]: !params[id], })
                    }
                    return (<Button key={index} onClick={handleClick} colorScheme={params[id] ? 'blue' : 'gray'} >
                        {title}
                    </Button>)
                })}
            </HStack>
        )
    }, [params])

    return {
        params,
        resetParams: () => setParams(defaultParams),
        ParamSelector
    }
}

export default useParamManager