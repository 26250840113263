import { VStack } from 'native-base'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getwears } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { columns } from './static'

const Wear = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'wears', service: getwears, pagination: true })

    return (
        <VStack>
            <CompHeader
                title='Wears'
                buttonTitle='Create New Wear'
                onPress={() => navigate(`/admin/wear/create`)}
            />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Wear