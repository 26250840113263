import React from 'react'
import useSubmit from '../../../hook/useSubmit'
import CropArea from '../../../components/compo/CropArea/CropArea'
import { createNewProductFromExisting } from '../../../apis/admin'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import { IMAGE_BASE_URL, contProps, contPropsTitle } from '../../../helper/variables'

const ProductImages = ({ form, refetch }) => {
    const images = form.watch('images')
    const _id = form.watch('_id')

    const { submit, isLoading } = useSubmit({
        service: createNewProductFromExisting,
        onSuccess: (res) => {
            refetch()
            window.open(`/admin/storeuploads/${res._id}`, '_blank')
        }
    })

    return (
        <VStack {...contProps} >
            <Text {...contPropsTitle}>
                Product Images
            </Text>

            <HStack alignItems={'flex-start'} spacing={2}>
                {Array(images?.length + 1).fill(0).map((_, i) =>
                    <VStack key={i} borderRadius={10} bg={'gray.100'}>
                        <CropArea aspect={2 / 3} defaultImage={images?.[i] ? `${IMAGE_BASE_URL}${images[i]}` : undefined}
                            removeImage={() => form.setValue(`images[${i}]`, '')}
                            setImage={(file) => form.setValue(`images[${i}]`, file)}
                            width={400} height={600}
                        />
                        {i > 0 && images?.[i] ?
                            <Button isLoading={isLoading}
                                onClick={() => {
                                    // if (window.prompt('Enter the password to create a new product') !== '123456') return
                                    if (window.confirm('Are you sure you want to create a new product?') !== true) return
                                    if (window.confirm('This image will get remove from here and similiar product will get create with this image') !== true) return
                                    submit({ style_id: _id, image: images[i], })
                                }}
                            >
                                Create as new product
                            </Button>
                            : <></>
                        }
                    </VStack>
                )}
            </HStack>

            {/* <HStack alignItems={'center'} spacing={2}>
                {images.filter((image) => image).map((image) =>
                    <Image h={350} w={250} borderRadius={10}
                        _hover={{
                            transform: 'scale(1.1)',
                            transition: 'transform 0.5s ease-in-out',
                        }}
                        src={typeof image === 'string' ? `${IMAGE_BASE_URL}${image}` : URL.createObjectURL(image)}
                    />
                )}
            </HStack> */}

        </VStack>
    )
}

export default ProductImages