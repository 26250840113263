import { formatDateTimes } from "../../utils/moment";
import { formatNumber } from "../../helper/stringOperations";

export const columns = (navigate, setCall) => [
    {
        name: 'Campaign',
        selector: (row) => row?.campaign,
        sortable: true,
    },
    {
        name: 'Template',
        selector: (row) => row?.template_name,
        sortable: true,
    },
    {
        name: 'phone_number',
        selector: (row) => formatNumber(row?.phone_number),
        sortable: true,
    },
    {
        name: 'Success',
        selector: (row) => row?.message_status === 'accepted' ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row) => row?.status,
        sortable: true,
    },
    {
        name: 'Delivered',
        selector: (row) => formatDateTimes(row?.deliveredAt, 5),
        sortable: true,
    },
    {
        name: 'Read',
        selector: (row) => formatDateTimes(row?.readAt, 5),
        sortable: true,
    },
    {
        name: 'Sent',
        selector: (row) => formatDateTimes(row?.createdAt, 5),
        sortable: true,
    },
]

