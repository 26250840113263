import React, { useEffect, useMemo, useState } from "react";
import { Text, Box, Stack, CheckboxGroup, Checkbox, SimpleGrid, Button } from "@chakra-ui/react";
import useFetch from "../../hook/useFetch";
import { getgender, getWearProducts } from "../../apis/admin";
import Loading from "../../commons/Loading";

const ProductSelection = ({ form }) => {
    const updateState = (id, value) => form.setValue(id, value)
    const state = { ...form.getValues() }
    const [active, setActive] = useState('')
    const genders = useFetch({ name: `genders`, service: getgender, dataOnly: true, })

    const { data, isLoading } = useFetch({
        service: getWearProducts, name: 'getWearProducts',
        props: { gender: active },
        conditionalRefetch: [active],
        condition: !!active
    })

    useEffect(() => {
        if (!active) setActive(genders?.[0]?._id)
    }, [genders])

    useMemo(() => { }, [form.watch()])

    return (
        <Box w="100%" mt='10'>
            <Text fontSize={['2xl']}>Product Selection</Text>
            <Stack spacing="20px" w="100%"
                borderWidth={[0, 1]} borderRadius={"lg"} p={[1, 8]} >
                <SimpleGrid columns={[3, 5, 8]} spacing={5}>
                    {genders.map(({ type, _id }) =>
                        <Button size='sm' colorScheme={active === _id ? 'red' : 'blackAlpha'}
                            onClick={() => setActive(_id)}
                        > {type} </Button>
                    )}
                </SimpleGrid>
                {isLoading ?
                    <Loading />
                    :
                    data.map(({ wear, products, _id }) =>
                        <Stack key={_id}>
                            <Text fontSize={['md']} fontWeight='semibold'>{wear}</Text>
                            <CheckboxGroup
                                value={state.products?.find(({ wear, gender }) => wear === _id && gender === active)?.products || []}
                                onChange={(values) => {
                                    const index = state.products.findIndex(({ wear, gender }) => wear === _id && gender === active)
                                    if (index === -1) {
                                        updateState('products', [...state.products, { wear: _id, products: values, gender: active }])
                                        return;
                                    }
                                    const newProducts = [...state.products]
                                    newProducts[index].products = values
                                    updateState('products', newProducts)
                                }}
                            >
                                <SimpleGrid columns={[2, 4, 7]} >
                                    {products.map(({ name, _id }) =>
                                        <Checkbox key={_id} value={_id}>{name}</Checkbox>
                                    )}
                                </SimpleGrid>
                            </CheckboxGroup>
                        </Stack>
                    )
                }
            </Stack>


        </Box>
    )
}

export default ProductSelection

// import React, { useEffect, useMemo, useState } from "react";
// import { Text, Box, Stack, CheckboxGroup, Checkbox, SimpleGrid, Button, VStack } from "@chakra-ui/react";
// import useFetch from "../../hook/useFetch";
// import { getAllWearProducts, getgender, getWearProducts } from "../../apis/admin";
// import Loading from "../../commons/Loading";

// const ProductSelection = ({ form }) => {
//     const updateState = (id, value) => form.setValue(id, value)
//     const state = { ...form.getValues() }
//     const [active, setActive] = useState('')
//     const genders = useFetch({ name: `genders`, service: getgender, dataOnly: true, })

//     const { data, isLoading } = useFetch({
//         service: getAllWearProducts, name: 'getWearProducts',
//         props: { gender: active },
//         conditionalRefetch: [active],
//         condition: !!active
//     })

//     useEffect(() => {
//         if (!active) setActive(genders?.[0]?._id)
//     }, [genders])

//     useMemo(() => { }, [form.watch()])

//     return (
//         <Box w="100%" mt='10'>
//             <Text fontSize={['2xl']}>Product Selection</Text>
//             <Stack spacing="20px" w="100%" borderWidth={[0, 1]} borderRadius={"lg"} >
//                 {/* <SimpleGrid columns={[3, 5, 8]} spacing={5}>
//                     {genders.map(({ type, _id }) =>
//                         <Button size='sm' colorScheme={active === _id ? 'red' : 'blackAlpha'}
//                             onClick={() => setActive(_id)}
//                         >
//                             {type}
//                         </Button>
//                     )}
//                 </SimpleGrid> */}
//                 {isLoading ?
//                     <Loading />
//                     :
//                     genders.map(({ type, _id: active }, i) =>
//                         <VStack alignItems={['flex-start']} bg={i % 2 === 0 ? 'gray.100' : 'white'} px={8} py={4}>
//                             <Text fontSize={['xl']} fontWeight='bold'
//                                 textDecoration={'underline'} >
//                                 {type}
//                             </Text>
//                             {data.map(({ wear, products, _id }) =>
//                                 <Stack key={_id}>
//                                     <Text fontSize={['md']} fontWeight='semibold'>{wear}</Text>
//                                     <CheckboxGroup
//                                         value={state.products?.find(({ wear, gender }) => wear === _id && gender === active)?.products || []}
//                                         onChange={(values) => {
//                                             const index = state.products.findIndex(({ wear, gender }) => wear === _id && gender === active)
//                                             if (index === -1) {
//                                                 updateState('products', [...state.products, { wear: _id, products: values, gender: active }])
//                                                 return;
//                                             }
//                                             const newProducts = [...state.products]
//                                             newProducts[index].products = values
//                                             updateState('products', newProducts)
//                                         }}
//                                     >
//                                         <SimpleGrid columns={[2, 4, 7]} >
//                                             {products.map(({ name, _id }) =>
//                                                 <Checkbox key={_id} value={_id}>{name}</Checkbox>
//                                             )}
//                                         </SimpleGrid>
//                                     </CheckboxGroup>
//                                 </Stack>
//                             )}
//                         </VStack>
//                     )
//                 }
//             </Stack>
//         </Box>
//     )
// }

// export default ProductSelection


