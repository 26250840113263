import React from 'react'
import { VStack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import useFetch from '../../hook/useFetch'
import { getpayments } from '../../apis/admin'

const Payments = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'payments', service: getpayments, pagination: true })

    return (
        <VStack>
            <CompHeader title='Payments' />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default Payments