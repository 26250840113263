import { Button, Image } from 'native-base';
import { IMAGE_BASE_URL } from '../../helper/variables';
import { formatDateTimes } from '../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/payments/${row._id}`)}>
            Details</Button>,
        sortable: false,
    },
    { name: 'Initiated At', selector: (row) => formatDateTimes(row?.createdAt, 6), sortable: true, width: '15%' },
    { name: 'Completed At', selector: (row) => row.status === 'pending' ? row.status : formatDateTimes(row?.updatedAt, 6), sortable: true, width: '15%' },
    { name: 'Payment ID(pay_)', selector: (row) => row?.razorpay?.payment_id?.split('_')[1], sortable: true, width: '10%' },
    { name: 'Order ID(order_)', selector: (row) => row?.order_id?.split('_')[1], sortable: true, width: '10%' },
    { name: 'User ID', selector: (row) => row.user?._id, sortable: true, width: '15%' },
    { name: 'Status', selector: (row) => row?.status, sortable: true, },
    { name: 'Amount', selector: (row) => `₹ ${row?.amount / 100}`, sortable: true, },
    { name: 'Plan', selector: (row) => row?.sub_id?.code, sortable: true, },
    { name: 'User Name', selector: (row) => row.user?.name, sortable: true, },
    { name: 'Payment Method', selector: (row) => row?.razorpay?.method, sortable: true, },
]