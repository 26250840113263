import React from 'react'
import useFetch from '../../hook/useFetch'
import { useForm } from 'react-hook-form';
import StoreAuthCard from './StoreAuthCard';
import Loading from '../../commons/Loading';
import FilterSelection from './FilterSelection';
import { Button, Text, VStack } from '@chakra-ui/react'
import { getStoreWithAdvanceFilters, } from '../../apis/admin';
import CompHeader from '../../commons/CompHeader/CompHeader';
import FiltersTiles from '../../commons/FiltersTiles';
import toast from 'react-hot-toast';

const defaultValues = { brand: "", from_date: new Date(), claimed: true, pre_register: false, type: "", state: [], city: [], mall: "", offer_type: "", chain: "", stores: [], extra: { existInChain: false, } }

const AdvanceSearchStore = () => {
    const form = useForm({ defaultValues })

    const params = () => {
        const data = form.watch()
        const { state, city } = data
        return {
            ...data,
            state: state.map(i => i?.value),
            city: city.map(i => i?.value),
        }
    }

    const { data, isLoading, refetch, isRefetching } = useFetch({
        name: `getStoreWithAdvanceFilters`,
        service: getStoreWithAdvanceFilters,
        params: params(),
    })

    const copyData = () => {
        const list = data.map(i => {
            return `${i.store_name},${i.store_address.address.replace(/,/g, ' -')},${i.store_address.city.name},${i.store_address.state.name},${i?.auths?.map(j => `${j.name} - ${j?.phone_number}`).filter(i => i).join(' || ')}`
        })
        const text = list.join('\n')
        navigator.clipboard.writeText(text)
        toast('Copied to clipboard', { icon: '📋' })
    }

    return (
        <VStack w='100%' space='2' alignItems={'flex-start'} >
            <CompHeader title='Advance Search for Store' />

            <VStack w='100%' space='2' alignItems={'flex-start'} >
                <FilterSelection form={form} />
                <FiltersTiles form={form} defaultValues={defaultValues} />
                <Button onClick={() => {
                    const query = JSON.stringify(params())
                    window.history.pushState({}, '', `/admin/store_with_no_offers?query=${query}`)
                    refetch()
                }} isLoading={isLoading || isRefetching}>
                    Get Stores
                </Button>
            </VStack>

            <Text fontSize='xl' fontWeight='bold' color='gray.500' alignSelf='flex-start' mt='4' >
                {data?.length} Stores
            </Text>


            <Button onClick={copyData}>
                Copy All data to clipboard
            </Button>

            {isLoading ?
                <Loading />
                :
                data.map((item, index) => <StoreAuthCard form={form} key={index} data={item} />)
            }
        </VStack>
    )
}

export default AdvanceSearchStore