import React, { memo, useState } from 'react'
import useSubmit from '../../hook/useSubmit'
import { enrollUserInAlert } from '../../apis/admin'
import { Button, Input, Text, VStack } from '@chakra-ui/react'

const Enroll = ({ refetch }) => {
    const [phone, setPhone] = useState('')

    const { submit, isLoading } = useSubmit({
        service: enrollUserInAlert,
        onSuccess: () => {
            setPhone('')
            refetch()
        }
    })

    return (
        <VStack w={'100%'} alignItems={'flex-start'} bg='white' p={4} borderRadius={8} boxShadow='md' >
            <Text fontSize='lg' fontWeight='bold'>
                Enroll User for Admin Alerts
            </Text>
            <Input placeholder='Enter phone number' value={phone} onChange={(e) => {
                if (e.target.value.length <= 10) {
                    setPhone(e.target.value)
                }
            }} type='number' />
            <Button size='sm' disabled={!phone} isLoading={isLoading}
                onClick={() => submit({ phone_number: phone })}>
                Enroll
            </Button>
        </VStack>
    )
}

export default memo(Enroll)