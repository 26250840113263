import React from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import { colors } from '../../theme/colors';

const NavItem = ({ icon, children, link, active, ...rest }) => {
  return (
    <Flex align="center" _hover={{ bg: colors.info, color: 'white', }}
      p="2" my='2' mx="4" borderRadius="lg" role="group" cursor="pointer"
      transition="all 0.32s" color={active ? 'red.900' : 'gray.600'}
      animation="0.32s ease-in-out" {...rest}>
      {icon && <Icon mr="4" fontSize="16" as={icon}
        _groupHover={{ color: 'white', }} />}
      {children}
    </Flex>
  )
}

export default NavItem