import React, { useMemo } from 'react'
import { Button, HStack, Text, VStack } from 'native-base'
import { useForm } from 'react-hook-form'
import { InputField } from '../../commons/InputField'
import useSubmit from '../../hook/useSubmit'
import { createwear, deletewearbyid, editwearbyid, getgender } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import useFetch from '../../hook/useFetch'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { colors } from '../../theme/colors'

const defaultValues = { wear: '', genders: [] }

const AddWear = ({ data }) => {
    const form = useForm({ defaultValues: data || defaultValues })
    const genders = useFetch({ name: 'getGenders', service: getgender, dataOnly: true })
    const navigate = useNavigate()
    const { submit, isLoading } = useSubmit({
        service: data ? editwearbyid : createwear,
        id: data?._id,
        onSuccess: () => navigate(-1)
    })

    const Delete = useSubmit({
        service: deletewearbyid,
        id: data?._id,
        onSuccess: () => navigate(-1)
    })


    useMemo(() => { }, [form.watch()])

    return (
        <VStack p='3' space='2' maxW='400'>
            {data && <InputField form={form} title='Wear id' id='_id' disabled />}
            <InputField form={form} title='Wear' id='wear' autoCapatilize />

            {!data && <HStack space='1'>
                <Text fontWeight='600' w='100'>Genders</Text>
                <Text fontWeight='600' >:</Text>
                <CheckboxGroup onChange={e => form.setValue('genders', e)}>
                    <HStack space='2'>
                        {genders.map(({ _id, type }) =>
                            <Checkbox value={_id}>{type}</Checkbox>
                        )}
                    </HStack>
                </CheckboxGroup>
            </HStack>
            }
            <HStack alignItems={'center'} space='2'>
                <Button
                    backgroundColor={data ? 'green.900' : 'blue.400'}
                    onPress={() => submit(form.getValues())}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update' : "Add"}</Button>
                {data && <Button
                    backgroundColor={colors.error}
                    disabled
                    onPress={() => Delete.submit(form.getValues())}
                    isLoading={Delete.isLoading}
                    size='sm'
                >Remove</Button>}
            </HStack>
        </VStack>
    )
}

export default AddWear