import React from 'react'
import BatchCard from './BatchCard'
import { Button, VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import useSubmit from '../../hook/useSubmit'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { createBatches, getAllUserbatches } from '../../apis/admin'
import { selectProfile } from '../../redux/authSlice'
import { useSelector } from 'react-redux'
import { getPastDate } from '../../utils/moment'
import DateRangeSelector from '../../commons/DateRangeSelector'

const UserCallingBatches = () => {
    const profile = useSelector(selectProfile)
    const [range, setRange] = React.useState([getPastDate(1000), new Date()])

    const { data, isLoading, refetch } = useFetch({
        service: getAllUserbatches,
        params: {
            date1: range[0],
            date2: range[1]
        },
        conditionalRefetch: [range]
    })

    const { submit, isLoading: isSubmitting } = useSubmit({
        service: createBatches,
        onSuccess: refetch,
    })

    if (isLoading) return <Loading />

    return (
        <VStack w='100%' alignItems={'flex-start'} >
            <CompHeader title={`User Calling Batches (${data.length} batches)`}
                renderRight={
                    profile.role === 'ADMIN' &&
                    <Button onClick={submit} isLoading={isSubmitting} colorScheme='blue' variant='solid' size='sm' >
                        Create New Batches
                    </Button>
                }
            />

            <DateRangeSelector range={range} setRange={setRange} />

            {data.map((item, index) => <BatchCard refetch={refetch} key={item._id} data={item} index={index} />)}
        </VStack>
    )
}

export default UserCallingBatches