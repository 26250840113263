import React from 'react'
import { VStack } from 'native-base'
import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/react'
import useSubmit from '../../../hook/useSubmit'
import { linkStoreAuth } from '../../../apis/store'
import SelectField from '../../../commons/SelectField'
import { InputField } from '../../../commons/InputField'

const storeRoles = [
    ['STORE OWNER', "Store Owner",],
    ['STORE MANAGER', "Store Manager",],
    ['STORE REP', "Store Representative",]
].map(([value, label]) => ({ value, label }))

const LinkStoreAuth = ({ store, close, refetch }) => {
    const form = useForm({ defaultValues: { role: 'STORE OWNER', name: '', phone_number: '', email: '', } })

    const { submit, isLoading } = useSubmit({
        service: linkStoreAuth, onSuccess: () => {
            refetch()
            close()
        }
    })

    return (
        <VStack px={4} mb={5} space={2}>
            <SelectField id='role' title='Role' form={form} placeholder='Role' list={storeRoles} />
            <InputField id='name' title='Name' form={form} placeholder='Name' />
            <InputField id='phone_number' title='Phone Number' form={form} placeholder='Phone Number' />
            <InputField id='email' title='Email' form={form} placeholder='Email' />

            <Button mt={5}
                isLoading={isLoading} colorScheme={'red'}
                bg='red.500' size='sm'
                onClick={() => submit({
                    ...form.getValues(),
                    store_id: store._id,
                })} >
                Link
            </Button>

        </VStack>
    )
}

export default LinkStoreAuth