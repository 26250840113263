import { VStack } from 'native-base'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import OffingoVersionManager from './OffingoVersionManager'
import OffingoBusinessVersionManager from './OffingoBusinessVersionManager'
import useFetch from '../../hook/useFetch'
import { getAppVersions } from '../../apis/admin'

const VersionManager = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({
        name: 'users',
        service: getAppVersions,
        pagination: true
    })

    return (
        <VStack>
            <CompHeader title='Version Manager' />

            <OffingoVersionManager />
            <OffingoBusinessVersionManager />

            {/* <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} /> */}
        </VStack>
    )
}

export default VersionManager