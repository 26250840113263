import React from 'react'
import Tile from '../../../commons/Tile'
import { colors } from '../../../theme/colors'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { formatDateTimes } from '../../../utils/moment'

const CouponCard = ({ data, selected, toggle, alreadyAssigned }) => {
    return (
        <VStack borderRadius={'md'} bg={colors.light} cursor={'pointer'}
            w={'23vw'} shadow={'md'} alignItems={'flex-start'} h={'100%'}
            borderColor={alreadyAssigned ?
                colors.error :
                selected ? colors.success : colors.border}
            borderWidth={3} p={2} onClick={toggle}>

            <HStack w={'100%'} justifyContent={'space-between'} alignItems={'center'} borderBottomWidth={1} borderColor={colors.border} pb={2} >
                <Text fontSize={'md'} fontWeight='600' color={selected ? colors.success : colors.primary} >
                    {data.name}({data.code})
                </Text>
                <Text fontSize={'md'} fontWeight='600' >
                    {parseInt(data.max_redeems) - parseInt(data.redeems?.length)} left / {data.max_redeems}
                </Text>
            </HStack>

            {data.store.map(store =>
                <Tile titleWidth={60} key={store._id} label={'Store'} alignItems='flex-start'
                    value={`${store.store_name}(${store.store_address.address})`} />
            )}

            <Tile titleWidth={60} label={'Valid Till'} value={formatDateTimes(data.expiry, 2)} />

        </VStack>
    )
}

export default CouponCard