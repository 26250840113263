import React from 'react'
import CreateQr from './CreateQr'
import useFetch from '../../hook/useFetch'
import { getQRById, resetQRById } from '../../apis/admin'
import { useParams } from 'react-router-dom'
import Loading from '../../commons/Loading';
import { Button, Text, VStack } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit'

const EditQr = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: 'getQRById',
        service: getQRById,
        id
    })

    const { submit, isLoading: resetLoading } = useSubmit({
        service: resetQRById,
        onSuccess: () => window.location.reload(),
        id
    })

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <CreateQr data={data} />
            <VStack w={'100%'} alignItems={'flex-start'} bg='white' p={4} borderRadius={8} boxShadow='md' mt={10}>
                <Text fontSize='lg' fontWeight='bold' >
                    Reset this QR
                </Text>
                <Button
                    colorScheme='red'
                    isLoading={resetLoading}
                    onClick={() => {
                        if (window.confirm('Are you sure?')) {
                            submit()
                        }
                    }}
                >
                    reset
                </Button>
            </VStack>
        </>
    )
}

export default EditQr