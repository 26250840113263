import "./index.css";
import App from "./App";
import React, { useEffect } from "react";
import store from "./redux";
import { Provider } from "react-redux";
import { Toaster } from 'react-hot-toast'
import { NativeBaseProvider } from "native-base";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme, nativeTheme } from "./theme/themes";
import ReactQueryWrapper from "./wrappers/ReactQueryWrapper";

const Main = () => {

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                localStorage.setItem("latitude", latitude);
                localStorage.setItem("longitude", longitude);
            },
            (error) => {
                localStorage.setItem("latitude", 30.888479);
                localStorage.setItem("longitude", 75.795735);
            }
        );

    }, [])

    return (
        <ReactQueryWrapper>
            <Provider store={store}>
                <BrowserRouter>
                    <ChakraProvider theme={chakraTheme}>
                        <NativeBaseProvider theme={nativeTheme}>
                            <Toaster />
                            <App />
                        </NativeBaseProvider>
                    </ChakraProvider>
                </BrowserRouter>
            </Provider>
        </ReactQueryWrapper>
    )
}

export default Main