import React from 'react'
import { CloseIcon, HStack, Pressable, Text } from 'native-base'
import { capitalize } from '@material-ui/core'
const labels = ['brand', 'state', 'city', 'type', 'mall', 'offer_type', 'chain']

const FiltersTiles = ({ form, defaultValues }) => {
    const reset = (label) => form.setValue(label, defaultValues[label])
    const state = form.getValues()
    return (
        <HStack my='4' space='2' w='100%'>
            {labels.map(item => {
                const visible = (() => {
                    if (Array.isArray(state[item])) {
                        return state[item].length !== 0
                    }
                    return !!state[item]
                })()
                return visible ?
                    <HStack key={item} p='2' alignItems={'center'} borderRadius='lg'
                        bg='#2e2e2e' color='white' space='2'>
                        <Text color='white'>{capitalize(item)}</Text>
                        <Pressable onPress={() => reset(item)}>
                            <CloseIcon color='white' />
                        </Pressable>
                    </HStack> : null
            })}
        </HStack>
    )
}

export default FiltersTiles