import { Text, VStack } from '@chakra-ui/react'
import QRCode from 'qrcode.react'
import React from 'react'

const StoreQr = ({ form }) => {
    const qr = form.watch('qr')
    if (!qr) return <></>

    const link = `https://offn.in/${qr}`
    return (
        <VStack w={'100%'} alignItems={'flex-start'}>
            <Text fontWeight={'semibold'} fontSize={'lg'} w={'100%'} textAlign={'center'} >
                Store Qr
            </Text>
            <QRCode id={link} value={link} size={90} level={"H"} includeMargin={true} />
            <Text fontWeight={'semibold'} fontSize={'lg'} w={'100%'} textAlign={'center'}
                onClick={() => {
                    window.open(link, '_blank')
                }} cursor={'pointer'}
            >
                {link}
            </Text>
        </VStack>
    )
}

export default StoreQr