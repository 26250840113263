import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { getAvaEnq } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const AvaEnquires = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getAvaEnq,
        name: 'getAvaEnq',
        pagination: true,
    })

    return (
        <VStack w={'100%'} alignItems={'flex-start'} >
            <CompHeader title={'Available Enquires'} />

            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default AvaEnquires