import React from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import { formatNumber } from '../../helper/stringOperations'
import { formatDateTimes } from '../../utils/moment'

const UserCallingCard = ({ data, isDone, index, setCall, markUserAsDone }) => {
    return (
        <HStack w='100%' alignItems={'center'} borderWidth={1} p={4}
            bg={isDone ? 'gray.300' : 'white'} borderRadius={5}>

            <Text fontSize='lg' fontWeight='semibold' flex={1} >
                {index + 1}.{' '}
                {data.name} {'  '}
                <Text as='span' fontSize='sm' color='gray.500'  >
                    {formatDateTimes(data.createdAt, 2)}
                </Text>
            </Text>

            <Text flex={1} fontSize='lg' fontWeight='semibold'
                cursor={'pointer'}
                onClick={() => navigator.clipboard.writeText(data.phone_number)}
            >
                {formatNumber(data.phone_number)}
            </Text>
            {isDone ?
                <HStack flex={1} alignItems='center' >
                    <Button colorScheme='blue' flex={1} onClick={() => setCall(data)} >
                        Call
                    </Button>
                    <Text color='green.500' fontWeight='bold' flex={1} textAlign='center' >
                        Done
                    </Text>
                </HStack>
                :
                <HStack flex={1} alignItems='center' >
                    <Button colorScheme='blue' flex={1} onClick={() => setCall(data)} >
                        Call
                    </Button>
                    <Button colorScheme='green' flex={1} onClick={markUserAsDone} >
                        Mark as Done
                    </Button>
                </HStack>
            }
        </HStack>
    )
}

export default UserCallingCard