import React from 'react'
import { useForm } from 'react-hook-form'
import Tile from '../../../../commons/Tile';
import useSubmit from '../../../../hook/useSubmit';
import { addCallRecord } from '../../../../apis/store';
import SelectField from '../../../../commons/SelectField';
import { formatNumber } from '../../../../helper/stringOperations';
import { Button, HStack, Text, Textarea, VStack } from '@chakra-ui/react'

const tags = [
    "Fresh Arrival",
    "Busy with customers",
    "Store closed",
    "Store location changed",
    "No. of different store",
]

const comments = [
    "Call Not Answered",
    "Call Again or follow up",
    "No. out of service",
    "No. Busy",
    "No Offers",
    "Not Interested",
    "Wrong No.",
    "Concerned person left the job",
    "Store does'nt exist",
]


const InitialCall = ({ store, auth, setRefresh }) => {
    const form = useForm({ defaultValues: { store: '', store_auth: '', phone_number: '', mod: '', tags: [], comment: '', review: '', purpose: 'Offer collection' } })

    const { submit, isLoading } = useSubmit({
        service: addCallRecord,
        onSuccess: () => {
            form.reset();
            setRefresh(d => !d)
        }
    })

    const save = () => {
        const data = {
            ...form.getValues(),
            store: store._id,
            store_auth: auth._id,
            phone_number: auth.phone_number,
        }

        submit(data)
    }

    return (
        <VStack px={4} alignItems={'flex-start'} w='100%'>
            <Text fontSize={'md'} fontWeight='600'>
                Add Call Record
            </Text>

            <HStack justifyContent={'space-between'} w='100%' >
                <VStack alignItems={'flex-start'}>
                    <Tile fw='100' label='Store' value={store.store_name} />
                    <Tile fw='100' label='Auth Name' value={auth.name} />
                    <Tile fw='100' label='Phone Number' value={auth.phone_number} />
                </VStack>
                <Text px={10} flex={1} fontSize={'3xl'} fontWeight={'bold'} >
                    {formatNumber(auth.phone_number)}
                </Text>
            </HStack>


            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Purpose:
                </Text>
                <Textarea rows={1} variant='outline' borderColor='gray.300'
                    value={form.watch('purpose')} placeholder='Purpose of Call'
                    onChange={e => form.setValue('purpose', e.target.value)}
                />
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Comment:
                </Text>
                <SelectField tw={120} form={form} id='comment' list={comments.map(item => ({ label: item, value: item }))} />
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Tags:
                </Text>
                <HStack w='100%' alignItems={'flex-start'} flexWrap={'wrap'}>
                    {tags.map(item =>
                        <Text key={item} w={'20%'} textAlign={'center'}
                            p={1} mb={2} h={12}
                            borderRadius={'md'}
                            cursor={'pointer'}
                            bg={form.watch('tags').includes(item) ? 'blue.500' : 'gray.100'}
                            color={form.watch('tags').includes(item) ? 'white' : 'black'}
                            fontSize={'sm'}
                            onClick={() => {
                                const tags = form.watch('tags')
                                form.setValue('tags', tags.includes(item) ? tags.filter(i => i !== item) : [...tags, item])
                            }} >
                            {item}
                        </Text>
                    )}
                </HStack>
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Review:
                </Text>
                <Textarea rows={2} variant='outline' borderColor='gray.300'
                    value={form.watch('review')} placeholder='Review'
                    onChange={e => form.setValue('review', e.target.value)} />
            </HStack>

            <Button onClick={save} isLoading={isLoading} colorScheme={'red'} >
                Save
            </Button>

        </VStack>
    )
}

export default InitialCall