import { Button, Text, } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import CompHeader from '../../../commons/CompHeader/CompHeader';
import Loading from '../../../commons/Loading';
import useFetch from '../../../hook/useFetch';
import { getstorebyid, saveAndVerify } from '../../../apis/store';
import { HStack, VStack } from 'native-base'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import StoreForm from './StoreForm';
import { Box } from '@chakra-ui/react';
import useSubmit from '../../../hook/useSubmit';
import StoreVersionHistory from './StoreVersionHistory';
import StoreAuthories from './StoreAuthories';
import StoreImages from './StoreImages';
import { colors } from '../../../theme/colors';
import StoreProductSelection from './StoreProductSelection';
import RemoveStore from '../ReviewStore/RemoveStore';
import StoreOffers from './StoreOffers/StoreOffers';
import StoreProducts from './StoreProducts/StoreProducts';
import StoreReels from './StoreReels/StoreReels';

const Review = () => {
    const form = useForm({ mode: 'onBlur' });
    const form2 = useForm({ mode: 'onBlur' });

    const { id } = useParams();
    const { data } = useFetch({ name: `store_${id}`, service: getstorebyid, id, params: { raw: true } })

    const { submit, isLoading } = useSubmit({ service: saveAndVerify, id })

    useEffect(() => {
        if (!data) return;
        form.reset({ ...data, viewOnly: true })
        form2.reset({ ...data, default: data, viewOnly: false })
    }, [data]);

    const store_name1 = form.watch('store_name')
    const store_name2 = form2.watch('store_name')
    if (!store_name1 || !store_name2) return <Loading />

    return (
        <VStack space='3' w='100%'>

            <CompHeader title='Store Details' backButton
                renderRight={
                    data.verified ?
                        <Text color='green.600'
                            fontWeight={'semibold'}
                            fontSize='lg'>Store Verified & Live</Text>
                        : null}
            />

            <StoreVersionHistory form={form} form2={form2} />

            <VStack space='3' w='100%' borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
                <HStack space={2} alignItems={'flex-start'}>
                    <StoreForm form={form} viewOnly />
                    <Box w={.5} bg={'black'} alignSelf={'center'} h='120vh' />
                    <StoreForm form={form2} />
                </HStack>

                <Button w='50%' alignSelf={'flex-end'} isLoading={isLoading}
                    variant='solid' colorScheme='teal'
                    onClick={() => {
                        const data = form2.watch()
                        delete data.default
                        delete data.viewOnly
                        submit(data)
                    }}
                >
                    Update
                </Button>
            </VStack>

            <StoreProductSelection />

            <StoreAuthories store={data} />

            <StoreImages store={data} />

            <StoreOffers store={data} />
            <StoreProducts store={data} />
            <StoreReels store={data} />

            {/* <StoreQrs store={data} /> */}

            <RemoveStore store={data} />
        </VStack>

    )
}

export default Review