import { Input } from "@chakra-ui/react";

export const registerDateTimeProps = {
    // customInput: <Input bg="#E7E6E6" />,
    timeIntervals: 30,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
    showTimeSelectOnly: true,
    showTimeSelect: true,
}

export const wearSelectionTypes = ['exclusive']
export const genderSelectionTypes = ['multibranded', 'branded']

export const offerTypes = [
    ["factory-outlet", ' My Store is a Factory Outlet and we generally have better offers than retail stores'],
    ['brand-supervised', 'Brand Supervised Discounts (Our Discounts are similar across all the stores of the brand)'],
    ['other', 'Other (Discounts in our store are generally different from other stores of same Brand)'],
]

export const offerTypes2 = [
    ['chain-supervised', 'Chain Supervised Discounts (Our Discounts are similar across all the stores of the Chain)'],
    ['other', 'Other (Discounts in our store are generally different from other stores of same Brand)'],
]

export const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const yesNo = [
    ['Yes', 'true'],
    ['No', 'false'],
]

export const chainTypes = [
    ['primary', 'Larger Chain', 'Select Larger Chain if your Chain is well recognized, has good number of stores in india and is spread out in various cities.'],
    ['secondary', 'Smaller Chain', 'Select Smaller Chain if you have fewer stores spread out in limited number of cities'],
]

export const storeStructure = [
    ['chain', 'Multi Store', ' This Store is Part of a Chain or has Multiple Branches By same Name'],
    ['single store', 'Single Standalone Store', 'This store is not part of a chain and has no other branches.'],
]

export const storeCategories = [
    ['exclusive', 'Exclusive Brand Store', 'The store only serves 1 brand'],
    ['multibranded', 'Multiple Brands Store', 'The store serves multiple brands'],
    ['branded', 'Branded + Non-Branded/Local Branded Clothing available', 'Branded + Non Branded'],
    ['non-branded', 'Non-Branded/Generic Brands/Local Branded Available Only', 'Non-Branded/Generic Brands/Local'],
]

export const clothingProvided = [
    ['For Readymade', 'readymade'],
    ['For Custom Made', 'stitched']
]
export const subClothingProvided = [
    ['Branded', 'branded'],
    ['Non-Branded', 'non_branded']
]
// label,value
export const clothingTypes = [
    ['ReadyMade', 'readymade'],
    ['CustomMade', 'stitched'],
    ['Both', 'both'],
]

export const validateStoreInfo = (state, store) => {
    if (state.located && state.mall_id === "") {
        return false;
    }
    if (
        state.role !== "STORE OWNER" &&
        state.owner_contact === "" &&
        state.owner_name === ""
    ) {
        return false;
    }
    if (
        state.state !== "" &&
        state.city !== "" &&
        state.type !== "" &&
        state.gst_number !== ""
    ) {
        if (state.type === "exclusive") {
            if (state.role === "STORE OWNER") {
                return true;
            }
            if (state.offer_type && state.ownership) {
                return true;
            } else if (state.type === "multibranded") {
                if (store.structure) {
                    if (store.structure === "chain") {
                        if (store.chain) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            } else {
                return false;
            }
        }
    }
}

export const validatePersonalInfo = (state, extra) => {
    if (
        state.name !== "" &&
        state.phone_number !== "" &&
        state.role !== "" &&
        extra.sent &&
        extra.verified
    )
        return true
    return false

};

export const storeRoles = [
    ['STORE OWNER', 'Store Owner'],
    ['STORE MANAGER', 'Store Manager'],
    ['STORE REP', 'Store Representative'],
]
export const storeRegisterDefaultValues = {
    role: '',
    name: '',
    phone_number: '',
    geo_location: [],
    genders_served: [],
    genders_served_stitched: [],
    images: [],
    brands: [],
    brands_stitched: [],
    new_brands: [],
    working_hours: [],
    working: [],
    type: 'exclusive',
    chain: 'larger',
    offer_type: 'chain-supervised',
    store_timing: [],
    type_of_clothing: {},
    structure: 'multi',
    address: '',
    located: null,
    clothing_type: 'readymade',
    cloth_type: '',
    clothing_wear: [],
    clothing_wear_stitched: [],
    wear: [],
    clothing_provided: {
        readymade: {
            branded: true,
            non_branded: false,
        },
        stitched: {
            branded: true,
            non_branded: false,
        },
    },
    extra: {
        verified: !false,
        sent: !false,
        newChain: false,
        manager_same_as_owner: false,
        same_as_chain_name: false,
        newMall: false,
        chainType: '',
        custom: [],
        brands: [],
        chains: [],
        store: "",
        clotheType: "",
        readymade_custom: [],
        readymade_newBrand: false,
        readymade_selected: [],
        readymade_active: false,
        readymade_brandss: [],
        index: 0,
        images: [],
        timings: [],
        advanced: false,
        working: [],
        genders: [],
        update: Math.random(),
    }
}