import React, { useState } from 'react'
import { Input } from '@chakra-ui/react'
import { colors } from '../../theme/colors'
import { useParams } from 'react-router-dom'
import useSubmit from '../../hook/useSubmit'
import { Box, Button, Checkbox, HStack, Spacer, Text, VStack } from 'native-base'
import { createParametric, deleteParametric, toggleFilterRequired, toggleFilterRequiredInUpload } from '../../apis/admin'

const FilterCard = ({ data, form, wears, parametric, refetchParametric, product }) => {
    const { id, name } = useParams()
    const [search, setSearch] = useState('')
    const [showAllGender, setShowAllGenders] = useState(false)
    const { gender, wear } = form.getValues()
    let wear_genders = wears.filter(({ wears }) => !!wears.find(({ _id }) => _id === wear)).map(({ gender }) => gender)
    if (!showAllGender) {
        wear_genders = wear_genders.filter(({ _id }) => _id === gender)
    }

    const keys = data.keys.sort((a, b) => {
        if (a.label < b.label) {
            return -1
        }
        if (a.label > b.label) {
            return 1
        }
        return 0
    })

    const Parametric = useSubmit({
        service: parametric ? deleteParametric : createParametric,
        id: parametric?._id,
        onSuccess: refetchParametric
    })

    const makeParametric = () => {
        const keys = data.keys
            .filter(({ _id }) => form.getValues().keys?.[gender]?.[data._id].includes(_id))
            .map(({ label, _id }) => ({ label: `${label} ${name}`, key: _id }))

        Parametric.submit({ product: id, titleKey: data._id, keys, })
    }

    const MakeRequired = useSubmit({
        service: toggleFilterRequired,
        id,
        props: { filter_id: data._id, gender },
        onSuccess: product.refetch
    })

    const MakeRequiredInUpload = useSubmit({
        service: toggleFilterRequiredInUpload,
        id,
        props: { filter_id: data._id, gender },
        onSuccess: product.refetch,
    })


    return (
        <VStack w={'100%'} bg='white' p='4' borderRadius={'lg'} space='2'>

            <HStack space='4' mb='4' alignItems={'center'}>
                <Text fontSize={'lg'} fontWeight='semibold'>{data.title}</Text>

                <Spacer />

                <Box mr='10'>
                    <Checkbox
                        opacity={product.isLoading ? .5 : 1}
                        pointerEvents={product.isLoading ? 'none' : 'auto'}
                        onChange={() => MakeRequiredInUpload.submit()}
                        isChecked={product.data?.required_filters_in_upload?.find(item => item.gender === gender)?.keys?.includes(data._id)}
                        _text={{ fontSize: 'sm', fontWeight: 'bold' }}>Required in Product Upload</Checkbox>
                </Box>

                <Box mr='10'>
                    <Checkbox
                        opacity={product.isLoading ? .5 : 1}
                        pointerEvents={product.isLoading ? 'none' : 'auto'}
                        onChange={() => MakeRequired.submit()}
                        isChecked={product.data?.required_filters?.find(item => item.gender === gender)?.keys?.includes(data._id)}
                        _text={{ fontSize: 'sm', fontWeight: 'bold' }}>Required in CBS</Checkbox>
                </Box>

                <Button size='sm' isLoading={Parametric.isLoading} colorScheme={parametric ? 'red' : 'blue'}
                    onPress={makeParametric}>{parametric ? 'Remove Parametric' : 'Make Parametric'}</Button>

            </HStack>


            <HStack w={'100%'} bg='white' space={4} alignItems='center'
                borderBottomWidth={1} pb={0} borderColor={colors.border}
            >
                <Text fontWeight={'bold'} fontSize='md'>Options</Text>
                <HStack space='2'>
                    <Checkbox isChecked={showAllGender}
                        onChange={() => setShowAllGenders(!showAllGender)}
                    />
                    <Text>
                        Show All Genders
                    </Text>
                </HStack>
                <Spacer />
                <Input
                    value={search}
                    bg={'white'}
                    borderWidth={0}
                    outline={0}
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Search filters'
                />
            </HStack>

            <VStack space='2' w={'100%'}>
                {wear_genders?.map(({ type, _id }) =>
                    <HStack w={'100%'} space='2'>
                        <VStack mb='10'>
                            <Text w='60'>{type}</Text>
                            <Text onPress={() => {
                                form.setValue(`keys[${_id}][${data._id}]`, [...(form.watch(`keys[${_id}]`)?.[data._id] || []), ...data.keys.map(key => key._id)])
                            }} position={'absolute'} color={colors.info} mt='5' fontSize='xs' w='60' >Add All</Text>
                        </VStack>
                        <Checkbox.Group
                            value={form.watch(`keys[${_id}]`)?.[data._id] || []}
                            onChange={(e) => form.setValue(`keys[${_id}][${data._id}]`, e)}
                        >
                            <HStack w={window.innerWidth - 300} flex='1' flexWrap={'wrap'} space='2' >
                                {keys.filter(({ label }) => label.toLowerCase().startsWith(search.toLowerCase())).map(key =>
                                    <Checkbox mb='1' w='130'
                                        _text={{ fontSize: 'sm' }} value={key._id}>{key.label}</Checkbox>
                                )}
                            </HStack>
                        </Checkbox.Group>
                    </HStack>
                )}
            </VStack>

        </VStack>
    )
}

export default FilterCard