import React, { memo, useCallback } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react'

const usePagingQuery = ({
    service,
    name,
    conditionalRefetch = [],
    params = {},
    limit: _limit = 16,
    defaultPage = 1,
    enabled = true,
    // action,
    id,
}) => {

    const [limit, setLimit] = React.useState(_limit)
    const [page, setPage] = React.useState(defaultPage)

    conditionalRefetch = { ...conditionalRefetch, page, limit, params }

    const {
        data, fetchNextPage, hasNextPage,
        isFetching, isLoading, isFetchingNextPage,
        refetch, isRefetching
    } = useInfiniteQuery({
        queryKey: [`${name}_${JSON.stringify(params)}`, conditionalRefetch],
        queryFn: async ({ pageParam = 1 }) => {
            const data = await service({
                id, params: {
                    ...params, page, limit,
                    // action: `${action}_${pageParam}_at_${new Date().getTime()}`
                }
            })
            return data
        },
        enabled,
        getNextPageParam: (lastPage, pages) => {
            const _pages = lastPage.data.result?.next?.page || null
            return _pages
        },
    })

    const totalPage = data?.pages?.[0]?.data?.result?.pages
    const totalData = data?.pages?.[0]?.data?.result?.total
    const inView = {
        start: (page - 1) * limit + 1,
        end: Math.min(page * limit, totalData),
    }
    const nextPage = useCallback((page, totalPage) => {
        if (page >= totalPage) return
        setPage(page + 1)
    }, [])

    const prevPage = useCallback((page) => {
        if (page === 1) return
        setPage(page - 1)
    }, [])

    const goToPage = useCallback((page, totalPage) => {
        if (page < 1 || page > totalPage) return
        setPage(page)
    }, [])

    const Nav = memo(() => {
        if (totalPage === 0) return null
        return (
            <VStack w={'100%'} alignItems={'center'} spacing={2} bg={'white'} p={4}
                borderRadius={10} borderWidth={2} borderColor={'gray.200'}
            >
                <HStack spacing={2} justifyContent={'center'} w={'100%'} alignItems={'center'}>
                    <Input
                        width={150}
                        type='number'
                        placeholder={`per page(${limit})`}
                        onChange={(e) => {
                            setTimeout(() => {
                                setLimit(Number(e.target.value))
                                setPage(1)
                            }, 500)
                        }}
                    />
                    <Button onClick={() => prevPage(page)} disabled={page === 1} >
                        {`< Prev `}
                    </Button>
                    <Button onClick={() => nextPage(page, totalPage)} disabled={page === totalPage} >
                        {`Next >`}
                    </Button>
                    <Input
                        width={150}
                        type='number'
                        placeholder={`Go to page(${page})`}
                        onChange={(e) => {
                            setTimeout(() => {
                                goToPage(Number(e.target.value), totalPage)
                            }, 500)
                        }}
                    />
                </HStack>
                <HStack spacing={2} justifyContent={'center'} w={'100%'} alignItems={'center'} overflowX={'scroll'}>
                    <Text cursor={'pointer'} color={page === 1 ? 'blue.500' : 'black'} >
                        {totalPage === 0 ? 0 : page}/{totalPage}
                    </Text>
                </HStack>
            </VStack>
        )
    }, [totalPage, page])

    return {
        data: data?.pages?.[0]?.data?.result?.data || [],
        totalData, inView,
        fetchNextPage, hasNextPage,
        isFetching, isLoading, isFetchingNextPage,
        refetch, isRefetching,
        Nav
    }
}

export default usePagingQuery