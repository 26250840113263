import { VStack } from '@chakra-ui/react';
import React from 'react'
import { BallTriangle } from 'react-loader-spinner'

const Loading = ({
    size = 100,
}) => {
    return (
        <VStack w="100%" h="100%" alignItems='center' justifyContent='center'>
            <BallTriangle
                height={size}
                width={size}
                color='grey'
                ariaLabel='loading'
            />
        </VStack>
    )
}

export default Loading