import { Avatar, Button, Image } from 'native-base';
import { getAppLinks } from '../../helper/applink';
import { IMAGE_BASE_URL } from '../../helper/variables';

export const columns = (navigate) => [
    {
        name: 'Logo',
        cell: (row) => !row.logo || row.logo === 'null' ?
            <Avatar bg={'white'} _text={{ color: 'black' }}>{row.brand[0]}</Avatar>
            :
            <Image resizeMode='contain' cursor='pointer' src={IMAGE_BASE_URL + row.logo} h='50' w='50' />
        ,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row.brand,
        sortable: true,
    },
    // {
    //     name: 'Type',
    //     selector: (row) => row.type,
    //     sortable: true,
    // },
    {
        name: 'Status',
        selector: (row) => row.status,
        sortable: true,
    },
    {
        name: 'Stores',
        selector: (row) => row.stores.length,
        sortable: true,
    },
    {
        name: 'App Link',
        button: true,
        cell: (row) => <Button
            color='blue.400'
            size='sm' variant={'link'}
            onPress={() => getAppLinks(`/brand/${row._id}`)}>
            copy</Button>,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/brands/edit/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
]