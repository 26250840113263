import { Text, VStack } from '@chakra-ui/react'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Services from './services/Services'

const Whatsapp = () => {
    return (
        <VStack w="100%" alignItems="flex-start" >
            <CompHeader title='Whatsapp Services' />

            <Services />
        </VStack>
    )
}

export default Whatsapp