import React from 'react'
import { colors } from '../../theme/colors';
import { Box, HStack, Text, VStack } from '@chakra-ui/react'

const list = [
    { title: "All", q: {}, },
    { title: "Verified", q: { verified: true }, },
    { title: "Not verified", q: { verified: false }, },
    { title: "Pre Register", q: { pre_register: true }, },
    { title: "Claimed", q: { claimed: true }, },
    { title: "No offers", q: { no_offers: true }, },
    { title: "No Products", q: { no_products: true }, },
]

const Filters = ({ active = [], setActive }) => {
    const selectedTitles = active?.map(item => item.title)

    return (
        <VStack alignItems={'flex-start'} >
            <Text color={colors.secondary} fontWeight={'bold'} fontSize={'sm'} >
                Filters
            </Text>
            <HStack spacing={3}>
                {list.map((item, i) => {
                    const selected = selectedTitles?.includes(item.title)
                    return (
                        <Box borderRadius={'lg'} p={2} py={1} borderWidth={1} bg={colors.input}
                            borderColor={selected ? colors.primary : colors.input}
                            cursor={'pointer'} key={i}
                            onClick={() => {
                                if (selected)
                                    setActive(active.filter(i => i.title !== item.title))
                                else
                                    setActive([...active, item])
                            }}>
                            <Text fontSize={'sm'} transition={'all .2s ease-in-out'}
                                fontWeight={selected ? 'bold' : 'normal'}
                                color={selected ? colors.primary : colors.secondary} >
                                {item.title}
                            </Text>
                        </Box>
                    )
                }
                )}
            </HStack>
        </VStack>
    )
}

export default Filters