import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable';
import CBSTabs from './CBSTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from '../../hook/useFetch';
import { columns } from './static';
import { getCBSEquiries } from '../../apis/admin';
import { getURLQuery } from '../../helper/url';

const CBS = () => {
    const navigate = useNavigate()
    const { search } = useLocation();
    const status = getURLQuery(search, 'status') || 'all';

    const { data, isLoading } = useFetch({
        name: 'cbs_enquiries',
        service: getCBSEquiries,
        params: { status },
        conditionalRefetch: [status],
        pagination: true
    })

    return (
        <VStack>
            <CompHeader title='Context Based Shopping' />
            <CBSTabs />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default CBS