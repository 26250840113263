import React from 'react'
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useNavigate, useParams } from 'react-router-dom'
import useSubmit from '../../hook/useSubmit'
import { editProductThumbnail, getProductById } from '../../apis/admin'
import { useForm } from 'react-hook-form'
import Loading from '../../commons/Loading'
import ProductThumnailCard from './ProductThumnailCard'
import useFetch from '../../hook/useFetch'
import { getGenders } from '../../apis/other'

const ProductThumbnails = () => {
    const navigate = useNavigate()
    const product_id = useParams()?.id
    const form = useForm({ defaultValues: {} })

    const { data: product } = useFetch({
        name: `product_${product_id}`,
        service: getProductById,
        id: product_id,
        onSuccess: (data) => {
            form.reset({
                thumbnails: data.thumbnails
            })
        }
    })

    const { data: genders, isLoading: genderLoading } = useFetch({ name: 'getGenders', service: getGenders })

    if (genderLoading) return <Loading />

    return (
        <VStack w={'100%'} alignItems={'flex-start'}>

            <CompHeader title='Product Thumbnails' />

            <Text fontSize={'xl'} fontWeight={'bold'}>
                Product: {product.name}
            </Text>

            <HStack flexWrap={'wrap'} w='100%'>
                {genders.map(gender =>
                    <Box key={gender._id} w='45%'>
                        <ProductThumnailCard
                            gender={gender}
                            form={form}
                            product_id={product_id}
                            product={product}
                        />
                    </Box>
                )}
            </HStack>



        </VStack>
    )
}

export default ProductThumbnails