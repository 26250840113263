import { useEffect } from 'react'
import useFetch from '../../../../hook/useFetch'
import { arrayIntersection } from '../../../../helper/stringOperations'
import { getFilters } from '../../../../apis/other'

const showKeyRules = ({ filter = {}, titles, keys, rule, }) => {
    if (!rule)
        rule = filter?.title?.rules
    if (!rule) return true
    return (arrayIntersection(titles, rule?.titles)?.length > 0 || arrayIntersection(keys, rule?.keys)?.length > 0) ? false : true
}

const showKey = (rules, titles, keys) => {
    const _titles = rules?.titles || []
    const _keys = rules?.keys || []
    const filteredArray1 = _titles?.filter(value => titles?.includes(value));
    const filteredArray2 = _keys?.filter(value => keys?.includes(value));
    return !filteredArray1.length && !filteredArray2.length
}
export const useFilterRules = ({
    product, gender, wear,
    selection = [],
    onFilterFetch = () => { },
    changeSelection = () => { },
    type,
}) => {

    let filters = useFetch({
        name: `filters_${product}_${gender}_${wear}`,
        service: getFilters, dataOnly: true,
        props: { data: { product, gender, mode: 'store', wear } },
        conditionalRefetch: [product, gender, wear],
        condition: !!product && !!gender && !!wear,
        cache: true,
        onSuccess: onFilterFetch
    })

    if (type)
        filters = filters.filter(({ title }) => type === 'req' ? title.required : !title.required)

    const titles = selection?.filter(key => key?.keys?.length)?.map(item => item?.title)
    const keys = selection?.map(key => key?.keys).flat()

    const visibleFilters = filters.filter(item => showKeyRules({ filter: item, titles, keys }))
    const visibleFilterIds = visibleFilters.map(item => item.title._id)
    const nonVisibleFilters = filters.filter(item => !visibleFilterIds.includes(item.title._id))
    const nonVisibleFiltersCount = nonVisibleFilters?.length

    const allKeys = visibleFilters.map(({ keys }) => keys).flat()
    const nonVisibleFiltersKeys = allKeys.filter(item => !showKey(item.rules, titles, keys))
    const nonVisibleFiltersKeysIds = nonVisibleFiltersKeys.map(item => item._id)

    useEffect(() => {
        if (nonVisibleFiltersCount === 0) return
        nonVisibleFilters.map(item => {
            const index = selection.findIndex(({ title }) => title === item.title._id)
            if (selection?.[index]?.keys?.length) {
                selection[index].keys = []
                changeSelection(selection)
            }
        })
    }, [nonVisibleFiltersCount])

    useEffect(() => {
        nonVisibleFiltersKeysIds.map(id => {
            const index = selection.findIndex(({ keys }) => keys?.includes(id))
            if (index === -1) return
            selection[index].keys = selection[index]?.keys.filter(item => item !== id)
            changeSelection(selection)
        })
    }, [nonVisibleFiltersKeysIds])

    const finalFilters = visibleFilters.map(({ title, keys }) => ({
        title,
        keys: keys.filter(({ _id }) => !nonVisibleFiltersKeysIds.includes(_id))
    }))


    const reqFilters = finalFilters.filter(({ title }) => title.required)
    const nonReqFilters = finalFilters.filter(({ title }) => !title.required)

    return {
        filters: finalFilters,
        selectedTitles: titles,
        selectedKeys: keys,
        reqFilters,
        nonReqFilters,
    }
}