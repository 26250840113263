import { VStack } from '@chakra-ui/react'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import useFetch from '../../hook/useFetch'
import { getUserSource } from '../../apis/admin'

const UserSrc = () => {

    const { data, isLoading } = useFetch({
        service: getUserSource,
        name: 'getUserSource'
    })

    return (
        <VStack w={'100%'} alignItems={'flex-start'} >
            <CompHeader title='User Source' />





        </VStack>
    )
}

export default UserSrc