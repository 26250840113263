
import React from 'react'
import { Text } from 'native-base'
import { HStack } from '@chakra-ui/react'
import ProductWearCard from './ProductWearCard'

const ProductGenderCard = ({ wears, gender, refetch, activeWears, editWear }) => {
    const activeWearsIds = activeWears?.map(({ _id }) => _id)

    wears = [
        ...wears.filter(({ _id }) => activeWearsIds?.includes(_id)),
        ...(editWear ? wears.filter(({ _id }) => !activeWearsIds?.includes(_id)) : [])
    ]

    return (
        <HStack alignItems={'flex-start'} w='100%'>
            <Text fontSize={'md'} fontWeight={'semibold'} w="20">{gender.type}:</Text>
            <HStack flexWrap={'wrap'}
                spacing={editWear ? 0 : 2}
                alignItems={'flex-start'} justifyContent={'flex-start'}>
                {wears.map(item =>
                    <ProductWearCard
                        editWear={editWear}
                        gender={gender}
                        key={item._id}
                        isActive={activeWearsIds?.includes(item._id)}
                        wear={item}
                        refetch={refetch}
                    />
                )}
            </HStack>
        </HStack>
    )
}

export default ProductGenderCard