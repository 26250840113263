import Tabs from './Tabs'
import { columns } from './static'
import { VStack } from 'native-base'
import React, { useState } from 'react'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import UserCalls from './UserCalls/UserCalls'
import { useNavigate } from 'react-router-dom'
import Model from '../../components/compo/UI/Model'
import UserCoupons from './UserCoupons/UserCoupons'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { getusers, sendBannerToPhoneNumber } from '../../apis/admin'
import ExcelDownload from '../../components/ExcelDownload/ExcelDownload'

const Users = () => {
    const navigate = useNavigate()
    const [params, setParams] = useState({})
    const { data, isLoading } = useFetch({
        name: 'users',
        service: getusers,
        pagination: true,
        params,
        conditionalRefetch: [params]
    })
    const [call, setCall] = useState(null)
    const [coupon, setCoupon] = useState(null)

    const SendBannerToPhoneNumber = useSubmit({
        service: sendBannerToPhoneNumber,
    })

    return (
        <VStack>
            <CompHeader title='Users' />

            <Tabs params={params} setParams={setParams} />

            <ExcelDownload service={getusers} params={{ ...params }} />
            <DataTable tableData={{ columns: columns({ navigate, setCall, setCoupon, SendBannerToPhoneNumber }), data, isLoading, pagination: true }} />

            {call && <Model size='full' title={'Calls'} show={!!call} close={() => setCall(false)}
                body={<UserCalls user={call} close={() => setCall(false)} />}
            />}

            {coupon && <Model size='full' title={'Coupons'} show={!!coupon} close={() => setCoupon(false)}
                body={<UserCoupons user={coupon} close={() => setCoupon(false)} />}
            />}

        </VStack>
    )
}

export default Users