import { Button } from 'native-base';
import { truncate } from '../../helper/stringOperations';

export const columns = (navigate) => [
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: 'Total Options',
        selector: (row) => row.options.length,
        sortable: true,
    },
    {
        name: 'Options',
        selector: (row) => truncate(row.options.map(item => item).join(', '), 130),
        sortable: true,
        width: '60%',

    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/offerTemplateList/edit/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
]