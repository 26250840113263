import React from 'react'
import { Text, VStack } from '@chakra-ui/react'
import { FilterSelection } from './FilterSelection'
import { contProps, contPropsTitle } from '../../../helper/variables'

const ProductsFilters = ({ form }) => {

    const state = form.watch()
    const gender = state.gender?.value
    const category = state.product?.value
    const wear = state.wear?.value

    return (
        <VStack {...contProps} >
            <Text {...contPropsTitle}>
                Product Details
            </Text>
            <FilterSelection product={category} gender={gender}
                wear={wear} selection={state?.filters}
                // onFilterFetch={(data) => setFilters(data) }
                setRequiredFilters={(data) => {
                    form.setValue(`filtersData`, data.filter(({ title, keys }) => title.required && keys?.length > 0).map(({ title }) => title._id))
                }}
                changeSelection={(data) => {
                    form.setValue(`filters`, data)
                }}
            />
        </VStack>
    )
}

export default ProductsFilters