import React from 'react';
import { VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hook/useFetch';
import Loading from '../../../commons/Loading';
import { getRestaurantById } from '../../../apis/admin';
import CompHeader from '../../../commons/CompHeader/CompHeader';
import AddRestaurantform from '../CreateRestaurant/AddRestaurantform';

const EditRestaurant = () => {
    const { id } = useParams()
    const { data, isLoading } = useFetch({ name: `getRestaurantById_${id}`, service: getRestaurantById, id })

    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2' w={'100%'} >
            <CompHeader title='Edit Restuarant' backButton />
            {data && <AddRestaurantform data={{ ...data, verified: data?.verified?.toString() }} />}
        </VStack>
    )
}

export default EditRestaurant