import React from 'react'
import { formatDateTimes, fromNow } from '../../utils/moment'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import { formatNumber } from '../../helper/stringOperations'
import Tile from '../../commons/Tile'
import { colors } from '../../theme/colors'
import Model from '../../components/compo/UI/Model'
import StoreAuthCalls from '../Stores/Review/StoreAuthCalls/StoreAuthCalls'

const WaMessageActionsCard = ({ data }) => {
    const [calls, setCalls] = React.useState(false)
    const [store, setStore] = React.useState(false)
    const latestCall = data?.call_records?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]

    return (
        <VStack w={'100%'} bg={'white'} p={4} borderRadius={4} boxShadow={'md'} alignItems={'flex-start'} >

            <HStack w={'100%'} alignItems={'flex-start'} pb={2}>
                <VStack alignItems={'flex-start'} flex={1} spacing={1} >
                    {data.stores.map(store => {
                        const lastFiveOffers = store?.offers?.slice(0, 5)

                        return (
                            <VStack alignItems={'flex-start'} flex={1} spacing={0} w={'100%'}>
                                <HStack w={'100%'}>
                                    <Text fontWeight={'bold'} as='span' fontSize={'2xl'} flex={1}
                                        onClick={() => window.open(`/admin/reviewStore/${store._id}`, '_blank')} >
                                        {store?.store_name}
                                    </Text>
                                    <Button size='sm' colorScheme={'green'} isLoading={false} onClick={() => {
                                        setCalls(true)
                                        setStore(store)
                                    }} >
                                        Call
                                    </Button>
                                </HStack>
                                <Text w={'100%'} fontWeight={'normal'} as='span' mx={2} fontSize={'md'}>
                                    {store?.store_address?.address}
                                </Text>
                                {lastFiveOffers?.length > 0 &&
                                    <VStack borderWidth={1} borderColor={colors.border} p={2}
                                        alignItems={'flex-start'} w={'100%'} spacing={0} >
                                        <Text color={colors.secondary} fontWeight={'bold'} fontSize={'md'} >
                                            Last {lastFiveOffers?.length} Offers
                                        </Text>
                                        {lastFiveOffers.map((offer, index) =>
                                            <VStack w='100%' alignItems={'flex-start'} pt={1} key={index} spacing={0} >
                                                <HStack w='100%' alignItems={'flex-start'}>
                                                    <Text flex={1} fontWeight={'bold'} color={colors.secondary} >
                                                        {offer.offer}
                                                    </Text>
                                                    <Text flex={1} fontWeight={'bold'} color={colors.secondary} >
                                                        {fromNow(offer.createdAt)}
                                                    </Text>
                                                </HStack>
                                            </VStack>
                                        )}
                                    </VStack>
                                }
                            </VStack>
                        )
                    })}
                </VStack>

                <VStack alignItems={'flex-start'} flex={1} spacing={0}>
                    <HStack alignItems={'center'} flex={1} spacing={2}>
                        <Text fontWeight={'bold'} as='span' fontSize={'xl'}>
                            {data?.auth?.name} -
                        </Text>
                        <Text fontWeight={'bold'} as='span' fontSize={'2xl'}>
                            {formatNumber(data?.auth?.phone_number)}
                        </Text>
                    </HStack>
                    <Text fontWeight={'normal'} as='span' mx={2} fontSize={'md'}>
                        {data?.auth?.role}
                    </Text>
                </VStack>
            </HStack>

            {data.clicks.map((item, index) =>
                <HStack alignItems={'flex-start'} spacing={0} borderBottomWidth={1} borderBottomColor={'gray.200'} w='100%'>
                    <Text fontWeight={'bold'} flex={1}>
                        {index + 1}. Pressed:
                        <Text ml={2} fontWeight={'semibold'} as='span' color={item?.url_params?.button === 'no' ? 'red' : 'green'} >
                            {item?.url_params?.button.toUpperCase()}
                        </Text>
                        <Text ml={2} as='span' fontSize={'md'} fontWeight='semibold' color={colors.secondary}>
                            At  {formatDateTimes(item?.createdAt, 4)}
                        </Text>
                    </Text>
                    <Text flex={1}>
                        <Text fontWeight={'bold'} as='span' mr={1}>
                            Campaign:
                        </Text>
                        {item?.url_params?.utm_campaign}
                    </Text>
                </HStack>
            )}

            {latestCall &&
                <VStack w='100%' spacing='0' alignItems={'flex-start'} pt={2}>
                    <Text color={colors.secondary} fontWeight={'bold'} fontSize={'md'} >
                        Last Call - {formatDateTimes(latestCall?.createdAt, 8)}
                    </Text>

                    <HStack w='100%' alignItems={'flex-start'} >
                        <Text flex={1} fontWeight={'bold'} color={colors.secondary} >
                            Phone Number
                            <Text ml={1} as='span'>{latestCall?.phone_number}</Text>
                        </Text>

                        <Text flex={1} fontWeight={'bold'} color={colors.secondary} >
                            Purpose
                            <Text ml={1} as='span'>{latestCall?.purpose || '--'}</Text>
                        </Text>

                        <Text flex={1} fontWeight={'bold'} color={colors.secondary} >
                            Comment
                            <Text ml={1} as='span'>{latestCall?.comment || '--'}</Text>
                        </Text>

                        <Text flex={1} fontWeight={'bold'} color={colors.secondary} >
                            Review
                            <Text ml={1} as='span'>{latestCall?.review || '--'}</Text>
                        </Text>
                    </HStack>
                </VStack>
            }



            {calls && <Model size='full' title={'Calls'} show={calls} close={() => setCalls(false)}
                body={<StoreAuthCalls auth={data.auth} store={store} close={() => setCalls(false)} />}
            />}
        </VStack>
    )
}

export default WaMessageActionsCard