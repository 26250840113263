import React from 'react'
import { columns } from './static'
import { useNavigate } from 'react-router-dom'
import useFetch from '../../../../hook/useFetch'
import { colors } from '../../../../theme/colors'
import { getStoreOffers } from '../../../../apis/store'
import { Button, Text, VStack } from '@chakra-ui/react'
import Model from '../../../../components/compo/UI/Model'
import ChangeMultipleOffers from './ChangeMultipleOffers'
import DataTable from '../../../../commons/DataTable/DataTable'

const StoreOffers = ({ store }) => {
    const navigate = useNavigate()
    const [selection, setSelection] = React.useState([])
    const [open, setOpen] = React.useState(null)

    const { data, isLoading, refetch } = useFetch({
        name: `storeOffers${store._id}`,
        service: getStoreOffers,
        id: store._id,
    })

    return (
        <VStack alignItems={'flex-start'} space='2' borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
            <Text fontSize={'md'} fontWeight='600'>Store Offer ({data?.length})</Text>

            {selection.length > 0 &&
                <Button colorScheme='blue' size='sm' onClick={() => setOpen(!open)}>
                    Change {selection.length} Offers
                </Button>}

            <Button colorScheme='red' variant='outline' size='sm' onClick={() => {
                setSelection(data.map((row) => row._id))
            }}>
                Select All Offers
            </Button>

            <DataTable selectableRows pagination={false}
                onSelectedRowsChange={(e) => setSelection(e.selectedRows.map((row) => row._id))}
                tableData={{ columns: columns(navigate), data, pagination: false, isLoading }} />

            {
                open && <Model size='2xl' show={open}
                    title={`Change ${selection.length} Offers`}
                    close={() => setOpen(!open)}
                    body={<ChangeMultipleOffers selection={selection} refetch={refetch} close={() => setOpen(null)} />}
                />
            }
        </VStack >
    )
}

export default StoreOffers