import { Button, Image } from 'native-base';
import { IMAGE_BASE_URL } from '../../helper/variables';
import { getAppLinks } from '../../helper/applink';

export const columns = ({ navigate, setID }) => [
    {
        name: 'Logo',
        cell: (row) => <Image cursor='pointer' src={IMAGE_BASE_URL + row.logo} h='50' w='50' />,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: 'Type',
        selector: (row) => row.type,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row) => row.status,
        sortable: true,
    },
    {
        name: 'Stores',
        selector: (row) => row.stores.length,
        sortable: true,
    },
    // {
    //     name: 'State',
    //     selector: (row) => row?.state?.name,
    //     sortable: true,
    // },
    // {
    //     name: 'City',
    //     selector: (row) => row?.city?.name,
    //     sortable: true,
    // },
    {
        name: 'App Link',
        button: true,
        cell: (row) => <Button
            color='blue.400'
            size='sm' variant={'link'}
            onPress={() => getAppLinks(`/chain/${row._id}`)}>
            copy</Button>,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <>
            <Button
                size='sm' variant={'link'}
                onPress={() => navigate(`/admin/chains/edit/${row._id}`)}>
                View</Button>
            {row.stores.length === 0 && row.status === 'Pending' &&
                <Button size='sm' variant={'link'}
                    colorScheme='red'
                    onPress={() => setID(row._id)}
                >Delete</Button>
            }
        </>
        ,
        sortable: false,
    },
]