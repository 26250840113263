import { VStack } from 'native-base'
import React, { } from 'react'
import { useNavigate, } from 'react-router-dom'
import { deleteChain, getchains } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import { columns } from './static'
import { useEffect } from 'react';

const Chains = () => {
    const navigate = useNavigate()
    const [id, setID] = React.useState(null)
    const { data, isLoading, refetch } = useFetch({ name: 'chains', service: getchains, pagination: true })

    const Delete = useSubmit({
        service: deleteChain,
        onSuccess: () => {
            refetch()
            setID(null)
        },
        alert: true,
        id,
    })

    useEffect(() => {
        if (id) Delete.submit()
    }, [id])

    return (
        <VStack>
            <CompHeader
                title='Chains'
                buttonTitle='Create New Chain'
                onPress={() => navigate(`/admin/chains/create`)}
            />
            <DataTable tableData={{ columns: columns({ navigate, setID }), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Chains