import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import SearchFilters from './SearchFilters'
import SearchResults from './SearchResults'
import SuggestionInput from './SuggestionInput'

const Search = () => {
    return (
        <VStack>
            <CompHeader title='Global Search' />
            <SearchFilters />
            <SuggestionInput />
            <SearchResults />
        </VStack>
    )
}

export default Search