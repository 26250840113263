import React from 'react'
import { useForm } from 'react-hook-form'
import { colors } from '../../../theme/colors'
import useSubmit from '../../../hook/useSubmit'
import { InputField } from '../../../commons/InputField'
import { sendWaMessageToStores } from '../../../apis/whatsapp'
import { Button, Text, Textarea, VStack } from '@chakra-ui/react'

const defaultValues = { message: '', test_number: '', campaign: '', tested: false }

const SendMessageToStores = () => {
    const form = useForm({ defaultValues })
    const state = form.watch()
    const { message, campaign, tested } = state

    const { submit, isLoading } = useSubmit({
        service: sendWaMessageToStores,
        onSuccess: (res) => {
            console.log(res)
            if (res?.tested) {
                form.setValue('tested', true)
            }

            // form.reset(defaultValues)
        }
    })

    return (
        <VStack w="100%" alignItems="flex-start" p={4} spacing={2} bg='white' borderRadius='md' >

            <InputField form={form} title='Campaign' id='campaign' placeholder='Campaign(like happy womens day, christmas..)' />
            <VStack w="100%" alignItems="flex-start">
                <Text fontSize='md' fontWeight='bold' color={colors.dark} >
                    Paste / Write your message:
                </Text>
                <Textarea rows={8} variant='filled' borderColor={colors.secondary} bg='white'
                    value={message} placeholder='Paste / Write your message..'
                    _focus={{ borderColor: 'gray.200', bg: 'white' }}
                    _hover={{ borderColor: 'white', bg: 'white' }}
                    onChange={e => form.setValue('message', e.target.value)}
                />
            </VStack>

            <VStack w="100%" alignItems="flex-start" borderColor={colors.secondary} borderWidth={1} p={4} borderRadius='md' >
                <Text fontSize='md' fontWeight='bold' color={colors.dark} >
                    Test Before Sending
                </Text>
                <InputField form={form} title='Test Number' id='test_number' placeholder='Test Number' />
                <Button isLoading={isLoading} colorScheme='blue' onClick={() => submit({ ...state, test: true })}
                    disabled={!message || !campaign || !state.test_number}>
                    Test Number
                </Button>
            </VStack>

            <Button isLoading={isLoading} colorScheme='green' w='100%'
                disabled={!message || !campaign || !tested}
                onClick={() => submit({ ...state, test: false })}
            >
                Send to All Stores
            </Button>


        </VStack>
    )
}

export default SendMessageToStores