import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getSponsoredStories } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const SponsorReels = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getSponsoredStories',
        service: getSponsoredStories,
        pagination: true,
    })

    return (
        <VStack w="100%" alignItems={'flex-start'} >
            <CompHeader title="Sponsored Reels" buttonTitle='Add New'
                buttonLink={'/admin/sponsor_reels/create'} />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default SponsorReels