import React from 'react'
import List from './List'
import Enroll from './Enroll'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { getAdminAlertUsers } from '../../apis/admin'

const AdminAlerts = () => {

    const { data, isLoading, refetch } = useFetch({
        service: getAdminAlertUsers,
        name: 'getAdminAlertUsers'
    })

    if (isLoading) return <Loading />

    return (
        <VStack w={'100%'} alignItems={'flex-start'}>
            <CompHeader title={'Admin Alerts'} />
            <Enroll refetch={refetch} />
            <List data={data} refetch={refetch} />
        </VStack>
    )
}

export default AdminAlerts