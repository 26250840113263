import { HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Tile from '../../../commons/Tile'

const Stats = ({ data }) => {

    return (
        <VStack w={'100%'} alignItems={'flex-start'} >
            <HStack w={'100%'} justifyContent={'space-between'} alignItems={'flex-start'} >
                <VStack alignItems={'flex-start'} w={'100%'} borderWidth={1} p={4}>
                    <Text fontSize='lg' fontWeight='bold' textAlign='center' mb={4} >
                        Pre Registered
                    </Text>
                    <Tile titleWidth={200} label='Total Pre-Registered Stores' value={data?.pre?.total} />
                    <Tile titleWidth={200} label='Approved' value={data?.pre?.approved} />
                    <Tile titleWidth={200} label='Approved_rate' value={`${data?.pre?.approved_rate}%`} />
                    <Tile titleWidth={200} label='Claimed' value={data?.pre?.claimed} />
                    <Tile titleWidth={200} label='Claimed_rate' value={`${data?.pre?.claimed_rate}%`} />
                    <Tile titleWidth={200} label='Non Claimed' value={data?.pre?.non_claimed} />
                    <Tile titleWidth={200} label='Non Claimed Rate' value={`${data?.pre?.non_claimed_rate}%`} />
                    <Tile titleWidth={200} label='Pending' value={data?.pre?.pending} />
                    <Tile titleWidth={200} label='Pending Rate' value={`${data?.pre?.pending_rate}%`} />
                    <Tile titleWidth={200} label='Rejected' value={data?.pre?.rejected} />
                    <Tile titleWidth={200} label='Rejected Rate' value={`${data?.pre?.rejected_rate}%`} />
                </VStack>

                <VStack alignItems={'flex-start'} w={'100%'} borderWidth={1} p={4}>
                    <Text fontSize='lg' fontWeight='bold' textAlign='center' mb={4} >
                        Registered
                    </Text>
                    <Tile titleWidth={200} label='Total Store Registered' value={data?.reg?.total} />
                    <Tile titleWidth={200} label='Approved' value={data?.reg?.approved} />
                    <Tile titleWidth={200} label='EBO Count' value={`${data?.logs.filter(({ store }) => store?.claimed && store.type === 'exclusive')?.length}`} />
                    <Tile titleWidth={200} label='Multi Branded Count' value={`${data?.logs.filter(({ store }) => store?.claimed && store.type === 'multibranded')?.length}`} />
                    <Tile titleWidth={200} label='Non Branded Count' value={`${data?.logs.filter(({ store }) => store?.claimed && store.type === 'non-branded')?.length}`} />
                    <Tile titleWidth={200} label='Approved Rate' value={`${data?.reg?.approved_rate}%`} />
                    <Tile titleWidth={200} label='Pending' value={data?.reg?.pending} />
                    <Tile titleWidth={200} label='Pending Rate' value={`${data?.reg?.pending_rate}%`} />
                    <Tile titleWidth={200} label='Rejected' value={data?.reg?.rejected} />
                    <Tile titleWidth={200} label='Rejected Rate' value={`${data?.reg?.rejected_rate}%`} />
                </VStack>
            </HStack>
        </VStack>
    )
}

export default Stats