import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getStoresWithStat } from '../../apis/store'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const StoreStats = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getStoresWithStat',
        service: getStoresWithStat,
        pagination: true
    })

    return (
        <VStack w="100%" alignItems={'flex-start'} >

            <CompHeader title={'Store Stats'} />

            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default StoreStats