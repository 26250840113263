import React from 'react'
import { columns } from './static';
import StoreTabs from './StoreTabs'
import { VStack } from 'native-base'
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import useFetch from '../../hook/useFetch'
import { getstores } from '../../apis/store'
// import CitySelector from './CitySelector';
import { getURLQuery } from '../../helper/url'
import FilterSelection from './FilterSelection';
import FiltersTiles from '../../commons/FiltersTiles';
import DataTable from '../../commons/DataTable/DataTable'
import { useLocation, useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import ExcelDownload from '../../components/ExcelDownload/ExcelDownload';

const defaultValues = {
    brand: "", claimed: '', pre_register: '', type: "",
    state: [], city: [], mall: "", offer_type: "", chain: "",
    stores: [], extra: { existInChain: false, }
}

const Stores = () => {
    const navigate = useNavigate()
    const { search } = useLocation();
    const verified = getURLQuery(search, 'verified') || 'all';
    const form = useForm({ defaultValues })

    const params = () => {
        const data = form.watch()
        const { state, city } = data
        return {
            ...data,
            state: state.map(i => i?.value),
            city: city.map(i => i?.value),
        }
    }

    const { data, isLoading, refetch, isRefetching } = useFetch({
        name: 'inactive_stores',
        service: getstores,
        params: { verified, ...params() },
        conditionalRefetch: [verified],
        pagination: true
    })

    return (
        <VStack>
            <CompHeader title='Stores' buttonTitle='New Store' onPress={() => navigate('/admin/create_new_store')} />
            <StoreTabs />
            <VStack w='100%' space='2' alignItems={'flex-start'} bg={'white'} p={'4'} mb={'4'}>
                <FilterSelection form={form} />
                <FiltersTiles form={form} defaultValues={defaultValues} />
                <Button onClick={() => {
                    const query = JSON.stringify(params())
                    window.history.pushState({}, '', `/admin/stores?query=${query}`)
                    refetch()
                }} isLoading={isLoading || isRefetching} >
                    Get Stores
                </Button>
            </VStack>
            <ExcelDownload service={getstores} params={{ verified, ...params() }} />
            <DataTable title={`${data?.total || ''} Stores`}
                tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default Stores