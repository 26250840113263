import React from 'react'
import { Button, Text, VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { getUserAllAnalytics } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import Tile from '../../commons/Tile'
import { getPercentage } from '../../helper/stringOperations'
import UserCallAnalysis from './UserCallAnalysis/UserCallAnalysis'
import { useNavigate } from 'react-router-dom'

const list = [
    { label: 'Opened Once', id: 'openedOnce' },
    { label: 'Notification Denied', id: 'notificationDenied' },
    { label: 'Location Denied', id: 'locationDenied' },
    { label: 'Men', id: 'men' },
    { label: 'Women', id: 'women' },
    { label: 'Has Subscribed', id: 'hasSubscribed' },
    { label: 'User Calls', id: 'userCalls' },
]

const UserAnalyst = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getUserAllAnalytics',
        service: getUserAllAnalytics,
    })

    if (isLoading) return <Loading />

    return (
        <VStack width='100%' alignItems='flex-start' >
            <CompHeader title="User Analyst" />

            <VStack width='100%' alignItems='flex-start' bg='white' p='4' borderRadius='10' boxShadow='md' >
                <Tile label='Total' value={data?.total} />
                {list.map(({ label, id }) =>
                    <Tile key={id} label={label} value={`${data?.[id]} (${getPercentage(data?.[id], data.total)})`} />
                )}
            </VStack>

            <VStack width='100%' alignItems='flex-start' bg='white' p='4' borderRadius='10' boxShadow='md' >
                <Text fontSize='xl' fontWeight='bold' color='gray.500' alignSelf='flex-start' >
                    User Search
                </Text>
                <Button onClick={() => navigate('/admin/analytics/user_search')} colorScheme='blue' size='sm' mt={4} alignSelf='flex-start' >
                    View User Search
                </Button>
            </VStack>


            <UserCallAnalysis />


        </VStack>
    )
}

export default UserAnalyst