import React from 'react'
import { useForm } from 'react-hook-form'
import Tile from '../../../commons/Tile';
import useSubmit from '../../../hook/useSubmit';
import { addUserCallRecord } from '../../../apis/admin';
import SelectField from '../../../commons/SelectField';
import { formatNumber } from '../../../helper/stringOperations';
import { Button, HStack, Text, Textarea, VStack } from '@chakra-ui/react'

const tags = [
    'Exp. Everything about offingo',
    'Exp. Specific inquiry only',
    'Exp. discount feature only',
    'Exp. trending inquiry only',
    'Exp. specific inquiry and discount feature',
    'Exp. specific inquiry,trending product inquiry and discount feature',
    'Exp. trending product inquiry and discount feature',
]
const comments = [
    "Call Not Answered",
    "Call Again or follow up",
    "No. out of service",
    "No. Busy",
    "Liked the application",
    "Didn't liked the application",
    "Installed By mistake",
    "Haven't used yet",
    "Not Interested in call",
]


const InitialCall = ({ user, setRefresh, onSuccess = () => { } }) => {
    const form = useForm({ defaultValues: { user: '', phone_number: '', mod: '', comment: '', review: '', purpose: 'Feedback', tags: [] } })

    const { submit, isLoading } = useSubmit({
        service: addUserCallRecord,
        onSuccess: () => {
            form.reset();
            setRefresh(d => !d)
            onSuccess()
        }
    })

    const save = () => {
        const state = form.watch()
        const data = {
            ...state,
            user: user._id,
            phone_number: user.phone_number,
            comment: state.comment,
        }
        submit(data)
    }


    return (
        <VStack px={4} alignItems={'flex-start'} w='100%'>
            <Text fontSize={'md'} fontWeight='600'>
                Add Call Record
            </Text>

            <HStack justifyContent={'space-between'} w='100%'>
                <VStack>
                    <Tile fw='100' label='Store' value={user?.name} />
                    <Tile fw='100' label='Phone Number' value={user?.phone_number} />
                </VStack>
                <Text px={10} flex={1} fontSize={'3xl'} fontWeight={'bold'} >
                    {formatNumber(user.phone_number)}
                </Text>
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Purpose:
                </Text>
                <Textarea rows={1} variant='outline' borderColor='gray.300'
                    value={form.watch('purpose')} placeholder='Purpose of Call'
                    onChange={e => form.setValue('purpose', e.target.value)}
                />
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Comment:
                </Text>
                <SelectField tw={120} form={form} id='comment' list={comments.map(item => ({ label: item, value: item }))} />
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Tags:
                </Text>
                <HStack w='100%' alignItems={'flex-start'} flexWrap={'wrap'}>
                    {tags.map(item =>
                        <Text key={item} w={'20%'} textAlign={'center'}
                            p={1} mb={2} h={12}
                            borderRadius={'md'}
                            cursor={'pointer'}
                            bg={form.watch('tags').includes(item) ? 'blue.500' : 'gray.100'}
                            color={form.watch('tags').includes(item) ? 'white' : 'black'}
                            fontSize={'sm'}
                            onClick={() => {
                                const tags = form.watch('tags')
                                form.setValue('tags', tags.includes(item) ? tags.filter(i => i !== item) : [...tags, item])
                            }} >
                            {item}
                        </Text>
                    )}
                </HStack>
            </HStack>

            <HStack w='100%' alignItems={'center'} spacing={2} >
                <Text fontWeight={'bold'} fontSize={'md'} w={100}>
                    Review:
                </Text>
                <Textarea rows={2} variant='outline' borderColor='gray.300'
                    value={form.watch('review')} placeholder='Review'
                    onChange={e => form.setValue('review', e.target.value)} />
            </HStack>

            <Button onClick={save} isLoading={isLoading} colorScheme={'red'} >
                Save
            </Button>

        </VStack>
    )
}

export default InitialCall