import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import AddOfferTemplateList from './AddOfferTemplateList'

const CreateOfferTemplateList = () => {
    return (
        <VStack p='3' space='2'>
            <CompHeader title='Create New List' backButton />
            <AddOfferTemplateList />
        </VStack>
    )
}

export default CreateOfferTemplateList