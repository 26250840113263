import { Text } from "@chakra-ui/react";
import { capitalize } from "./stringOperations";

export const textHighlighter = ({ text, highlight = [], style = {}, highlightStyle = { backgroundColor: '#ffff00', } }) => {
    highlight = highlight.map(h => h?.toLowerCase());
    const parts = text.split(new RegExp(`(${highlight.join('|')})`, 'i')).filter(part => part !== '');
    const caseInsensitiveParts = parts.map(part => part?.toLowerCase());
    return <Text>
        {caseInsensitiveParts.map((part, i) =>
            <Text as="span" key={i} {...style} {...(highlight.includes(part) ? highlightStyle : {})} >
                {i === 0 ? capitalize(part) : part}
            </Text>
        )}
    </Text>;
}

export const listHighlighter = ({
    list = [],
    labelKey = 'label | name | title',
    highlightKeys = 'labelHighlight',
    resultKey = 'highlighted',
    style = { display: 'inline' },
    highlightStyle = {}
}) => {

    const getText = (item) => {
        const keys = labelKey.split('|').map(key => key.trim());
        for (let i = 0; i < keys.length; i++) {
            if (item[keys[i]]) return item[keys[i]];
        }
        return '';
    }


    return list.map(item => (
        {
            ...item,
            [resultKey]: textHighlighter({
                text: getText(item),
                highlight: item[highlightKeys],
                style, highlightStyle
            })
        }
    ))
}