import { Checkbox, CheckboxGroup, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { contProps } from '../../../helper/variables'

const checksItems = [
    { key: 'image', value: 'Image' },
    { key: 'tag', value: 'Tags' },
    { key: 'filter', value: 'Filters' },
    { key: 'name', value: 'Name' },
]

const Checks = ({ form }) => {
    const checks = form.watch('checks')
    return (
        <Stack spacing={4} {...contProps} >
            <Text fontSize='xl' fontWeight='bold' color='gray.600' >
                Style Quality Checks
            </Text>
            <CheckboxGroup value={checks} onChange={(_value) => form.setValue('checks', _value)} >
                <Stack direction={["column"]} spacing={3}>
                    {checksItems?.map(item =>
                        <Checkbox colorScheme={"red"} size={"md"} value={item.key} >
                            {item.value}
                        </Checkbox>
                    )}
                </Stack>
            </CheckboxGroup>
        </Stack>
    )
}

export default Checks