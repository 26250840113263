import React, { useEffect, useState, useMemo } from 'react'
import { Checkbox, CheckboxGroup, SimpleGrid } from '@chakra-ui/react'
import { Box, Button, HStack, Pressable, Text, VStack } from 'native-base'
import { getClothingTypes, getgender, getwears, createclothingTypes } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { colors } from '../../theme/colors'
import useSubmit from '../../hook/useSubmit';

const ClothingTypes = () => {
    const [gender, setGender] = useState(null)
    const [selected, setSelected] = useState([])

    const genders = useFetch({ service: getgender, name: 'genders', dataOnly: true })
    const wears = useFetch({ service: getwears, name: 'wears', dataOnly: true, params: { limit: 0 } })

    const { isLoading, refetch } = useFetch({
        name: 'ClothingTypes',
        service: getClothingTypes,
        onSuccess: (data) => setSelected(data)
    })

    useEffect(() => {
        if (isLoading || gender) return
        setGender(genders[0]?._id)
    }, [genders, isLoading])

    const Save = useSubmit({ service: createclothingTypes, onSuccess: refetch })

    const CheckBoxCompo = useMemo(() => {
        if (!gender || isLoading) return <Loading />
        const i = selected.findIndex(item => item.gender === gender)
        if (i === -1) {
            setSelected([...selected, { gender, wears: [] }])
            return
        }

        return (
            <CheckboxGroup value={selected?.[i]?.wears} colorScheme='red'
                onChange={e => {
                    const _selected = [...selected]
                    _selected[i].wears = e
                    setSelected(_selected)
                }}
            >
                <SimpleGrid columns={[2, 8]} spacing='1'>
                    {wears?.map(item =>
                        <Checkbox key={item._id} value={item._id} > {item.wear} </Checkbox>
                    )}
                </SimpleGrid>
            </CheckboxGroup>
        )
    }, [selected, gender])


    if (isLoading) return <Loading />

    return (
        <VStack>
            <CompHeader title='Clothing Types' />
            <VStack>
                <HStack space='4'>
                    {genders?.map(item =>
                        <Pressable key={item._id} onPress={() => setGender(item._id)}>
                            <Box bg={item._id === gender ? colors.primary : colors.border}
                                p='2' px='4'
                                borderRadius={'lg'} >
                                <Text color={item._id === gender ? colors.light : colors.dark}>{item.type}</Text>
                            </Box>
                        </Pressable>
                    )}
                </HStack>

                <VStack space='2' p='4' bg={colors.light} h={500} overflowY='scroll' my='4' borderRadius={'lg'}>
                    {CheckBoxCompo}
                </VStack>

                <Button w='150' colorScheme={'red'} size='sm'
                    onPress={() => Save.submit(selected)}
                    isLoading={Save.isLoading}
                > Save </Button>
            </VStack>
        </VStack>
    )
}

export default ClothingTypes