import React from "react";
import { IconButton, Avatar, Box, Flex, HStack, VStack, useColorModeValue, Text, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer, } from '@chakra-ui/react';
import { FiMenu, FiBell, FiChevronDown, } from 'react-icons/fi';
import { useDispatch, useSelector } from "react-redux";
import { logout, selectProfile } from "../../redux/authSlice";

const MobileNav = ({ onOpen, ...rest }) => {
    const profile = useSelector(selectProfile)
    const dispatch = useDispatch();

    const handleLogOut = () => dispatch(logout());

    const version = (() => {
        const location = window.location.href;
        // const location = 'https://dev1-admin.offingo.com/'
        if (location.includes('dev1')) {
            return 'dev1'
        } else if (location.includes('dev2')) {
            return 'dev2'
        } else {
            return 'Main'
        }
    })();

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="12"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            w="100%"
            justifyContent={{ base: 'space-between', md: 'space-between' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                OFFINGO
            </Text>

            <HStack spacing={{ base: '0', md: '4' }}>
                <Text
                    display={{ base: 'none', md: 'flex' }}
                    fontSize="2xl"
                    fontFamily="monospace"
                    fontWeight="bold"
                    textTransform={'uppercase'}
                >
                    {version}
                </Text>
                <Spacer />
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                {/* <Avatar
                                    size={'sm'}
                                    src={
                                        'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                    }
                                /> */}
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{profile?.name}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {profile?.role}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};

export default MobileNav;