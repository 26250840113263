import axios from "axios";
import { baseurl } from "./url";
const API = axios.create({ baseURL: `${baseurl}` });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("token")) {
        req.headers.Authorization = localStorage.getItem("token");
    }
    return req;
})

export const getofferbystoreid = ({ id, params }) => API.get(`/offers/store/${id}`, { params });
export const getGenders = ({ params }) => API.get(`/gender`, { params });

export const getProductsByGender = ({ id, params }) => API.get(`/products/gender/${id}`, { params })
export const getProductWearsByGender = ({ id, gender }) => API.get(`/products/wears/${id}/gender/${gender}`)

export const getFilters = ({ data }) => API.post(`/context-based/filters`, data)

export const getStoreBrands = ({ id, params }) => API.get(`/brands/stores/${id}`, { params });