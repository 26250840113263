import React from 'react'
import { VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useNavigate } from 'react-router-dom';
import DataTable from '../../commons/DataTable/DataTable';
import { columns } from './static';
import useFetch from '../../hook/useFetch';
import { getProducts } from '../../apis/admin';

const Product = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'getProducts', service: getProducts, pagination: true })

    return (
        <VStack space='4'>
            <CompHeader title='Products' buttonTitle='Add Product'
                onPress={() => navigate('/admin/product/create')} />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Product