import React from 'react'
import { VStack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import useFetch from '../../hook/useFetch'
import { getmalls } from '../../apis/admin'

const Malls = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'malls',
        service: getmalls,
        pagination: true,
    })

    return (
        <VStack>
            <CompHeader title='Malls'
                buttonTitle='Create New Mall'
                onPress={() => navigate(`/admin/malls/create`)}
            />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Malls