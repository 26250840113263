import React from 'react'
import { VStack } from 'native-base';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/authSlice';
import { Heading, Text } from 'native-base';

const AdminIntro = () => {
    const profile = useSelector(selectProfile)

    return (
        <VStack alignItems='center'>
            <Heading mb='4' size='xl'>OFFINGO Admin Panel</Heading>
            <Text fontSize={'xl'} fontWeight='600' letterSpacing={'1'} >Welcome, {profile.name}</Text>
            <Text fontSize={'sm'}>Role - {profile.role}</Text>
        </VStack>
    )
}

export default AdminIntro