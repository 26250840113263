import { Button } from 'native-base';

export const columns = (navigate) => [
    {
        name: 'Template',
        selector: (row) => row.template,
        sortable: true,
    },
    {
        name: 'Indexes',
        selector: (row) => row.value.map(item => `${item.value}(${item.type}),`),
        sortable: true,
    },
    {
        name: ' Example',
        selector: (row) => row.example,
        sortable: true,
    },
    {
        name: 'Effective Discount',
        selector: (row) => row.effectiveDiscount,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/offerTemplate/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]