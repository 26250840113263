import { useForm } from 'react-hook-form'
import Loading from '../../commons/Loading'
import StoreAuthCard from './StoreAuthCard'
import { Text, VStack } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import usePagingQuery from '../../hook/usePagingQuery'
import { getStoreWithNoOffers } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import ExcelDownload from '../../components/ExcelDownload/ExcelDownload'
import MultipurposeFilter from '../../components/Filters/MultipurposeFilter'

const defaultValues = { brand: "", from_date: new Date(), claimed: true, pre_register: false, type: "", state: [], city: [], mall: "", offer_type: "", chain: "", stores: [], extra: { existInChain: false, } }

const StoreWithNoOffers = () => {
    const form = useForm({ defaultValues })

    const params = (() => {
        const data = form.watch()
        const { state, city } = data
        return {
            ...data,
            state: state.map(i => i?.value),
            city: city.map(i => i?.value),
        }
    })()

    const { data, totalData, Nav, isLoading, isRefetching, inView, refetch } = usePagingQuery({
        name: `StoreWithNoOffers`,
        service: getStoreWithNoOffers,
        params,
    })

    const reload = useCallback(() => { if (document.visibilityState === 'visible') refetch() }, [refetch])
    useEffect(() => {
        document.addEventListener('visibilitychange', reload);
        return () => {
            document.removeEventListener('visibilitychange', reload);
        }
    }, [reload]);

    // const copyData = useCallback(() => {
    //     const list = data.map(i => {
    //         return `${i.store_name} - ${i?.auths?.map(j => j?.phone_number).filter(i => i).join(' - ')}`
    //     })
    //     const text = list.join('\n')
    //     navigator.clipboard.writeText(text)
    //     toast('Copied to clipboard', { icon: '📋' })
    // }, [data])

    return (
        <VStack w='100%' space='2' alignItems={'flex-start'} >
            <CompHeader title='Store With No Offers' />

            <MultipurposeFilter
                defaultValues={defaultValues}
                form={form}
                isLoading={isLoading || isRefetching}
                getData={() => {
                    const query = JSON.stringify(params)
                    window.history.pushState({}, '', `/admin/store_with_no_offers?query=${query}`)
                    refetch()
                }}
            />

            <ExcelDownload service={getStoreWithNoOffers} params={{ ...params }} />

            <Text fontSize='xl' fontWeight='bold' color='gray.500' alignSelf='flex-start' mt='4' >
                {totalData} Stores ({inView.start} - {inView.end})
            </Text>

            {isLoading ? <Loading /> :
                data.map(item => <StoreAuthCard refetch={refetch} form={form} key={item._id} data={item} />)
            }
            <Nav />

        </VStack>
    )
}

export default StoreWithNoOffers