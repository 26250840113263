import { Heading, Input, Text, VStack } from 'native-base'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { adminlogin } from '../../apis/auth'
import useSubmit from '../../hook/useSubmit'
import { login } from '../../redux/authSlice'
import { Button } from '@chakra-ui/react'

const Login = () => {
    const dispatch = useDispatch()
    const form = useForm({ defaultValues: { code: '', pass: '' } })

    const state = { ...form.getValues() }

    const { submit, isLoading } = useSubmit({
        service: adminlogin,
        onSuccess: (res) => {
            dispatch(login(res))
        }
    })

    const signin = () => {
        submit(state)
    }

    useMemo(() => { }, [form.watch()])

    return (
        <VStack h='100vh' space='4' bg='light.100' alignItems={'center'} justifyContent='center'>

            <Heading size={'3xl'} >OFFINGO</Heading>
            <VStack space='4' bg='white' p='4' pb='0' w='100%'
                maxW={'300'} borderRadius={'lg'} shadow='9' >
                <Text fontWeight={'semibold'} fontSize='md'>Login</Text>
                <Input
                    value={state.code}
                    placeholder='ID'
                    maxLength={10}
                    onChangeText={text => {
                        form.setValue('code', text)
                        form.setValue('pass', text)
                    }}
                />
                {/* <Input
                    value={state.pass}
                    placeholder='Pass'
                    onChangeText={text => form.setValue('pass', text)}
                /> */}
                <Button w='100' variant={'solid'} size='sm'
                    isLoading={isLoading} type='submit' onClick={signin}
                >Login</Button>
                <button type='submit' onClick={signin} hidden>submit</button>
            </VStack>
        </VStack>
    )
}

export default Login