import { HStack, Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../helper/url'
import { colors } from '../../theme/colors'

const list = [
    ['All', '/admin/offers', 'all'],
    ['Verified', '/admin/offers?verified=true', 'true'],
    ['Not-Verified', '/admin/offers?verified=false', 'false'],
]

const OfferTabs = ({ as }) => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const status = getURLQuery(search, 'verified') || 'all';

    return (
        <HStack mb='2' space='4'>
            {list.map(([name, link, active]) =>
                <Text key={link} fontWeight='600' fontSize='lg'
                    color={as ? as === active ? colors.dark : colors.secondary : status === active ? colors.dark : colors.secondary}
                    onPress={() => navigate(link)}>{name}</Text>
            )}
        </HStack>
    )
}

export default OfferTabs