import ApiStats from './ApiStats/ApiStats';
import React, { useEffect } from 'react'
import { VStack } from '@chakra-ui/react';
import useFetch from '../../hook/useFetch';
import Loading from '../../commons/Loading';
import { getApiCalls } from '../../apis/admin';
import { AgChartsReact } from 'ag-charts-react';
import CompHeader from '../../commons/CompHeader/CompHeader';
import { getPastMinute, getTimeListWithIntervals } from '../../utils/moment';
import ApiStatsUrlWise from './ApiStatsUrlWise/ApiStatsUrlWise';

const dataConvertor = ({ start, end, interval, data }) => {
    const timeList = getTimeListWithIntervals({ start, end, interval })

    const finalList = []
    timeList.forEach((time) => {
        const start_time = new Date(time)
        const end_time = new Date(time + interval)


        const local_time = start_time.toLocaleTimeString()
        let display = `${local_time.split(':')[0]}:${local_time.split(':')[1]}`
        if (interval < 60_000) {
            display = `${display}:${local_time.split(':')[2]}`
        }
        const obj = {
            time: display,
            count: data.filter((item) => {
                const item_time = new Date(item.createdAt)
                return item_time >= start_time && item_time <= end_time
            }).length
        }

        finalList.push(obj)

    })
    console.log(' finalList', finalList)
    return {
        data: finalList,
        series: [{ type: 'line', xKey: 'time', yKey: 'count' }],
    }
}

const ApiCallsGraphs = () => {
    const { data, isLoading, refetch } = useFetch({
        service: getApiCalls,
        name: 'getApiCalls',
        params: {
            // start: getPastMinute(25),
            // end: getPastMinute(20),
        }
    })

    useEffect(() => {
        const interval = setInterval(() => { refetch() }, 10_000)
        return () => clearInterval(interval)
    }, [])

    const interval = 60_000
    const start = getPastMinute(60)

    if (isLoading) return <Loading />

    return (
        <VStack w="100%" alignItems="flex-start" >
            <CompHeader title="Api Calls Graphs" />
            <AgChartsReact options={dataConvertor({ start, interval, data })} />
            <ApiStats data={data} />
            <ApiStatsUrlWise data={data} />
        </VStack>
    )
}

export default ApiCallsGraphs