import React from 'react'
import GiveCoupon from './GiveCoupon';
import AllCoupons from './AllCoupons';
import { Divider, VStack } from '@chakra-ui/react'

const UserCoupons = ({ user, close }) => {
    const [refresh, setRefresh] = React.useState(false)

    return (
        <VStack alignItems={'flex-start'}>
            <GiveCoupon user={user} setRefresh={setRefresh} />
            <Divider borderWidth={2} />
            <AllCoupons user={user} refresh={refresh} />
        </VStack>
    )
}

export default UserCoupons