import { Button } from "native-base";
import { Text, VStack } from "@chakra-ui/react";
import { getAppLinks } from "../../helper/applink";
import { formatDateTimes } from "../../utils/moment";
import { capitalize } from "../../helper/stringOperations";
import { getDistancefromLocal } from "../../helper/coords";

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) =>
            <VStack>
                <Button size='sm' variant={'link'}
                    onPress={() => window.open(`/admin/reviewStore/${row?._id}`, '_blank')}>
                    Review</Button>
                <Button size='sm' variant={'link'}
                    onPress={() => window.open(`/admin/coupons/create?store_id=${row?._id}&store_name=${row?.store_name}`, '_blank')}>
                    Create Coupon
                </Button>
            </VStack>
        ,
        sortable: false,
    },
    {
        name: 'Date Registered',
        selector: (row) => formatDateTimes(row?.createdAt, 4),
        sortable: true,
    },
    {
        name: 'City/State',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'}>
                <Text>
                    {row?.store_address.city?.name},
                </Text>
                <Text>
                    {row?.store_address.state?.name}
                </Text>
                <Text>
                    (  {getDistancefromLocal(row?.geo_location.coordinates[0], row?.geo_location.coordinates[1])}Km)
                </Text>
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row?.store_name,
        sortable: true,
    },
    {
        name: 'Auths',
        // width: '150px',
        cell: (row) =>
            <VStack>
                {row?.auths.map(item =>
                    <VStack spacing={0} alignItems={'flex-start'} key={item?._id}>
                        <Text>
                            {item?.name}
                        </Text>
                        <Text>
                            {item?.phone_number}
                        </Text>
                    </VStack>
                )}
            </VStack>
        ,
        sortable: false,
    },
    {
        name: 'Address',
        width: '250px',
        selector: (row) => row?.store_address.address,
        sortable: true,
    },
    {
        name: 'Offers/Coupons',
        selector: (row) => `${row?.offers}/${row?.coupons}`,
        sortable: true,
    },
    {
        name: 'Points',
        selector: (row) => row?.points,
        sortable: true,
    },
    {
        name: 'Status',
        width: '250px',
        button: true,
        cell: (row) => <Text flex={1}>
            <Text as='span' color={row?.verified ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Verified-{row?.verified ? 'Y' : 'N'}/
            </Text>
            <Text as='span' color={row?.claimed ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Claimed-{row?.claimed ? 'Y' : 'N'}/
            </Text>
            <Text as='span' color={row?.pre_register ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Pre-Register-{row?.pre_register ? 'Y' : 'N'}
            </Text>
        </Text>,
        sortable: false,
    },
    {
        name: 'Budget',
        selector: (row) => getRangeLabels(row?.range_type),
        sortable: true,
    },
    {
        name: 'category',
        selector: (row) => row?.type,
        sortable: true,
    },
    {
        name: 'Chain',
        selector: (row) => row?.store_address.chain?.name ? `${row?.store_address.chain?.name}(${row?.store_address.chain.type === 'Primary' ? "Lg" : "Sm"})` : '--',
        sortable: true,
    },

    {
        name: 'Discount Types',
        selector: (row) => row?.offer_type,
        sortable: true,
    },
    {
        name: 'Ownership',
        selector: (row) => row?.ownership,
        sortable: true,
    },
    {
        name: 'App Link',
        button: true,
        cell: (row) => <Button
            color='blue.400'
            size='sm' variant={'link'}
            onPress={() => ['exclusive', 'non-branded'].includes(row?.type) ? getAppLinks(`/store/${row?._id}`) : getAppLinks(`/mutliBrandStore/${row?._id}`)
            } >
            copy</Button>,
        sortable: false,
    },
] 