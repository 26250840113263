import { Divider } from '@chakra-ui/react'
import { Box, Button, HStack, Spacer, Text, VStack } from 'native-base'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { createUserSubscriptions, deleteUserSubscriptionsById, editSubscriptionsById, editUserSubscriptionsById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { InputField } from '../../commons/InputField'
import SelectField from '../../commons/SelectField'
import useSubmit from '../../hook/useSubmit'
import { properties, staticData } from './static';

const defaultValues = {
    code: '', name: '', perks: [''], price: '', duration: '', status: '', properties: {
        enquiry_limit: 1,
        enquiry_gap: 1,
        daily_limit: 1,
        rollover_limit: 0,
        price_per_response: 10,
        include_checklist: 'false',
        serial_no_identification: 'false',
        store_selection: 'false',
        custom_area: 'false',
        best_deal: 'false',
    },
}

const CreateSubscriptions = ({ data }) => {
    if (data) {
        Object.keys(data?.properties).map((obj) => {
            if (typeof data?.properties[obj] === 'boolean') {
                data.properties[obj] = data.properties[obj].toString()
            }
        })
    }
    const form = useForm({ defaultValues: data ? data : defaultValues })
    const state = form.getValues()
    useMemo(() => { }, [form.watch()])
    const navigate = useNavigate()
    const [showDesc, setShowDesc] = useState(false)

    const { submit, isLoading } = useSubmit({
        service: data ? editUserSubscriptionsById : createUserSubscriptions,
        onSuccess: () => navigate(-1),
        id: data ? data._id : null,
    })

    const create = () => {
        const state = { ...form.getValues() }
        state.perks = state.perks.filter(perk => perk !== '')
        submit(state)
    }

    const Delete = useSubmit({
        service: deleteUserSubscriptionsById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    return (
        <Box >
            {!data && <CompHeader title='Create Subscriptions' backButton />}
            <VStack w='100%' space='2'>
                <InputField tw={150} form={form} title='Code' id='code' />
                <InputField tw={150} form={form} title='Name' id='name' />

                <Divider />
                <HStack>
                    <Text fontSize={'xl'} fontWeight='600'>Properties</Text>
                    <Spacer />
                    <Button size={'sm'} variant={'link'} onPress={() => setShowDesc(!showDesc)}>
                        <Text color={!showDesc ? 'blue.400' : "gray.500"}> {showDesc ? 'Hide' : 'Show'} Description </Text>
                    </Button>
                </HStack>

                {properties.map(({ label, value, desc, list }) => {
                    const props = {
                        tw: 150,
                        desc,
                        form,
                        title: label,
                        id: `properties.${value}`,
                        showDesc,
                    }

                    if (list)
                        return <SelectField {...props} list={list} />

                    return <InputField  {...props} />
                })}

                <Divider />

                <Text fontSize={'xl'} fontWeight='600'>Perks</Text>
                <VStack space='2'>
                    {state.perks.map((perk, index) =>
                        <VStack space='0'>
                            <InputField tw={150} key={`index${index}`} form={form} title={`Perk ${index + 1}`} id={`perks[${index}]`} />
                            <Button size={'sm'}
                                w='18'
                                mt='-8'
                                mr='4'
                                _text={{ fontSize: 'xs', color: 'red.400' }}
                                alignSelf='flex-end'
                                onPress={() => form.setValue(`perks`, state.perks.filter((_, i) => i !== index))}
                                variant={'link'}
                            >remove</Button>
                        </VStack>
                    )}
                    <Button size={'sm'}
                        onPress={() => form.setValue(`perks`, [...state.perks, ''])}
                        alignSelf='flex-end' variant={'link'}>Add More</Button>
                </VStack>

                <InputField tw={150} form={form} title='Price' id='price' />
                <InputField tw={150} form={form} title='Duration(days)' id='duration' />
                <SelectField tw={150} form={form}
                    list={staticData.status}
                    title='Status' id='status' />
                <HStack mt='2'>
                    <Button size='sm' isLoading={isLoading} onPress={create} >
                        {data ? 'Edit subscription' : 'Create subscription'}
                    </Button>
                    <Spacer />
                    {data && <Button m='auto'
                        colorScheme={'red'}
                        isLoading={Delete.isLoading}
                        onPress={Delete.submit} size='sm' >
                        Delete Template List
                    </Button>}
                </HStack>
            </VStack>
        </Box>
    )
}

export default CreateSubscriptions