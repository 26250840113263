import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import "./index.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-datepicker/dist/react-datepicker.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<StrictMode> <Main /> </StrictMode>);