import React from 'react'
import { useForm } from 'react-hook-form'
import useSubmit from '../../../hook/useSubmit'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../../helper/url'
import CompHeader from '../../../commons/CompHeader/CompHeader'
import { Button, HStack, Spacer, VStack } from '@chakra-ui/react'
import { createFoodCoupons, deleteFoodCouponsById, editFoodCouponsById } from '../../../apis/admin'
import FoodCouponForm from './FoodCouponForm'

const defaultValues = {
    code: '',
    name: '',
    restuarant: '',
    user: [],
    discount: '',
    start_date: new Date(),
    expiry: '',
    active: 'true',
    public: 'true',
    paid: 'false',
    price: '',
    type: '',
    total_redeems: '',
    max_redeems: '',
    terms: '',
    lottery: 'false',
    constraints: {
        min_lottery_discount: '',
        max_lottery_discount: '',
        min_amount: '',
        max_discount: '',
        max_amount: 0,
        max_items: 0,
        min_items: 0,
        min_lottery_amount: 0,
        max_lottery_amount: 0,
        styles: [],
    },
}

const CreateFoodCoupons = ({ data }) => {
    const navigate = useNavigate()
    const form = useForm({ defaultValues: data || defaultValues })
    const value = form.watch()

    const { search } = useLocation()
    const _params = getURLQuery(search)
    const { restaurant_id, restaurant_name } = _params || {}

    const { submit, isLoading } = useSubmit({
        service: data?._id ? editFoodCouponsById : createFoodCoupons,
        id: data?._id,
        onSuccess: () => navigate('/admin/coupons'),
    })

    const Delete = useSubmit({
        service: deleteFoodCouponsById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const save = () => {
        const state = { ...form.getValues() }
        submit({
            ...state,
            restaurant: restaurant_id ? [restaurant_id] : [state.restaurant.value],
            app_event: state?.app_event?.map((event) => event.value),
            products: state?.products?.map((product) => product.value),
            terms: state.terms.replace('(Auto Applied)', ''),
            constraints: {
                ...state.constraints,
                styles: state.constraints.styles.map((style) => style.value),
            },
        })
    }

    return (
        <VStack alignItems={'flex-start'} w="100%">
            {!data && <CompHeader title={'Create Food Coupons'} />}

            <FoodCouponForm form={form} data={data} restaurant_id={restaurant_id} restaurant_name={restaurant_name} />

            <HStack alignItems={'center'} w="100%">
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={save}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update Coupon' : "Add Coupon"}</Button>
                <Spacer />
                {data && <Button m='auto'
                    variant={'solid'}
                    color='red'
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Coupon
                </Button>}
            </HStack>
        </VStack>
    )
}

export default CreateFoodCoupons