import React from 'react'
import BrandCard from './BrandCard'
import { VStack } from 'native-base'
import useFetch from '../../../hook/useFetch'
import { Divider, HStack, Spacer, Text } from '@chakra-ui/react'
import { getbrands, getgender } from '../../../apis/admin'
import SelectField from '../../../commons/SelectField'

const StoreBrands = ({ form, viewOnly }) => {
    const { brands: storeBrands } = form.watch()
    const [refresh, setRefresh] = React.useState(false)
    const brands = useFetch({
        name: 'getbrands', service: getbrands, dataOnly: true,
        conditionalRefetch: [refresh],
        sort: true, params: { limit: 0 }
    })
    const refetch = () => setRefresh(!refresh)

    const genders = useFetch({ name: 'getgender', service: getgender, dataOnly: true, params: { limit: 0 } })

    return (
        <VStack space={3} w='100%' alignItems='flex-start'>

            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' >
                    Brands ({storeBrands?.length ? `${storeBrands.length}` : 0})
                </Text>
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('brands', form.watch('default.brands'))
                        }}>
                        Reset Brands
                    </Text>}
            </HStack>


            <VStack w='100%' divider={<Divider borderColor="gray.900" />} >
                <VStack pb={2}>
                    <Text> {viewOnly ? 'Store Brands' : 'Add Brands'} </Text>
                    <SelectField
                        tw={90} id='brands' list={brands.map(({ brand: label, _id: value, status }) => ({ value, label: `${label} ${status === 'Pending' ? "- Not Verified" : ""} ` }))}
                        value={'null'}
                        onChange={(val) => {
                            if (val === 'null') return;
                            form.setValue('brands', [...storeBrands, { brand: val, readymade: [], stitched: [] }])
                        }}
                        disabled={viewOnly}
                        placeholder='Select Brands'
                    />
                </VStack>
                {storeBrands.map((brand, i) =>
                    <BrandCard key={i} index={i} form={form} refetch={refetch}
                        brand={brand} genders={genders} viewOnly={viewOnly}
                        brandObj={brands.find(item => item._id === brand.brand)}
                    />
                )}
            </VStack>

        </VStack>
    )
}

export default StoreBrands