import { VStack } from 'native-base'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getFlagReasons } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import useFetch from '../../hook/useFetch'
import { columns } from './static';

const FlagsReasons = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({ name: 'getFlagReasons', service: getFlagReasons, })

    return (
        <VStack space='4'>
            <CompHeader title='Flags Reasons'
                buttonTitle='Create New' onPress={() => navigate(`/admin/flags/reasons/create`)}
            />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading }} />
        </VStack>
    )
}

export default FlagsReasons