import React from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { formatTimestamp } from '../../utils/moment'
import { UpperCase } from '../../helper/stringOperations'

const _toSpace = (str) => {

    if (str === 'undefined' || str === undefined) return 'N/A'

    str = str.replace(/_/g, ' ')
    return UpperCase(str)
}

const JourneyCard = ({ data, index }) => {

    let str = data
    const screen = str?.split('screen')?.[0] || '--'
    str = str?.split('screen')?.[1]
    const timestamp = str?.split('_at_')?.[1]
    str = str?.split('_at_')?.[0]


    return (
        <VStack alignItems={'flex-start'} w={'100%'} bg={index % 2 === 0 ? 'gray.100' : 'white'}>
            {/* <Text>
                {data}
            </Text> */}
            <HStack w='100%' justifyContent='space-between' py={2} spacing={4}>
                <Text>
                    {_toSpace(`${screen} Screen`)}
                </Text>
                <Text flex={1}>
                    {_toSpace(`${str}`)}
                </Text>
                <Text>
                    {formatTimestamp(timestamp, 5)}
                </Text>
            </HStack>
        </VStack>
    )
}

export default JourneyCard