import React from 'react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { getWaCampaigns } from '../../apis/whatsapp'
import { HStack, Text, VStack } from '@chakra-ui/react'

const CompaignSelector = ({ setCompaign, compaign }) => {

    const { data, isLoading } = useFetch({
        service: getWaCampaigns,
        name: 'getWaCampaigns',
    })

    if (isLoading) return <Loading />

    return (
        <VStack w={'100%'} alignItems={'flex-start'} >
            <HStack spacing={2} alignItems={'center'} overflowY={'scroll'} w={'80vw'} >
                {data.campaigns.map((item, index) =>
                    <Text key={index} onClick={() => setCompaign(item)}
                        px={2} py={1} cursor={'pointer'}
                        bg={compaign === item ? 'blue.500' : 'gray.200'}
                        color={compaign === item ? 'white' : 'black'}
                        borderRadius={'md'}
                        mb={4}
                    >
                        {item || 'All'}
                    </Text>
                )}
            </HStack>
        </VStack>
    )
}

export default CompaignSelector