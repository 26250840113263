import React from 'react'
import { VStack } from 'native-base'
import useFetch from '../../../hook/useFetch'
import { getmallsByCity, verifyMall } from '../../../apis/admin'
import { Button, HStack, Spacer, Text } from '@chakra-ui/react'
import SelectField from '../../../commons/SelectField'
import useSubmit from '../../../hook/useSubmit'
import VerifyMall from '../ReviewStore/VerifyMall';
import { InputField } from '../../../commons/InputField'

const StoreMall = ({ form, viewOnly }) => {
    const [refresh, setRefresh] = React.useState(false)
    const _refetch = () => setRefresh(!refresh)
    const state = form.watch()
    const { store_address: { city: storeCity, mall_id } } = state

    const malls = useFetch({
        name: `malls_${storeCity}`,
        service: getmallsByCity,
        dataOnly: true,
        conditionalRefetch: [storeCity, refresh],
        id: storeCity,
        params: { status: 'all' },
        condition: !!storeCity,
    })

    const isVerified = malls.find(({ _id }) => _id === mall_id)?.status === 'Active'

    const { submit, isLoading } = useSubmit({
        service: verifyMall,
        id: form.watch('store_address.mall_id'),
        onSuccess: _refetch
    })

    return (
        <VStack space={3} w='100%' alignItems='flex-start'>
            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' > Mall </Text>
                {mall_id && (isVerified ?
                    <Text color='green.600' fontWeight={'semibold'} fontSize='md'>Verified</Text>
                    :
                    <Text color='red.600' fontWeight={'semibold'} fontSize='md'>Not Verified</Text>
                )}
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('store_address.mall_id', form.watch('default.store_address.mall_id'))
                        }}>
                        Reset Mall
                    </Text>}
            </HStack>

            <SelectField form={form} value={mall_id}
                onChange={(e) => {
                    const mall = malls.find(({ _id }) => _id === e)
                    form.setValue('store_address.mall_id', e)
                    if (!mall) return
                    form.setValue('geo_location', mall.geo_location)
                    form.setValue('store_address.address', mall.mall_address.address)
                    form.setValue('store_address.city', mall.mall_address.city?._id || mall.mall_address.city)
                    form.setValue('store_address.locality', mall.mall_address.locality)
                    form.setValue('store_address.pincode', mall.mall_address.pincode)
                    form.setValue('store_address.state', mall.mall_address.state?._id || mall.mall_address.state)
                }}
                list={malls.map(({ mall_name: label, _id: value, status }) => ({ value, label: `${label} ${status !== 'Active' ? "- Not Verified" : ""}` }))}
                disabled={viewOnly} placeholder='Select Mall'
            />

            <InputField
                id='store_address.floor'
                title='Floor'
                disabled={viewOnly}
                form={form}
            />

            {!isVerified && form.watch('store_address.mall_id') && (
                <Button w='100%' alignSelf={'flex-end'} isLoading={isLoading}
                    variant='solid' colorScheme='red'
                    disabled={viewOnly}
                    onClick={() => { submit() }} >
                    Verify this Mall - {malls.find(({ _id }) => _id === form.watch('store_address.mall_id'))?.mall_name}
                </Button>
            )}

        </VStack>
    )
}

export default StoreMall