import { HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { capitalize } from '../../helper/stringOperations'

const Stats = ({ data = [] }) => {
    return (
        <VStack w={'100%'} alignItems={'flex-start'} p={4} bg={'white'} borderRadius={8} boxShadow={'md'}>

            <HStack w={'100%'} flexWrap={'wrap'}>
                {data.map((item, index) => (
                    <Text key={index} fontSize={'md'} fontWeight={500} >
                        {capitalize(item.status)} : {item.count}
                    </Text>
                ))}
            </HStack>
        </VStack>
    )
}

export default Stats