import React from 'react'
import { VStack, Button, Text } from 'native-base';
import useSubmit from '../../../hook/useSubmit';
import { deletestore } from '../../../apis/store';
import { colors } from '../../../theme/colors';
import { useNavigate } from 'react-router-dom';
import AlertAction from '../../../commons/UI/AlertAction';

const RemoveStore = ({ store, simple, onSuccess }) => {
    const navigate = useNavigate()
    const [show, setShow] = React.useState(false)

    const { submit, isLoading } = useSubmit({
        service: deletestore, id: store._id, onSuccess: () => {
            if (onSuccess)
                onSuccess()
            else
                navigate('/admin/stores')
        }
    })

    return (
        simple ?
            <Button mt='5' w='200' isLoading={isLoading} bg={isLoading ? 'gray.400' : 'red.900'} onPress={() => submit()} >
                Remove Store
            </Button>
            :
            <VStack space='2' borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
                <Text fontSize={'md'} fontWeight='600'>Remove Store</Text>
                <Button mt='5' w='400' isLoading={isLoading} bg={isLoading ? 'gray.400' : 'red.900'} onPress={() => setShow(true)} >
                    Remove Store
                </Button>
                <AlertAction
                    ok={submit}
                    show={show}
                    close={() => setShow(false)}
                    isLoading={isLoading}
                    title={'Are you sure you want to remove this store?'}
                    okText={'Remove'}
                    confirmText={store.store_name}
                />
            </VStack>
    )
}

export default RemoveStore