import { HStack, Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../helper/url'
import { colors } from '../../theme/colors'

const list = [
    ['All', '/admin/stores', 'all'],
    ['New', '/admin/stores?verified=false', 'false'],
    ['Active', '/admin/stores?verified=true', 'true'],
    ['No-Auth Stores', '/admin/stores/noAuthStores', 'noAuthStores'],
    ['Authorities', '/admin/stores/authorities', 'authorities'],
    ['Maps', '/admin/stores/mapline', 'maps'],
]

const StoreTabs = ({ as }) => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const status = getURLQuery(search, 'verified') || 'all';

    return (
        <HStack mb='2' space='4'>
            {list.map(([name, link, active]) =>
                <Text key={link} fontWeight='600' fontSize='lg'
                    color={as ? as === active ? colors.dark : colors.secondary : status === active ? colors.dark : colors.secondary}
                    onPress={() => navigate(link)}>{name}</Text>
            )}
        </HStack>
    )
}

export default StoreTabs