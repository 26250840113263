import React from 'react'
import CreateADS from './CreateADS'
import { VStack } from 'native-base';
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { useParams } from 'react-router-dom'
import { getAdsById } from '../../apis/admin'

const EditADS = () => {
    const { id } = useParams()
    const { data, isLoading } = useFetch({
        name: `ads_${id}`,
        service: getAdsById,
        id
    })

    if (isLoading) return <Loading />

    return (
        <VStack space='2' w={'100%'} maxW={500}>
            {data && <CreateADS data={data} />}
        </VStack>
    )
}

export default EditADS