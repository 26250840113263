import React from 'react'
import { HStack, Input, VStack, Text, Box } from '@chakra-ui/react'
import { colors } from '../theme/colors'
import DatePicker from 'react-datepicker';

export const InputField = ({ form, type = 'text', debounce, show = true, title, date, desc, showDesc = true, placeholder, tw = 150, id, disabled, defaultValue, autoCapatilize, error, valueDresser, RenderInput, styles, body, ...props }) => {
    const capatilize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

    return (
        show && <HStack w='100%' alignItems={showDesc && desc ? "flex-start" : 'center'}
            opacity={disabled ? 0.8 : 1} space='1' flex='1' {...styles}
        >
            {title &&
                <HStack alignItems={'center'} mt='4.6'  >
                    <Text fontWeight='600' w={tw}>{title}</Text>
                    <Text fontWeight='600'>:</Text>
                </HStack>
            }
            {body ||
                (date ?
                    <Box zIndex='100' flex='1' alignItems={'flex-start'} >
                        <DatePicker
                            dateFormat={"dd/MM/yyyy"}
                            selected={form.watch(id) ? new Date(form.watch(id)) : ''}
                            onChange={(date) => form.setValue(id, date)}
                            customInput={<Input variant={"outline"} size="lg" placeholder="From" bg={'white'} />}
                        />
                    </Box>
                    :
                    <VStack flex='1' alignItems={'flex-start'}>
                        {RenderInput ||
                            <Input type={type} size='sm' disabled={disabled} id={id}
                                bg={disabled ? 'gray.100' : 'white'}
                                defaultValue={defaultValue}
                                borderColor={error ? 'red.400' : 'gray.300'}
                                {...debounce ? {} : {
                                    value: valueDresser ? valueDresser(form.watch(id)) : form.watch(id)
                                }}
                                onChange={e => {
                                    if (props?.maxLength && e.target.value.length > props.maxLength) return

                                    if (debounce) {
                                        setTimeout(() => {
                                            form.setValue(id, autoCapatilize ? capatilize(e.target.value) : e.target.value)
                                        }, debounce)
                                    }
                                    else
                                        form.setValue(id, autoCapatilize ? capatilize(e.target.value) : e.target.value)
                                }}
                                placeholder={placeholder ? placeholder : title}
                                {...props}
                            />
                        }
                        {(desc && showDesc) && <Text fontWeight={'500'} fontSize='sm' color={colors.secondary}>{desc}</Text>}
                    </VStack>)}
        </HStack>
    )
}
