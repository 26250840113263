import { VStack } from 'native-base'
import React from 'react'
import { getverifiedstores } from '../../apis/store';
import CompHeader from '../../commons/CompHeader/CompHeader';
import useFetch from '../../hook/useFetch';
import { useNavigate } from 'react-router-dom';
import Ranger from '../../components/commons/Map/Ranger';
import StoreTabs from './StoreTabs';

const StoresMapLines = () => {
    const navigate = useNavigate()
    const [limit, setLimit] = React.useState(100)

    const { data, isLoading } = useFetch({
        name: 'active_stores',
        service: getverifiedstores,
        params: { limit },
        conditionalRefetch: [limit]
    })

    return (
        <VStack>
            <CompHeader title='Stores' buttonTitle='New Store' onPress={() => navigate('/admin/pre-register')} />
            <StoreTabs as='maps' />
            <Ranger data={data} isLoading={isLoading} limit={limit} setLimit={setLimit} />
        </VStack>
    )
}

export default StoresMapLines