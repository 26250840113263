import React from 'react'
import { columns } from './static'
import LiveSection from './LiveSection'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { getBasicKPI } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const KPI = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getBasicKPI,
        name: 'KPI_basic',
    })

    if (isLoading) return <Loading />

    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title="KPI" />
            <LiveSection />
            <DataTable title={'Basic'} pagination={false} tableData={{ columns: columns(navigate), data, isLoading, }} showSearch={false} />
            {/* <ViewWiseStores /> */}
        </VStack>
    )
}

export default KPI