import { Box, Button, HStack, Input, Text, VStack } from 'native-base'
import React, { useState } from 'react'
import SelectField from '../../../commons/SelectField'

const EditInputCard = ({ form, label, target, form2, options }) => {
    const [edit, setEdit] = useState(false)
    const value = form.watch(target)
    const value2 = form2.watch(target)

    const changed = value !== value2
    const color = changed || edit ? '#000' : 'gray.500'


    return (
        <VStack w='100%'>
            <Text color={color} fontSize="sm" fontWeight='semibold'>{label}</Text>
            <HStack color={color} space='2' w={'100%'} alignItems='flex-start'>
                {options ?
                    <Box>
                        <VStack flex={1}>
                            <SelectField
                                form={form2}
                                list={options}
                                placeholder={label}
                                // title={label}
                                id={target}
                                disabled={!edit}
                            />
                            {changed &&
                                <HStack alignItems={'center'} space='2'>
                                    <Text color='gray.500'>{value}</Text>
                                    <Text onPress={() => form2.setValue(target, value)} color='blue.400'>reset </Text>
                                </HStack>
                            }
                        </VStack>
                    </Box>

                    : <VStack flex='1'>
                        <Input
                            color={color}
                            borderColor={color}
                            flex='1'
                            placeholder={label}
                            value={value2}
                            onChange={(e) => form2.setValue(target, e.target.value)}
                            disabled={!edit}
                        />
                        {changed &&
                            <HStack alignItems={'center'} space='2'>
                                <Text color='gray.500'>{value}</Text>
                                <Text onPress={() => form2.setValue(target, value)} color='blue.400'>reset </Text>
                            </HStack>
                        }
                    </VStack>}

                <Button onPress={() => setEdit(!edit)} variant='ghost' size='sm'>{edit ? 'done' : 'edit'}</Button>
            </HStack>
        </VStack>
    )
}

export default EditInputCard