import React, { useMemo } from 'react'
import { Button, HStack, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { InputField } from '../../commons/InputField'
import SelectField from '../../commons/SelectField'
import CompHeader from '../../commons/CompHeader/CompHeader';
import { useNavigate, useParams } from 'react-router-dom'
import { editOffersById, getbrands, getgender, getOffersById } from '../../apis/admin'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import { formatDateTimes } from '../../utils/moment'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import Loading from '../../commons/Loading'

const EditOffers = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const form = useForm({ mode: 'onBlur' })
    const state = { ...form.getValues() }
    const { offer_applicable } = state


    const genders = useFetch({ name: 'getgenders', service: getgender, dataOnly: true, converterLabel: 'type' })

    const { isLoading: loading } = useFetch({
        name: `offer_${id}`, service: getOffersById,
        props: { id },
        onSuccess: (data) => {
            data.offer_applicable = data.offer_applicable.map(({ _id }) => _id)
            data.brands = data.brands.map(({ brand, _id }) => ({ label: brand, value: _id }))
            form.reset(data)
        }
    })

    const store_id = form.watch('store_id')

    const brands = useFetch({
        name: `brands_${store_id}`, service: getbrands,
        dataOnly: true, converterLabel: 'brand', sort: true,
        params: { limit: 0, store_id },
        conditionalRefetch: [store_id]
    })

    const { submit, isLoading } = useSubmit({
        service: editOffersById, id,
        onSuccess: () => navigate(-1),
    })

    const list = useMemo(() => {
        return [
            { title: 'Offer id', id: '_id', disabled: true },
            { title: 'Offer', id: 'offer' },
            { title: 'Description', id: 'description' },
            {
                title: 'Offer Applicable',
                body: <CheckboxGroup value={offer_applicable} colorScheme='red'
                    onChange={(e) => form.setValue('offer_applicable', e)} >
                    <HStack alignItems={'center'} space='2'>
                        {genders.map(({ label, value }) => <Checkbox value={value} >{label}</Checkbox>)}
                    </HStack>
                </CheckboxGroup>
            },
        ]
    }, [form.watch()])

    const list2 = useMemo(() => {
        return [
            { title: 'Start Date', id: 'start_date', date: true, },
            { title: 'End Date', id: 'end_date', date: true, },
        ]
    }, [form.watch()])

    const list3 = useMemo(() => {
        return [
            { title: 'Status', id: 'verified', body: <SelectField form={form} onChange={val => form.setValue('verified', val === 'true')} id='verified' list={[true, false].map(value => ({ label: value.toString(), value }))} /> },
            { title: 'Brand', id: 'brand', body: <SelectField multi form={form} id='brands' list={brands} disabled={!true} /> },
            { title: 'Created At', id: 'createdAt', disabled: true, valueDresser: (val) => formatDateTimes(val, 5) },
            { title: 'Updated At', id: 'updatedAt', disabled: true, valueDresser: (val) => formatDateTimes(val, 5) },
        ]
    }, [form.watch()])


    if (loading) return <Loading />

    return (
        <VStack p='3' space='2' w={'100%'} alignItems={'flex-start'} >

            <CompHeader title='Edit Offer' backButton />

            {list.map(item =>
                <InputField tw={150} key={item.title} form={form} {...item} />
            )}

            <HStack alignItems={'center'} space='2' >
                {list2.map(item =>
                    <InputField tw={150} key={item.title} form={form} {...item} />
                )}
            </HStack>

            {list3.map(item =>
                <InputField tw={150} key={item.title} form={form} {...item} />
            )}

            <Button onClick={() => submit({
                ...form.watch(),
                brands: form.watch('brands').map(({ value }) => value)
            })} size='sm' isLoading={isLoading}
                colorScheme='red' variant='solid' >
                Update
            </Button>
        </VStack>
    )
}

export default EditOffers