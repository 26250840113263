import { Box } from 'native-base'
import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import Markers from './Markers'
import { useLocation } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import { getURLQuery } from '../../../helper/url';

const url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"

const Maps = ({ positions, center }) => {
    const { search } = useLocation()
    const fullScreen = getURLQuery(search, 'fullscreen')

    return (
        <Box bg='white' overflow={'hidden'}>
            <MapContainer center={[24.086781, 77.952350]} zoom={6} scrollWheelZoom={true}
                style={{ height: fullScreen ? '100vh' : "800px", width: "100%" }} >
                <TileLayer url={url} />
                <Markers positions={positions} />
            </MapContainer>
        </Box>
    )
}

export default Maps