import React from 'react'
import Tile from '../../commons/Tile'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { colors } from '../../theme/colors'
import { AiFillStar } from 'react-icons/ai'
import useSubmit from '../../hook/useSubmit'
import { getRangeLabels } from '../Stores/static'
import { Image, Tag, TagLabel } from '@chakra-ui/react'
import { useNavigate, useParams, } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { formatDateTimes, fromNow } from '../../utils/moment'
import { IMAGE_BASE_URL, KmView } from '../../helper/variables'
import { Box, Button, HStack, Spacer, Text, VStack } from 'native-base'
import { deleteCBSEquiriesById, editCBSEquiriesById, getCBSEquiriesById } from '../../apis/admin'

const contProps = { borderWidth: 0, bg: colors.light, p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1, w: '100%' }

const CBSEnquiryDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading, refetch } = useFetch({
        service: getCBSEquiriesById,
        name: 'getCBSEquiriesById',
        id
    })

    const last_data = data.user?.subscription?.end_date
    const expired = new Date(last_data) < new Date()

    const Remove = useSubmit({ service: deleteCBSEquiriesById, id, onSuccess: () => navigate('/admin/cbs') })
    const Edit = useSubmit({ service: editCBSEquiriesById, id, onSuccess: refetch })


    const closetime = (() => {
        if (data.status !== 'closed') return null
        const time = new Date(data.createdAt)
        time.setHours(time.getHours() + data.round)
        return time
    })()

    if (isLoading) return <Loading />

    return (
        <VStack>
            <CompHeader title='CBS Enquiry Details' backButton />
            <VStack space='4'>
                <VStack {...contProps} justifyContent={'space-between'} >
                    <Text fontWeight='600' mb='2'>Enquiry</Text>
                    <HStack>
                        <VStack flex={1}>
                            <Tile fw='500' label='ID' value={data._id} />
                            <Tile fw='500' label='Brand' value={data?.brand?.brand || 'N/A'} />
                            {data?.product_id ?
                                <Tile fw='500' label='Product ID' value={data?.product_id} />
                                :
                                <>
                                    <Tile fw='500' label='Product' value={data.product?.name} />
                                    <Tile fw='500' label='Wear' value={data.wear?.wear} />
                                    <Tile fw='500' label='Gender' value={data.gender?.type} />
                                    <Tile fw='500' label='Budget' value={data?.budget ? data?.budget.join('-') : 'N/A'} />
                                    <Tile fw='500' label='Store Budget Range' value={data?.range_type ? `${getRangeLabels(data?.range_type)} ${data?.range_type_extended ? ' (EXTENDED)' : ''}` : 'N /A'} />
                                </>
                            }
                        </VStack>
                        {data.images.map(img =>
                            <Image h='300' w='200' borderRadius={'md'}
                                resizeMode='contain' src={`${IMAGE_BASE_URL}${img}`} />
                        )}
                    </HStack>
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Describe</Text>
                    <Tile color fw='500' label={'Describe'} value={`${data?.describe}`} />
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Filters</Text>
                    {data.keys.map(({ title, keys }) =>
                        <Tile fw='500' label={title.title} value={keys.map(item => item.label).join(", ")} />
                    )}
                    {data?.color?.name && <Tile color fw='500' label={'Color'} value={`${data?.color?.name}-${data?.color?.color}`} />}
                    {data?.age?.name && <Tile age fw='500' label={'Age'} value={data?.age?.name} />}
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Timeline</Text>
                    <Tile fw='500' label='Status' value={data?.status} />
                    <Tile fw='500' label='Rounds' value={data?.round} />
                    <Tile fw='500' label='Last Update' value={`${formatDateTimes(data.updatedAt, 4)}, ${fromNow(data.updatedAt)}`} />
                    <Tile fw='500' label='Started' value={`${formatDateTimes(data.createdAt, 4)}, ${fromNow(data.createdAt)}`} />
                    {closetime && <Tile fw='500' label='Estimated close time' value={`${formatDateTimes(closetime, 4)}, ${fromNow(closetime)}`} />}
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Geo</Text>
                    <Tile fw='500' label='Coordinates' value={`${data.coordinates[0]}, ${data.coordinates[1]}`} />
                    <Tile fw='500' label='Miles' value={data.miles} />
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>User</Text>
                    <Tile fw='500' label='ID' value={data.user._id} />
                    <Tile fw='500' label='Name' value={data.user.name} />
                    <Tile fw='500' label='Mobile Number' value={data.user.phone_number} />
                    {data.user?.subscription?.sub_id ? <>
                        <Tile fw='500' label='Subscription Name' value={`${data.user?.subscription?.sub_id?.name} (${data.user?.subscription?.sub_id?.code})`} />
                        <Tile fw='500' label='Subscription Duration' value={`${data.user?.subscription?.sub_id?.duration} days`} />
                        <Tile fw='500' label='Subscription Price' value={data.user?.subscription?.sub_id?.price} />
                        <Tile fw='500' label='Subscription Status' value={expired ? 'Expired ' : 'Active'} />
                    </> : null}
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Sent To ({data.store.length})</Text>
                    <HStack flexWrap={'wrap'} space='4'>
                        {data.store.map(store =>
                            <Tag cursor={'pointer'} onClick={() => navigate(`/admin/reviewStore/${store._id}`)} mb='2' size={'md'} key={store?._id} variant='solid' colorScheme='teal'>
                                {store.premium ?
                                    <>
                                        <AiFillStar color={colors.warning} />
                                        <Box w='2' />
                                    </> : null
                                }
                                <TagLabel > {store.store_name}</TagLabel>
                            </Tag>
                        )}
                    </HStack>
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Matches ({data.store_matches.flat().length})</Text>
                    {data.store_matches.map((storeArr, i) => {
                        const time = new Date(data.createdAt)
                        time.setHours(time.getHours() + i)
                        {/* storeArr?.length ? <VStack {...contProps}> */ }
                        return (
                            true ? <VStack {...contProps}>
                                <VStack mb='2'>
                                    <Text fontWeight='600' >Set {i + 1}</Text>
                                    {/* <Text fontWeight='500' color={colors.secondary}>{formatDateTimes(time, 4)}({fromNow(time)}) </Text> */}
                                </VStack>
                                <HStack flexWrap={'wrap'} space='4'>
                                    {storeArr.map(store =>
                                        store &&
                                        <Tag cursor={'poniter'} onClick={() => navigate(`/admin/reviewStore/${store._id}`)} mb='2' size={'md'} key={store?._id} variant='solid'
                                            colorScheme={
                                                data.store.find(item => item?._id === store?._id)
                                                    ? 'teal' : 'telegram'}
                                        >
                                            {store.premium ?
                                                <>
                                                    <AiFillStar color={colors.warning} />
                                                    <Box w='2' />
                                                </> : null
                                            }
                                            <TagLabel>
                                                {store.store_name}
                                                {store?.store_address?.city?.name}
                                                ({KmView({ store, coordinates: data.coordinates })})
                                            </TagLabel>
                                        </Tag>
                                    )}
                                </HStack>
                            </VStack> : <></>
                        )
                    })}
                </VStack>

                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Responses (Total: {data.responses?.length}) </Text>
                    {data.responses.map(({ items, store }, i) =>
                        <VStack borderWidth={1} borderColor={colors.border} p='2' borderRadius={'lg'} space='2'>
                            <Text fontWeight='600' mb='2'>Response {i + 1} </Text>
                            <HStack alignItems={'center'} borderWidth={1} borderColor={colors.border} p='2' borderRadius={'lg'}>
                                <VStack>
                                    <Text fontWeight='600' mb='2'>Store </Text>
                                    <Tile fw='500' label='ID' value={store._id} />
                                    <Tile fw='500' label='Name' value={store.store_name} />
                                    <Tile fw='500' label='GST' value={store.gst_number} />
                                    <Text
                                        cursor={'pointer'}
                                        color={colors.primary}
                                        _hover={{ color: colors.secondary }}
                                        onClick={() => navigate(`/admin/reviewStore/${store._id}`)}
                                    >
                                        Store page
                                    </Text>
                                </VStack>
                                <Spacer />
                                <Image h='100' w='200' resizeMode='contain' src={`${IMAGE_BASE_URL}${store.store_images?.[0]}`} />
                            </HStack>
                            {items.map((item, i) =>
                                <HStack alignItems={'center'} borderWidth={1} borderColor={colors.border} p='2' borderRadius={'lg'}>
                                    <VStack>
                                        <Text fontWeight='600' mb='2'>Item {i + 1}</Text>
                                        <Tile fw='500' label='ID' value={item._id} />
                                        <Tile fw='500' label='Description' value={item.description} />
                                        <Tile fw='500' label='Price' value={item.price} />
                                        <Tile fw='500' label='Discount' value={store.discount} />
                                    </VStack>
                                    <Spacer />
                                    {item?.images?.[0] &&
                                        <Image h='140' transition={'all 0.32s ease-in-out'} _hover={{ bg: '#fff', p: 2, borderRadius: 'md', transform: 'scale(2.5)' }} w='140' objectFit='contain' src={`${IMAGE_BASE_URL}${item?.images?.[0]}`} />
                                    }
                                </HStack>
                            )}
                        </VStack>
                    )}
                </VStack>
                <VStack {...contProps}>
                    <Text fontWeight='600' mb='2'>Actions</Text>
                    <HStack space={4}>
                        <Button isLoading={Remove.isLoading} onPress={Remove.submit} w='300' size='sm' colorScheme={'error'}>Remove</Button>
                        {data?.status !== 'active' && <Button isLoading={Edit.isLoading} onPress={() => Edit.submit({ status: 'active' })} w='300' size='sm' colorScheme={'info'}>Active</Button>}
                        {data?.status !== 'pause' && <Button isLoading={Edit.isLoading} onPress={() => Edit.submit({ status: 'pause' })} w='300' size='sm' colorScheme={'info'}>Pause</Button>}
                        {data?.status !== 'closed' && <Button isLoading={Edit.isLoading} onPress={() => Edit.submit({ status: 'closed' })} w='300' size='sm' colorScheme={'yellow'}>Close</Button>}
                    </HStack>
                </VStack>
            </VStack>
        </VStack>
    )
}

export default CBSEnquiryDetails