import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useParams } from 'react-router-dom';
import AddMall from './AddMall';
import useFetch from '../../hook/useFetch';
import { getmallsbyid } from '../../apis/admin';
import Loading from '../../commons/Loading';

const EditMall = () => {
    const { id } = useParams()
    const { data, isLoading } = useFetch({
        name: `mall_${id}`,
        service: getmallsbyid,
        props: { id },
    })

    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2' w={'100%'} >
            <CompHeader title='Edit Mall' backButton />
            {data && <AddMall data={data} />}
        </VStack>
    )
}

export default EditMall