import React from 'react'
import { Spacer, VStack } from 'native-base'
import { Button, Checkbox, CheckboxGroup, HStack, Text } from '@chakra-ui/react'
import { verifyBrand } from '../../../apis/admin'
import useSubmit from '../../../hook/useSubmit'

const BrandCard = ({ brandObj, brand, index, genders, form, viewOnly, refetch }) => {

    const isVerified = brandObj?.status === 'Active' || brandObj?.status === 'Passive'

    const { submit, isLoading } = useSubmit({
        service: verifyBrand,
        id: brand.brand,
        onSuccess: refetch
    })

    return (
        <VStack w='100%' py={1}>
            <HStack>
                <Text> {index + 1}. {brandObj?.brand} </Text>
                {isVerified ?
                    <Text color='green.600' fontWeight={'semibold'} fontSize='md'>Verified</Text>
                    :
                    <Text color='red.600' fontWeight={'semibold'} fontSize='md'>Not Verified</Text>
                }
                <Spacer />
                {viewOnly ? <></> : <Text color='red.500' fontWeight={'semibold'}
                    fontSize='sm' cursor='pointer'
                    onClick={() => {
                        form.setValue('brands', form.watch('brands').filter((item, i) => i !== index))
                    }}
                >Remove Brand </Text>}
            </HStack>
            <VStack>
                <Text fontWeight={'semibold'} fontSize='sm' textAlign='left' color='gray.500' >Readymade </Text>
                <CheckboxGroup value={brand.readymade} colorScheme='red' onChange={(e) => {
                    form.setValue(`brands[${index}].readymade`, e)
                }} >
                    <HStack space='4' flexWrap={'wrap'}>
                        {genders?.map(({ _id, type }) =>
                            <Checkbox mb='1' key={_id} value={_id} >
                                {type}
                            </Checkbox>
                        )}
                    </HStack>
                </CheckboxGroup>
            </VStack>

            {!isVerified && brandObj && (
                <Button w='100%' alignSelf={'flex-end'} isLoading={isLoading}
                    variant='solid' colorScheme='red'
                    disabled={viewOnly}
                    onClick={() => { submit() }} >
                    Verify this Brand - {brandObj?.brand}
                </Button>
            )}

        </VStack>
    )
}

export default BrandCard