import { Button } from "native-base";

export const columns = (navigate) => [
    {
        name: 'Name',
        selector: (row) => row.wear,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/wear/edit/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
] 