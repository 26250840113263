import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react';
import React from 'react'
import Model from './Model';

const AlertAction = ({
    title = 'Are you sure ?',
    ok = () => { },
    show = true,
    close = () => { },
    okText = 'ok',
    cancelText = 'cancel',
    confirmText,
}) => {
    const [input, setInput] = React.useState('')
    return (
        show && <Model
            title={'Alert'}
            show={show}
            close={close}
            body={
                <VStack alignItems={'flex-start'} w='100%' p='6' pt='0'>
                    <Text fontSize={'lg'} >{title}</Text>
                    {confirmText &&
                        <VStack w='100%' >
                            <Text>
                                Type '<Text as='span' fontWeight='bold'>{confirmText}</Text>' to confirm
                            </Text>
                            <Input
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder={confirmText}
                            />
                        </VStack>
                    }
                    <HStack alignSelf={'flex-end'} pt='4' spacing='5'>
                        <Button variant='link' onClick={ok}
                            disabled={confirmText && input !== confirmText}
                            color='black'>{okText}</Button>
                        <Button variant='link' onClick={close} color='red.900'>{cancelText}</Button>
                    </HStack>
                </VStack>
            }
        />
    )
}

export default AlertAction