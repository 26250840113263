import React from 'react'
import { Text, VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import { colors } from '../../theme/colors'
import { useLocation } from 'react-router-dom'
import { getURLQuery } from '../../helper/url'
import { globalSearch } from '../../apis/admin'
import { HStack, Spacer } from '@chakra-ui/react'
import MallCard from '../../commons/Search/Cards/MallCard';
import WearCard from '../../commons/Search/Cards/WearCard';
import BrandCard from '../../commons/Search/Cards/BrandCard';
import StoreCard from '../../commons/Search/Cards/StoreCard';
import ChainCard from '../../commons/Search/Cards/ChainCard';
import OfferCard from '../../commons/Search/Cards/OfferCard';
import ProductCard from '../../commons/Search/Cards/ProductCard';

const contProps = { borderWidth: 0, bg: colors.light, p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const list = [
    { label: 'Brands', key: 'brands', Renderer: BrandCard },
    { label: 'Stores', key: 'stores', Renderer: StoreCard },
    { label: 'Chains', key: 'chains', Renderer: ChainCard },
    { label: 'Malls', key: 'malls', Renderer: MallCard },
    { label: 'Wears', key: 'wears', Renderer: WearCard },
    { label: 'Offers', key: 'offers', Renderer: OfferCard },
    { label: 'Wear Stores', key: 'wearStores', Renderer: StoreCard, converter: (data) => data?.map(item => item?.store_id) },
    { label: 'Wear Offers', key: 'wearOffers', Renderer: OfferCard },
    { label: 'Products', key: 'products', Renderer: ProductCard },
    { label: 'Product Stores', key: 'productStores', Renderer: StoreCard, converter: (data) => data?.map(item => item?.store_id) },
    { label: 'Product Offers', key: 'productOffers', Renderer: OfferCard },
]

const SearchResults = () => {
    const { search, pathname } = useLocation()
    const params = getURLQuery(search)
    const { q } = params

    const { data, isLoading } = useFetch({
        service: globalSearch,
        params,
        conditionalRefetch: [params],
        condition: !!q
    })

    return (
        q && <VStack my='2' space='4'>
            <Text fontWeight={'semibold'} fontSize='md'>{isLoading ? 'Loading....' : 'Results'}</Text>
            <VStack space={4}>
                {list.map(({ label, key, Renderer, converter }) => {
                    const _data = converter ? converter(data?.[key]) : data?.[key]
                    return (_data?.length ? <VStack {...contProps}>
                        <HStack alignItems={'center'}>
                            <Text fontWeight='600' mb='2'> {label} ({_data?.length}) </Text>
                            <Spacer />
                            {_data?.length > 10 && <Text>Load more</Text>}
                        </HStack>
                        <HStack flexWrap='wrap'>
                            {_data?.slice(0, 6)?.map((item, index) => <Renderer item={item} key={index} />)}
                        </HStack>
                    </VStack> : null)
                })}
            </VStack>
        </VStack>
    )
}

export default SearchResults