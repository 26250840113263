import { useForm } from 'react-hook-form'
import { Checkbox } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { InputField } from '../../commons/InputField'
import { IMAGE_BASE_URL } from '../../helper/variables'
import { capitalize } from '../../helper/stringOperations'
import { Button, HStack, Text, VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import CropArea from '../../components/compo/CropArea/CropArea'
import { multipledataformater } from '../../helper/multipledataformater'
import { createProduct, deleteProductById, editProductById } from '../../apis/admin'

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']
const range = [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]

const defaultValues = { name: '', tags: [''], images: [], clothing_types: { readymade: true, stitched: false }, range }

const AddProduct = ({ data, close = () => { }, refetch }) => {

    const [images, setImages] = useState([])
    if (data?.range?.length === 0) data.range = range

    const navigate = useNavigate()
    const form = useForm({ defaultValues: data ? data : defaultValues })
    const state = form.watch()
    const { clothing_types: { readymade = false, stitched = false } } = state

    useEffect(() => {
        if (data?.images?.length > 0) {
            setImages(data?.images.map(image => `${IMAGE_BASE_URL}${image}`))
        }
    }, [data])

    const { submit, isLoading } = useSubmit({
        service: data ? editProductById : createProduct,
        id: data?._id,
        onSuccess: () => {
            if (refetch) {
                refetch()
            } else {
                navigate(-1)
            }
            close()
        }
    })

    const Delete = useSubmit({
        name: 'deleteProductById',
        service: deleteProductById,
        id: data?._id,
        onSuccess: () => navigate(-1)
    })

    const save = () => {
        const _state = {
            ...state,
            tags: state.tags.filter(tag => tag !== ''),
            filters: [],
            wears: [],
            required_filters: [],
            required_filters_in_upload: [],
            images: state.images.filter(image => typeof image === 'string' && image !== ''),
        }

        const formdata = multipledataformater({ state: _state, images: state.images.filter(image => typeof image !== 'string' && image !== '') })

        submit(formdata)
    }

    return (
        <VStack p={close ? 0 : '3'} space='4' w={'100%'} maxW={500}>
            {!close && <CompHeader title={data ? 'Edit Product Name' : 'Create New Product'} backButton />}

            <VStack space='4' w={'100%'} bg="white" p={4} borderRadius={'md'}>

                {data && <InputField form={form} title='Product id' id='_id' disabled />}
                <InputField form={form} title='Name' id='name' autoCapatilize />

                <HStack alignItems={'center'} space='1'>
                    <Text fontWeight='600' w='100'>Clothing Type</Text>
                    <Text fontWeight='600' >:</Text>
                    <Checkbox isChecked={readymade} mr='4'
                        onChange={e => form.setValue('clothing_types.readymade', e.target.checked)}
                    >Readymade</Checkbox>
                    <Checkbox isChecked={stitched}
                        onChange={e => form.setValue('clothing_types.stitched', e.target.checked)}
                    >Stitched</Checkbox>
                </HStack>

                <VStack space='2'>
                    {form.getValues().tags?.map((item, index) => (
                        <HStack space='2' alignItems={'center'}>
                            <InputField form={form} title={`Tag ${index + 1}`}
                                autoCapatilize id={`tags[${index}]`} />
                            <Button variant={'solid'} borderRadius='md' h='6'
                                w={'6'} bg='red.400' alignItems={'center'}
                                justifyContent={'center'}
                                onPress={() => {
                                    const { tags } = form.getValues()
                                    tags.splice(index, 1)
                                    form.setValue('tags', tags)
                                }}>
                                <Text color='white'>-</Text>
                            </Button>
                        </HStack>
                    ))}
                    <Button w='fit-content' alignSelf={'flex-end'} size='sm' variant={'link'}
                        onPress={() => {
                            const { tags } = form.getValues()
                            tags.push('')
                            form.setValue('tags', tags)
                        }}>
                        Add More
                    </Button>
                </VStack>

                <Text fontWeight={'semibold'} fontSize={'md'}>
                    Product price range (in INR)
                </Text>
                {state.range.map((_, index) =>
                    <HStack key={index} space={2}>
                        <Text fontWeight={'semibold'} w={32}>
                            {capitalize(range_labels[index])} :</Text>
                        <InputField type='number' form={form} id={`range.${index}.0`} />
                        <InputField type='number' form={form} id={`range.${index}.1`} />
                    </HStack>
                )}

                <Text fontWeight={'semibold'} fontSize={'md'}>
                    Product Image
                </Text>
                {['Image 1', 'Image 2', 'Image 3', 'Image 4'].map((gender, i) => {
                    const _images = form.watch('images')
                    const last_vacancy_filled = i === 0 || _images?.[i - 1]
                    return (<VStack space={1} opacity={last_vacancy_filled ? 1 : 0.5}
                        pointerEvents={last_vacancy_filled ? 'auto' : 'none'}>
                        <Text fontWeight={'semibold'} fontSize={'md'}>
                            {gender}
                        </Text>
                        <CropArea aspect={9 / 16}
                            {...images?.[i] && { defaultImage: images?.[i] }}
                            removeImage={() => {
                                setImages(images.filter((_, index) => index !== i))
                                form.setValue(`images[${i}]`, '')
                            }}
                            setImage={(file) => {
                                if (!file) return
                                form.setValue(`images[${i}]`, file)
                            }}
                        />
                    </VStack>)
                })}


                <Button onPress={save} isLoading={isLoading} size='sm'
                    backgroundColor={data ? 'green.900' : 'blue.400'} >
                    {data ? 'Update' : "Add"}
                </Button>
            </VStack>
            {/* {data && <Button onPress={() => Delete.submit()}>
                <Text color='white'>Delete</Text>
            </Button>} */}
        </VStack>
    )
}

export default AddProduct