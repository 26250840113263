import React from 'react'
import useFetch from '../../../hook/useFetch'
import { Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { getUserCoupons } from '../../../apis/admin'
import Loading from '../../../commons/Loading';
import DataTable from '../../../commons/DataTable/DataTable'
import { columns } from './static'

const AllCoupons = ({ user, refresh }) => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getUserCoupons,
        name: `getUserCouponsForUser${user._id}`,
        id: user._id,
        conditionalRefetch: [refresh],
    })

    return (
        <VStack w='100%' space='2' px={4} alignItems={'flex-start'}>
            <Text fontSize={'md'} fontWeight='600'>
                User Coupons
            </Text>

            <DataTable tableData={{ columns: columns(navigate), data, isLoading, }} pagination={false} />

        </VStack>
    )
}

export default AllCoupons