import React from 'react'
import { columns } from './static'
import { Text, VStack } from '@chakra-ui/react'
import DataTable from '../../../commons/DataTable/DataTable'
import { getPastHour, getPastMinute, getPastSec } from '../../../utils/moment'

export const getTimeWiseApiCalls = ({ start, end, data, show }) => {
    if (!end) end = new Date()

    const list = data.filter((item) => {
        const item_time = new Date(item.createdAt)
        return item_time >= start && item_time <= end
    })

    return {
        api_calls: list.length,
        users: [...new Set([...list.map((item) => item.user)])].length,
    }

}

const ApiStats = ({ data }) => {
    const last_10_sec = getTimeWiseApiCalls({ start: getPastSec(10), data, })
    const last_30_sec = getTimeWiseApiCalls({ start: getPastSec(30), data, })
    const last_min = getTimeWiseApiCalls({ start: getPastMinute(1), data, show: true })
    const last_10_min = getTimeWiseApiCalls({ start: getPastMinute(10), data })
    const last_30_min = getTimeWiseApiCalls({ start: getPastMinute(30), data })
    const last_one_hour = getTimeWiseApiCalls({ start: getPastHour(1), data })
    const last_two_hour = getTimeWiseApiCalls({ start: getPastHour(2), data })
    const last_six_hour = getTimeWiseApiCalls({ start: getPastHour(6), data })
    const last_12_hour = getTimeWiseApiCalls({ start: getPastHour(12), data })
    const last_24_hours = getTimeWiseApiCalls({ start: getPastHour(24), data })

    const list = [
        { key: 'Last 10 Seconds', api_calls: last_10_sec?.api_calls, users: last_10_sec.users },
        { key: 'Last 30 Seconds', api_calls: last_30_sec?.api_calls, users: last_30_sec.users },
        { key: 'Last 1 Minute', api_calls: last_min?.api_calls, users: last_min.users },
        { key: 'Last 10 Minutes', api_calls: last_10_min?.api_calls, users: last_10_min.users },
        { key: 'Last 30 Minutes', api_calls: last_30_min?.api_calls, users: last_30_min.users },
        { key: 'Last 1 Hour', api_calls: last_one_hour?.api_calls, users: last_one_hour.users },
        { key: 'Last 2 Hours', api_calls: last_two_hour?.api_calls, users: last_two_hour.users },
        { key: 'Last 6 Hours', api_calls: last_six_hour?.api_calls, users: last_six_hour.users },
        { key: 'Last 12 Hours', api_calls: last_12_hour?.api_calls, users: last_12_hour.users },
        { key: 'Last 24 Hours', api_calls: last_24_hours?.api_calls, users: last_24_hours.users },
    ]

    return (
        <VStack alignItems={'flex-start'} spacing={0} w={'100%'} >
            <Text fontSize={'2xl'} fontWeight={'bold'}>Api Calls Stats</Text>
            <DataTable tableData={{ columns: columns(), data: list }} showSearch={false} pagination={false} />
        </VStack>
    )
}

export default ApiStats