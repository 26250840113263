export const filtersToLocalDS = (data, wear) => {
    const keys = {}
    data.filter(item => item.wear === wear)
        .map(item => {
            keys[item.gender] = {}
            item.filters.map(filter =>
                keys[item.gender][filter.title] = filter.keys
            )
        })
    return keys
}

export const filtersToCloudDS = (data) => {
    return [
        ...Object.keys(data).map(gender => {
            return {
                gender,
                filters: [
                    ...Object.keys(data[gender]).map(filter => {
                        return {
                            title: filter,
                            keys: (() => {
                                let filters = data[gender][filter]
                                // remove empty keys
                                filters = filters.filter(item => item !== '' && item !== null)
                                // remove duplicate keys
                                filters = [...new Set(filters)]
                                return filters
                            })()
                        }
                    })
                ]
            }
        })
    ]
}