import React from 'react'
import { useForm } from 'react-hook-form'
import CompHeader from '../../commons/CompHeader/CompHeader'
import CropArea from '../../components/compo/CropArea/CropArea'
import useSubmit from '../../hook/useSubmit'
import { useNavigate } from 'react-router-dom'
import { postSponsoredStory, uploadImage } from '../../apis/admin'
import { Box, Button, HStack, Input, Text, Textarea, VStack } from '@chakra-ui/react'

const w = 300
const h = w * 16 / 9

const AddSponsorReels = () => {
    const navigate = useNavigate()
    const [video, setVideo] = React.useState(null)
    const [thumbnail, setThumbnail] = React.useState(null)
    const [avatar, setAvatar] = React.useState(null)

    const form = useForm({ defaultValues: { title: '', caption: "", avatar: '', reel: '', thumbnail: '', link: '' } })

    const handleVideo = (e) => {
        const video = e.target.files[0]
        setVideo(video)
    }

    const { submit, isLoading } = useSubmit({
        service: postSponsoredStory,
        onSuccess: () => navigate(-1)
    })

    const { submit: submitImage, isLoading: isLoadingImage } = useSubmit({
        service: uploadImage,
        onSuccess: (res) => {
            const thumbnail = res?.[0]
            const avatar = res?.[1]

            const formdata = new FormData();
            const state = {
                ...form.getValues(), thumbnail, avatar,
                duration: video?.duration,
            }

            Object.keys(state).map((obj) => {
                if (Array.isArray(state[obj])) {
                    state[obj].map((item) => {
                        if (typeof item === "object") {
                            return formdata.append(obj, JSON.stringify(item));
                        }
                        formdata.append(obj, item);
                    });
                } else if (!Array.isArray(state[obj]) && typeof state[obj] === "object") {
                    formdata.append(obj, JSON.stringify(state[obj]));
                }
                if (typeof state[obj] === "string") {
                    formdata.append(obj, state[obj]);
                }
            })
            formdata.append("videos", video);

            submit(formdata)
        }
    })


    const pulish = () => {
        const formdata = new FormData();
        formdata.append("images", thumbnail);
        formdata.append("images", avatar);
        submitImage(formdata)
    }


    return (
        <VStack w="100%" alignItems={'flex-start'} >
            <CompHeader title="Add Sponsor Reels" />

            <VStack w={'100%'} alignItems={'flex-start'} bg={'white'} p={4}>
                <HStack w={'100%'} alignItems={'flex-start'} >
                    <VStack flex={1} alignItems={'flex-start'} maxW={350} >
                        <Text fontSize="xl" fontWeight="bold" >
                            Pick Video
                        </Text>
                        <Input type="file" accept="video/*" onChange={handleVideo} outline={'none'} border={'none'} p={0} borderRadius={0} />
                        {video && (
                            <Box>
                                <Text fontWeight="bold">Selected Video:</Text>
                                <Text>{video.name}</Text>
                                <video height={h} width={w} src={URL.createObjectURL(video)} controls />
                            </Box>
                        )}
                    </VStack>

                    {video &&
                        <VStack flex={1} alignItems={'flex-start'} maxW={350} >
                            <Text fontSize="xl" fontWeight="bold" >
                                Pick Thumbnail
                            </Text>
                            <Input type="file" accept="image/*" onChange={(e) => setThumbnail(e.target.files[0])} outline={'none'} border={'none'} p={0} borderRadius={0} />
                            {thumbnail && (
                                <Box>
                                    <Text fontWeight="bold">Selected Thumbnail:</Text>
                                    <Text>{thumbnail.name}</Text>
                                    <Box
                                        w={w} h={h} overflow={'hidden'}
                                        border={'1px solid #000'}
                                        bg={'#000'}
                                    >
                                        <img height={'100%'} width={'100%'} src={URL.createObjectURL(thumbnail)} alt="thumbnail" />
                                    </Box>
                                </Box>
                            )}
                        </VStack>}
                </HStack>

                {video &&
                    <VStack alignItems={'flex-start'} spacing={2}>
                        <VStack alignItems={'flex-start'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Avatar
                            </Text>
                            <CropArea
                                circularCrop
                                defaultImage={avatar || ''}
                                removeImage={() => { setAvatar(null) }}
                                setImage={(file) => { setAvatar(file) }}
                            />
                        </VStack>

                        <HStack alignItems={'center'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Title:
                            </Text>
                            <Input variant='filled' borderColor='gray.300'
                                placeholder='Title' value={form.watch('title')}
                                onChange={e => form.setValue('title', e.target.value)}
                            />
                        </HStack>

                        <HStack alignItems={'center'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Caption:
                            </Text>
                            <Textarea variant='filled' borderColor='gray.300'
                                placeholder='Caption' value={form.watch('caption')}
                                onChange={e => form.setValue('caption', e.target.value)}
                                rows={4}
                            />
                        </HStack>

                        <HStack alignItems={'center'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Link:
                            </Text>
                            <Textarea variant='filled' borderColor='gray.300'
                                placeholder='Link' value={form.watch('link')}
                                onChange={e => form.setValue('link', e.target.value)}
                                rows={4}
                            />
                        </HStack>

                    </VStack>
                }

                <Button colorScheme="teal" size="lg" onClick={pulish} isLoading={isLoading || isLoadingImage} w={250} >
                    Pulish
                </Button>

            </VStack>

        </VStack>
    )
}

export default AddSponsorReels