import React, { useEffect } from 'react'
import { Text } from 'native-base'
import SelectField from '../../../commons/SelectField'
import { InputField } from '../../../commons/InputField'
import { Button, HStack, Input, Spacer, VStack } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { getDistancefromLocal } from '../../../helper/coords'

const StoreCoordinates = ({ form, viewOnly }) => {
    const lat = form.watch('geo_location.coordinates[0]')
    const lng = form.watch('geo_location.coordinates[1]')

    const distance = getDistancefromLocal(lat, lng);

    const list = [
        { title: 'Latitude', id: 'geo_location.coordinates[0]' },
        { title: 'Longitude', id: 'geo_location.coordinates[1]' },
    ].map(item => ({ ...item, disabled: viewOnly || item.disabled, }))


    return (
        <VStack space={3} w='100%' alignItems='flex-start'>

            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' >
                    Store Coordinates
                </Text>
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('store_address', form.watch('default.store_address'))
                            form.setValue('geo_location.coordinates', form.watch('default.geo_location.coordinates'))
                        }}>
                        Reset Store Coordinates
                    </Text>}
            </HStack>

            <Input value=''
                placeholder={"Paste Google map Coordinates here"}
                onChange={(e) => {
                    const [lat, lng] = e.target.value.split(',')
                    form.setValue('geo_location.coordinates[0]', lat)
                    form.setValue('geo_location.coordinates[1]', lng)
                }}
            />

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}

            <Text fontSize='xl' color='gray.500'>
                Distance from your location: {distance} km
            </Text>

            <Button
                w='100%'
                bg='blue.500'
                size='sm'
                colorScheme={'blue'}
                onClick={() => {
                    // show on map
                    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
                }}
                rightIcon={<ExternalLinkIcon />}
            >
                Verify on G.Maps
            </Button>
        </VStack>
    )
}

export default StoreCoordinates