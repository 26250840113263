import React from 'react'
import { columns } from './static'
import { useNavigate } from 'react-router-dom'
import { Text, VStack } from '@chakra-ui/react'
import useFetch from '../../../../hook/useFetch'
import { colors } from '../../../../theme/colors'
import { getStoreStyles } from '../../../../apis/store'
import DataTable from '../../../../commons/DataTable/DataTable'

const StoreProducts = ({ store }) => {

    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: `storestyles_${store._id}`,
        service: getStoreStyles,
        id: store._id,
    })

    return (
        <VStack alignItems={'flex-start'} space='2' borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>

            <Text fontSize={'md'} fontWeight='600'>Store Products ({data?.length})</Text>

            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: false }} pagination={false} />

        </VStack>
    )
}

export default StoreProducts