import React from 'react'
import CouponCard from './CouponCard'
import useFetch from '../../../hook/useFetch'
import { SimpleGrid, Text } from 'native-base'
import Loading from '../../../commons/Loading'
import { getCoupons } from '../../../apis/admin'
import { VStack } from '@chakra-ui/react'

const CouponCards = ({ form, user }) => {

    const coupons = form.watch('coupons')

    const { data, isLoading } = useFetch({
        service: getCoupons,
        name: 'getCouponsForUser',
        params: { active: true, pagination: false }
    })

    return (
        <VStack w={'100%'} alignItems={'flex-start'}>
            <Text fontSize={'md'} fontWeight='600' mb={4} mt={4} >
                Coupon List ({data?.length})
            </Text>
            {isLoading ? <Loading /> :
                <SimpleGrid columns={[1, 4]} spacingX={4} spacingY={2} >
                    {data.map((coupon, index) => {
                        const selected = coupons.includes(coupon._id)
                        const alreadyAssigned = coupon.redeems.includes(user._id)
                        const toggle = () => !alreadyAssigned && form.setValue('coupons', selected ? coupons.filter(c => c !== coupon._id) : [...coupons, coupon._id])
                        return (
                            <CouponCard key={index} data={coupon} selected={selected} toggle={toggle}
                                alreadyAssigned={alreadyAssigned}
                            />
                        )
                    })}
                </SimpleGrid>}
        </VStack>
    )
}

export default CouponCards