import { Button } from "native-base";
import { formatDateTimes } from "../../utils/moment";

export const columns = (navigate) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button size='sm' variant={'link'}
            onPress={() => window.open(`/admin/coupons/edit/${row._id}`, '_blank')} >
            Details
        </Button>,
        sortable: false,
    },
    { name: 'Name(code)', selector: (row) => `${row?.name}(${row?.code})`, sortable: true, },
    { name: 'Active', selector: (row) => row?.active ? "Yes" : "No", sortable: true, },
    { name: 'Paid/Rupees', selector: (row) => `${row?.paid ? "Yes" : "No"} / ${row?.price}`, sortable: true, },
    { name: 'Public', selector: (row) => row?.public ? "Yes" : "No", sortable: true, },
    { name: 'Type', selector: (row) => `${row?.type}`, sortable: true, },
    { name: 'Discount', selector: (row) => `${row?.discount}`, sortable: true, },
    { name: 'Store', selector: (row) => `${row?.store?.[0]?.store_name}`, sortable: true, },
    { name: 'Count', selector: (row) => `${row?.max_redeems}`, sortable: true, },
    {
        name: 'Start Date',
        selector: (row) => formatDateTimes(row?.start_date, 5),
    },
    {
        name: 'Expiry',
        selector: (row) => formatDateTimes(row?.expiry, 5),
        sortable: true,
    },
    {
        name: 'Created At',
        selector: (row) => formatDateTimes(row?.createdAt, 5),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => window.open(`/admin/coupons/all_coupon_data/${row._id}`, '_blank')} >
            All Coupons
        </Button>,
        sortable: false,
    },
]

