import React from 'react'
import { useNavigate } from 'react-router-dom'
import useFetch from '../../hook/useFetch'
import { createMultiQR, getQR } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { columns } from './static'
import DataTable from '../../commons/DataTable/DataTable'
import { VStack } from 'native-base'
import { Box, Button, HStack } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit';
import QRCode from 'react-qr-code'

const QRs = () => {
    const navigate = useNavigate()

    const { data, isLoading, refetch } = useFetch({
        name: 'getQR',
        service: getQR,
        pagination: true,
    })

    const Add10Qrs = useSubmit({
        name: 'createMultiQR',
        service: createMultiQR,
        onSuccess: refetch
    })

    const downloadAllQr = () => {
        data?.data?.map((item) => {
            const canvas = document.getElementById(item.qr);
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${item?.qr}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        })
    }

    return (
        <VStack>
            <CompHeader title='QRs'
                renderRight={
                    <HStack space='5' alignItems={'center'} >
                        <Button size='sm' onClick={Add10Qrs.submit}
                            isLoading={Add10Qrs.isLoading}
                        >
                            Create 10 Qrs
                        </Button>
                        <Button size='sm' onClick={() => navigate('/admin/qr/create')}>
                            Create QR
                        </Button>
                    </HStack>
                }
                buttonTitle='Create QR' buttonLink='/admin/qr/create' />

            {data?.data?.map((item, index) =>
                <Box position='absolute' top='-100000' key={index} >
                    <QRCode id={item.qr} value={item.qr} size={300} level={"H"} includeMargin={true} />
                </Box>
            )}

            <Button onClick={downloadAllQr} >
                Download QR Images
            </Button>

            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default QRs