import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form';
import useFetch from '../../../hook/useFetch';
import { useNavigate } from 'react-router-dom';
import useSubmit from '../../../hook/useSubmit';
import SelectField from '../../../commons/SelectField';
import { getCity, getStates } from '../../../apis/misc';
import { InputField } from '../../../commons/InputField';
import { Button, HStack, Input, Spacer, Text, VStack } from '@chakra-ui/react';
import { createRestaurant, deleteRestaurantById, editRestaurantById } from '../../../apis/admin';
import { clothingProvided } from '../../../components/PreRegister/static/register';

const defaultValues = {
    name: '', phone_number: '', geo_location: { coordinates: [0, 0] },
    address: { address: '', locality: '', state: '', city: '', pincode: '' },
    verified: 'false'
}

const AddRestaurantform = ({ data }) => {
    const form = useForm({ defaultValues: data || defaultValues })
    const navigate = useNavigate()
    const selectedState = form.getValues()?.address?.state

    const { submit, isLoading } = useSubmit({
        service: data ? editRestaurantById : createRestaurant,
        id: data?._id,
        onSuccess: () => navigate('/admin/restaurants'),
    })

    const Delete = useSubmit({
        service: deleteRestaurantById,
        id: data?._id,
        onSuccess: () => navigate('/admin/restaurants'),
        alert: true,
    })

    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name' })
    const cities = useFetch({
        name: `cities_${selectedState}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: selectedState,
        id: selectedState,
        condition: !!selectedState,
        converterLabel: 'name'
    })

    const list = [
        { title: 'Restuarant id', id: '_id', disabled: true, show: !!data },
        { title: 'Restuarant Name', id: 'name' },
        { title: 'Phone Number', id: 'phone_number' },
        { title: 'Manager Name', id: 'manager_name' },
        {
            title: 'Google Maps Coords', Comp:
                <VStack w="100%" alignItems="flex-start" >
                    <Text fontSize="sm" color="gray.500" >
                        Enter the coordinates in the format: latitude,longitude
                    </Text>
                    <Input
                        placeholder='latitude, longitude'
                        onChange={(e) => {
                            form.setValue('geo_location.coordinates', e.target.value.split(',').map(Number))
                        }}
                    />
                </VStack>
        },
        { title: 'Latitude', id: 'geo_location.coordinates[0]' },
        { title: 'Longtitude', id: 'geo_location.coordinates[1]' },
        { title: 'Address', id: 'address.address' },
        { title: 'Locality', id: 'address.locality' },
        { title: 'Verified', id: 'verified', list: [{ label: 'Verified', value: 'true' }, { label: 'Not Verified', value: 'false' }] },
        { title: 'State', id: 'address.state', list: states },
        { title: 'City', id: 'address.city', list: cities },
        { title: 'Pincode', id: 'address.pincode' },
    ]

    const save = () => {
        const state = { ...form.getValues() }
        submit(state)
    }

    const state = form.watch()

    return (
        <VStack w={"100%"}>

            {list.map((item, index) => {
                if (item?.Comp) {
                    return item.Comp
                }
                const Comp = item?.Comp || item.list ? SelectField : InputField
                return <Comp tw={120} key={index} form={form} {...item} />
            })}

            <HStack>
                <Button m='auto' variant={'solid'}
                    colorScheme={data ? 'green' : 'blue'}
                    onClick={save} isLoading={isLoading} size='sm'>
                    {data ? 'Update Restaurant' : "Add Restaurant"}
                </Button>
                <Spacer />
                {data && <Button m='auto' variant={'solid'}
                    isLoading={Delete.isLoading} onClick={Delete.submit} size='sm'>
                    Delete Restaurant
                </Button>}
            </HStack>

        </VStack>
    )
}

export default AddRestaurantform