export const columns = (navigate) => [
    { name: 'Key', selector: (row) => row?.label, sortable: true, },
    { name: 'Today', selector: (row) => row?.today, sortable: true, },
    { name: 'Yesterday', selector: (row) => row?.yesterday, sortable: true, },
    { name: 'Week(Last 7 days)', selector: (row) => row?.week, sortable: true, },
    { name: 'Month(Last 30 days)', selector: (row) => row?.month, sortable: true, },
    { name: 'All', selector: (row) => row?.all, sortable: true, },
    // {
    //     name: 'Actions',
    //     button: true,
    //     cell: (row) => <Button
    //         size='sm' variant={'link'}
    //         onPress={() => navigate(`/admin/genders/edit/${row?._id}`)}>
    //         View</Button>,
    //     sortable: false,
    // },
]