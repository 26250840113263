import { VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Conn = () => {
    const navigate = useNavigate()
    useEffect(() => { navigate('/admin/conn/offingo') }, [])
    return (<VStack> </VStack>)
}

export default Conn