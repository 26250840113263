const wifi_1 = '192.168.29.229'
const origin = window.location.origin.includes('offingo.com')
const alpha = window.location.origin.includes('admin-offingo')
const dev = window.location.origin.includes('dev-admin')
const wifi = window.location.origin.includes(wifi_1)

// export const baseurl = 'https://api.offingo.com/api/v1'
export const baseurl = {
    dev: 'https://floppy-planets-stand.loca.lt/api/v1',
    p: 'https://api.offingo.com/api/v1',
    a: 'https://offingo-backend.onrender.com/api/v1',
    wifi: `http://${wifi_1}:4000/api/v1`,
    d: 'http://localhost:4000/api/v1',
}[dev ? 'dev' : origin ? 'p' : alpha ? 'p' : wifi ? "wifi" : 'd']