import React from 'react'
import { VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import { getAnalytics } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DateRangeSelector from './components/DateRangeSelector'
import { getPastDate } from '../../utils/moment'
import OverAll from './components/OverAll'

const Analytics = () => {
    const [range, setRange] = React.useState([getPastDate(30), new Date()])

    // const { data, isLoading } = useFetch({
    //     name: 'allAnalytics',
    //     service: getAnalytics,
    //     params: { range: range.join(',') },
    //     conditionalRefetch: [range]
    // })


    return (
        <VStack space={4}>
            <CompHeader title='Analytics' />
            <DateRangeSelector range={range} setRange={setRange} />
            <OverAll range={range} />
        </VStack>
    )
}

export default Analytics