import React from 'react'
import { VStack } from 'native-base';
import CompHeader from '../../commons/CompHeader/CompHeader';
import DataTable from '../../commons/DataTable/DataTable';
import { columns } from './static';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hook/useFetch';
import { getstores } from '../../apis/store';

const StorePendingVerfication = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'StorePendingVerfication',
        service: getstores,
        pagination: true,
        params: { verified: false },
        pagination: true
    })


    return (
        <VStack>
            <CompHeader title="Store Pending Verfication" />

            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default StorePendingVerfication