import React, { useMemo } from 'react'
import { Input, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import useFetch from '../../hook/useFetch';
import useSubmit from '../../hook/useSubmit';
import { useNavigate } from 'react-router-dom';
import CompHeader from '../../commons/CompHeader/CompHeader';
import { getCity, getStates } from '../../apis/misc';
import { editAdsById, createAds, deleteAdsById } from '../../apis/admin';
import SelectField from '../../commons/SelectField';
import { BiCrop, BiTrash } from "react-icons/bi";
import { InputField } from '../../commons/InputField';
import { HStack, Spacer } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import { FiCamera } from 'react-icons/fi';
import { useRef } from 'react';
import { multipledataformater } from '../../helper/multipledataformater';
import { Text } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Image } from '@chakra-ui/react';
import CropAreaSingle from '../../components/compo/CropArea2/CropAreaSingle';
import { IMAGE_BASE_URL } from '../../helper/variables';

const defaultValues = {
    name: '',
    images: [],
    link: '',
    city: '',
    state: '',
    in_app_link: '',
    priority: '',
    start_date: new Date(),
    end_date: new Date(new Date().setDate(new Date().getDate() + 15)),
    active: true,
    extra: {
        urls: [],
        edit: null
    }
}

const CreateADS = ({ data }) => {
    if (data) {
        data.extra = {
            urls: data.images.map(item => `${IMAGE_BASE_URL}${item}`),
            edit: null
        }
    }
    const form = useForm({ defaultValues: data || defaultValues })
    const updateState = (name, value) => form.setValue(name, value)
    const { state, images, extra: { urls, edit } } = form.getValues()
    const navigate = useNavigate()
    const ref = useRef(null);

    const { submit, isLoading } = useSubmit({
        service: data ? editAdsById : createAds,
        id: data?._id,
        onSuccess: () => navigate(-1),
    })

    const Delete = useSubmit({
        service: deleteAdsById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name' })
    const cities = useFetch({
        name: `cities_${state}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: state,
        id: state,
        condition: !!state,
        converterLabel: 'name'
    })

    const save = () => {
        const state = { ...form.getValues() }
        const formdata = multipledataformater({ state, images, })
        submit(formdata)
    }

    const uploadImage = async (e) => {
        let { files } = e.target
        if (!files.length) return
        const file = files?.[0]
        if (!file) return
        const allImages = [...images, file]
        updateState("images", allImages)
        updateState("extra.urls", [...urls, URL.createObjectURL(file)])
        updateState('extra.edit', allImages.length - 1)
        return
    };

    const list = [
        { title: 'Ad id', id: '_id', disabled: true, show: !!data },
        { title: 'Ad Name', id: 'name' },
        { title: 'Active', id: 'active', list: [true, false].map(item => ({ label: item.toString(), value: item })) },
        { title: 'Link', id: 'link' },
        { title: 'In App Link', id: 'in_app_link' },
        { title: 'State', id: 'state', list: states },
        { title: 'City', id: 'city', list: cities },
        { title: 'Priority', id: 'priority', list: [1, 2, 3, 4, 5].map(item => ({ label: item.toString(), value: item.toString(), })) },
        { title: 'Start Date', id: 'start_date', date: true },
        { title: 'End Date', id: 'end_date', date: true },
    ]

    useMemo(() => { }, [form.watch()])

    return (
        <VStack p='3' space='2' w='100%' alignItems={'flex-start'}>
            <CompHeader title={data ? 'Edit Advertisement' : "Create Advertisement"} backButton />

            {images.length !== 1 &&
                <Stack onClick={() => ref.current.click()} color={colors.secondary}
                    w={['150px', "200px"]} border={"2px dashed #E7E6E6"} h={['15em', "20em"]}
                    cursor="pointer" justifyContent={"center"} alignItems={"center"} >
                    <FiCamera />
                    <Text>Add Images</Text>
                </Stack>
            }

            <HStack w={['100%', '500']} space='4' alignItems={'flex-start'}>
                {urls.map((url, index) => (
                    <Stack key={index} color={colors.secondary}
                        w={400} h={225} borderRadius={"md"}
                        position={'relative'} cursor="pointer"
                        justifyContent={"center"} alignItems={"center"} >
                        {/* <Stack cursor={"pointer"}
                            position={'absolute'} top={2} right={2}
                            onClick={() => updateState('extra.edit', index)}
                            backgroundColor={"rgba(255, 255, 255, 0.45)"}
                            color="black" w="fit-content" px={4} py={2}
                            borderRadius={"md"} fontSize={'sm'}
                            direction={"row"} alignItems={"center"} spacing={1} >
                            <BiCrop size={16} />
                        </Stack> */}
                        <Stack cursor={"pointer"}
                            position={'absolute'} top={10} right={2}
                            onClick={() => {
                                updateState('images', [...images.filter((item, i) => i !== index)])
                                updateState('extra.urls', [...urls.filter((item, i) => i !== index)])
                            }}
                            backgroundColor={"rgba(255, 255, 255, 0.45)"}
                            color="black" w="fit-content" px={4} py={2} borderRadius={"md"} fontSize={'sm'}
                            direction={"row"} alignItems={"center"} spacing={1} >
                            <BiTrash size={16} />
                        </Stack>
                        <Image src={url} w={'100%'} h={'100%'} />
                    </Stack>
                ))}
            </HStack>

            {edit !== null &&
                <CropAreaSingle
                    aspect={16 / 9}
                    close={() => updateState('edit', null)}
                    image={images?.[edit]}
                    setImage={async (file) => {
                        updateState('images', [...images.slice(0, edit), file, ...images.slice(edit + 1)])
                        updateState('extra.urls', [...urls.slice(0, edit), URL.createObjectURL(file), ...urls.slice(edit + 1)])
                        updateState('extra.edit', null)
                    }}
                />
            }

            <VStack space='2' w={['100%', '500']}>
                {list.map((item, index) => {
                    const Comp = item.list ? SelectField : InputField
                    return <Comp tw={90} key={index} form={form} {...item} />
                })}

                <HStack>
                    <Button m='auto'
                        colorScheme={data ? 'green' : 'blue'}
                        variant={'solid'}
                        onClick={save}
                        isLoading={isLoading}
                        size='sm'
                    >{data ? 'Update Ad' : "Publish Ad"}</Button>
                    <Spacer />
                    {data && <Button m='auto'
                        variant={'solid'}
                        isLoading={Delete.isLoading}
                        onClick={Delete.submit} size='sm' >
                        Delete Ad
                    </Button>}
                </HStack>
            </VStack>

            <input multiple={false} ref={ref}
                onChange={(e) => {
                    uploadImage(e)
                    e.target.value = null
                }}
                type="file" accept="image/png, image/gif, image/jpeg" hidden />
        </VStack>
    )
}

export default CreateADS