import React from 'react'
import { columns } from './static';
import useFetch from '../../../hook/useFetch'
import { Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { getUserCallRecord } from '../../../apis/admin'
import DataTable from '../../../commons/DataTable/DataTable'

const AllCalls = ({ user, refresh }) => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getUserCallRecord,
        id: user._id,
        conditionalRefetch: [refresh],
        pagination: true,
    })

    return (
        <VStack w='100%' space='2' px={4} alignItems={'flex-start'}>
            <Text fontSize={'md'} fontWeight='600'>
                All Calls
            </Text>

            <DataTable tableData={{
                columns: columns(navigate),
                data: data?.data?.map((item, i) => item.records).flat() || [],
                pagination: true, isLoading
            }} />
        </VStack>
    )
}

export default AllCalls