import { Box, Image, Text, VStack, Button } from "@chakra-ui/react";
import { IMAGE_BASE_URL } from "../../helper/variables";
import { formatDateTimes, fromNow } from "../../utils/moment";

export const columns = (navigate) => [
    {
        name: 'Logo',
        cell: (row) => <Box alignItems={'center'} justifyContent={'center'} >
            <Image resizeMode='contain' p={2} rounded={'2xl'} borderWidth={1}
                cursor='pointer' src={IMAGE_BASE_URL + row?.images?.[0]} h='200' w='100' />
        </Box>,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => `${row?.display_name || row?.name}(${row?.product?.name})`,
        sortable: true,
    },
    {
        name: 'Wear',
        selector: (row) => row?.wear?.wear,
        sortable: true,
    },
    {
        name: 'Store',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'}>
                <Button variant='link' color={'blue.500'} outline={'none'} border={'none'}
                    onClick={() => window.open(`/admin/reviewStore/${row.store._id}`)} >
                    {row.store.store_name}
                </Button>
                <Text fontSize={'10'}>
                    {row?.store?.store_address?.address}
                </Text>
            </VStack>,
        sortable: true,
    },
    {
        name: 'Brand',
        selector: (row) => row?.brand?.brand,
        sortable: true,
    },
    {
        name: 'Images',
        selector: (row) => row?.images?.length,
        sortable: true,
    },
    {
        name: 'Price',
        selector: (row) => row?.price,
        sortable: true,
    },
    {
        name: 'Filters',
        selector: (row) => `${row?.filters?.length}(${row?.filters?.map(({ keys }) => keys)?.flat()?.length})`,
        sortable: true,
    },
    {
        name: 'Created At',
        selector: (row) => `${formatDateTimes(row?.createdAt, 4)}(${fromNow(row?.createdAt)})`,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onClick={() => window.open(`/admin/storeuploads/${row._id}`, '_blank')} >
            Edit</Button>,
        sortable: false,
    },
]
// navigate(`/admin/storeuploads/${row._id}`)}>