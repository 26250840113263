import { VStack } from '@chakra-ui/react'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import useFetch from '../../hook/useFetch'
import { getDuplicateStores } from '../../apis/admin'
import DupliStoreCard from './DupliStoreCard'
import Loading from '../../commons/Loading'
import Tile from '../../commons/Tile'

const DuplicateStores = () => {

    const { data, isLoading, refetch } = useFetch({
        name: 'getDuplicateStores',
        service: getDuplicateStores,
    })

    if (isLoading) return <Loading />

    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title={'Duplicate Stores'} />


            <VStack w="100%" alignItems={'flex-start'} spacing={2}>
                <Tile label={'Total Duplicate Stores'} value={data.length} />


            </VStack>


            <VStack w="100%" alignItems={'flex-start'} spacing={2}>
                {data.map((item, index) =>
                    <DupliStoreCard data={item} key={index} refetch={refetch} />
                )}
            </VStack>

        </VStack>
    )
}

export default DuplicateStores