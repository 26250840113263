import React from 'react'
import { VStack } from 'native-base';
import CompHeader from '../../commons/CompHeader/CompHeader';
import { Button } from '@chakra-ui/react';
import useSubmit from '../../hook/useSubmit';
import { performSupperActions } from '../../apis/admin';
import { HStack } from '@chakra-ui/react';

const SuperActions = () => {

    const actions = [
        { name: 'Clear malls', collections: 'malls', },
        { name: 'Clear stores', collections: 'stores store_products store_notications store_authorities latest_styles offers', },
        { name: 'Clear products', collections: 'products product_wears product_filters', },
        { name: 'Clear Chains', collections: 'chains', },
        { name: 'Clear Brands', collections: 'brands', },
        { name: 'Clear Clothing Types', collections: 'clothing_types', },
        { name: 'Clear Product filters', collections: 'titlekeys keys', },
        { name: 'Clear Wears', collections: 'wear', },
    ]

    const { submit, isLoading } = useSubmit({
        service: performSupperActions,
        onSuccess: () => alert('Done'),
    })

    return (
        <VStack>
            <CompHeader title='Super Actions' />
            <HStack flexWrap={'flex'}>
                {actions.map(({ name, collections }) =>
                    <Button variant={'solid'}
                        colorScheme={'red'}
                        isLoading={isLoading}
                        onClick={() => {
                            if (window.confirm(`Are you sure you want to clear ${collections} collections?`))
                                submit({ name, collections })
                        }}
                        key={name}>
                        {name}
                    </Button>
                )}
            </HStack>
        </VStack>
    )
}

export default SuperActions