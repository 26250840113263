import React, { useState } from 'react'
import { Input, SimpleGrid } from '@chakra-ui/react'
import { colors } from '../../../theme/colors'
import { useParams, useSearchParams } from 'react-router-dom'
import useSubmit from '../../../hook/useSubmit'
import { Box, Button, Checkbox, HStack, Spacer, Text, VStack } from 'native-base'
import { createParametric, deleteParametric, toggleFilterRequired, toggleFilterRequiredInUpload } from '../../../apis/admin'

const FilterCard = ({ data, form, wears, parametric, refetchParametric, product, addRules, setAddRules, }) => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const name = searchParams.get('name')
    const [search, setSearch] = useState('')
    const [showAllGender, setShowAllGenders] = useState(false)
    const { gender, wear } = form.watch()

    let wear_genders = wears.filter(({ wears }) => !!wears.find(({ _id }) => _id === wear)).map(({ gender }) => gender)
    const wearLabel = wears.map(({ wears }) => wears).flat().find(({ _id }) => _id === wear)?.wear
    const genderLabel = wears.map(({ gender }) => gender).flat().find(({ _id }) => _id === gender)?.type
    if (!showAllGender) {
        wear_genders = wear_genders.filter(({ _id }) => _id === gender)
    }

    const keys = data.keys.sort((a, b) => {
        if (a.label < b.label) {
            return -1
        }
        if (a.label > b.label) {
            return 1
        }
        return 0
    })

    const Parametric = useSubmit({
        service: parametric ? deleteParametric : createParametric,
        id: parametric?._id,
        onSuccess: refetchParametric
    })

    const makeParametric = () => {
        const { gender, wear } = form.watch()
        const keys = data.keys
            .filter(({ _id }) => form.watch("keys")?.[gender]?.[data._id]?.includes(_id))
            .map(({ label, _id }) => ({ label: `${label} ${name}`, key: _id }))

        Parametric.submit({ product: id, titleKey: data._id, keys, wear, gender })
    }

    const MakeRequired = useSubmit({
        service: toggleFilterRequired,
        id,
        props: { filter_id: data._id, gender },
        onSuccess: product.refetch
    })

    const MakeRequiredInUpload = useSubmit({
        service: toggleFilterRequiredInUpload,
        id,
        props: { filter_id: data._id, gender },
        onSuccess: product.refetch,
    })

    const setRules = (rules) => {
        const query = Object.keys(rules).map((key) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(rules[key])}`
        }).join('&');
        const url = `${window.location.origin}/admin/product-filters/edit/rules?${query}`;
        window.open(url, '_blank');

    }

    return (
        <VStack bg='white' borderRadius={'lg'} space='2'>
            <HStack space='4' mb='4' alignItems={'center'}>
                <Text fontSize={'lg'} fontWeight='semibold'
                    onPress={() =>
                        addRules && setRules({
                            title: `${name} > ${wearLabel} > Filters > ${genderLabel} > Title Rules > ${data.title}`,
                            type_id: data._id,
                            type: 'title',
                            gender, wear, id,
                            parent: data._id,
                        })
                    }
                >{data.title}</Text>

                <Text color={colors.info} fontWeight={'bold'}
                    cursor={'pointer'} onPress={() => setAddRules(!addRules)}
                >
                    {addRules ? 'Done with rules' : 'Add Rules'}
                </Text>


                <Spacer />

                <Box mr='10'>
                    <Checkbox
                        opacity={product.isLoading ? .5 : 1}
                        pointerEvents={product.isLoading ? 'none' : 'auto'}
                        onChange={() => MakeRequiredInUpload.submit()}
                        isChecked={product.data?.required_filters_in_upload?.find(item => item.gender === gender)?.keys?.includes(data._id)}
                        _text={{ fontSize: 'sm', fontWeight: 'bold' }}>Required in Product Upload</Checkbox>
                </Box>

                <Box mr='10'>
                    <Checkbox
                        opacity={product.isLoading ? .5 : 1}
                        pointerEvents={product.isLoading ? 'none' : 'auto'}
                        onChange={() => MakeRequired.submit()}
                        isChecked={product.data?.required_filters?.find(item => item.gender === gender)?.keys?.includes(data._id)}
                        _text={{ fontSize: 'sm', fontWeight: 'bold' }}>Required in CBS</Checkbox>
                </Box>

                <Button size='sm' isLoading={Parametric.isLoading} colorScheme={parametric ? 'red' : 'blue'}
                    onPress={makeParametric}>{parametric ? 'Remove Parametric' : 'Make Parametric'}</Button>

            </HStack>


            <HStack w={'100%'} bg='white' space={4} alignItems='center'
                borderBottomWidth={1} pb={0} borderColor={colors.border}
            >
                <Text fontWeight={'bold'} fontSize='md'>Options</Text>
                <HStack space='2'>
                    <Checkbox isChecked={showAllGender}
                        onChange={() => setShowAllGenders(!showAllGender)}
                    />
                    <Text>Show All Genders</Text>
                </HStack>
                <Spacer />
                <Input
                    value={search}
                    bg={'white'}
                    borderWidth={0}
                    outline={0}
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Search filters'
                />
            </HStack>

            <VStack space='2' >
                {wear_genders?.map(({ type, _id }) =>
                    <HStack w={'100%'} space='2'>
                        <VStack mb='10'>
                            <Text w='60'>{type}</Text>
                            <Text onPress={() => {
                                form.setValue(`keys[${_id}][${data._id}]`, [...(form.watch(`keys[${_id}]`)?.[data._id] || []), ...data.keys.map(key => key._id)])
                            }} position={'absolute'} color={colors.info} mt='5' fontSize='xs' w='60' >Add All</Text>
                        </VStack>
                        {addRules ?
                            <SimpleGrid columns={[3, 8]} spacing='2' mb='2'>
                                {keys
                                    .filter(({ label }) => label.toLowerCase().startsWith(search.toLowerCase()))
                                    .map(key =>
                                        form.watch(`keys[${_id}]`)?.[data._id]?.includes(key._id) &&
                                        <Text value={key._id}
                                            p={2} borderWidth={1} borderColor={colors.border} borderRadius={4}
                                            h='10' w='130' textAlign='center' _text={{ fontSize: 'sm' }}
                                            onPress={() => setRules({
                                                title: `${name} > ${wearLabel} > Filters > ${type} > Key Rules > ${key.label}`,
                                                type_id: key._id,
                                                type: 'key',
                                                gender: _id,
                                                wear, id,
                                                parent: data._id,
                                                child: key._id
                                            })}
                                        >
                                            {key.label}
                                        </Text>
                                    )}
                            </SimpleGrid>
                            :
                            <Checkbox.Group
                                value={form.watch(`keys[${_id}]`)?.[data._id] || []}
                                onChange={(e) => form.setValue(`keys[${_id}][${data._id}]`, e)} >
                                <SimpleGrid columns={[3, 8]} spacing='2' mb='2'>
                                    {keys.filter(({ label }) => label.toLowerCase().startsWith(search.toLowerCase())).map(key =>
                                        <Checkbox mb='1' w='130'
                                            _text={{ fontSize: 'sm' }} value={key._id}>{key.label}</Checkbox>
                                    )}
                                </SimpleGrid>
                            </Checkbox.Group>
                        }
                    </HStack>
                )}
            </VStack>



        </VStack>
    )
}

export default FilterCard