import React from 'react'
import { VStack } from '@chakra-ui/react'
import CompHeader from '../../../commons/CompHeader/CompHeader'
import { getAllCouponData } from '../../../apis/admin'
import { useParams } from 'react-router-dom'
import useFetch from '../../../hook/useFetch'
import { columns } from './static'
import DataTable from '../../../commons/DataTable/DataTable'
import GetDevData from './GetDevData'

const AllCouponData = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `getAllCouponData_${id}`,
        service: getAllCouponData,
        id
    })

    return (
        <VStack w='100%' alignItems={'flex-start'} >

            <CompHeader title={'All Coupon Data'} />

            <GetDevData data={data} />

            <DataTable tableData={{ columns: columns(), data, isLoading, }} pagination={false} />

        </VStack>
    )
}

export default AllCouponData