import { addAdminOffers, getgender, getoffertemplate, getProducts } from '../../apis/admin';
import { Button, Text, VStack } from '@chakra-ui/react';
import CompHeader from '../../commons/CompHeader/CompHeader';
import GetStoreFromFilters from './GetStoreFromFilters';
import FiltersTiles from '../../commons/FiltersTiles';
import React, { useMemo } from 'react';
import CreateOfferBox from './CreateOfferBox';
import useSubmit from '../../hook/useSubmit';
import useFetch from '../../hook/useFetch';
import { useForm } from 'react-hook-form';
import { colors } from '../../theme/colors';
import FilterSelection from './FilterSelection';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { getURLQuery } from '../../helper/url';

const newOffer = { template_id: '', template: '', start_date: '', end_date: '', action: 'create', offer_applicable: [], description: '', products: [] }
const defaultValues = { brand: "", claimed: true, pre_register: false, type: "", state: [], city: [], mall: "", offer_type: "", chain: "", offers: [newOffer], stores: [], extra: { existInChain: false, } }

const AddAdminOffer = () => {
	const form = useForm({ defaultValues })
	const state = { ...form.getValues() }
	const { offers, stores, brand, } = state

	const { search } = useLocation();
	const store_id = getURLQuery(search, 'store')

	const templates = useFetch({ name: 'templates', service: getoffertemplate, dataOnly: true, params: { limit: 0 } })
	const genders = useFetch({ name: 'genders', service: getgender, dataOnly: true })
	const products = useFetch({ service: getProducts, dataOnly: true, name: 'getProducts', params: { limit: 0 } })

	const Create = useSubmit({
		service: addAdminOffers,
		onSuccess: () => form.reset()
	})

	const createOffer = () => {
		if (!brand)
			return toast.error("Please select a brand!")

		if (!offers.length)
			return toast.error("Please add atleast one offer!")

		if (!stores.length)
			return toast.error("No store available for this filter!")

		let error = ''

		state.offers.map((item, i) => {

			if (!item.start_date)
				error = `Please select start date for ${i + 1}`

			if (!item.end_date)
				error = `Please select end date for ${i + 1}`

			if (!item.offer)
				error = `Please select offer for ${i + 1}`

			if (!item.action)
				error = `Please select action for ${i + 1}`

			if (item.offer_applicable.length === 0)
				error = `Please select offer applicable for offer ${i + 1}`

		})

		if (error) {
			return toast.error(error)
		}

		state.offers = offers.map(item => ({
			...item,
			products: item?.products?.map((item) => item?.value),
			// offer: item?.template_id ? item.template.join(' ') : item.template,
			brand,
		}))

		state.stores = stores.map(item => item._id)

		Create.submit(state)
	}

	useMemo(() => { }, [form.watch()])

	return (
		<VStack spacing='4' alignItems={'flex-start'} w='100%'>
			<CompHeader title="Add Admin Offer" backButton />

			<FilterSelection form={form} store_id={store_id} />
			<FiltersTiles form={form} defaultValues={defaultValues} />

			<GetStoreFromFilters store_id={store_id} filters={{ ...form.getValues(), stores: [] }} form={form} />

			{stores.length &&
				<>
					{offers?.map((offer, i) =>
						<CreateOfferBox key={i} form={form}
							templates={templates} products={products}
							genders={genders}
							offer={offer}
							index={i}
						/>
					)}

					<Text fontSize={'md'} color={colors.secondary}
						_hover={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() => form.setValue('offers', [...offers, newOffer])}>
						Add More Offer
					</Text>

					<Button variant={'solid'} mt='6' w={250}
						bg='blue.400' textColor={'white'}
						onClick={createOffer} isLoading={Create.isLoading}>
						Create
					</Button>

				</>
			}
		</VStack>
	);

}

export default AddAdminOffer;