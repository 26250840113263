import React from 'react';
import Areas from './Areas';
import { VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import MarketDetails from './MarketDetails';
import CompHeader from '../../../commons/CompHeader/CompHeader';

const defaultValues = {
    name: '', images: [''],
    geo_location: { coordinates: {} },
    address: { locality: '', address: '', state: '', city: "", pincode: "" },
    status: '', phone_number: '',
}

const AddMarket = ({ data, refetch }) => {
    if (data) {
        data = {
            ...data,
            geo_location: {
                coordinates: {
                    latitude: data?.geo_location?.coordinates?.[0],
                    longitude: data?.geo_location?.coordinates?.[1],
                }
            },
            address: {
                ...data.address,
                locality: data?.address?.locality,
                address: data?.address?.address,
                city: data?.address?.city?._id,
                state: data?.address?.state?._id,
            }
        }
    }

    const form = useForm({ defaultValues: data || defaultValues })

    return (
        <VStack w="100%" alignItems="flex-start" >
            {!data && <CompHeader title='Add Market' backButton />}

            <MarketDetails form={form} data={data} />
            {data && <Areas data={data} refetch={refetch} />}

        </VStack>
    )
}

export default AddMarket