import Brands from '../Brands';
import Chains from '../Chains';

import OfferTemplate from '../Offer Templates/Index';
import PreRegister from '../../components/PreRegister/PreRegister';
import ReviewStore from '../Stores/ReviewStore/ReviewStore';
import ContextBased from '../ContextBased/ContextBased';
import CreateNewTitleKey from '../ContextBased/CreateNewTitleKey';
import EditTitleKey from '../ContextBased/EditTitleKey';
import EditProductFilter from '../Product/EditProductFilter';
import Genders from '../Genders/Genders';
import CreateGender from '../Genders/CreateGender';
import EditGender from '../Genders/EditGender';
import Stores from '../Stores/Stores';
import Users from '../Users/Users';
import EditUsers from '../Users/EditUsers';
import Subscriptions from '../Subscriptions/Subscriptions';
import CreateSubscriptions from '../Subscriptions/CreateSubscriptions';
import EditSubscriptions from '../Subscriptions/EditSubscriptions';
import EditChain from '../Chains/EditChain';
import CreateChain from '../Chains/CreateChain';
import CreateBrand from '../Brands/CreateBrand';
import EditBrand from '../Brands/EditBrand';
import Malls from '../Malls';
import EditMall from '../Malls/EditMall';
import CreateMall from '../Malls/CreateMall';
import Mods from '../Mods/Mods';
import CreateMods from '../Mods/CreateMods';
import EditMods from '../Mods/EditMods';
import Payments from '../Payments/Payments';
import PaymentsDetails from '../Payments/PaymentsDetails';
import UsersSubscriptions from '../Subscriptions/UsersSubscriptions';
import CreateOfferTemplate from '../Offer Templates/CreateOfferTemplate';
import EditOfferTemplate from '../Offer Templates/EditOfferTemplate';
import Notifications from '../Notifications/Notifications';
import ViewNotifications from '../Notifications/ViewNotifications';
import AdminIntro from '../AdminIntro/AdminIntro';
import ReviewStoreBrand from '../Stores/ReviewStore/ReviewStoreBrand';
import Wear from '../Wear/Wear';
import EditWear from '../Wear/EditWear';
import CreateWear from '../Wear/CreateWear';
import ClothingTypes from '../ClothingTypes/ClothingTypes';
import Product from '../Product/Product';
import AddProduct from '../Product/AddProduct';
import EditProduct from '../Product/EditProduct';
import EditProductWear from '../Product/EditProductWear';
import Offers from '../Offers/Offers';
import EditOffers from '../Offers/EditOffers';
import CreateStoreSubscriptions from '../StoreSubscriptions/CreateStoreSubscriptions';
import CreateOfferTemplateList from '../OfferTemplateList/CreateOfferTemplateList';
import EditStoreSubscriptions from '../StoreSubscriptions/EditStoreSubscriptions';
import EditOfferTemplateList from '../OfferTemplateList/EditOfferTemplateList';
import StoreSubscriptions from '../StoreSubscriptions/StoreSubscriptions';
import OfferTemplateList from '../OfferTemplateList/OfferTemplateList';
import StoreAuthorities from '../StoreAuthorities/StoreAuthorities';
import StoreAuthEdit from '../StoreAuthorities/StoreAuthEdit';
import CBSEnquiryDetails from '../CBS/CBSEnquiryDetails';
import GlobalVars from '../GlobalVars/GlobalVars';
import CBS from '../CBS/CBS';
import StoresMapLines from '../Stores/StoresMapLines';
import Search from '../Search/Search';
import NotFound from '../NotFound/NotFound';
import Flags from '../Flags/Flags';
import FlagsReasons from '../FlagsReasons/FlagsReasons';
import CreateFlagsReasons from '../FlagsReasons/CreateFlagsReasons';
import EditFlagsReasons from '../FlagsReasons/EditFlagsReasons';
import FlagsDetails from '../Flags/FlagsDetails';
import NoAuthStores from '../Stores/NoAuthStores';
import AdminOffers from '../AdminOffers/AdminOffers';
import EditCreatedAdminOffers from '../AdminOffers/EditCreatedAdminOffers';
import AddAdminOffers from '../AdminOffers/AddAdminOffer';
import CreatedAdminOffers from '../AdminOffers/CreatedAdminOffers';
import SuggestedAdminOffers from '../AdminOffers/SuggestedAdminOffers';
import EditSuggestedAdminOffers from '../AdminOffers/EditSuggestedAdminOffers';
import BotActivities from '../BotActivities/BotActivities';
import ADS from '../ADS/ADS';
import CreateADS from '../ADS/CreateADS';
import EditADS from '../ADS/EditADS';
import SuperActions from '../SuperActions/SuperActions';
import Analytics from '../Analytics/Analytics';
import Onboarders from '../Onboarders/Onboarders';
import CreateOnboarders from '../Onboarders/CreateOnboarders';
import EditOnboader from '../Onboarders/EditOnboader';
// import ReportsOnboader from './Onboarders/ReportsOnboader';
import StoreUploads from '../StoreUploads/StoreUploads';
import EditStoreUpload from '../StoreUploads/EditStoreUpload';
import ActiveLocations from '../ActiveLocations/ActiveLocations';
import ProductTree from '../Product/ProductTree';
import EditRules from '../Product/ProductTree/EditRules';
import EditRules2 from '../ContextBased/EditRules';
import QRs from '../QR/QRs';
import CreateQr from '../QR/CreateQr';
import EditQr from '../QR/EditQr';
import LinkShortener from '../LinkShortener/LinkShortener';
import EditLinkShortener from '../LinkShortener/EditLinkShortener';
import AddLinkShortener from '../LinkShortener/AddLinkShortener';
import Review from '../Stores/Review';
import VersionManager from '../VersionManager/VersionManager';
import AppUpdates from '../AppUpdates/AppUpdates';
import StorePendingVerfication from '../StorePendingVerfication/StorePendingVerfication';
import ModsCalls from '../Mods/ModCalls/ModsCalls';
import AvaEnquires from '../AvaEnquires/AvaEnquires';
import EditAvaEnquires from '../AvaEnquires/EditAvaEnquires';
import StoreWithNoOffers from '../StoreWithNoOffers/StoreWithNoOffers';
import ArrangeMeeting from '../ArrangeMeeting/ArrangeMeeting';
import CreateArrangeMeeting from '../ArrangeMeeting/CreateMeeting/CreateArrangeMeeting';
import EditArrangeMeeting from '../ArrangeMeeting/EditArrangeMeeting';
import ReportsOnboader from '../Onboarders/stats/ReportsOnboader';
import StoreStats from '../StoreStats/StoreStats';
import Stories from '../Stories/Stories';
import KPI from '../KPI/KPI';
import ConnOffingo from '../Conn/ConnOffingo/ConnOffingo';
import ConnOffingoBusiness from '../Conn/ConnOffingoBusiness';
import ConnAdminPanel from '../Conn/ConnAdminPanel';
import Conn from '../Conn/Conn';
import ApiCallsGraphs from '../ApiCallsGraphs/ApiCallsGraphs';
import Coupons from '../Coupons/Coupons';
import CreateCoupons from '../Coupons/CreateCoupons';
import EditCoupons from '../Coupons/EditCoupons';
import AdvanceSearchStore from '../AdvanceSearchStore/AdvanceSearchStore';
import DuplicateStores from '../DuplicateStores/DuplicateStores';
import WAMessage from '../WAMessage/WAMessage';
import StoreDump from '../StoreDump/StoreDump';
import WAMessageLogs from '../WAMessageLogs/WAMessageLogs';
import RefLinks from '../RefLinks/RefLinks';
import CreateRefLinks from '../RefLinks/CreateRefLinks';
import WaMessageActions from '../WaMessageActions/WaMessageActions';
import SponsorReels from '../SponsorReels/SponsorReels';
import AddSponsorReels from '../SponsorReels/AddSponsorReels';
import EditSponsorReels from '../SponsorReels/EditSponsorReels';
import WAShare from '../WAShare/WAShare';
import AllCouponData from '../Coupons/AllCouponData/AllCouponData';
import UserAnalyst from '../UserAnalyst/UserAnalyst';
import UserSrc from '../UserSrc/UserSrc';
import StoreOnMap from '../StoreOnMap/StoreOnMap';
import AssignUserCalling from '../AssignUserCalling/AssignUserCalling';
import UserCallingBatches from '../UserCallingBatches/UserCallingBatches';
import MyUserCallingBatches from '../MyUserCallingBatches/MyUserCallingBatches';
import Whatsapp from '../Whatsapp.js/Whatsapp';
import WhatsappConversations from '../WhatsappConversations/WhatsappConversations';
import UserSearchs from '../UserAnalyst/UserSearchs/UserSearchs';
import LinkTypes from '../LinkTypes/LinkTypes';
import GrabbedCoupons from '../Coupons/GrabbedCoupons/GrabbedCoupons';
import Shops from '../Shops/Shops';
import Calls from '../Calls/Calls';
import ProductThumbnails from '../ProductThumbnails/ProductThumbnails';
import Markets from '../Markets/Markets';
import AddMarket from '../Markets/AddMarket/AddMarket';
import EditMarket from '../Markets/EditMarket/EditMarket';
import AppReviews from '../AppReviews/AppReviews';
import CreateNewStore from '../CreateNewStore/CreateNewStore';
import UserJourney from '../UserJourney/UserJourney';
import AdminAlerts from '../AdminAlerts/AdminAlerts';
import Otps from '../Otps/Otps';
import Restaurants from '../Restaurants/Restaurants';
import CreateRestaurant from '../Restaurants/CreateRestaurant/CreateRestaurant';
import FoodCoupons from '../FoodCoupons/FoodCoupons';
import EditFoodCoupon from '../FoodCoupons/EditFoodCoupon/EditFoodCoupon';
import CreateFoodCoupons from '../FoodCoupons/CreateFoodCoupons/CreateFoodCoupons';
import EditRestaurant from '../Restaurants/EditRestaurant/EditRestaurant';
import RedeemCouponReward from '../RedeemCouponReward/RedeemCouponReward';

export const list_public = [
    [AdminIntro, '/'],
    [NotFound, '/404'],
    [LinkTypes, '/link_types/:type'],
]

export const list = [
    [RedeemCouponReward, '/redeem-coupon-reward'],
    [EditFoodCoupon, '/food_coupons/edit/:id'],
    [CreateFoodCoupons, '/food_coupons/create'],
    [FoodCoupons, '/food_coupons'],
    [Restaurants, '/restaurants'],
    [EditRestaurant, '/restaurants/edit/:id'],
    [CreateRestaurant, '/restaurants/create'],
    [Otps, '/otps'],
    [AppReviews, '/app_reviews'],
    [AdminAlerts, '/admin_alerts'],
    [Markets, '/markets'],
    [AddMarket, '/markets/create'],
    [EditMarket, '/markets/edit/:id'],
    [ProductThumbnails, '/product/thumbnails/:id'],
    [SuperActions, '/superactions'],
    [Calls, '/calls'],
    [Flags, '/flags'],
    [FlagsDetails, '/flags/details/:id'],
    [FlagsReasons, '/flags/reasons'],
    [CreateFlagsReasons, '/flags/reasons/create'],
    [EditFlagsReasons, '/flags/reasons/edit/:id'],
    [Search, '/search'],
    [GlobalVars, '/globals/vars'],
    // [UsersSubscriptions, '/subscriptions/users/:id'],
    [StoreSubscriptions, '/subscriptions/store'], [EditStoreSubscriptions, '/subscriptions/store/edit/:id'],
    [CreateStoreSubscriptions, '/subscriptions/store/new'], [UsersSubscriptions, '/subscriptions/user/users/:id'],
    [Subscriptions, '/subscriptions/user'], [EditSubscriptions, '/subscriptions/user/edit/:id'], [CreateSubscriptions, '/subscriptions/user/new'],
    [Wear, '/wear'], [EditWear, '/wear/edit/:id'], [CreateWear, '/wear/create'],
    [Payments, '/payments'], [PaymentsDetails, '/payments/:id'],
    [Notifications, '/notifications'], [ViewNotifications, '/notifications/view'],
    [Brands, '/brands'], [CreateBrand, '/brands/create'], [EditBrand, '/brands/edit/:id'],
    [Malls, '/malls'], [CreateMall, '/malls/create'], [EditMall, '/malls/edit/:id'],
    [Chains, '/chains'], [EditChain, '/chains/edit/:id'], [CreateChain, '/chains/create'],
    [Mods, '/mods'], [EditMods, '/mods/edit/:id'],
    [CreateMods, '/mods/create'], [ModsCalls, '/mods/calls/:id'],
    [Genders, '/genders'], [CreateGender, '/genders/create'], [EditGender, '/genders/edit/:id'],
    [Users, '/users'], [EditUsers, '/users/edit/:id'],
    [UserJourney, '/users/journey/:id'],
    [ClothingTypes, '/clothingTypes'], [PreRegister, '/pre-register'],
    [Stores, '/stores'], [NoAuthStores, '/stores/noAuthStores'],
    [StoresMapLines, '/stores/mapline'],
    [StoreAuthorities, '/stores/authorities'], [StoreAuthEdit, '/stores/authorities/edit/:id'],
    [Offers, '/offers'], [EditOffers, '/offers/edit/:id'],
    [OfferTemplate, '/offerTemplate'], [CreateOfferTemplate, '/offerTemplate/create'], [EditOfferTemplate, '/offerTemplate/edit/:id'],
    [OfferTemplateList, '/offerTemplateList'], [CreateOfferTemplateList, '/offerTemplateList/create'], [EditOfferTemplateList, '/offerTemplateList/edit/:id'],
    [EditCreatedAdminOffers, '/system_offers/created/edit/:id'],
    [EditSuggestedAdminOffers, '/system_offers/suggested/edit/:id'],
    [AddAdminOffers, '/system_offers/create'], [AdminOffers, '/system_offers'],
    [CreatedAdminOffers, '/system_offers/created'], [SuggestedAdminOffers, '/system_offers/suggested'], [AdminOffers, '/system_offers'],
    [Product, '/product'], [ProductTree, '/product/tree/:id'], [AddProduct, '/product/create'], [EditProduct, '/product/edit/:id'],
    [EditProductWear, '/product/wear/edit/:id/:name'],
    [EditProductFilter, '/product/filter/edit/:id/:name'],
    // [ReviewStore, '/reviewStore/:id'],
    // [ReviewStoreBrand, '/reviewStore/:store_id/brand/:brand_id'],
    [Review, '/reviewStore/:id'],
    // [ReviewStoreBrand, '/reviewStore/:store_id/brand/:brand_id'],
    [ContextBased, '/product-filters'],
    [CreateNewTitleKey, '/product-filters/create'],
    [EditTitleKey, '/product-filters/edit/:id'],
    [EditRules, '/product-filters/edit/rules'],
    // [EditRules2, '/product-filters/edit/:id/rules/:rule_type/:type/:type_id'],
    [CBS, '/CBS'], [CBSEnquiryDetails, '/CBS/:id'],
    [BotActivities, '/bot-activities'],
    [ADS, '/ads'],
    [CreateADS, '/ads/create'],
    [EditADS, '/ads/edit/:id'],
    [Analytics, '/analytics'],
    [Onboarders, '/onboarders'],
    [CreateOnboarders, '/onboarders/create'],
    [EditOnboader, '/onboarders/edit/:id'],
    [ReportsOnboader, '/onboarders/reports/:id'],
    [StoreUploads, '/storeuploads'],
    [EditStoreUpload, '/storeuploads/:id'],
    [ActiveLocations, '/activelocations'],
    [QRs, '/qr'],
    [CreateQr, '/qr/create'],
    [EditQr, '/qr/edit/:id'],
    [LinkShortener, '/link_shortener'],
    [EditLinkShortener, '/link_shortener/edit/:id'],
    [AddLinkShortener, '/link_shortener/create'],
    [VersionManager, '/version_manager'],
    [AppUpdates, '/app_updates'],
    [StorePendingVerfication, '/store_verification_pending'],
    [AvaEnquires, '/ava_enquires'],
    [EditAvaEnquires, '/ava_enquires/edit/:id'],
    [StoreWithNoOffers, '/store_with_no_offers'],
    [AdvanceSearchStore, '/advance_search_store'],
    [Shops, '/shops'],
    // [ArrangeMeeting, '/arrange_meeting'],
    // [CreateArrangeMeeting, '/arrange_meeting/create'],
    // [EditArrangeMeeting, '/arrange_meeting/edit/:id'],
    [StoreStats, '/store_stats'],
    [Stories, '/stories'],
    [KPI, '/kpi'],
    [Conn, '/conn'],
    [ConnOffingo, '/conn/offingo'],
    [ConnOffingoBusiness, '/conn/offingo_business'],
    [ConnAdminPanel, '/conn/admin_panel'],
    [ApiCallsGraphs, '/api_calls/graph'],
    [Coupons, '/coupons'],
    [CreateCoupons, '/coupons/create'],
    [AllCouponData, '/coupons/all_coupon_data/:id'],
    [EditCoupons, '/coupons/edit/:id'],
    [DuplicateStores, '/duplicate_stores'],
    [WAMessage, '/wa_messages'],
    [WAMessageLogs, '/wa_messages_logs'],
    [StoreDump, '/store_dump'],
    [RefLinks, '/ref_link'],
    [CreateRefLinks, '/ref_link/create'],
    [WaMessageActions, '/wa_messages_actions'],
    [SponsorReels, '/sponsor_reels'],
    [EditSponsorReels, '/sponsor_reels/edit/:id'],
    [AddSponsorReels, '/sponsor_reels/create'],
    [WAShare, '/wa_share'],
    [UserSrc, '/user_src'],
    [StoreOnMap, '/store_on_map'],
    [UserAnalyst, '/analytics/user_analyst'],
    [UserSearchs, '/analytics/user_search'],
    // [MyUserCalling, '/user_calling/my'],
    // [AssignUserCalling, '/user_calling/assign'],
    [UserCallingBatches, '/user_calling_batches'],
    [MyUserCallingBatches, '/user_calling_my_batches'],
    [Whatsapp, '/whatsapp'],
    [WhatsappConversations, '/whatsapp_conversations'],
    [GrabbedCoupons, '/grabbed_coupons'],
    [CreateNewStore, '/create_new_store'],
]

export const getList = (profile) => {
    if (!profile) return []
    let routes = []
    const { role, permissions } = profile

    if (role?.toLowerCase() === 'admin') {
        routes = list
    } else {
        const _list = []
        list.map(([_, path]) => {
            let _path = path.split('/:id')[0]
            _path = _path.split('/').slice(1).join('/')
            if (permissions.includes(_path))
                _list.push([_, path])
        })
        routes = _list
    }
    return [...routes, ...list_public]
}

export const app_actions_filters = 'marketing research view edit create admin'
export const getAppActionFilters = () => app_actions_filters.split(' ')