import { VStack } from 'native-base'
import React from 'react'
import { useParams } from 'react-router-dom'
import { getProductById } from '../../apis/admin'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import AddProduct from './AddProduct'

const EditProductName = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `product_${id}`,
        service: getProductById,
        id,
    })

    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2' w={'100%'} maxW={500}>
            <AddProduct data={data} />
        </VStack>
    )
}

export default EditProductName