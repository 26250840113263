import { Text, VStack } from '@chakra-ui/react'
import React from 'react'

const WearCard = ({ item }) => {
    return (
        <VStack space='2' w={'fit-content'}
            bg='#cfcfcf4D' borderRadius='md'
            py='2' px='2' mb='4'
        >
            <Text textAlign={'center'} fontSize='sm' fontWeight='normal'>{item.wear}</Text>
        </VStack>
    )
}

export default WearCard