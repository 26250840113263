import { Button, Checkbox, HStack, Input, Select, Text } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import DatePicker from "react-datepicker";
import React from 'react';
import { CheckboxGroup } from '@chakra-ui/react';
import { formatDateTimes } from '../../utils/moment';

export const createOfferlist = ({ form, i, templates, products, genders, offer }) => {
    return [
        {
            type: 'select', title: 'Action', id: `offers.[${i}].action`,
            list: ['suggest', 'create'].map(item => ({ label: item, value: item })),
        },
        {
            type: 'input', title: 'Genders',
            body: <HStack alignItems={'center'}>
                {genders?.map(item =>
                    <CheckboxGroup value={offer.offer_applicable} onChange={val => form.setValue(`offers.[${i}].offer_applicable`, val)} >
                        <HStack>
                            <Checkbox value={item._id}>
                                <Text>{item.type}</Text>
                            </Checkbox>
                        </HStack>
                    </CheckboxGroup>
                )}
            </HStack>
        },
        // {
        //     type: 'select', title: 'Templates', value: offer.template_id,
        //     list: templates.map(item => ({ label: item.template, value: item._id })),
        //     onChange: (val) => {
        //         form.setValue(`offers.[${i}].template_id`, val)
        //         form.setValue(`offers.[${i}].template`, templates.find(item => item._id === val)?.template.split(' '))
        //     }
        // },
        {
            type: 'select', placeholder: 'Select products',
            title: 'Products', id: `offers.[${i}].products`,
            list: products?.map(({ name, _id }) => ({ value: _id, label: name })),
            multi: true
        },
        {
            type: 'input', title: 'Offer', id: `offers.[${i}].offer`,
            // body: offer.template_id &&
            //     <HStack alignItems='center' spacing='4'>
            //         {templates
            //             .find(item => item._id === offer.template_id)?.template
            //             .split(' ').map((obj, j) => {
            //                 if (obj === '_') {
            //                     const list = templates.find(item => item._id === offer.template_id).value.find(item => item.value === j)?.list?.options?.map(item => item.trim())

            //                     const props = {
            //                         value: offer.template[j] === '_' ? '' : offer.template[j],
            //                         onChange: e => form.setValue(`offers.[${i}].template.[${j}]`, e.target.value),
            //                         placeholder: list ? 'Select' : 'Enter',
            //                     }

            //                     return list ?
            //                         <Select {...props} >
            //                             {list?.map(item => <option value={item}>{item}</option>)}
            //                         </Select>
            //                         :
            //                         <Input {...props} />
            //                 }
            //                 return <Text>{obj}</Text>
            //             })}
            //     </HStack>

        },
        {
            type: 'input', title: 'Start Date',
            body: <DatePicker
                selected={offer.start_date}
                onChange={(date) => form.setValue(`offers.[${i}].start_date`, date)}
                customInput={
                    <Input
                        variant={"outline"}
                        size="lg"
                        id="offer"
                        placeholder="From"
                        bg={colors.inputBg}
                    />
                }
            />
        },
        {
            type: 'input', title: 'End Date',
            body: <DatePicker
                dropdownMode
                selected={offer.end_date}
                onChange={(date) => form.setValue(`offers.[${i}].end_date`, date)}
                customInput={
                    <Input
                        variant={"outline"}
                        size="lg"
                        id="offer"
                        placeholder="From"
                        bg={colors.inputBg}
                    />
                }
            />
        },
        {
            type: 'input', title: 'Description', id: `offers.[${i}].description`,
            placeholder: "Enter your Terms & Conditions here"
        },
    ]
}



export const offerTypes = [
    ["factory-outlet", 'factory-outlet: My Store is a Factory Outlet and we generally have better offers than retail stores'],
    ['brand-supervised', 'brand-supervised: Brand Supervised Discounts (Our Discounts are similar across all the stores of the brand)'],
    ['other', 'Other (Discounts in our store are generally different from other stores of same Brand)'],
].map(([value, label]) => ({ label, value }))

export const offerTypesChain = [
    ['chain-supervised', 'Chain Supervised Discounts (Our Discounts are similar across all the stores of the chain)'],
    ['other', 'Other (Discounts in our store are generally different from other stores of same Brand)'],
].map(([value, label]) => ({ label, value }))

export const storeTypes = [
    ["exclusive", "Exclusive Brand Store (EBO)", "The Store has apparels of only 1 single brand."],
    ["branded", "Branded + Non-Branded/Generic-Brand Clothing Available", "The store has both branded and non-branded/local-brand apparels."],
    ["multibranded", "Multiple Brands Store (MBO)", "The store has only branded clothing of various (more than 1) brands."],
    ["non-branded", "Non-Branded or Clothing by Regional Designer Available", "The store has generic brand clothing or clothing designed by regional designers."],
    ["designer", "Designer Store", "The store has clothing by a designer only."],
].map(([value, label]) => ({ label, value }))

export const brandTypes = [
    ["big", "Big Brand", 'Big Brand'],
    ["small", "Small Brand", 'Small Brand'],
].map(([value, label]) => ({ label, value }))

export const chainTypes = [
    ["primary", "Larger Chain", "Select Larger Chain if your Chain is well recognized, has good number of stores in india and is spread out in various cities."],
    ["secondary", "Smaller Chain", "Select Smaller Chain if you have fewer stores spread out in limited number of cities"],
].map(([value, label, desc]) => ({ label, value, desc }))

export const storeStructure = [
    ["chain", "Multi Store", " This Store is Part of a Chain or has Multiple Branches By same Name"],
    ["single store", "Single Standalone Store", "This store is not part of a chain and has no other branches."],
].map(([value, label]) => ({ label, value }))


export const createdAdminOffersColumns = (navigate) => [
    {
        name: 'Offers Added',
        selector: (row) => row.offers.length,
        sortable: true,
    },
    {
        name: 'Applied by',
        selector: (row) => row.mod?.name,
        sortable: true,
    },
    {
        name: 'Role',
        selector: (row) => row.mod?.role,
        sortable: true,
    },
    {
        name: 'Last update',
        selector: (row) => formatDateTimes(row.updatedAt, 4),
        sortable: true,
    },
    {
        name: 'Started',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onClick={() => navigate(`/admin/system_offers/created/edit/${row._id}`)}
        >
            View</Button>,
        sortable: false,
    },
]

export const suggestionsAdminOffersColumns = (navigate) => [
    {
        name: 'Offers Added',
        selector: (row) => row.offers.length,
        sortable: true,
    },
    {
        name: 'Applied by',
        selector: (row) => row.mod?.name,
        sortable: true,
    },
    {
        name: 'Role',
        selector: (row) => row.mod?.role,
        sortable: true,
    },
    {
        name: 'Last update',
        selector: (row) => formatDateTimes(row.updatedAt, 4),
        sortable: true,
    },
    {
        name: 'Started',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    // {
    //     name: 'Actions',
    //     button: true,
    //     cell: (row) => <Button
    //         size='sm' variant={'link'}
    //     // onPress={() => navigate(`/admin/flags/details/${row._id}`)}
    //     >
    //         View</Button>,
    //     sortable: false,
    // },
]