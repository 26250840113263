import { useEffect } from 'react'
import { io } from "socket.io-client"
import { baseurl } from '../../apis/url'
import sound from '../../assets/audio/noti.mp3'
import toast from 'react-hot-toast'

const useAppUpdator = ({ setData }) => {

    const playSound = ({ text = `New Activity` }) => {
        const audio = new Audio(sound)
        audio.play()
        toast(text,
            {
                icon: '🔔',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
    }



    useEffect(() => {
        const socket = io(baseurl.split('/api/v1')[0])
        socket.on("logincode-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'logincode-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });
        socket.on("store-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'store-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });
        socket.on("offer-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'offer-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });
        socket.on("style-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'style-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });
        socket.on("enq1-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'enq1-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });
        socket.on("enq2-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'enq2-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });
        socket.on("enq3-new", (data, id) => {
            if (data) {
                setData(_data => [{ ...data, socket_id: id, socket_type: 'enq3-new' }, ..._data].filter(i => i))
                playSound({})
            }
        });

        return () => {
            socket.off("logincode-new");
            socket.off("store-new");
            socket.off("offer-new");
            socket.off("style-new");
            socket.off("enq1-new");
            socket.off("enq2-new");
            socket.off("enq3-new");
        }
    }, []);

    return
}

export default useAppUpdator