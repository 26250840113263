import React from 'react'
import { Box, Button, Checkbox, CheckboxGroup, HStack, Text, VStack } from '@chakra-ui/react'
import { getWearProducts } from '../../../apis/admin'
import useFetch from '../../../hook/useFetch'
import Loading from '../../../commons/Loading'

const GenderWearSelection = ({ gender, selected, setSelected, stitched = false }) => {

    const { data, isLoading } = useFetch({
        service: getWearProducts,
        name: `getWearProducts-${gender?._id}`,
        props: { gender: gender?._id },
        conditionalRefetch: [gender?._id],
        condition: !!gender?._id
    })

    const product_str = stitched ? 'pd_sch' : 'pd_rmd';

    if (isLoading) return <Box w='100%' borderWidth={1} p={4} h='25%'
        bg={'white'} borderRadius='lg' borderColor={'gray.200'} >
        <Loading />
    </Box>


    return (
        <VStack w='100%' alignItems={'flex-start'} borderBottomWidth={2} pb={1}>
            <HStack alignItems={'center'} w='100%' justifyContent={'space-between'} >
                <Text fontSize='lg' flex={1} fontWeight={'bold'} color='black' >{gender.type}</Text>
                <Button
                    onClick={() => {
                        const wears = data.map(({ _id }) => _id)
                        let newSelected = [...selected]
                        wears.map((_id, _index) => {
                            const products = data.find(({ _id: id }) => id === _id)?.products || []
                            let index = newSelected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                            if (index === -1) {
                                newSelected = [
                                    ...newSelected,
                                    { wear: _id, [product_str]: [], gender: gender?._id }
                                ]
                            }
                            index = newSelected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                            newSelected[index][product_str] = products.map(({ _id }) => _id)
                        })
                        setSelected(newSelected)
                    }}
                >
                    Select all from {gender.type}
                </Button>
                <Button
                    onClick={() => {
                        const wears = data.map(({ _id }) => _id)
                        let newSelected = [...selected]
                        wears.map((_id, _index) => {
                            let index = newSelected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                            if (index === -1) {
                                newSelected = [
                                    ...newSelected,
                                    { wear: _id, [product_str]: [], gender: gender?._id }
                                ]
                            }
                            index = newSelected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                            newSelected[index][product_str] = []
                        })
                        setSelected(newSelected)
                    }}
                >
                    Clear all from {gender.type}
                </Button>
            </HStack>
            {data.map(({ wear, products, _id }) =>
                <VStack space={1} alignItems={'flex-start'} w='100%'
                    borderBottomWidth={1} pb={1} borderColor={'gray.200'} >
                    <HStack alignItems={'center'} w='100%' justifyContent={'space-between'} >
                        <Text fontSize='sm' fontWeight={'semibold'} flex={1} color='black'>{wear}</Text>
                        <Button variant='outline' size='xs'
                            onClick={() => {
                                const _products = products.map(({ _id }) => _id)
                                const index = selected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                                if (index === -1) {
                                    setSelected([...selected, { wear: _id, [product_str]: _products, gender: gender?._id }])
                                    return;
                                }
                                const newWearProducts = [...selected]
                                newWearProducts[index][product_str] = _products
                                setSelected(newWearProducts)
                            }}
                        >
                            Select all from {wear}
                        </Button>
                        <Button variant='outline' size='xs'
                            onClick={() => {
                                const index = selected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                                if (index === -1) {
                                    setSelected([...selected, { wear: _id, [product_str]: [], gender: gender?._id }])
                                    return;
                                }
                                const newWearProducts = [...selected]
                                newWearProducts[index][product_str] = []
                                setSelected(newWearProducts)
                            }}
                        >
                            Clear all from {wear}
                        </Button>
                    </HStack>
                    <CheckboxGroup
                        value={selected?.find(({ wear, gender: g }) => wear === _id && g === gender?._id)?.[product_str] || []}
                        colorScheme='red'
                        onChange={(values) => {
                            const index = selected.findIndex(({ wear, gender: g }) => wear === _id && g === gender?._id)
                            if (index === -1) {
                                setSelected([...selected, { wear: _id, [product_str]: values, gender: gender?._id }])
                                return;
                            }
                            const newWearProducts = [...selected]
                            newWearProducts[index][product_str] = values
                            setSelected(newWearProducts)
                        }}
                    >
                        <HStack space='4' flexWrap={'wrap'}>
                            {products?.map(item =>
                                <Checkbox key={item._id} value={item._id} >
                                    {item.name}
                                </Checkbox>
                            )}
                        </HStack>
                    </CheckboxGroup>
                </VStack>
            )}

        </VStack>
    )
}

export default GenderWearSelection