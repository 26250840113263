import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, Box, } from '@chakra-ui/react'

const Model = ({
    title = 'Are you sure ?',
    show = true,
    close = () => { },
    body = <></>
}) => {
    return (
        show && <Modal blockScrollOnMount={false} isOpen={show} onClose={close}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <Box onClick={close}>
                    <ModalCloseButton />
                </Box>
                {body}
            </ModalContent>
        </Modal>
    )
}

export default Model