import { formatDateTimes } from "../../utils/moment";

export const columns = () => [
    {
        name: 'user',
        selector: (row) => row.user.name,
        sortable: true,
    },
    {
        name: 'Phone Number',
        selector: (row) => row.user.phone_number,
        sortable: true,
    },
    {
        name: 'tags',
        selector: (row) => row.tags.join(', '),
        sortable: true,
    },
    {
        name: 'Review',
        selector: (row) => row.review,
        sortable: true,
    },

    {
        name: 'Created At',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
] 