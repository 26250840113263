
export const columns = (navigate) => [
    {
        name: 'name',
        selector: (row) => row?.user?.name,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Phone Number',
        selector: (row) => row?.user?.phone_number,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Keys',
        selector: (row) => Object.entries(row?.url).map(([key, value]) => `${key}_${value}`).join(', '),
        sortable: true,
        width: '80%',
    },
]