import { Text, VStack } from '@chakra-ui/react'
import React from 'react'

const ProductCard = ({ item }) => {
    return (
        <VStack space='2' w={'fit-content'}
            bg='#cfcfcf4D' py='2' px='2' borderRadius='md'
        >
            <Text textAlign={'center'} fontSize='sm' fontWeight='normal'>{item.name}</Text>
        </VStack>
    )
}

export default ProductCard