import { VStack } from '@chakra-ui/react'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import useFetch from '../../hook/useFetch'
import { getStoreDump } from '../../apis/admin'
import Loading from '../../commons/Loading'
import StoreDumpCard from './StoreDumpCard'

const StoreDump = () => {

    const { data, isLoading, refetch } = useFetch({
        name: 'getStoreDump',
        service: getStoreDump,
    })

    if (isLoading) return <Loading />

    return (
        <VStack alignItems={'flex-start'} w={'100%'} >
            <CompHeader title={'Store Dump'} />
            {data.map((store, i) =>
                <StoreDumpCard key={i} data={store} refetch={refetch} />
            )}
        </VStack>
    )
}

export default StoreDump