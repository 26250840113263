import React from 'react'
import Stats from './Stats';
import { columns } from './static'
import useFetch from '../../../hook/useFetch'
import Loading from '../../../commons/Loading'
import { Text, VStack } from '@chakra-ui/react'
import { getPastDate } from '../../../utils/moment'
import { getOnboardersStats } from '../../../apis/admin'
import { useNavigate, useParams } from 'react-router-dom'
import DataTable from '../../../commons/DataTable/DataTable'
import CompHeader from '../../../commons/CompHeader/CompHeader'
import DateRangeSelector from '../../Analytics/components/DateRangeSelector'

const ReportsOnboader = () => {
    const firstDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    const [range, setRange] = React.useState([firstDate, new Date()])

    const navigate = useNavigate()
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: 'getOnboardersStats',
        service: getOnboardersStats,
        id,
        params: { date1: range[0], date2: range[1] },
        conditionalRefetch: [range]
    })

    if (isLoading) return <Loading />

    return (
        <VStack w='100%' alignItems={'flex-start'} >
            <CompHeader title="Reports Onboader" />

            <DateRangeSelector range={range} setRange={setRange} />

            <Stats data={data} />

            <VStack w='100%' alignItems={'flex-start'} >
                <Text fontSize='lg' fontWeight='bold' textAlign='center' mb={4} >
                    Total Store: {data?.logs?.length}
                </Text>
                <DataTable tableData={{ columns: columns(navigate), forceLimit: 1000, data: data?.logs, isLoading }} />
            </VStack>

            {/* details */}
            {/* 
                <Tile titleWidth={200} label='Name' value={data?.onBoarder?.name} />
                <Tile titleWidth={200} label='Phone Number' value={data?.onBoarder?.phone_number} />
                <Tile titleWidth={200} label='Total Store Registered' value={data?.logs?.total} />
                <Tile titleWidth={200} label='Store Pending' value={data?.logs?.pending} />
                <Tile titleWidth={200} label='Store Approved' value={data?.logs?.approved} />
                <Tile titleWidth={200} label='Store Rejected' value={data?.logs?.rejected} /> 
            */}
        </VStack>
    )
}

export default ReportsOnboader