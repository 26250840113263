import React from 'react'
import { VStack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import { getmod } from '../../apis/admin';
import useFetch from '../../hook/useFetch'

const Mods = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'mods', service: getmod, })
    return (
        <VStack>
            <CompHeader
                title='Moderators'
                buttonTitle='Create New Moderators'
                onPress={() => navigate(`/admin/mods/create`)}
            />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading }} />
        </VStack>
    )
}

export default Mods