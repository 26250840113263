import React from 'react'
import { columns } from './static';
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { appReviews } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable';
import CompHeader from '../../commons/CompHeader/CompHeader'

const AppReviews = () => {
    const { data, isLoading } = useFetch({
        name: 'appReviews',
        service: appReviews,
        pagination: true,
    })

    console.log('Manjodh Saran', data)

    return (
        <VStack w="100%" alignItems="flex-start" >
            <CompHeader title='App Reviews' />
            <DataTable tableData={{ columns: columns(), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default AppReviews