import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useForm } from 'react-hook-form'
import { createRefLinks } from '../../apis/admin'
import useSubmit from '../../hook/useSubmit'
import toast from 'react-hot-toast'


const obj = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: ''
}

const CreateRefLinks = () => {
    const form = useForm(obj)
    const state = form.watch()

    const [link, setLink] = useState('')


    const { submit, isLoading } = useSubmit({
        service: createRefLinks,
        onSuccess: (res) => {
            if (res?.final_link)
                setLink(res.final_link)
        }
    })

    return (
        <VStack alignItems={'flex-start'} width={'100%'}  >
            <CompHeader title={'Create Referral Links'} backButton />

            <VStack alignItems={'flex-start'} width={'100%'} bg={'white'} p={4} borderRadius={4} spacing={4}>

                {link ?
                    <VStack alignItems={'flex-start'} width={'100%'} bg={'white'}
                        p={4} borderRadius={4} spacing={2} border={'1px solid gray'} >
                        <Text fontWeight={'semibold'} fontSize={'md'} color={'gray.500'} >
                            Your Link:
                        </Text>
                        <Text fontSize={'2xl'} color={'blue.500'} cursor={'pointer'}
                            onClick={() => {
                                navigator.clipboard.writeText(link)
                                toast('Copied to clipboard', { icon: '📋' })
                            }}
                            wordBreak={'break-all'} w={'100%'} textAlign={'left'}>
                            {link}
                        </Text>
                    </VStack>
                    :
                    <>
                        <HStack alignItems={'center'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Compaign(event):
                            </Text>
                            <Input variant='filled' borderColor='gray.300'
                                placeholder='Campaign(event)' value={state.utm_campaign}
                                onChange={e => form.setValue('utm_campaign', e.target.value)}
                            />
                        </HStack>


                        <HStack alignItems={'center'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Source:
                            </Text>
                            <Input variant='filled' borderColor='gray.300'
                                placeholder='Source' value={state.utm_source}
                                onChange={e => form.setValue('utm_source', e.target.value)}
                            />
                        </HStack>

                        <HStack alignItems={'center'} spacing={2}>
                            <Text w={60} fontWeight={'semibold'}>
                                Medium:
                            </Text>
                            <Input variant='filled' borderColor='gray.300'
                                placeholder='Medium' value={state.utm_medium}
                                onChange={e => form.setValue('utm_medium', e.target.value)}
                            />
                        </HStack>
                        <Button onClick={() => submit({ ...form.getValues() })} colorScheme='blue' isLoading={isLoading} mt={4} >
                            Create
                        </Button>
                    </>
                }
            </VStack>
        </VStack>
    )
}

export default CreateRefLinks