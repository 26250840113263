import { Box, Image, Text } from "@chakra-ui/react";
import { Button } from "native-base";
import { IMAGE_BASE_URL } from "../../helper/variables";

export const columns = (navigate) => [
    {
        name: 'Edit',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/product/tree/${row._id}?name=${row.name}`)}>
            Edit</Button>,
        sortable: false,
    },
    {
        name: 'Image',
        button: true,
        cell: (row) => row.images?.[0] ? <Image
            alt="Product Image"
            w='50px' h='100px'
            src={`${IMAGE_BASE_URL}${row.images?.[0]}`} />
            :
            <Box w='50px' h='100px' borderWidth={1} borderStyle={'dashed'} borderColor={'gray.300'} borderRadius={5} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                <Text
                    color={'gray.500'}
                    fontSize={'sm'}
                    textAlign={'center'}
                    fontWeight={'bold'}
                    w={'100%'}
                >
                    No Image
                </Text>

            </Box>
        ,
        sortable: false,
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
    },
    // {
    //     name: 'Readymade',
    //     cell: (row) => <Text color={row.clothing_types.readymade ? colors.success : colors.dark}>
    //         {row.clothing_types.readymade ? "Yes" : "No"}
    //     </Text>,
    //     sortable: true,
    // },
    // {
    //     name: 'Stitched',
    //     cell: (row) => <Text color={row.clothing_types.stitched ? colors.success : colors.dark}>
    //         {row.clothing_types.stitched ? "Yes" : "No"}
    //     </Text>,
    //     sortable: true,
    // },
] 