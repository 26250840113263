import { createbrands, deleteBrand, editbrandsbyid, getchains } from '../../apis/admin'
import CropArea from '../../components/compo/CropArea/CropArea'
import { HStack, Spacer, VStack } from 'native-base'
import { IMAGE_BASE_URL } from '../../helper/variables'
import { InputField } from '../../commons/InputField'
import SelectField from '../../commons/SelectField'
import React, { useEffect, useState } from 'react'
import ProductSelection from './ProductSelection'
import { useNavigate } from 'react-router-dom'
import useSubmit from '../../hook/useSubmit'
import 'react-image-picker/dist/index.css'
import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/react';
import LinkedStoresList from '../../components/LinkedStoresList/LinkedStoresList'
import useFetch from '../../hook/useFetch'

const defaultValues = { brand: '', logo: '', company: '', clothing: '', status: '', type: '', products: [] }
const staticData = { status: ["Active", "Pending"], type: ["primary", "secondary"] }

const AddBrand = ({ data }) => {
    const form = useForm({ defaultValues: data ? data : defaultValues })
    const [image, setImage] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (!data) return
        setImage(image ? image : (data ? `${IMAGE_BASE_URL}${data.logo}` : image))
    }, [data])

    const Delete = useSubmit({
        service: deleteBrand,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const { submit, isLoading } = useSubmit({
        service: data ? editbrandsbyid : createbrands,
        id: data?._id,
        onSuccess: () => navigate(-1),
    })
    const { data: chainData, isLoading: chainLoading } = useFetch({ name: 'chains', service: getchains, params: { limit: 0 } })

    const save = () => {
        const formdata = new FormData();
        const state = { ...form.getValues() }
        Object.keys(state).map((obj) => {
            if (Array.isArray(state[obj])) {
                state[obj].map((item) => {
                    if (typeof item === "object") {
                        return formdata.append(obj, JSON.stringify(item));
                    }
                    formdata.append(obj, item);
                });
            } else if (!Array.isArray(state[obj]) && typeof state[obj] === "object") {
                formdata.append(obj, JSON.stringify(state[obj]));
            }
            if (typeof state[obj] === "string") {
                formdata.append(obj, state[obj]);
            }
        })
        formdata.append("images", state.logo);
        submit(formdata)
    }

    return (
        <VStack p='3' space='2' >

            <CropArea
                //  circularCrop
                defaultImage={image}
                removeImage={() => { setImage(null); form.setValue('logo', null) }}
                setImage={(file) => form.setValue('logo', file)}
            />

            <VStack space='2' w={'100%'} maxW={500}>
                {data && <InputField form={form} title='Brand id' id='_id' disabled />}
                <InputField form={form} title='Brand Name' id='brand' autoCapatilize />
                <SelectField form={form} list={staticData.status.map(item => ({ label: item, value: item }))} title='Status' id='status' />
                {!chainLoading &&
                    <SelectField form={form} list={chainData?.data?.map(item => ({ label: item.name, value: item._id }))} title='Chain' id='chain' />
                }
                {/* <SelectField form={form} list={staticData.type.map(item => ({ label: item, value: item }))} title='Type' id='type' /> */}
            </VStack>

            <ProductSelection form={form} />
            {data && <LinkedStoresList data={data?.stores} type='brand' />}

            <HStack alignItems={'center'}>
                <Button isLoading={isLoading}
                    // backgroundColor={data ? 'green.900' : 'blue.400'}
                    onClick={save}
                    size='sm'
                >{data ? 'Update Brand' : "Add Brand"}</Button>
                <Spacer />
                {data && <Button
                    m='auto'
                    disabled={data?.stores?.length !== 0}
                    variant={'solid'}
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Brand
                </Button>}
            </HStack>
        </VStack>
    )
}

export default AddBrand