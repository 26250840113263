import React from 'react'
import { columns } from './static'
import AddMarketArea from './AddMarketArea'
import Model from '../../../components/compo/UI/Model'
import DataTable from '../../../commons/DataTable/DataTable'
import { contProps, contPropsTitle } from '../../../helper/variables'
import { Button, HStack, Spacer, Text, VStack } from '@chakra-ui/react'

const Areas = ({ data, refetch }) => {
    const [open, setOpen] = React.useState(null)
    const edit = (id) => { setOpen(id) }
    const area = data?.areas?.find(area => area?._id === open)

    return (
        <VStack {...contProps}>
            <HStack w='100%' justifyContent='space-between' alignItems='center'>
                <Text {...contPropsTitle}>
                    {data.name} Areas
                </Text>
                <Spacer />
                <Button onClick={() => setOpen('new')} colorScheme='blue'>Add Area</Button>
            </HStack>
            <DataTable tableData={{ columns: columns(edit), data: data.areas, pagination: false }} pagination={false} />
            {open && <Model
                size='full'
                title={`${data.name} - ${open === 'new' ? `Add Area` : `Edit'${area?.name}' Area`}`}
                show={open}
                close={() => setOpen(null)}
                body={<AddMarketArea market_id={data._id} data={area} refetch={refetch} close={() => setOpen(null)} />}
            />}
        </VStack>
    )
}

export default Areas