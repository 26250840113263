import React from 'react'
import { Text, VStack, HStack, Button, Input } from '@chakra-ui/react'

const StoreKeywords = ({ form }) => {
    const state = form.watch()
    const { keywords } = state
    const [newKeyword, setNewKeyword] = React.useState('')

    return (
        <VStack w={"100%"} space={3}>

            <Text fontWeight={'semibold'} fontSize='md' >
                Keywords
            </Text>

            <HStack flexWrap={'wrap'} w='100%' space={2} alignItems='center'>
                {keywords?.map((keyword, index) => (
                    <HStack key={index} space={2} alignItems='center' borderWidth={1} pl={"2"} borderRadius={"md"}>
                        <Text>{keyword}</Text>
                        <Button size='xs' colorScheme='red' onClick={() => {
                            form.setValue('keywords', keywords.filter((_, i) => i !== index))
                        }}>
                            X
                        </Button>
                    </HStack>
                ))}
            </HStack>

            <HStack w='100%' space={2} alignItems='center'>
                <Input value={newKeyword} onChange={(e) => setNewKeyword(e.target.value)} />
                <Button size='xs' colorScheme='teal' onClick={() => {
                    form.setValue('keywords', [...new Set([...keywords, ...newKeyword?.split(',')])])
                    setNewKeyword('')
                }}>
                    Add
                </Button>
            </HStack>

        </VStack>
    )
}

export default StoreKeywords