import React from 'react'
import { Text, VStack } from 'native-base'
import useFetch from '../../../hook/useFetch'
import { HStack, Spacer } from '@chakra-ui/react'
import { getCity, getStates } from '../../../apis/misc'
import SelectField from '../../../commons/SelectField'
import { InputField } from '../../../commons/InputField'
import { getMarketsByCity } from '../../../apis/admin'

const StoreAddress = ({ form, viewOnly }) => {
    const state = form.watch()
    const { store_address: { state: storeState, city: storeCity, market } } = state

    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name', params: { limit: 0 } })

    const cities = useFetch({
        name: `cities_${storeState}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: [storeState],
        id: storeState,
        condition: !!storeState,
        converterLabel: 'name'
    })

    const markets = useFetch({
        name: `market_${storeCity}`,
        service: getMarketsByCity, dataOnly: true,
        conditionalRefetch: [storeCity],
        id: storeCity,
        condition: !!storeCity,
    })

    const list = [
        { title: 'Address', id: 'store_address.address' },
        { title: 'Locality', id: 'store_address.locality' },
        { title: 'Landmark', id: 'store_address.landmark' },
        { title: 'Pincode', id: 'store_address.pincode' },
        { visiblity: true, title: 'State', id: 'store_address.state', list: states },
        { visiblity: state.state?.length === 1, title: 'City', id: 'store_address.city', list: cities },
        {
            title: 'Market', id: 'store_address.market', list: markets.map(i => ({ value: i._id, label: i.name })),
            onChange: (val) => {
                form.setValue('store_address.market', val)
                form.setValue('store_address.area', '')
            }
        },
        { title: 'Area', id: 'store_address.area', list: markets.find(i => i._id === market)?.areas.map(i => ({ value: i._id, label: i.name })) },
    ].map(item => ({ ...item, disabled: viewOnly || item.disabled, }))

    return (
        <VStack space={3} w='100%' alignItems='flex-start'>

            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' >
                    Store Address
                </Text>
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('store_address', form.watch('default.store_address'))
                        }}>
                        Reset Store Address
                    </Text>}
            </HStack>

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}
        </VStack>
    )
}

export default StoreAddress