import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hook/useFetch';
import { getAds } from '../../apis/admin';
import DataTable from '../../commons/DataTable/DataTable';
import { columns } from './static';

const ADS = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'ads',
        service: getAds,
        pagination: true,
    })

    return (
        <VStack>
            <CompHeader title="Offingo Advertisements"
                buttonTitle='Add Advertisement'
                onPress={() => navigate('/admin/ads/create')}
            />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default ADS