import React from 'react'
import { VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import AddBrand from './AddBrand'
import { useParams } from 'react-router-dom';
import { getbrandsbyid } from '../../apis/admin';
import Loading from '../../commons/Loading';
import useFetch from '../../hook/useFetch';

const EditBrand = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `brand_${id}`,
        service: getbrandsbyid,
        props: { id },
    })

    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2' >
            <CompHeader title='Edit Brand' backButton />
            <AddBrand data={data} />
        </VStack>
    )
}

export default EditBrand