import React from 'react'
import FiltersTiles from './FiltersTiles'
import FilterSelection from './FilterSelection'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Text, VStack } from '@chakra-ui/react'

const MultipurposeFilter = ({ form, defaultValues, isLoading, getData, type = 'noOffers' }) => {
    return (
        <VStack w='100%' space='2' alignItems={'flex-start'} bg={'white'} borderRadius={10} borderWidth={2} borderColor={'gray.200'} >

            <Accordion w='100%' allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Text fontSize='lg' fontWeight='bold' color='gray.700'>
                                    Filters
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <VStack w='100%' space='2' alignItems={'flex-start'}>
                            <FilterSelection form={form} type={type} />
                            <FiltersTiles form={form} defaultValues={defaultValues} />
                            <Button onClick={getData} isLoading={isLoading} >
                                Get Stores
                            </Button>
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </VStack>
    )
}

export default MultipurposeFilter