import React, { useEffect } from 'react'
import FilterCard from './FilterCard'
import { VStack } from '@chakra-ui/react'
import { useFilterRules } from './useFilterRules'

export const FilterSelection = ({
    product, gender, wear,
    selection,
    onFilterFetch,
    changeSelection,
    type,
    expandable,
    setRequiredFilters = () => { },
}) => {
    const { reqFilters, nonReqFilters, selectedKeys } = useFilterRules({
        product, gender, wear,
        selection,
        onFilterFetch,
        changeSelection
    })

    useEffect(() => {
        if (reqFilters?.length) setRequiredFilters(reqFilters)
    }, [reqFilters?.length])

    const commonProps = { selection, selectedKeys, changeSelection, multiple: true }

    const ReqFilters = <FilterCard {...commonProps} filters={reqFilters}
        title={`Required Filters* (${reqFilters?.length})`}
    />

    const OptionalFilters = <FilterCard {...commonProps} filters={nonReqFilters}
        title={`Optional Filters (${nonReqFilters?.length})`}
        expandable
    />

    const allFilters = [...reqFilters, ...nonReqFilters]
    const Filters = <FilterCard {...commonProps} filters={allFilters}
        title={`Filters (${allFilters?.length})`}
        expandable={expandable}
    />

    if (type === 'req') return ReqFilters
    if (type === 'nonReq') return OptionalFilters
    if (type === 'all') return Filters

    return (
        <VStack w={'100%'} alignItems={'flex-start'} >
            {ReqFilters}
            {OptionalFilters}
        </VStack>
    )
}