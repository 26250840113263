import { Button, Image } from 'native-base';
import { IMAGE_BASE_URL } from '../../../helper/variables';
import { Text, VStack } from '@chakra-ui/react';

export const columns = (edit) => [
    {
        name: 'Image',
        cell: (row) => <Image resizeMode='contain' borderRadius={'lg'} cursor='pointer' src={IMAGE_BASE_URL + row.images?.[0]} h='50' w='50' />,
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row) => row.status === 'Active' ? 'Verified' : 'Not-Verified',
        sortable: true,
    },
    {
        name: 'Coordinates',
        cell: (row) =>
            <Text fontSize="xs"
                onClick={() => {
                    const lat = row?.geo_location?.coordinates?.[0]
                    const lng = row?.geo_location?.coordinates?.[1]
                    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
                }}
                cursor={'pointer'}
                _hover={{ color: 'blue.500' }}
            >
                {row?.geo_location?.coordinates?.[0]},{row?.geo_location?.coordinates?.[1]}
            </Text>
    },
    {
        name: 'Phone Number',
        selector: (row) => row?.phone_number,
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button size='sm' variant={'link'} onPress={() => edit(row._id)}>
            Edit</Button>,
        sortable: false,
    },
]