import { VStack } from '@chakra-ui/react'
import React from 'react'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import { useNavigate } from 'react-router-dom'

const AllStores = ({ data, isLoading }) => {
    const navigate = useNavigate()

    return (
        <VStack
            overflowX={'scroll'}
            maxW='100vw' w='100vw'
        >
            <DataTable
                tableData={{ columns: columns(navigate), data, isLoading }} pagination={false} />
        </VStack>
    )
}

export default AllStores