import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import PreRegisterSession from './PreRegisterSession'
// import Register from './Register'

const PreRegister = () => {
    return (
        <VStack>
            <CompHeader title='Pre Register' />
            <PreRegisterSession />
            {/* <Register /> */}
        </VStack>
    )
}

export default PreRegister