import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box, } from '@chakra-ui/react'

const Model = ({
    title = 'Are you sure ?',
    show = true,
    close,
    size,
    body = <></>
}) => {

    const props = {
        isOpen: show,
        onClose: close,
        ...size && { size },
        blockScrollOnMount: false,
    }

    return (
        <Modal {...props} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                {close && <Box onClick={close}>
                    <ModalCloseButton />
                </Box>}
                {body}
            </ModalContent>
        </Modal>
    )
}

export default Model