import { VStack } from 'native-base'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form';
import { InputField } from '../../commons/InputField';
import StringDivider from '../../commons/StringDivider'
import { Button, HStack, Text, } from '@chakra-ui/react';
import { capitalize } from '../../helper/stringOperations'
import { getglobalvarsByType, updateGlobalVariableList } from '../../apis/admin';
import useSubmit from '../../hook/useSubmit';
import useFetch from '../../hook/useFetch';

const GlobalVarUpdater = ({ type }) => {
    const form = useForm({ defaultValues: { type, values: [] } })
    const { values } = form.getValues()

    const { submit } = useSubmit({ service: updateGlobalVariableList })

    const { data } = useFetch({
        name: `getglobalvars_${type}`,
        service: getglobalvarsByType,
        id: type,
    })

    useEffect(() => {
        if (!data || values.length > 0) return
        form.setValue('values', data)
    }, [data])

    const onSubmit = () => {
        const state = form.getValues()
        state.values = state.values.filter(value => value !== '')
        submit(state)
    }

    useMemo(() => { }, [form.watch()])

    return (
        <VStack space='4' p={4} borderRadius={'xl'} bg='white'>
            <Text fontSize={'xl'} fontWeight={'semibold'} >{capitalize(type)}</Text>

            <StringDivider value={values} onChange={val => form.setValue('values', val)} />
            <VStack space='2'>
                {values?.map((item, index) => (
                    <HStack space='2' alignItems={'center'}>
                        <InputField form={form} title={`values ${index + 1}`}
                            autoCapatilize id={`values[${index}]`} />
                        <Button
                            variant={'solid'} borderRadius='md' h='6'
                            w={'6'} bg='red.400' alignItems={'center'}
                            justifyContent={'center'}
                            onClick={() => {
                                const { values } = form.getValues()
                                values.splice(index, 1)
                                form.setValue('values', values)
                            }}>
                            <Text color='white'>-</Text>
                        </Button>
                    </HStack>
                ))}

                <Button
                    w='fit-content' alignSelf={'flex-end'}
                    size='sm' variant={'link'}
                    onClick={() => {
                        const { values } = form.getValues()
                        values.push('')
                        form.setValue('values', values)
                    }}>
                    Add More
                </Button>
            </VStack>

            <Button
                w='fit-content' alignSelf={'flex-end'}
                size='sm' variant={'solid'}
                onClick={onSubmit}>
                Save
            </Button>
        </VStack>
    )
}

export default GlobalVarUpdater