export const colors = {
    primary: '#FE3D3E',
    secondary: '#838DA6',
    border: '#E7E6E6',
    input: '#CAC9C966',
    dark: '#2e2e2e',
    light: '#ffffff',
    background: '#ffffff',
    inputBg: '#F5F5F5',
    error: '#FE3D3E',
    warning: '#FFA500',
    success: '#00C851',
    info: '#33b5e5'
}