import { Button } from '@chakra-ui/react'
import { VStack } from 'native-base'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteCreatedAdminOffersByID } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import useSubmit from '../../hook/useSubmit'

const EditCreatedAdminOffers = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    // const form = useForm()

    // const templates = useFetch({ name: 'templates', service: getoffertemplate, dataOnly: true })
    // const genders = useFetch({ name: 'genders', service: getgender, dataOnly: true })
    // const products = useFetch({ name: 'getProducts', service: getProducts, dataOnly: true })

    // const { data } = useFetch({
    //     service: getCreatedAdminOffersByID,
    // })

    const Delete = useSubmit({
        service: deleteCreatedAdminOffersByID,
        onSuccess: () => navigate(-1),
        id,
    })

    return (
        <VStack spacing='4' alignItems={'flex-start'} w='100%'>
            <CompHeader title="Edit Admin Created Offer" backButton />
            <Button variant={'solid'} bg='red' onClick={Delete.submit}>
                Delete these offers
            </Button>

            {/* {offers?.map((offer, i) =>
                <CreateOfferBox key={i} form={form}
                    templates={templates}
                    products={products}
                    genders={genders}
                    offer={offer}
                    index={i} />
            )} */}

        </VStack>
    )
}

export default EditCreatedAdminOffers