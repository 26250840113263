import React from 'react'
import { isValid } from './helper'
import { useForm } from 'react-hook-form'
import ProductImages from './ProductImages'
import ProductDetails from './ProductDetails'
import ProductsFilters from './ProductsFilters'
import useSubmit from '../../../hook/useSubmit'
import { VStack, Button, Box } from '@chakra-ui/react'
import { editLatestStylesById } from '../../../apis/admin'
import toast from 'react-hot-toast'
import { multipledataformater } from '../../../helper/multipledataformater'
import { useNavigate } from 'react-router-dom'
import KeywordInput from '../../../components/KeywordsInput/KeywordInput'
import Checks from './Checks'

const EditProduct = ({ data, refetch }) => {
    const form = useForm({ defaultValues: data })
    const navigate = useNavigate()

    React.useEffect(() => {
        form.reset(data)
    }, [data])

    const { submit, isLoading } = useSubmit({
        service: editLatestStylesById,
        id: data?._id,
        onSuccess: () => {
            navigate(-1)
        }
    })

    const onClick = () => {
        const state = form.watch()

        const errorStr = isValid(state)

        if (errorStr) {
            toast.error(errorStr)
            return
        }

        const _state = {
            ...state,
            filtersData: undefined,
            display_name: state.display_name?.trim(),
            store: state.store._id,
            brand: state.brand.value,
            wear: state.wear.value,
            gender: state.gender.value,
            product: state.product.value,
            brand: state.brand.value,
            images: state.images.filter((image) => typeof image === 'string'),
            discount_price: state.discount_price ? state.discount_price : 0,
        }


        delete _state?.offers
        const formdata = multipledataformater({
            state: _state,
            images: state.images.filter((image) => typeof image !== 'string')
        })

        submit(formdata)

    };

    const state = form.watch()

    return (
        <VStack w="100%" alignItems="flex-start" justifyContent="flex-start" pb={20}>
            <ProductImages form={form} refetch={refetch} />
            <Checks form={form} />
            <ProductDetails form={form} />
            <KeywordInput tags={state.tags}
                onKeywordsChange={(newKeywords) => form.setValue('tags', newKeywords)}
            />
            <ProductsFilters form={form} />
            <Box h={10} />
            <Button isLoading={isLoading} onClick={onClick}
                colorScheme="teal" variant="solid" w="50%" alignSelf={'center'}>
                Save
            </Button>
        </VStack>
    )
}

export default EditProduct