import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader';
import { useNavigate } from 'react-router-dom';
import { columns } from './static';
import DataTable from '../../commons/DataTable/DataTable';
import { getnotification } from '../../apis/admin';
import Loading from '../../commons/Loading';
import useFetch from '../../hook/useFetch';

const ViewNotifications = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'notifications',
        service: getnotification,
    })

    return (
        <VStack>
            <CompHeader title='Notifications History' backButton />
            {isLoading ? <Loading /> :
                <DataTable tableData={{ columns: columns(navigate), data }} />}
        </VStack>
    )
}

export default ViewNotifications