import React from 'react'
import { useForm } from 'react-hook-form';
import useSubmit from '../../hook/useSubmit';
import { VStack, Button } from 'native-base';
import { useNavigate } from 'react-router-dom';
import SelectField from '../../commons/SelectField';
import { createnotification } from '../../apis/admin';
import { InputField } from '../../commons/InputField';
import CompHeader from '../../commons/CompHeader/CompHeader';

const defaultValues = { title: '', description: '', payload: { url: '' }, type: 'user' }

const types = [
    { label: 'Offingo Business', value: 'store' },
    { label: 'Offingo', value: 'user' },
]

const Notifications = () => {
    const navigate = useNavigate()
    const form = useForm({ defaultValues })

    const { submit, isLoading } = useSubmit({
        name: 'createNotification',
        service: createnotification,
    })

    return (
        <VStack>
            <CompHeader
                title='Notifications'
                buttonTitle='Notifications History'
                onPress={() => navigate(`/admin/notifications/view`)}
            />
            <VStack space='4'>
                <InputField form={form} title='Title' id='title' />
                <InputField form={form} title='Description' id='description' />
                <InputField form={form} title='URL' id='payload.url' />
                <SelectField form={form} title='App' id='type' list={types} />
                <Button w='200' size='sm' isLoading={isLoading} onPress={() => submit({ ...form.getValues() })}>Submit </Button>
            </VStack>
        </VStack>
    )
}

export default Notifications