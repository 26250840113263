import { HStack, Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../../helper/url'
import { colors } from '../../../theme/colors'

const ModCallsTabs = ({ as, id }) => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const status = getURLQuery(search, 'type') || 'user';

    const list = [
        // ['All', '/admin/mod/calls', 'all'],
        ['User', `/admin/mods/calls/${id}?type=user`, 'user'],
        ['Store', `/admin/mods/calls/${id}?type=store`, 'store'],
    ]

    return (
        <HStack mb='2' space='4'>
            {list.map(([name, link, active]) =>
                <Text key={link} fontWeight='600' fontSize='lg'
                    color={as ? as === active ? colors.dark : colors.secondary : status === active ? colors.dark : colors.secondary}
                    onPress={() => navigate(link)}>{name}</Text>
            )}
        </HStack>
    )
}

export default ModCallsTabs