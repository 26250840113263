import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import AddWear from './AddWear'

const CreateWear = () => {
    return (
        <VStack p='3' space='2' >
            <CompHeader title='Create New Wear' backButton />
            <AddWear />
        </VStack>
    )
}

export default CreateWear