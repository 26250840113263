import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import AddBrand from './AddBrand'

const CreateBrand = () => {
    return (
        <VStack p='3' space='2' >
            <CompHeader title='Create New Brand' backButton />
            <AddBrand />
        </VStack>
    )
}

export default CreateBrand