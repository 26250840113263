import React from 'react'
import { VStack } from 'native-base'
import { useNavigate } from 'react-router-dom'
import { getgender } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { columns } from './static'

const Genders = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({ name: 'genders', service: getgender, })

    if (isLoading) return <Loading />

    return (
        <VStack space='4'>
            <CompHeader title='Genders'
                buttonTitle='Create New Gender' onPress={() => navigate(`/admin/genders/create`)}
            />
            <DataTable tableData={{ columns: columns(navigate), data }} />
        </VStack>
    )
}

export default Genders