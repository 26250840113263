import { formatDateTimes, fromNow } from '../../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Assigned By(Mod)',
        selector: (row) => row?.assigned_by?.name,
        sortable: true,
    },

    {
        name: 'Coupon',
        selector: (row) => `${row?.coupon?.name}(${row?.coupon?.code})`,
        sortable: true,
    },
    {
        name: 'Valid Till',
        selector: (row) => `${formatDateTimes(row?.coupon?.expiry, 2)}(${fromNow(row?.coupon?.expiry)})`,
        sortable: true,
    },
    {
        name: 'Redeemed',
        selector: (row) => row?.redeemed ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Redeemed At',
        selector: (row) => row?.redeemed ? `${formatDateTimes(row?.redeemed_at, 4)}(${fromNow(row?.redeemed_at)})` : 'Not Redeemed',
        sortable: true,
    },
    {
        name: 'Assigned At',
        selector: (row) => `${formatDateTimes(row?.createdAt, 4)}(${fromNow(row?.createdAt)})`,
        sortable: true,
    },
] 