import { formatDateTimes } from '../../utils/moment';
import { IMAGE_BASE_URL } from '../../helper/variables';
import { Image, Text, VStack, Button } from '@chakra-ui/react';

export const columns = (navigate) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Image
            // zoom on hover
            _hover={{ cursor: 'pointer', transform: 'scale(1.1)' }}
            transition={'all .2s ease-in-out'}
            borderRadius={'lg'}
            src={`${IMAGE_BASE_URL}${row?.style?.images?.[0]}`} w={'100px'} h={'100px'} />,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onClick={() => navigate(`/admin/ava_enquires/edit/${row._id}`)}>
            Details
        </Button>,
        sortable: false,
    },
    {
        name: 'User Count',
        selector: (row) => row.user?.length,
        sortable: true,
        width: '200px'
    },
    {
        name: 'User Names',
        selector: (row) => row.user?.map((user) => user.name).join(', '),
        sortable: true,
        width: '200px'
    },
    {
        name: 'User Phone Numbers',
        selector: (row) => row.user?.map((user) => user.phone_number).join(', '),
        sortable: true,
        width: '200px'
    },
    {
        name: 'Store',
        cell: (row) =>
            <VStack
                spacing={0}
                alignItems={'flex-start'}
            >
                <Button variant='link' color={'blue.500'} outline={'none'} border={'none'}
                    onClick={() => window.open(`/admin/reviewStore/${row.store._id}`)}
                >
                    {row.store.store_name}
                </Button>
                <Text
                    fontSize={'10'}
                >
                    {row?.store?.store_address?.address}
                </Text>
            </VStack>,
        sortable: true,
    },
    {
        name: 'Store Auth',
        width: '250px',
        cell: (row) =>
            <VStack alignItems={'flex-start'} justifyContent={'flex-start'} w={'100%'} >
                {row.auths?.map((auth, index) =>
                    <Text key={auth._id} fontSize={'md'} fontWeight={'500'} >
                        {`${index + 1}. ${auth.name}-${auth.phone_number}(${auth.role})`}
                    </Text>
                )}
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'status',
        button: true,
        cell: (row) => <Text color={row.status === 'approved' ? 'green.500' : 'red.500'} >
            {row.status}
        </Text>,
        sortable: true,
    },
    {
        name: 'Updated at',
        selector: (row) => formatDateTimes(row.updatedAt, 4),
        sortable: true,
        width: '200px'
    },
    {
        name: 'Created at',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
        width: '200px'
    },
]