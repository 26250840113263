import { useForm } from "react-hook-form";
import { Button, VStack } from "native-base";
import { colors } from "../../../theme/colors";
import useSubmit from "../../../hook/useSubmit";
import React, { useRef, useState } from "react";
import { updateStoreImage } from "../../../apis/store";
import { IMAGE_BASE_URL } from "../../../helper/variables";
import CropArea from '../../../components/compo/CropArea/CropArea';
import { useMediaQuery, Text, Stack, HStack } from "@chakra-ui/react";

const StoreImages = ({ store }) => {
    const { submit, isLoading } = useSubmit({ service: updateStoreImage, id: store._id })

    const [large] = useMediaQuery("(min-width: 1280px)")
    const form = useForm({ mode: 'onBlur', defaultValues: { images: store.store_images } })
    const [images, setImages] = useState([...store.store_images.map(item => `${IMAGE_BASE_URL}${item}`)])

    const updateState = (id, value) => form.setValue(id, value)
    const state = form.watch()

    const ref = useRef(null);

    const deleteImage = (index) => {
        setImages((state) => {
            state = state.filter((_, i) => i !== index)
            return [...state]
        })
        updateState('images', [...state.images.filter((obj, i) => i !== index)])
    };

    const uploadImage = async (image, index) => {
        const state_images = state.images
        state_images[index] = image
        updateState('images', state_images)
        const url_images = images
        url_images[index] = URL.createObjectURL(image)
        setImages([...url_images])
    };

    const save = () => {
        const formdata = new FormData()

        state.images.forEach((image, index) => {
            if (typeof image === 'string') {
                formdata.append('ids', image)
            } else {
                formdata.append('images', image)
            }
        })

        submit(formdata)
    }

    return (
        <VStack space='2' borderWidth={1} p='4' bg={colors.light} borderRadius='lg' borderColor={colors.border}>
            <Stack spacing={3}>
                <Text fontSize={'md'} fontWeight='600'>Store Images</Text>
                <HStack w='100%' h='100%' overflowX={'scroll'} alignItems="flex-start">
                    {new Array(5).fill(0).map((_, index) => {
                        return (
                            <CropArea aspect={16 / 9}
                                defaultImage={images[index]}
                                removeImage={() => deleteImage(index)}
                                setImage={(file) => uploadImage(file, index)}
                                width={90 * 2} height={160 * 2}
                            />
                        )
                    })}
                </HStack>
                <Button mt='2' w='150' bg='red.500' size='sm' colorScheme={'red'} onPress={save} isLoading={isLoading} >Save Store Images</Button>
            </Stack>
        </VStack>
    )
}

export default StoreImages