import { createmalls, deleteMall, editmallsbyid } from '../../apis/admin'
import CropArea from '../../components/compo/CropArea/CropArea'
import { IMAGE_BASE_URL } from '../../helper/variables'
import { InputField } from '../../commons/InputField'
import { getCity, getStates } from '../../apis/misc'
import { Spacer, VStack } from 'native-base'
import SelectField from '../../commons/SelectField'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSubmit from '../../hook/useSubmit'
import 'react-image-picker/dist/index.css'
import useFetch from '../../hook/useFetch'
import { useForm } from 'react-hook-form'
import { HStack } from '@chakra-ui/react'
import Maps from './Maps'
import { Button } from '@chakra-ui/react';
import LinkedStoresList from '../../components/LinkedStoresList/LinkedStoresList';

const defaultValues = {
    mall_name: '', image: '',
    geo_location: { coordinates: {} },
    mall_address: { locality: '', address: '', state: '', city: "", pincode: "" },
    status: ''
}

const staticData = { status: [["Active", "Verified"], ["Pending", "Not-Verified"]] }

const AddMall = ({ data }) => {
    if (data) {
        data = {
            ...data,
            geo_location: {
                coordinates: {
                    latitude: data?.geo_location?.coordinates?.[0],
                    longitude: data?.geo_location?.coordinates?.[1],
                }
            },
            mall_address: {
                ...data.mall_address,
                locality: data?.mall_address?.locality,
                address: data?.mall_address?.address,
                city: data?.mall_address?.city?._id,
                state: data?.mall_address?.state?._id,
            }
        }
    }
    const form = useForm({ defaultValues: data || defaultValues })
    // const [image, setImage] = useState(null)
    const state = { ...form.getValues() }
    const navigate = useNavigate()

    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name' })
    const cities = useFetch({
        name: `cities_${form.getValues()?.mall_address?.state}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: form.getValues()?.mall_address?.state,
        id: form.getValues()?.mall_address?.state,
        condition: !!form.getValues()?.mall_address?.state,
        converterLabel: 'name'
    })

    // useEffect(() => {
    //     if (!data) return
    //     setImage(image ? image : (data ? `${IMAGE_BASE_URL}${data.image}` : image))
    // }, [data])

    const { submit, isLoading } = useSubmit({
        service: data ? editmallsbyid : createmalls,
        id: data?._id,
        onSuccess: () => navigate(-1),
    })

    const Delete = useSubmit({
        service: deleteMall,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const save = () => {
        const formdata = new FormData();
        const state = { ...form.getValues() }
        Object.keys(state).map((obj) => {
            if (Array.isArray(state[obj])) {
                state[obj].map((item) => {
                    if (typeof item === "object") {
                        return formdata.append(obj, JSON.stringify(item));
                    }
                    formdata.append(obj, item)
                });
            } else if (!Array.isArray(state[obj]) && typeof state[obj] === "object") {
                formdata.append(obj, JSON.stringify(state[obj]));
            }
            if (typeof state[obj] === "string") {
                formdata.append(obj, state[obj]);
            }
        });
        formdata.append("images", state.image)
        submit(formdata)
    }

    const list = [
        { title: 'Mall id', id: '_id', disabled: true, show: !!data },
        { title: 'Mall Name', id: 'mall_name' },
        { title: 'Phone Number', id: 'phone_number' },
        { title: 'Latitude', id: 'geo_location.coordinates.latitude' },
        { title: 'Longtitude', id: 'geo_location.coordinates.longitude' },
        { title: 'Address', id: 'mall_address.address' },
        { title: 'Locality', id: 'mall_address.locality' },
        { title: 'Status', id: 'status', list: staticData.status.map(([value, label]) => ({ label, value })) },
        { title: 'State', id: 'mall_address.state', list: states },
        { title: 'City', id: 'mall_address.city', list: cities },
        { title: 'Pincode', id: 'mall_address.pincode' },
    ]
    const image = form.watch('image')
    return (
        <VStack p='3' space='2' >

            {/* <CropArea
                {...image && { defaultImage: image }}
                removeImage={() => { setImage(null); form.setValue('image', null) }}
                setImage={(file) => form.setValue('image', file)}
            /> */}

            <CropArea aspect={1}
                defaultImage={image ? `${IMAGE_BASE_URL}${image}` : null}
                removeImage={() => form.setValue(`image`, null)}
                setImage={(file) => form.setValue(`image`, file)}
                width={400} height={600}
            />

            {/* <Maps coordinates={state?.geo_location?.coordinates}
                setCoordinates={(val) => form.setValue('geo_location.coordinates', val)}
            /> */}

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}

            {data && <LinkedStoresList data={data?.stores} type='mall' />}

            <HStack>
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={save}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update Mall' : "Add Mall"}</Button>
                <Spacer />
                {data && <Button m='auto'
                    disabled variant={'solid'}
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Mall
                </Button>}
            </HStack>
        </VStack>
    )
}

export default AddMall