import { CgListTree } from 'react-icons/cg';
import { HiVariable } from 'react-icons/hi';
import { FcAdvertising } from 'react-icons/fc';
import { GiCrossedChains } from 'react-icons/gi';
import { SiMicrosoftteams } from 'react-icons/si';
import { IoLocationSharp } from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io';
import { FaUsers, FaQrcode, FaAd } from 'react-icons/fa';
import { BsGenderAmbiguous, BsSearch } from 'react-icons/bs';
import { RiBuilding2Line, RiProductHuntLine } from 'react-icons/ri';
import { TbDiscount2, TbBrandBing, TbLivePhoto, } from 'react-icons/tb';
import { MdEditNotifications, MdOutlineReport, MdStoreMallDirectory } from 'react-icons/md';

const LinkItems = [
    // { type: 'links', link: '/link_types', name: 'Link Types', icon: TbLivePhoto, },

    { type: 'Restaurants', link: '/redeem-coupon-reward', name: 'Redeem Coupon Reward', icon: MdStoreMallDirectory, },
    { type: 'Restaurants', link: '/restaurants/create', name: 'Create Restaurants', icon: MdStoreMallDirectory, },
    { type: 'Restaurants', link: '/restaurants', name: 'Restaurants', icon: MdStoreMallDirectory, },
    { type: 'Restaurants', link: '/food_coupons', name: 'Food Coupons', icon: MdStoreMallDirectory, },

    { type: 'admin', link: '/admin_alerts', name: 'Admin Alerts', icon: MdStoreMallDirectory, },
    { type: 'Pre Register store', link: '/create_new_store', name: 'CreateNewStore', icon: MdStoreMallDirectory, },
    { type: 'stores', link: '/stores', name: 'Stores', icon: MdStoreMallDirectory, },

    { type: 'offers', link: '/store_with_no_offers', name: 'Store With No Offers', icon: TbLivePhoto, },
    { type: 'offers', link: '/offers', name: 'Offers', icon: TbDiscount2, },
    { type: 'offers', link: '/system_offers/created', name: 'Admin Offers', icon: TbDiscount2, },

    { type: 'enquiries', link: '/ava_enquires', name: 'Ava Enquires', icon: TbLivePhoto, },
    { type: 'enquiries', link: '/CBS', name: 'CBS', icon: CgListTree, },

    { type: 'users', link: '/users', name: 'Users', icon: FaUsers, },
    // { type: 'users', link: '/users/journey', name: 'User Journey', icon: FaUsers, },

    { type: 'calling', link: '/calls', name: 'V Calls', icon: TbLivePhoto, },
    { type: 'calling', link: '/user_calling_my_batches', name: 'My User Calling Batch', icon: TbLivePhoto, },
    { type: 'calling', link: '/user_calling_batches', name: 'User Calling Batches', icon: TbLivePhoto, },

    { type: 'feedback', link: '/flags', name: 'Flags', icon: MdOutlineReport, },

    { type: 'coupons', link: '/coupons', name: 'Coupons', icon: TbLivePhoto, },
    { type: 'coupons', link: '/grabbed_coupons', name: 'Grabbed Coupons', icon: TbLivePhoto, },

    { type: 'whatsapp', link: '/whatsapp_conversations', name: 'Whatsapp Conversations', icon: TbLivePhoto, },
    { type: 'whatsapp', link: '/whatsapp', name: 'Whatsapp', icon: TbLivePhoto, },
    { type: 'whatsapp', link: '/wa_messages_actions', name: 'Wa Message Actions', icon: TbLivePhoto, },
    { type: 'whatsapp', link: '/wa_messages_logs', name: 'Wa Message logs', icon: TbLivePhoto, },
    { type: 'whatsapp', link: '/wa_messages', name: 'WA Messages', icon: TbLivePhoto, },

    { type: 'reels', link: '/stories', name: 'Reels', icon: TbLivePhoto, },

    { type: 'activities', link: '/app_updates', name: 'App Updates', icon: TbLivePhoto, },

    { type: 'marketing', link: '/notifications', name: 'Notifications', icon: MdEditNotifications, },

    { type: 'revenue', link: '/sponsor_reels', name: 'Sponsored Reels', icon: TbLivePhoto, },
    { type: 'revenue', link: '/ads', name: 'ADS', icon: FcAdvertising, },

    { type: 'stats', link: '/app_reviews', name: 'App Reviews', icon: MdStoreMallDirectory, },
    { type: 'stats', link: '/analytics/user_analyst', name: 'User Analyst', icon: TbLivePhoto, },
    { type: 'stats', link: '/store_stats', name: 'Store Stats', icon: TbLivePhoto, },
    { type: 'stats', link: '/analytics', name: 'Analytics', icon: BsSearch, },
    { type: 'stats', link: '/kpi', name: 'KPI', icon: TbLivePhoto, },

    { type: 'products', link: '/storeuploads', name: 'Store Uploads', icon: BsSearch, },

    { type: 'authorities', link: '/onboarders', name: 'Onboarders', icon: BsSearch, },
    { type: 'authorities', link: '/mods', name: 'moderators', icon: SiMicrosoftteams, },

    { type: 'tech', link: '/otps', name: 'Otps', icon: TbLivePhoto, },
    { type: 'tech', link: '/api_calls/graph', name: 'Api Calls', icon: TbLivePhoto, },
    { type: 'tech', link: '/conn', name: 'Connections', icon: TbLivePhoto, },
    { type: 'tech', link: '/link_shortener', name: 'Link Shortener', icon: FaAd, },
    { type: 'tech', link: '/qr', name: 'Qrs', icon: FaQrcode, },
    { type: 'tech', link: '/bot-activities', name: 'Bot Activities', icon: FaUsers, },


    { type: 'database', link: '/markets', name: 'Markets', icon: MdStoreMallDirectory, },
    { type: 'database', link: '/shops', name: 'Shops', icon: IoLocationSharp, },
    { type: 'database', link: '/activelocations', name: 'Active Locations', icon: IoLocationSharp, },
    { type: 'database', link: '/store_dump', name: 'Store Dump', icon: TbLivePhoto, },
    { type: 'database', link: '/duplicate_stores', name: 'Duplicate Stores', icon: TbLivePhoto, },
    { type: 'database', link: '/advance_search_store', name: 'Advance Search Store', icon: TbLivePhoto, },
    { type: 'database', link: '/search', name: 'Search', icon: BsSearch, },
    { type: 'database', link: '/globals/vars', name: 'Global Vars', icon: HiVariable, },
    { type: 'database', link: '/brands', name: 'Brands', icon: TbBrandBing, },
    { type: 'database', link: '/malls', name: 'Malls', icon: RiBuilding2Line, },
    { type: 'database', link: '/chains', name: 'Chains', icon: GiCrossedChains, },
    { type: 'database', link: '/wear', name: 'Wears', icon: RiProductHuntLine, },
    { type: 'database', link: '/clothingTypes', name: 'Clothing Types', icon: RiProductHuntLine, },
    { type: 'database', link: '/product-filters', name: 'Filters', icon: RiProductHuntLine, },
    { type: 'database', link: '/product', name: 'Products', icon: RiProductHuntLine, },
    { type: 'database', link: '/genders', name: 'Genders', icon: BsGenderAmbiguous, },
    { type: 'database', link: '/flags/reasons', name: 'Flags Reasons', icon: MdOutlineReport, },
].map(item => ({ ...item, link: `/admin${item.link}` }))

export const getLinkItems = (profile) => {
    if (!profile) return []
    const { role, permissions } = profile

    if (role?.toLowerCase() === 'admin') { return LinkItems }
    const _LinkItems = []
    LinkItems.map(item => {
        let _path = item.link.split('/admin')[1]
        _path = _path.split('/').slice(1).join('/')
        if (permissions.includes(_path)) _LinkItems.push(item)
    })
    return _LinkItems
}
const getLinkTypes = (profile) => {
    const LinkItems = getLinkItems(profile)
    const types = LinkItems.map(item => item.type).filter(type => type !== 'links')
    return [...new Set(types)]
}

export const getNavByType = ({ profile, type }) => {
    const LinkItems = getLinkItems(profile)
    return LinkItems.filter(item => item.type === type)
}

export const LinkTypes = (profile) => {
    const types = getLinkTypes(profile)

    return types.map((type, index) => {
        const nav = getNavByType({ profile, type })

        return {
            icon: IoIosArrowForward,
            type,
            index,
            nav: nav.map(i => i.name),
            links: nav.map(i => i.link),
            name: type.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(' '),
            link: `/admin/link_types/${type}`,
        }
    })
}