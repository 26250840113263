export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    return distance;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

export const getDistancefromLocal = (lat, lng) => {
    if (!lat || !lng) return '--'
    const local_lat = localStorage.getItem("latitude")
    const local_long = localStorage.getItem("longitude")
    const distance = getDistanceFromLatLonInKm(lat, lng, local_lat, local_long);
    return distance ? distance.toFixed(2) : 0
}