
export const columns = () => [
    {
        name: 'Key',
        selector: (row) => row.key,
        sortable: true,
    },
    {
        name: 'Api Calls',
        selector: (row) => row.api_calls,
        sortable: true,
    },
    {
        name: 'Users',
        selector: (row) => row.users,
        sortable: true,
    },

]