
export const columns = () => [
    { name: 'Url', selector: (row) => row.url, sortable: true, },
    { name: '10 Seconds[Calls(users)]', selector: (row) => `${row.last_10_sec.api_calls}(${row.last_10_sec.users})`, sortable: true, },
    { name: '30 Seconds[Calls(users)]', selector: (row) => `${row.last_30_sec.api_calls}(${row.last_30_sec.users})`, sortable: true, },
    { name: '1 Minute[Calls(users)]', selector: (row) => `${row.last_min.api_calls}(${row.last_min.users})`, sortable: true, },
    { name: '10 Minutes[Calls(users)]', selector: (row) => `${row.last_10_min.api_calls}(${row.last_10_min.users})`, sortable: true, },
    { name: '30 Minutes[Calls(users)]', selector: (row) => `${row.last_30_min.api_calls}(${row.last_30_min.users})`, sortable: true, },
    { name: '1 Hour[Calls(users)]', selector: (row) => `${row.last_one_hour.api_calls}(${row.last_one_hour.users})`, sortable: true, },
    { name: '2 Hours[Calls(users)]', selector: (row) => `${row.last_two_hour.api_calls}(${row.last_two_hour.users})`, sortable: true, },
    { name: '6 Hours[Calls(users)]', selector: (row) => `${row.last_six_hour.api_calls}(${row.last_six_hour.users})`, sortable: true, },
    { name: '12 Hours[Calls(users)]', selector: (row) => `${row.last_12_hour.api_calls}(${row.last_12_hour.users})`, sortable: true, },
    { name: '24 Hours[Calls(users)]', selector: (row) => `${row.last_24_hours.api_calls}(${row.last_24_hours.users})`, sortable: true, },
]