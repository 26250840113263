import { Button } from "native-base";
import { formatDateTimes, getSecDiff, secondsToMMSS } from "../../../utils/moment";

export const columns = (navigate) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/users/edit/${row._id}`)}>
            Details
        </Button>,
        sortable: false,
    },
    // {
    //     name: 'Created',
    //     selector: (row) => formatDateTimes(row?.createdAt, 5),
    //     sortable: true,
    // },
    {
        name: 'Stated',
        selector: (row) => formatDateTimes(row?.createdAt, 5),
        sortable: true,
    },
    {
        name: 'end',
        selector: (row) => formatDateTimes(row?.end_date, 5),
        sortable: true,
    },
    {
        name: 'App Time(min)',
        selector: (row) => secondsToMMSS(getSecDiff(row?.createdAt, row?.end_date)),
    },
    {
        name: 'Name',
        selector: (row) => row?.user?.name,
        sortable: true,
    },
    {
        name: 'Phone No.',
        selector: (row) => row?.user?.phone_number,
        sortable: true,
    },
    {
        name: 'Joined',
        selector: (row) => formatDateTimes(row?.user?.createdAt, 5),
        sortable: true,
    },
    {
        name: 'Platform',
        selector: (row) => row?.user?.device_tokens?.map(({ platform }) => platform).join(', '),
        sortable: true,
    },
    {
        name: 'Last Activity',
        selector: (row) => formatDateTimes(row?.user?.updatedAt, 8),
        sortable: true,
    },
    {
        name: 'City/State',
        selector: (row) => `${row?.user?.city?.name}/${row?.user?.state?.name}`.replace('undefined', '-').replace('undefined', '-'),
        sortable: true,
    },
    {
        name: 'Gender',
        selector: (row) => row?.user?.gender.type,
        sortable: true,
    },
    {
        name: 'DOB',
        selector: (row) => formatDateTimes(row?.user?.date_of_birth, 3),
        sortable: true,
    },
]

