import axios from "axios";
import { baseurl } from "./url";
const API = axios.create({ baseURL: `${baseurl}/admin/store` });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("token")) {
        req.headers.Authorization = localStorage.getItem("token");
    }
    return req;
})

export const getstoretokens = ({ id }) => API.get(`/auth/logins/${id}`)
export const getstoreauthority = ({ id }) => API.get(`/auth/${id}`)
export const unlinkStoreAuth = ({ id, data }) => API.put(`/auth/unlink/${id}`, data)
export const createLoginCode = ({ data }) => API.post(`/auth/login_code`, data)
export const linkStoreAuth = ({ id, data }) => API.post(`/auth/link`, data);
export const addCallRecord = ({ id, data }) => API.post(`/auth/call_record`, data);
export const getCallRecord = ({ id, data }) => API.get(`/auth/call_record/${id}`, data);
export const updateStoreAuths = ({ id, data }) => API.put(`/auth/${id}`, data)
export const saveAndVerify = ({ id, data }) => API.put(`/review/saveAndVerify/${id}`, data);
export const updateStoreChain = ({ id, data }) => API.put(`/review/chain/${id}`, data)
export const updateStoreBrand = ({ id, data }) => API.put(`/review/brand/${id}`, data)
export const updateStoreMall = ({ id, data }) => API.put(`/review/mall/${id}`, data);
export const updateStoreNWDays = ({ id, data }) => API.put(`/review/nwDays/${id}`, data);
export const updateStoreImage = ({ id, data }) => API.put(`/review/image/${id}`, data);
export const updateStoreProducts = ({ id, data }) => API.put(`/review/products/${id}`, data);
export const createNewStore = ({ data }) => API.post(`/b/create_new_store`, data)
export const getstores = ({ params }) => API.get("/b/", { params })
export const getStoresWithStat = ({ params }) => API.get("/b/stats", { params })
export const getFilteredStores = ({ params, data }) => API.post("/b/filters", data)
export const getStoreVersionHistory = ({ id, params }) => API.get(`/b/version_history/${id}`, { params });
export const getstorebyid = ({ id, params }) => API.get(`/b/${id}`, { params });
export const getunverifiedstores = ({ params }) => API.get("/b/", { params: { verified: false, ...params } });
export const getverifiedstores = ({ params }) => API.get("/b/", { params: { verified: true, ...params } });
export const createstore = (formData) => API.post("/b/", formData)
export const deletestore = ({ id }) => API.delete(`/b/${id}`)
export const getStoresWithNoAuth = ({ params }) => API.get("/b/noAuth", { params })
export const getstoreproducts = ({ id, params }) => API.get(`/b/products/${id}`, { params })

export const getStoreOffers = ({ id, params }) => API.get(`/b/offers/${id}`, { params })
export const getStoreReels = ({ id, params }) => API.get(`/b/reels/${id}`, { params })

export const getStoreStyles = ({ id, params }) => API.get(`/b/styles/${id}`, { params })

// all stores
// pending pre-register verification
// pending claims verification
// verified stores
// inactive stores
// has expired Offers
// has no offers
// has no products
