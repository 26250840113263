import React from 'react'
import { colors } from '../../theme/colors'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import Model from '../../components/compo/UI/Model'
import StoreAuthCalls from '../Stores/Review/StoreAuthCalls/StoreAuthCalls'
import { formatDateTimes } from '../../utils/moment'
import { formatNumber } from '../../helper/stringOperations'
import toast from 'react-hot-toast'

const StoreAuthCard = ({ data, refetch }) => {
    const [calls, setCalls] = React.useState(false)
    const [auth, setAuth] = React.useState(false)

    const call = data?.calls?.[0]

    return (
        <VStack w='100%' space='2' alignItems={'flex-start'} spacing={0}
            bg='white' p='4' borderRadius={'lg'} boxShadow={'lg'} >
            <HStack w='100%' spacing={2}
                alignContent={'flex-start'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <VStack alignItems={'flex-start'} flex={1} spacing={0}>
                    <Text fontWeight={'bold'} fontSize={'lg'} cursor={'pointer'}
                        _hover={{ color: colors.primary, textDecoration: 'underline' }}
                        onClick={() => window.open(`/admin/reviewStore/${data._id}`, '_blank')}
                    >{data.store_name}</Text>
                    <Text color={colors.secondary} >{data.address}</Text>
                </VStack>

                <VStack alignItems={'flex-start'} flex={1} spacing={0}>
                    <Text fontWeight={'bold'} fontSize={'lg'}>Contacts</Text>
                    <VStack>
                        {data?.auths?.map((item, index) => (
                            <HStack p={0} borderRadius={'lg'} >
                                <Text>{index + 1}. {item.name}/{item.role}/
                                    <Text as='span' fontWeight={'bold'} fontSize={'lg'} cursor={'pointer'}
                                        _hover={{ color: colors.primary, textDecoration: 'underline' }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(item.phone_number)
                                            toast('Copied to clipboard', { icon: '📋' })
                                        }}
                                    >
                                        {formatNumber(item.phone_number)}
                                    </Text>
                                </Text>
                                <Button size='sm' colorScheme={'green'} isLoading={false}
                                    onClick={() => {
                                        setAuth(item)
                                        setCalls(true)
                                    }}
                                >
                                    Call
                                </Button>
                            </HStack>
                        ))}
                    </VStack>
                </VStack>

                <Button onClick={() => window.open(`/admin/system_offers/create?store=${data._id}`, '_blank')}>
                    Add Offer
                </Button>

                {calls && <Model size='full' title={'Calls'} show={calls} close={() => {
                    setCalls(false)
                    refetch()
                }}
                    body={<StoreAuthCalls auth={auth} store={data} close={() => setCalls(false)} />}
                />}
            </HStack>

            {call && <VStack w='100%' space='2' alignItems={'flex-start'} borderTopWidth={1} mt={1}>
                <HStack w={'100%'} spacing={5}>
                    <Text color={colors.dark} fontWeight={'bold'} fontSize={'md'} >
                        Last Call - {formatDateTimes(call?.records?.createdAt, 8)}:
                    </Text>
                    <HStack alignItems={'center'}>
                        <Text fontWeight={'bold'} color={colors.secondary}>Phone Number</Text>
                        <Text>{formatNumber(call?.phone_number)}</Text>
                    </HStack>
                    <HStack alignItems={'center'}>
                        <Text fontWeight={'bold'} color={colors.secondary}>Purpose</Text>
                        <Text>{call?.records?.purpose || '--'}</Text>
                    </HStack>
                    <HStack alignItems={'center'} flex={1}>
                        <Text fontWeight={'bold'} color={colors.secondary}>Review/Comment/Tags</Text>
                        <Text fontWeight={'semibold'} >{call?.records?.review || '--'}/{call?.records?.comment || '--'}/{call?.records?.tags?.join(', ') || '--'}</Text>
                    </HStack>
                </HStack>
            </VStack>}
        </VStack>
    )
}

export default StoreAuthCard