import { formatDateTimes } from '../../utils/moment';
import { Button } from '@chakra-ui/react';

export const columns = ({ remove }) => [
    {
        name: 'Name',
        selector: (row) => row.user.name,
        sortable: true,
    },
    {
        name: 'Phone Number',
        selector: (row) => row.user.phone_number,
        sortable: true,
    },
    {
        name: 'Added on',
        selector: (row) => formatDateTimes(row.createdAt, 3),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) =>
            <Button size='sm' variant={'link'} onClick={() => remove({ id: row._id })} colorScheme='red'>
                Remove
            </Button>,
        sortable: false,
    },
]