import React from 'react'
import { HStack, Text, VStack } from '@chakra-ui/react'
import CropArea from '../../../components/compo/CropArea/CropArea'
import { IMAGE_BASE_URL, contProps, contPropsTitle } from '../../../helper/variables'

const MarketImages = ({ form }) => {
    const images = form.watch('images')

    return (
        <VStack {...contProps} >
            <Text {...contPropsTitle}>
                Images
            </Text>

            <HStack alignItems={'flex-start'} spacing={2}>
                {Array(5).fill(0).map((_, i) =>
                    <VStack key={i} borderRadius={10} bg={'gray.100'}>
                        <CropArea aspect={2 / 3} defaultImage={images?.[i] ? `${IMAGE_BASE_URL}${images[i]}` : undefined}
                            removeImage={() => form.setValue(`images[${i}]`, '')}
                            setImage={(file) => form.setValue(`images[${i}]`, file)}
                            width={400} height={600}
                        />
                    </VStack>
                )}
            </HStack>
        </VStack>
    )
}

export default MarketImages