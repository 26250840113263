import { Button } from "native-base";
import { getAppLinks } from "../../helper/applink";
import { formatDateTimes } from "../../utils/moment";
import { capitalize } from "../../helper/stringOperations";

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => {
                // navigate(`/admin/reviewStore/${row._id}`)
                const link = `/admin/reviewStore/${row._id}`
                // open in new tab 

                window.open(link, '_blank')
            }}
        >
            Review</Button>,
        sortable: false,
    },
    {
        name: 'Date Registered',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Registered By Name',
        selector: (row) => row?.createdBy === 'bot' ? "Bot" : `${row?.registered_by?.name}(${row?.registered_by?.register_by?.role?.split('STORE')[1]})`,
        sortable: true,
    },
    {
        name: 'Registered By P.Number',
        selector: (row) => row?.registered_by?.phone_number,
        sortable: true,
        hide: 'lg',
        reorder: true,
    },
    {
        name: 'Name',
        selector: (row) => row.store_name,
        sortable: true,
    },
    {
        name: 'Address',
        selector: (row) => row.store_address.address,
        sortable: true,
    },
    {
        name: 'Points',
        selector: (row) => row.points,
        sortable: true,
    },
    {
        name: 'State',
        selector: (row) => row.store_address.state?.name,
        sortable: true,
    },
    {
        name: 'City',
        selector: (row) => row.store_address.city?.name,
        sortable: true,
    },
    {
        name: 'Budget',
        selector: (row) => getRangeLabels(row.range_type),
        sortable: true,
    },
    {
        name: 'Readymade/Custommade',
        selector: (row) => row.clothing_type,
        sortable: true,
    },
    {
        name: 'category',
        selector: (row) => row.type,
        sortable: true,
    },
    {
        name: 'Chain',
        selector: (row) => row.store_address.chain?.name ? `${row.store_address.chain?.name}(${row.store_address.chain.type === 'Primary' ? "Lg" : "Sm"})` : '--',
        sortable: true,
    },
    {
        name: 'Pre-Register',
        selector: (row) => row.pre_register ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Claimed',
        selector: (row) => row.claimed ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Discount Types',
        selector: (row) => row.offer_type,
        sortable: true,
    },
    {
        name: 'Ownership',
        selector: (row) => row.ownership,
        sortable: true,
    },
    {
        name: 'Gst Number',
        selector: (row) => row.gst_number,
        sortable: true,
    },
    // {
    //     name: 'App Link',
    //     button: true,
    //     cell: (row) => <Button
    //         color='blue.400'
    //         size='sm' variant={'link'}
    //         onPress={() => ['exclusive', 'non-branded'].includes(row.type) ? getAppLinks(`/store/${row._id}`) : getAppLinks(`/mutliBrandStore/${row._id}`)
    //         } >
    //         copy</Button>,
    //     sortable: false,
    // },
] 