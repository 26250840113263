import React from 'react'
import useSubmit from '../../hook/useSubmit'
import { Button, Input, Text, VStack } from '@chakra-ui/react'
import { sendBannerToPhoneNumber } from '../../apis/admin'

const SendBannerToPhoneNumber = () => {
    const [number, setNumber] = React.useState('')

    const { submit, isLoading } = useSubmit({
        service: sendBannerToPhoneNumber,
        name: 'sendBannerToPhoneNumber',
    })

    return (
        <VStack w="100%" alignItems={'flex-start'} maxW={'500px'} bg={'white'} p={'4'} borderRadius={'lg'} >

            <Text fontSize={'md'} fontWeight='semibold' >
                Send Banner To Phone Number
            </Text>

            <Input placeholder="Enter Number(10-digit)" type="number" maxLength={10}
                value={number} onChange={(e) => setNumber(e.target.value)} />

            <Button onClick={() => submit({ phone_number: number })} isLoading={isLoading} >
                send
            </Button>

        </VStack>
    )
}

export default SendBannerToPhoneNumber  