
export const columns = (navigate) => [
    {
        name: 'Count',
        selector: (row) => row.count,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Key',
        selector: (row) => row.key,
        sortable: true,
        width: '50%',
    },
]