import { useForm } from 'react-hook-form'
import useSubmit from '../../hook/useSubmit';
import { useNavigate } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { getAppActions } from '../Routes/appActions'
import { InputField } from '../../commons/InputField'
import { getAppActionFilters } from '../Routes/routes'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { Button, HStack, Spacer, Text, VStack } from 'native-base'
import { createmod, deletemod, editmodbyid } from '../../apis/admin'

const defaultValues = { name: '', phone_number: '', pass: '', code: '', role: 'MOD', permissions: [] }

const CreateMod = ({ data }) => {
    const form = useForm({ defaultValues: data ? data : defaultValues })
    const navigate = useNavigate()
    const [filter, setFilters] = useState([])

    const { submit, isLoading } = useSubmit({
        service: data ? editmodbyid : createmod,
        id: data ? data._id : null,
        onSuccess: () => navigate(-1),
    })

    const DELETE = useSubmit({
        service: deletemod,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const actionList = getAppActions(filter)
    useMemo(() => { }, [form.watch()])

    return (
        <VStack p='3' space='2' w={'100%'} >
            {!data && <CompHeader title='Create  Moderator' backButton />}
            <VStack p='3' space='2' w={'100%'} bg='white' borderRadius={'lg'} >
                <InputField form={form} title='Name' id='name' />
                <InputField form={form} title='Phone Number' id='phone_number' />
                <InputField form={form} title='Code' id='code' />
                <InputField form={form} title='Pass' id='pass' />
                <InputField form={form} title='Role' id='role' disabled />

                <Text mt='2' fontWeight={'semibold'} fontSize='md'>Permissions ({actionList.length})</Text>
                <HStack space='2'>
                    {getAppActionFilters().map(item =>
                        <Button key={item} size='sm'
                            variant={filter.includes(item) ? 'solid' : 'outline'}
                            onPress={() => {
                                if (filter.includes(item))
                                    setFilters([])
                                else
                                    setFilters([item])
                            }}>
                            {item[0].toUpperCase() + item.slice(1)}
                        </Button>
                    )}
                </HStack>

                <Button size='sm' onPress={() => {
                    const values = form.getValues()
                    const _permissions = values.permissions
                    const permissions = []
                    actionList.map(([_, value]) => {
                        if (!_permissions.includes(value))
                            permissions.push(value)
                    })
                    const unique = [...new Set(permissions)]
                    form.setValue('permissions', unique)
                }}>
                    <Text color={'white'}>
                        Toggle All ({actionList.length})
                    </Text>
                </Button>

                <HStack flexWrap={'wrap'} space='2'>
                    {actionList.map(([_, value, label]) => {
                        return <Button mb='2' w='200' key={value} size='sm'
                            variant={form.getValues().permissions.includes(value) ? 'solid' : 'outline'}
                            onPress={() => {
                                const values = form.getValues()
                                const _permissions = values.permissions
                                const permissions = []
                                if (_permissions.includes(value))
                                    permissions.push(..._permissions.filter(p => p !== value))
                                else
                                    permissions.push(..._permissions, value)
                                const unique = [...new Set(permissions)]
                                form.setValue('permissions', unique)
                            }}>
                            {label}
                        </Button>
                    })}
                </HStack>


                <HStack mt='2' alignItems={'center'}>
                    <Button size='sm' isLoading={isLoading} onPress={() => submit({ ...form.getValues() })}>
                        {data ? 'Edit Mod' : 'Create Mod'}
                    </Button>
                    <Spacer />
                    {data && <Button colorScheme={'red'} size='sm' isLoading={DELETE.isLoading}
                        onPress={() => DELETE.submit({ ...form.getValues() })}>
                        Delete Mod
                    </Button>}
                </HStack>
            </VStack>
        </VStack>
    )
}

export default CreateMod