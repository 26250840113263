import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hook/useFetch';
import { getBotActivities } from '../../apis/admin';
import { columns } from './static';

const BotActivities = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getBotActivities',
        service: getBotActivities,
        pagination: true
    })

    return (
        <VStack>
            <CompHeader title='Bot Activities' />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default BotActivities