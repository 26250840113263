import React from 'react'
import { VStack } from 'native-base';
import CompHeader from '../../commons/CompHeader/CompHeader';
import AdminOffersTabs from './AdminOffersTabs';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hook/useFetch';
import { getCreatedAdminOffers } from '../../apis/admin';
import DataTable from '../../commons/DataTable/DataTable';
import { createdAdminOffersColumns } from './static';

const CreatedAdminOffers = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: "getCreatedAdminOffers",
        service: getCreatedAdminOffers,
        pagination: true
    })

    return (
        <VStack>
            <CompHeader title="Admin Offers"
                buttonTitle='Add Offer'
                onPress={() => navigate('/admin/system_offers/create')}
            />
            <AdminOffersTabs as='created' />
            <DataTable tableData={{ columns: createdAdminOffersColumns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default CreatedAdminOffers