import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import useFetch from '../../../hook/useFetch'
import Loading from '../../../commons/Loading'
import { Text, VStack } from '@chakra-ui/react'
import { getProductTree } from '../../../apis/admin'
import ProductDetails from './ProductDetails'
import ProductWears from './ProductWears'
import EditProductFilter from './EditProductFilter'

const ProductTree = () => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const gender = searchParams.get('gender')
    const wear = searchParams.get('wear')
    const _id = searchParams.get('id')
    const title = searchParams.get('title')

    const { data, isLoading, refetch } = useFetch({
        name: `product_${id}`,
        service: getProductTree,
        id,
    })

    if (isLoading) return <Loading />

    return (
        <VStack w='100%'>
            <Text fontSize={'2xl'} fontWeight={'bold'}>
                <Text as={'span'} color={'blue.500'}> {data.name}</Text>
            </Text>

            <ProductDetails data={data} refetch={refetch} />
            <ProductWears data={data} refetch={refetch} />
            {gender && wear && _id && title
                && <EditProductFilter gender={gender} wear={wear} id={_id} title={title} />
            }
        </VStack>
    )
}

export default ProductTree