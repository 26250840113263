import Home from "./Home";
import 'react-clock/dist/Clock.css';
import useFetch from "./hook/useFetch";
import useSocket from "./hook/useSocket";
import React, { useEffect } from "react";
import { getProfile } from "./apis/admin";
import 'react-calendar/dist/Calendar.css';
import Shops from './Admin Panel/Shops/Shops';
import Login from "./Admin Panel/Login/Login";
import { getList } from "./Admin Panel/Routes/routes";
import 'react-datetime-picker/dist/DateTimePicker.css';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from 'react-router-dom';
import { selectProfile, selectToken, setProfile } from "./redux/authSlice";

function App() {
  const token = useSelector(selectToken)
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()

  useFetch({
    service: getProfile,
    name: 'getProfile_new',
    onSuccess: (res) => dispatch(setProfile(res))
  })

  useEffect(() => {
    const origin = window.location.origin.includes('admin.offingo.com')
    if (origin) {
      window.open('https://admin-offingo.web.app', '_self')
    }
  }, [])

  useSocket()

  return (
    <Routes>
      {token ?
        <>
          <Route exact path={`/admin/shops`} element={<Shops />} key={'shops'} />
          <Route path="/admin" element={<Home />} >
            {getList(profile).map(([Component, route]) =>
              <Route exact path={`/admin${route}`} element={<Component />} key={route} />
            )}
          </Route>
          <Route path='/' element={<Navigate to="/admin" />} />
          <Route path='/login' element={<Navigate to="/admin" />} />
          <Route path='*' element={<Navigate to="/admin/404" />} />
        </>
        :
        <>
          <Route path='*' element={<Navigate to="/login" replace />} />
          <Route path='/login' element={<Login />} />
        </>
      }
    </Routes>
  );
}

export default App;
