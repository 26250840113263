import React from 'react'
import { VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import CreateCoupons from './CreateCoupons'
import Loading from '../../commons/Loading'
import { useParams } from 'react-router-dom'
import { getCouponsById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { formatDateTimes } from '../../utils/moment'

const EditCoupons = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `getCoupons/${id}`,
        service: getCouponsById,
        id,
    })

    if (isLoading) return <Loading />
    return (
        <VStack alignItems={'flex-start'} w="100%">
            <CompHeader title={'Edit Coupons'} backButton />
            <CreateCoupons data={{
                ...data,
                store: {
                    label: `${data?.store?.[0]?.store_name} || ${data?.store?.[0]?.store_address?.address} ||  ${formatDateTimes(data?.store?.[0]?.createdAt, 4)}`,
                    value: data?.store?.[0]?._id,
                },
                app_event: data?.app_event?.map((event) => ({ label: event.title, value: event._id, })),
                active: data?.active?.toString(),
                paid: data?.paid?.toString(),
                public: data?.public?.toString(),
                lottery: data?.lottery?.toString(),
                products: data?.products?.map((product) => ({ label: product.name, value: product._id, })),
                constraints: {
                    ...data?.constraints,
                    styles: data?.constraints?.styles?.map((style) => ({ label: style, value: style, })),
                },
            }} />
        </VStack>
    )
}

export default EditCoupons