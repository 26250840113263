import React from 'react'
import useFetch from '../../../hook/useFetch'
import { Text, VStack } from '@chakra-ui/react'
import { getgender } from '../../../apis/admin'
import SelectField from '../../../commons/SelectField'
import { InputField } from '../../../commons/InputField'
import { contProps, contPropsTitle } from '../../../helper/variables'
import { getProductWearsByGender, getProductsByGender, getStoreBrands, getofferbystoreid } from '../../../apis/other'

const ProductDetails = ({ form }) => {

    const state = form.watch()
    const { store, brand } = state

    const brand_id = brand?._id
    const store_id = store?._id

    const gender = state.gender?.value
    const category = state.product?.value

    const genders = useFetch({ name: 'getgenders', service: getgender, dataOnly: true, converterLabel: 'type' })

    const { data: offers } = useFetch({
        name: `offers_${store_id}_${brand_id}`,
        service: getofferbystoreid, id: store_id,
        converterLabel: 'offer',
        params: { brand_id }
    })

    const { data: categories } = useFetch({
        service: getProductsByGender,
        name: `getProductsByGender_${gender}`,
        cache: true,
        conditionalRefetch: gender,
        condition: !!gender,
        id: gender,
        params: { limit: 0 },
        converterLabel: 'name'
    })

    const { data: wears } = useFetch({
        service: getProductWearsByGender,
        name: `getProductsWears_${category}_${gender}`,
        id: category,
        props: { gender },
        conditionalRefetch: [category, gender],
        condition: !!category && !!gender,
        cache: true,
        params: { limit: 0 },
        converterLabel: 'wear',
        onSuccess: (data) => {
            if (data?.length === 1) {
                form.setValue(`wear`, { label: data[0].wear, value: data[0]._id })
            }
        }
    })

    const { data: brands } = useFetch({
        service: getStoreBrands,
        name: `getStoreBrands_${store_id}`,
        id: store_id,
        conditionalRefetch: [store_id],
        condition: !!store_id,
        cache: true,
    })

    const list = [
        { title: 'Style id', id: '_id', disabled: true },
        { title: 'Gender', id: 'gender', reactSelect: true, isMulti: false, list: genders },
        { title: 'Product', id: 'product', reactSelect: true, isMulti: false, list: categories?.map(item => ({ label: item.name, value: item._id })) },
        { title: 'Wear', id: 'wear', reactSelect: true, isMulti: false, list: wears?.map(item => ({ label: item.wear, value: item._id })) },
        { title: 'Brand', id: 'brand', reactSelect: true, isMulti: false, list: brands?.map(item => ({ label: item.brand, value: item._id })) },
        { title: 'Display Name', id: 'display_name' },
        { title: 'Name', id: 'name' },
        { title: 'Product ID', id: 'productId' },
        { title: 'Price', id: 'price' },
        { title: 'Discount Price', id: 'discount_price' },
        { title: 'Offers', id: 'offers', reactSelect: true, isMulti: false, list: offers?.data?.map(item => ({ label: item.offer, value: item._id })) },
        // {
        //     title: `Store (${stores?.length})`, id: 'store', list: stores?.map(item =>
        //     ({
        //         label: `${item.store_name} || ${item?.store_address?.address} || ${formatDateTimes(item.createdAt, 4)}`,
        //         value: item._id,
        //     })),
        //     reactSelect: true,
        //     isMulti: false,
        //     disabled: !!data,
        // },
        // { title: 'Active', id: 'active', list: ['true', 'false'].map(item => ({ label: item.toString(), value: item })) },
        // { title: 'Public', id: 'public', list: ['true', 'false'].map(item => ({ label: item.toString(), value: item })) },
    ]

    return (
        <VStack {...contProps} >
            <Text {...contPropsTitle}>
                Product Details
            </Text>
            <VStack alignItems={'flex-start'} w="100%">
                {list.map((item, index) => {
                    const Comp = item.list ? SelectField : InputField
                    return <Comp tw={120} key={index} form={form} {...item} />
                })}
            </VStack>
        </VStack>
    )
}

export default ProductDetails