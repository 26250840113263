import { Button } from "native-base";
import { getAppLinks } from "../../../../helper/applink";
import { formatDateTimes } from "../../../../utils/moment";
import { capitalize, formatNumber } from "../../../../helper/stringOperations";
import { Image } from "@chakra-ui/react";
import { IMAGE_BASE_URL } from '../../../../helper/variables';

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    // {
    //     name: 'Actions',
    //     button: true,
    //     cell: (row) => <Button
    //         size='sm' variant={'link'}
    //         onPress={() => navigate(`/admin//${row._id}`)}
    //     >
    //         Review</Button>,
    //     sortable: false,
    // },
    {
        name: 'Thumbnail',
        button: true,
        cell: (row) => <Image
            size='sm'
            src={`${IMAGE_BASE_URL}${row?.thumbnail}`}
            alt={row?.store.store_name}
        />
        ,
        sortable: false,
    },
    {
        name: 'Store',
        selector: (row) => row.store.store_name,
        sortable: true,
    },
    {
        name: 'Views/Shares/Likes/Comments',
        selector: (row) => `${row.views}/${row.shares}/${row.likes}/${row.comments}`,
        sortable: true,
    },
    {
        name: 'Open by Shares',
        selector: (row) => row.share_opens,
        sortable: true,
    },
    {
        name: 'Created At',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Uploaded by',
        selector: (row) => `${row?.user.name}(${formatNumber(row?.user.phone_number)})`,
        sortable: true,
    },
] 