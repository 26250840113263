import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { getTitleKeys } from '../../apis/admin'

const ContextBased = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'titleKeys', service: getTitleKeys, pagination: true })

    return (
        <VStack space='4' >
            <CompHeader title='Product Filters'
                buttonTitle='Create New Key'
                onPress={() => navigate(`/admin/product-filters/create`)}
            />
            <DataTable tableData={{ columns: columns(navigate), isLoading, data, pagination: true }} />
        </VStack>
    )
}

export default ContextBased