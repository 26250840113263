import { Button } from 'native-base';

export const columns = (navigate) => [
    { name: 'Name', selector: (row) => `${row.name}${!row.active ? '(INACTIVE)' : ''}`, sortable: true, },
    { name: 'Phone No.', selector: (row) => row.phone_number, sortable: true, },
    { name: 'Code', selector: (row) => row.code, sortable: true, },
    { name: 'Role', selector: (row) => row.role, sortable: true, },
    {
        name: 'Status',
        cell: row => <>
            {row.socket_id ?
                <Button size='sm' variant={'link'} colorScheme='green'
                    _text={{ fontWeight: '600', letterSpacing: .5, textTransform: "uppercase" }}
                >Online</Button>
                :
                <Button size='sm' variant={'link'} colorScheme='red'
                    _text={{ fontWeight: '600', letterSpacing: .5, textTransform: "uppercase" }}
                >Offline</Button>
            }
        </>,
        sortable: true,
    },
    {
        name: '',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/mods/calls/${row._id}`)}>
            View Calls</Button>,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => row?.role !== 'ADMIN' && <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/mods/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]