import { Text } from '@chakra-ui/react';
import { formatDateTimes, fromNow } from '../../../utils/moment';
import { formatNumber } from '../../../helper/stringOperations';

export const columns = (navigate) => [
    {
        name: 'Coupon Id',
        selector: (row) => row?.coupon?._id,
        sortable: true,
    },
    {
        name: 'Coupon Grabbed Id',
        selector: (row) => row?._id,
        sortable: true,
    },
    {
        name: 'User',
        selector: (row) => row?.user?.name,
        sortable: true,
    },
    {
        name: 'Phone Number',
        selector: (row) => formatNumber(row?.user?.phone_number),
        sortable: true,
    },
    {
        name: 'Store',
        selector: (row) => row?.store?.[0]?.store_name,
        sortable: true,
    },
    {
        name: 'Serial No',
        selector: (row) => row?.serial_no,
        sortable: true,
    },
    {
        name: 'Grabbed',
        selector: (row) => row?.user ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Coupon',
        selector: (row) => `${row?.coupon?.name}(${row?.coupon?.code})`,
        sortable: true,
    },
    {
        name: 'Valid Till',
        selector: (row) => `${formatDateTimes(row?.coupon?.expiry, 2)}(${fromNow(row?.coupon?.expiry)})`,
        sortable: true,
    },
    {
        name: 'Redeemed',
        cell: (row) => <Text color={row?.redeemed ? 'green.500' : 'red.500'} > {row?.redeemed ? 'Yes' : 'No'} </Text>,
        sortable: true,
    },
    {
        name: 'Redeemed At',
        selector: (row) => row?.redeemed ? `${formatDateTimes(row?.redeemed_at, 4)}(${fromNow(row?.redeemed_at)})` : 'Not Redeemed',
        sortable: true,
    },
    {
        name: 'Assigned At',
        selector: (row) => row?.user ? `${formatDateTimes(row?.assigned_at, 4)}(${fromNow(row?.assigned_at)})` : '--',
        sortable: true,
    },
    {
        name: 'last Update',
        selector: (row) => formatDateTimes(row?.updatedAt, 4),
        sortable: true,
    },
] 