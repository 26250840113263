import React, { useEffect } from 'react'
import { Box, Button, HStack, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router-dom';
import useFetch from '../../../hook/useFetch';
import { getgender } from '../../../apis/admin';
import GenderWearSelection from './GenderWearSelection';
import { getstoreproducts, updateStoreProducts } from '../../../apis/store';
import useSubmit from '../../../hook/useSubmit';

const StoreProductSelectionModel = ({ close }) => {
    const { id } = useParams();
    const [selected, setSelected] = React.useState([])
    const genders = useFetch({ name: 'getgender', service: getgender, dataOnly: true })

    useFetch({
        service: getstoreproducts,
        name: "getstoreproducts",
        id,
        conditionalRefetch: [id],
        condition: !!id,
        onSuccess: (data) => setSelected(data),
        dataOnly: true,
    })

    const { submit, isLoading } = useSubmit({
        service: updateStoreProducts,
        id,
        onSuccess: close
    })

    return (
        <VStack w='100%' p={4}>
            {genders.map(({ type, _id }) =>
                <GenderWearSelection gender={{ type, _id }}
                    selected={selected} setSelected={setSelected}
                />
            )}

            <Button isLoading={isLoading}
                onClick={() => { submit({ products: selected }) }}
                colorScheme="teal" size="md" w={'50%'}
            >
                Update Store Products
            </Button>
        </VStack>
    )
}

export default StoreProductSelectionModel