import React from 'react';
import { VStack } from '@chakra-ui/react';
import DateTimePicker from 'react-datetime-picker';
import { HStack, Spacer, Text } from '@chakra-ui/react';

const StoreTiming = ({ form, viewOnly }) => {

    const d_data = form.watch('default.working_hours')
    const data = form.watch('working_hours')

    const to = (() => {
        try { return JSON.parse(data?.to); }
        catch (err) { return data?.to }
    })();

    const from = (() => {
        try { return JSON.parse(data?.from); }
        catch (err) { return data?.from }
    })();

    return (
        <VStack space={3} w='100%' alignItems='flex-start'>

            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' >
                    Store Timing
                </Text>
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('working_hours', d_data)
                        }}>
                        Reset Store Timing
                    </Text>
                }
            </HStack>

            <HStack space={2} w='100%' alignItems={'center'}>

                <VStack alignItems={'flex-start'} flex={1}>
                    <Text fontWeight={'semibold'} fontSize='sm'>
                        From
                    </Text>
                    <DateTimePicker format='h:mm a'
                        calendarIcon={null}
                        onChange={(value) => {
                            console.log(value)
                            form.watch('working_hours.from', value?.toISOString())
                        }
                        }

                        value={from}
                    />
                </VStack>

                <VStack alignItems={'flex-start'} flex={1}>
                    <Text fontWeight={'semibold'} fontSize='sm'>
                        To
                    </Text>
                    <DateTimePicker format='h:mm a'
                        calendarIcon={null}
                        onChange={(value) => form.setValue('working_hours.to', value)}
                        value={to}
                    />
                </VStack>

            </HStack>

        </VStack>
    )
}

export default StoreTiming