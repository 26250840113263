import React, { useState } from 'react'
import { Checkbox, HStack, VStack } from 'native-base'
import { useEffect } from 'react'
import { Input, SimpleGrid } from '@chakra-ui/react';

const RulesSelectionCard = ({ data, form }) => {
    const updateState = (id, value) => form.setValue(id, value)
    const [search, setSearch] = useState('')
    const { titles, keys, isExcept, except } = form.getValues()
    const { title, _id } = data.title

    const titleSelected = titles.includes(_id)

    useEffect(() => {
        if (!titleSelected) return
        const _keys = data.keys.map(key => key._id)
        const newKeys = keys.filter(key => !_keys.includes(key))
        form.setValue('keys', newKeys)
    }, [titleSelected])

    return (
        <VStack bg='white' p='4' borderRadius={'lg'} mb='4' space={2}>
            <HStack>
                <Checkbox mb='1' w='200'
                    _text={{ fontSize: 'md', fontWeight: 'bold' }}
                    value={_id}
                    isDisabled={isExcept}
                    isChecked={titleSelected}
                    onChange={e => {
                        const _titles = e ? [...titles, _id] : titles.filter(item => item !== _id)
                        updateState('titles', _titles)
                    }}
                >{title}</Checkbox>
            </HStack>

            <Input
                placeholder='Search key'
                value={search}
                bg={'white'}
                borderWidth={0}
                outline={0}
                outlineColor={'transparent'}
                onChange={e => setSearch(e.target.value)}
            />

            <VStack space='2' w={'100%'} opacity={titleSelected ? '.4' : 1}
                pointerEvents={titleSelected ? 'none' : 'auto'} >
                <HStack w={'100%'} space='2' >
                    <Checkbox.Group value={keys} onChange={(e) => {
                        updateState(`keys`, e)
                    }} >
                        <SimpleGrid columns={[3, 6]} spacing='2' >
                            {data.keys.filter(({ label }) => label.toLowerCase().startsWith(search.toLowerCase())).map(key =>
                                <Checkbox mb='1' w='130' key={key._id}
                                    _text={{ fontSize: 'sm' }} value={key._id}>
                                    {key.label}</Checkbox>
                            )}
                        </SimpleGrid>
                    </Checkbox.Group>
                </HStack>
            </VStack>
        </VStack>
    )
}

export default RulesSelectionCard