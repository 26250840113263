import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getPastDate } from '../../utils/moment'
import CompaignSelector from './CompaignSelector'
import { getWAMessageLogs } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DateRangeSelector from '../../commons/DateRangeSelector'
import Stats from './Stats'
import Loading from '../../commons/Loading'

const WAMessageLogs = () => {
    const navigate = useNavigate()
    const [range, setRange] = React.useState([getPastDate(1000), new Date()])
    const [compaign, setCompaign] = React.useState('')

    const { data, isLoading } = useFetch({
        service: getWAMessageLogs,
        name: 'getWAMessageLogs',
        pagination: true,
        params: {
            date1: range[0],
            date2: range[1],
            compaign
        },
        conditionalRefetch: [range, compaign]
    })

    if (isLoading) return <Loading />

    return (
        <VStack alignItems={'flex-start'} w={'100%'}>
            <CompHeader title={`WA Message Logs(${data?.data?.total})`} />
            <DateRangeSelector range={range} setRange={setRange} />
            <CompaignSelector compaign={compaign} setCompaign={setCompaign} />
            <Stats data={data?.stats} />
            <DataTable tableData={{ columns: columns(navigate), data: data.data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default WAMessageLogs