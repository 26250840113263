import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { deleteSponsoredStory, editSponsoredStory, getStoriesById, uploadImage } from '../../apis/admin'
import { useNavigate, useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import CropArea from '../../components/compo/CropArea/CropArea'
import { IMAGE_BASE_URL, VIDEO_BASE_URL } from '../../helper/variables'
import { HStack, Input, Text, Textarea, Box, Button, VStack } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit'
import SelectField from '../../commons/SelectField'

const w = 300
const h = w * 16 / 9

const EditSponsorReels = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [avatar, setAvatar] = React.useState(null)
    const [video, setVideo] = React.useState(null)
    const [thumbnail, setThumbnail] = React.useState(null)

    const form = useForm({ defaultValues: { title: '', caption: "", avatar: '', reel: '', thumbnail: '', link: '' } })
    const state = form.watch()

    const { data, isLoading: dataLoading } = useFetch({ service: getStoriesById, name: `getStoriesById-${id}`, id })

    useEffect(() => {
        if (isLoading || !data) return
        form.reset({
            ...data,
            show: data?.show?.toString()
        })
    }, [data])

    const handleVideo = (e) => {
        const video = e.target.files[0]
        setVideo(video)
    }

    const { submit, isLoading } = useSubmit({
        service: editSponsoredStory,
        id,
        onSuccess: () => navigate(-1)
    })
    const { submit: Delete, isLoading: DeleteLoading } = useSubmit({
        service: deleteSponsoredStory,
        id,
        onSuccess: () => navigate(-1)
    })

    const { submit: submitImage, isLoading: isLoadingImage } = useSubmit({
        service: uploadImage,
        onSuccess: (res) => {

            let _thumbnail = ''
            let _avatar = ''

            if (thumbnail && avatar) {
                _thumbnail = res?.[0]
                _avatar = res?.[1]
            }

            if (thumbnail && !avatar) {
                _thumbnail = res?.[0]
            }

            if (!thumbnail && avatar) {
                _avatar = res?.[0]
            }

            const formdata = new FormData();

            const state = {
                ...form.getValues(),
                thumbnail: _thumbnail || null,
                avatar: _avatar || null,
                duration: video?.duration,
            }

            Object.keys(state).map((obj) => {
                if (Array.isArray(state[obj])) {
                    state[obj].map((item) => {
                        if (typeof item === "object") {
                            return formdata.append(obj, JSON.stringify(item));
                        }
                        formdata.append(obj, item);
                    });
                } else if (!Array.isArray(state[obj]) && typeof state[obj] === "object") {
                    formdata.append(obj, JSON.stringify(state[obj]));
                }
                if (typeof state[obj] === "string") {
                    formdata.append(obj, state[obj]);
                }
            })

            if (video)
                formdata.append("videos", video);

            submit(formdata)
        }
    })

    const pulish = () => {
        const formdata = new FormData();

        if (thumbnail)
            formdata.append("images", thumbnail);

        if (avatar)
            formdata.append("images", avatar);

        submitImage(formdata)
    }

    if (dataLoading) return <Loading />

    return (
        <VStack w="100%" alignItems={'flex-start'} >
            <CompHeader title="Sponsored Reels" backButton />

            <VStack w={'100%'} alignItems={'flex-start'} bg={'white'} p={4}>
                <HStack w={'100%'} alignItems={'flex-start'}>
                    <VStack flex={1} alignItems={'flex-start'} maxW={350} >
                        <Text fontSize="xl" fontWeight="bold" >
                            Pick Video
                        </Text>
                        <Input type="file" accept="video/*" onChange={handleVideo} outline={'none'} border={'none'} p={0} borderRadius={0} />
                        <Box>
                            <Text fontWeight="bold">Selected Video:</Text>
                            <Text>{video?.name}</Text>
                            <video height={h} width={w} src={video ? URL.createObjectURL(video) : `${VIDEO_BASE_URL}${data?.reel}`} controls />
                        </Box>
                    </VStack>

                    <VStack flex={1} alignItems={'flex-start'} maxW={350} >
                        <Text fontSize="xl" fontWeight="bold" >
                            Pick Thumbnail
                        </Text>
                        <Input type="file" accept="image/*" onChange={(e) => setThumbnail(e.target.files[0])} outline={'none'} border={'none'} p={0} borderRadius={0} />
                        <Box>
                            <Text fontWeight="bold">Selected Thumbnail:</Text>
                            <Text>{thumbnail?.name}</Text>
                            <Box w={w} h={h} overflow={'hidden'} border={'1px solid #000'} bg={'#000'}>
                                <img height={'100%'} width={'100%'} src={thumbnail ? URL.createObjectURL(thumbnail) : `${IMAGE_BASE_URL}${data?.thumbnail}`} alt="thumbnail" />
                            </Box>
                        </Box>
                    </VStack>
                </HStack>

                <VStack alignItems={'flex-start'} spacing={2}>
                    <VStack alignItems={'flex-start'} spacing={2}>
                        <Text w={60} fontWeight={'semibold'}>
                            Avatar
                        </Text>
                        <CropArea
                            circularCrop
                            aspect={1}
                            defaultImage={avatar || (state?.avatar ? `${IMAGE_BASE_URL}${state?.avatar}` : '')}
                            removeImage={() => {
                                setAvatar(null)
                                form.setValue('avatar', null)
                                console.log('remove')
                            }}
                            setImage={(file) => { setAvatar(file) }}
                        />
                    </VStack>

                    <HStack alignItems={'center'} spacing={2}>
                        <Text w={60} fontWeight={'semibold'}>
                            Title:
                        </Text>
                        <Input variant='filled' borderColor='gray.300'
                            placeholder='Title' value={state.title}
                            onChange={e => form.setValue('title', e.target.value)} />
                    </HStack>

                    <HStack alignItems={'center'} spacing={2}>
                        <Text w={60} fontWeight={'semibold'}>
                            Caption:
                        </Text>
                        <Textarea variant='filled' borderColor='gray.300'
                            placeholder='Caption' value={state.caption}
                            onChange={e => form.setValue('caption', e.target.value)}
                            rows={4} />
                    </HStack>

                    <HStack alignItems={'center'} spacing={2}>
                        <Text w={60} fontWeight={'semibold'}>
                            Link:
                        </Text>
                        <Textarea variant='filled' borderColor='gray.300'
                            placeholder='Link' value={state.link}
                            onChange={e => form.setValue('link', e.target.value)}
                            rows={4} />
                    </HStack>
                    <HStack alignItems={'center'} spacing={2}>
                        <Text w={60} fontWeight={'semibold'}>
                            Show:
                        </Text>
                        <SelectField tw={120} form={form} id='show'
                            list={[true, false].map(item => ({ label: item.toString(), value: item.toString() }))} />
                    </HStack>
                </VStack>



                <HStack w={'100%'} justifyContent={'space-between'} spacing={4} mt={4} >
                    <Button w={250} colorScheme="teal" size="lg" onClick={pulish} isLoading={isLoading || isLoadingImage} >
                        Update
                    </Button>
                    <Button w={250} colorScheme="red" size="lg" onClick={Delete} isLoading={DeleteLoading} >
                        Delete
                    </Button>
                </HStack>
            </VStack>


        </VStack>
    )
}

export default EditSponsorReels