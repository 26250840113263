import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { getRefLinks } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const RefLinks = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getRefLinks,
        name: 'getRefLinks',
    })

    return (
        <VStack alignItems={'flex-start'} width={'100%'} >
            <CompHeader title={'Referral Links'} buttonTitle='Create Referral Links' buttonLink={'/admin/ref_link/create'} />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default RefLinks