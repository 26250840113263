import { Button } from "native-base";
import { getAppLinks } from "../../helper/applink";
import { formatDateTimes } from "../../utils/moment";
import { capitalize } from "../../helper/stringOperations";

const range_labels = ['low', 'average', 'medium', 'high', 'luxury']

export const getRangeLabels = (range) => {
    return range_labels.filter((label, index) => range.includes(index))
        .map(capitalize)
        .join(', ')
}

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/reviewStore/${row._id}`)}
        >
            Review</Button>,
        sortable: false,
    },
    {
        name: 'Date Registered',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Name',
        selector: (row) => row.store_name,
        sortable: true,
    },
    {
        name: 'Rating / Total Reviews',
        selector: (row) => `${row.rating.rating} / ${row.rating.count}`,
        sortable: true,
    },
    {
        name: 'Views',
        selector: (row) => row.store_visits?.allTime,
        sortable: true,
    },
    {
        name: 'subscribers',
        selector: (row) => row.subscribers?.allTime,
        sortable: true,
    },
    {
        name: 'Points',
        selector: (row) => row.points,
        sortable: true,
    },
    {
        name: 'State',
        selector: (row) => row.store_address.state?.name,
        sortable: true,
    },
    {
        name: 'City',
        selector: (row) => row.store_address.city?.name,
        sortable: true,
    },
    {
        name: 'Budget',
        selector: (row) => getRangeLabels(row.range_type),
        sortable: true,
    },
    {
        name: 'Pre-Register',
        selector: (row) => row.pre_register ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'Claimed',
        selector: (row) => row.claimed ? 'Yes' : 'No',
        sortable: true,
    },
    {
        name: 'App Link',
        button: true,
        cell: (row) => <Button
            color='blue.400'
            size='sm' variant={'link'}
            onPress={() => ['exclusive', 'non-branded'].includes(row.type) ? getAppLinks(`/store/${row._id}`) : getAppLinks(`/mutliBrandStore/${row._id}`)
            } >
            copy</Button>,
        sortable: false,
    },
] 