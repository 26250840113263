import React from 'react'
import { VStack, Button } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteLatestStylesById, getLatestStylesById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import EditProduct from './EditProduct/EditProduct'
import useSubmit from '../../hook/useSubmit'

const EditStoreUpload = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading, refetch } = useFetch({
        name: `getLatestStylesById_${id}`,
        service: getLatestStylesById,
        id
    })

    const { submit, isLoading: isDeleting } = useSubmit({
        service: deleteLatestStylesById,
        id,
        onSuccess: () => navigate(-1)
    })

    if (isLoading) return <Loading />

    const _data = {
        ...data,
        product: { value: data?.product?._id, label: data?.product?.name },
        brand: { value: data?.brand?._id, label: data?.brand?.brand },
        wear: { value: data?.wear?._id, label: data?.wear?.wear },
        gender: { value: data.gender?._id, label: data.gender?.type },
        filters: data?.filters ? data?.filters?.map(({ title, keys }) => ({
            title: title._id,
            keys: keys.map(({ _id }) => _id)
        })) : [],
    }

    return (
        <VStack w="100%" alignItems="flex-start" justifyContent="flex-start" pb={20}>
            <CompHeader title='Edit Store Upload' backButton />
            <EditProduct data={_data} refetch={refetch} />

            <Button isLoading={isDeleting} onClick={() => {
                if (window.confirm('Are you sure you want to delete this product?')) {
                    submit()
                }
            }}
                colorScheme="red" variant="solid" w='100%' mt={5} borderRadius='lg' borderWidth={0} maxW={200}
                bg='red.500' color='white' _hover={{ bg: 'red.600' }} >
                Delete
            </Button>
        </VStack>
    )
}

export default EditStoreUpload