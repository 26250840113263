import { Button } from '@chakra-ui/react';
import React from 'react';

const text = `
🚨 * Kya Aapne Miss Kar Diya Season Ka Sabse Bada Sale ?* 😔 Store pahunch kar pata chala * Sale Khatam *?

🌟Par Ab, Fikar Not! 🌟

💡 * OFFINGO * Ke Saath, Apni City Mein kapdo ki Shopping Ho Jaye Super Aasan! 📲

Bas Ek Click Aur Paaye * Sare Top Discounts * Aapke Hatheli Par, Wo Bhi Ghar Se Nikalne Se Pehle! Kyun Karein Andhere Mein Shopping Jab Offingo Aapka Digital Shopping Assistant Ban Sakta Hai ?

👉 Download * 'OFFINGO' * Abhi aur ban jayein Smart Shopper! 🛍️💖
https://offn.in/offingo

* #SmartShopping #Offingo #LocalShopping #DiscountsBefikar *
`

const ShareWithWhatsApp = () => {
    const imageUrl = 'https://offingo.s3.ap-south-1.amazonaws.com/offingo-label-red-bg.png';

    const shareWithWhatsApp = () => {
        const url = `whatsapp://send?text=${encodeURIComponent(text)}&amp;image=${encodeURIComponent(imageUrl)}`;

        window.location.href = url;
    };

    return (
        <div>
            <h1>Share with WhatsApp</h1>
            {/* <img src={imageUrl} alt="Your Image" /> */}
            <Button onClick={shareWithWhatsApp}>Share on WhatsApp</Button>
        </div>
    );
};

export default ShareWithWhatsApp;
