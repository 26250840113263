import React from 'react'
import { formatDateTimes, fromNow } from '../../utils/moment';
import { HStack, Image, Text, VStack } from '@chakra-ui/react'
import { IMAGE_BASE_URL } from '../../helper/variables';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../theme/colors';
import Tile from '../../commons/Tile';


const UpdateTile = ({ data }) => {
    const { socket_type } = data
    const isNew = socket_type.includes('new')
    const navigate = useNavigate()

    const newProps = (isNew) => isNew ? {
        color: isNew ? colors.primary : colors.dark,
    } : {}

    const contProps = {
        borderBottomWidth: 1,
        borderColor: colors.secondary,
        pb: 2,
        mb: 2,
        spacing: 0,
        w: '100%',
        align: 'flex-start'
    }

    const timeStr = (time) => {
        return `${formatDateTimes(time, 4)} - ${fromNow(time, 3)}`
    }

    if (socket_type.includes('enq3')) {
        return (
            <HStack  {...contProps} spacing={4}>
                <VStack w='100%' align='flex-start' spacing={0} pb={4}>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        New {' '}
                        <Text as='span' fontWeight={'bold'}>
                            Style Enquiry {" "}
                        </Text>
                        {isNew ? '' : 'was'} Received - {' '}
                        <Text fontWeight="bold" as='span' cursor={'pointer'} color={colors.info}
                            onClick={() => window.open(`/admin/CBS/${data._id}`)} >
                            Details
                        </Text>
                    </Text>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        {data?.store_matches?.flat()?.length} Matches
                    </Text>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        Send to {data?.store?.length} Stores
                    </Text>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        Status - {data?.status}
                    </Text>
                    <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                        {timeStr(data.createdAt, 4)}
                    </Text>
                </VStack>
            </HStack>
        )
    }
    if (socket_type.includes('enq2')) {
        return (
            <HStack  {...contProps} spacing={4}>
                <VStack w='100%' align='flex-start' spacing={0} pb={4}>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        New {' '}
                        <Text as='span' fontWeight={'bold'}>
                            Specific Enquiry {" "}
                        </Text>
                        {isNew ? '' : 'was'} Received - {' '}
                        <Text fontWeight="bold" as='span' cursor={'pointer'} color={colors.info}
                            onClick={() => window.open(`/admin/CBS/${data._id}`)} >
                            Details
                        </Text>
                    </Text>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        {data?.store_matches?.flat()?.length} Matches
                    </Text>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        Send to {data?.store?.length} Stores
                    </Text>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        Status {data?.status}
                    </Text>
                    <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                        {timeStr(data.createdAt, 4)}
                    </Text>
                </VStack>
            </HStack>
        )
    }

    if (socket_type.includes('enq1')) {
        return (
            <HStack  {...contProps} spacing={4}>
                <Image w={20} h={20} src={IMAGE_BASE_URL + data?.style?.images?.[0]}
                    transition="transform .2s" borderRadius={'md'}
                    _hover={{ transform: 'scale(3.0)', borderRadius: 'sm', }} />
                <VStack w='100%' align='flex-start' spacing={0} pb={4}>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        New {' '}
                        <Text as='span' fontWeight={'bold'}>
                            Available Enquiry {" "}
                        </Text>
                        {isNew ? '' : 'was'} Received - {' '}
                        <Text fontWeight="bold" as='span' cursor={'pointer'} color={colors.info}
                            onClick={() => window.open(`/admin/reviewStore/${data?.store?._id}`)} >
                            {data?.store?.store_name}
                        </Text>
                    </Text>
                    <HStack>
                        <Text fontSize="sm"   {...newProps(isNew)}>
                            {data?.pre_register && data?.claimed ? 'Claimed Store' :
                                data?.pre_register ? 'Pre-Register Store' :
                                    data?.claimed ? 'New Store' : ''}
                        </Text>
                    </HStack>
                    <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                        {timeStr(data.createdAt, 4)}
                    </Text>
                </VStack>
            </HStack>
        )
    }

    if (socket_type.includes('logincode')) {
        return (
            <VStack {...contProps}>
                <Text fontSize="sm"  {...newProps(isNew)}>
                    New Login Code Generated for Phone number
                    <Text as='span' fontSize="sm" fontWeight="bold">
                        {' '}{data.phone_number}
                    </Text>
                    {' '} - Code
                    <Text as='span' fontSize="sm" fontWeight="bold">
                        {' '}{data.code}
                    </Text>
                </Text>
                <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                    {timeStr(data.createdAt, 4)}
                </Text>
            </VStack>
        )
    }

    if (socket_type.includes('store')) {
        return (
            <HStack  {...contProps} spacing={4}>
                <Image w={20} h={20} src={IMAGE_BASE_URL + data?.store_images?.[0]}
                    transition="transform .2s" borderRadius={'md'}
                    _hover={{ transform: 'scale(3.0)', borderRadius: 'sm', }} />
                <VStack w='100%' align='flex-start' spacing={0} pb={4}>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        New Store {isNew ? '' : 'was'} Added - {' '}
                        <Text fontWeight="bold" as='span' cursor={'pointer'} color={colors.info}
                            onClick={() => window.open(`/admin/reviewStore/${data?._id}`)} >
                            {data?.store_name}
                        </Text>
                    </Text>
                    <HStack>
                        <Text fontSize="sm"   {...newProps(isNew)}>
                            {data?.pre_register && data?.claimed ? 'Claimed Store' :
                                data?.pre_register ? 'Pre-Register Store' :
                                    data?.claimed ? 'New Store' : ''}
                        </Text>
                    </HStack>
                    <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                        {timeStr(data.createdAt, 4)}
                    </Text>
                </VStack>
            </HStack>
        )
    }

    if (socket_type.includes('offer')) {
        return (
            <VStack {...contProps}>
                <Text fontSize="sm" {...newProps(isNew)} onClick={() => window.open(`/admin/offers/edit/${data?._id}`)}>
                    New Offer {isNew ? '' : 'was'} Added
                    <Text as='span' fontSize="sm" fontWeight="bold" >
                        {' '}   {data.offer}
                    </Text>
                </Text>
                <HStack>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        Brands - {' '}
                        <Text fontWeight="bold" as='span' cursor={'pointer'}>
                            {data?.brands?.map(brand => brand?.brand).join(', ')}
                        </Text>
                    </Text>
                </HStack>
                <HStack>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        Store Name - {' '}
                        <Text fontWeight="bold" as='span' cursor={'pointer'}
                            onClick={() => window.open(`/admin/reviewStore/${data?.store_id?._id}`)} >
                            {data?.store_id?.store_name}
                        </Text>
                    </Text>
                </HStack>
                <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                    {timeStr(data.createdAt, 4)}
                </Text>
            </VStack>
        )
    }

    if (socket_type.includes('style')) {
        return (
            <HStack  {...contProps} spacing={4}>
                <Image w={20} h={20} src={IMAGE_BASE_URL + data?.images?.[0]}
                    transition="transform .2s" borderRadius={'md'}
                    _hover={{ transform: 'scale(3.0)', borderRadius: 'sm', }} />
                <VStack w='100%' align='flex-start' spacing={0} pb={4}>
                    <Text fontSize="sm"   {...newProps(isNew)}>
                        New Style {isNew ? '' : 'was'} Added -
                        <Text fontWeight="bold" as='span'>
                            {data?.display_name || data?.name}
                        </Text>
                    </Text>
                    <HStack>
                        <Text fontSize="sm"   {...newProps(isNew)}>
                            Store Name - {' '}
                            <Text fontWeight="bold" as='span' cursor={'pointer'}
                                onClick={() => window.open(`/admin/reviewStore/${data?.store?._id}`)} >
                                {data?.store?.store_name}
                            </Text>
                        </Text>
                    </HStack>
                    <Text fontSize="xs" fontWeight="normal" color="gray.500" >
                        {timeStr(data.createdAt, 4)}
                    </Text>
                </VStack>
            </HStack>
        )
    }
}

export default UpdateTile