import React from 'react'
import AddProduct from '../AddProduct'
import { Button, Text, VStack } from '@chakra-ui/react'
import Tile from '../../../commons/Tile';
import { colors } from '../../../theme/colors';
import { HStack, Image } from 'native-base';
import CModal from '../../../commons/CModal';
import { IMAGE_BASE_URL } from '../../../helper/variables';

const contProps = { borderWidth: 0, w: '100%', bg: colors.light, alignItems: 'flex-start', p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const ProductDetails = ({ data, refetch }) => {
    const [edit, setEdit] = React.useState(false)

    return (
        <VStack {...contProps} position='relative'>

            <HStack w='100%' justifyContent='space-between' alignItems='center' >
                <VStack  {...contProps} p={0}>
                    <Text fontSize={'xl'} fontWeight={'bold'}>Basic</Text>
                    <Tile titleWidth='100' label='Name' value={data.name} />
                    <Tile titleWidth='100' label='Id' value={data._id} />
                    <Tile titleWidth='100' label='Tags' value={data.tags.join(', ')} />
                    <Tile titleWidth='100' label='Range' value={data.range.join('/')} />
                </VStack>
                <HStack space={1}>
                    {[...data.images].map((image, index) =>
                        <Image src={`${IMAGE_BASE_URL}${image}`} height={200} width={150} borderRadius={10} mt={10} />
                    )}
                </HStack>
            </HStack>


            <HStack position={'absolute'} right={5} space={4}>
                <Button onClick={() => { window.open(`/admin/product/thumbnails/${data._id}`, '_blank') }}
                    textColor={colors.primary} variant='link'>
                    Edit Thumbnail
                </Button>
                <Button onClick={() => setEdit(true)} textColor={colors.primary} variant='link'>
                    Edit Product
                </Button>
            </HStack>

            {edit && (
                <CModal
                    title={'Edit Product'}
                    show={edit}
                    close={() => setEdit(false)}
                    body={<AddProduct data={data} refetch={refetch} close={() => setEdit(false)} />}
                />
            )}

        </VStack>
    )
}

export default ProductDetails