export const createBreaks = (start, end) => {
    const numBreaks = 50;
    const step = (end - start) / numBreaks;
    const arr = [];
    for (let i = start; i <= end; i += step) {
        arr.push(i);
    }

    return arr;
}

export const createRange = (min, max, level = 100) => {
    const range = [];
    const step = (max - min) / level
    for (let i = min; i <= max;) {
        range.push(i);
        i += step;
    }
    return range;

}