export const selectConverter = ({ list = [], label = '', value = '_id', sort }) => {
    const _list = list.map(item => {
        if (Array.isArray(item)) {
            return {
                label: item[0],
                value: item[1]
            }
        }
        return {
            label: item[label],
            value: item[value]
        }
    })


    if (sort) return _list.sort((a, b) => a.label.localeCompare(b.label))

    return _list
} 