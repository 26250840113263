import { Button } from 'native-base';
import { formatDateTimes } from '../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Code',
        selector: (row) => row.code,
        sortable: true,
    },
    {
        name: 'IS QR',
        selector: (row) => row.is_qr ? "Yes" : "No",
        sortable: true,
    },
    {
        name: 'Short Link',
        selector: (row) => row.final_link,
        sortable: true,
    },
    {
        name: 'Deep Link',
        selector: (row) => row.dynamic_link,
        sortable: true,
    },
    {
        name: 'Link',
        selector: (row) => row.link,
        sortable: true,
    },
    {
        name: 'Active',
        selector: (row) => row.active,
        sortable: true,
    },
    {
        name: 'Hits',
        selector: (row) => row.hits,
        sortable: true,
    },
    {
        name: 'Created on',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/link_shortener/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]