import { HStack, Button, Spacer, Text, VStack, Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid } from '@chakra-ui/react';
import { CloseIcon, Box, } from 'native-base'
import { getStoreList } from '../../apis/admin';
import { colors } from '../../theme/colors';
import React, { useMemo } from 'react'
import useSubmit from '../../hook/useSubmit';
import { useEffect } from 'react';

const GetStoreFromFilters = ({ filters, form, store_id }) => {
    const state = { ...form.getValues() }
    const { stores } = state
    const [page, setPage] = React.useState(1)

    const { submit, isLoading } = useSubmit({
        service: getStoreList,
        props: {
            data: {
                ...filters,
                state: filters.state.length ? filters.state.map(({ value }) => value) : [],
                city: filters.city.length ? filters.city.map(({ value }) => value) : [],
            }
        },
        onSuccess: (data) => {
            form.setValue('stores', data)
            setPage(1)
        }
    })

    useMemo(() => { }, [form.watch()])

    useEffect(() => {
        if (!store_id) return
        submit({ store_ids: [store_id] })
    }, [store_id])

    const removeStore = (id) => form.setValue('stores', stores.filter(store => store._id !== id))

    return (
        <VStack w='100%' space='2' p='2' borderRadius={'lg'} borderWidth={1} borderColor={colors.border}>
            <HStack w='100%' justifyContent='space-between' alignItems='center' flexWrap='wrap' >
                <Button w='150' bg={colors.info} variant='solid' isLoading={isLoading}
                    size={'sm'} onClick={() => submit()} isDisabled={store_id} >
                    Find Stores</Button>
                <Spacer />
                <Text onClick={() => page !== 1 && setPage(page - 1)}
                    color={page === 1 ? colors.secondary : colors.info}
                    cursor={page === 1 ? 'not-allowed' : 'pointer'} >
                    Previous
                </Text>
                <Spacer />
                <Text>
                    In View  from {page * 100 - 100} to {page * 100 > stores.length ? stores.length : page * 100}
                </Text>
                <Spacer />
                <Text
                    onClick={() => page * 100 < stores.length && setPage(page + 1)}
                    color={page * 100 > stores.length ? colors.secondary : colors.info}
                    cursor={page * 100 < stores.length ? 'pointer' : 'not-allowed'}
                >
                    Next
                </Text>
                <Spacer />
                <Text fontWeight={'semibold'}>Stores count: {stores.length}</Text>
            </HStack>
            <Spacer />
            <HStack space='2' alignItems='center' w='100%' >
                <HStack space='2' alignItems='center' >
                    <Text color={colors.success}>
                        Claimed({stores.filter(store => store.claimed).length})
                    </Text>
                    <Box bg={colors.success} w='4' h='4' borderRadius={'full'} />
                </HStack>
                <HStack space='2' alignItems='center' >
                    <Text color={colors.warning}>
                        Pre Register({stores.filter(store => !store.claimed).length})
                    </Text>
                    <Box bg={colors.warning} w='4' h='4' borderRadius={'full'} />
                </HStack>
            </HStack>
            <VStack maxH='800' overflowY={'scroll'} w='100%'>
                <SimpleGrid columns={6} spacing='2' w='100%'>
                    {stores
                        .slice(page * 100 - 100, page * 100)
                        .sort((a, b) => a.claimed - b.claimed)
                        .reverse()
                        .map((item) =>
                            <HStack borderWidth={2} borderRadius={'sm'} alignItems='center'
                                borderColor={item.claimed ? colors.success : colors.warning}
                                key={item._id} w='100%' zIndex={1}
                                py='2' pl='2' >
                                <VStack spacing={0} alignItems='flex-start' zIndex={100} >
                                    <Text cursor={'pointer'}
                                        fontSize={item?.store_name?.length > 20 ? 'xs' : 'sm'}
                                    >{item.store_name.substr(0, 20)}</Text>
                                    <Text fontSize={'10'}>
                                        {item?.store_address?.address?.substr(0, 40)}
                                    </Text>
                                </VStack>
                                <Spacer />
                                <Box cursor='pointer' p='2' onClick={() => removeStore(item._id)} >
                                    <CloseIcon color='black' />
                                </Box>
                            </HStack>
                        )}
                </SimpleGrid>
            </VStack>
        </VStack>
    )
}

export default GetStoreFromFilters