import React from 'react'
import { Button } from '@chakra-ui/react';

const ExcelDownload = ({ service, params }) => {

    const [isLoading, setLoading] = React.useState(false);

    const downloadExcelFile = async () => {
        try {
            setLoading(true);
            const response = await service({
                params: {
                    ...params,
                    responseType: 'arraybuffer',
                    get_excel: true,
                }
            })
            const downloadlink = response?.data?.result?.downloadlink

            if (!downloadlink) {
                window.alert('No data found for download')
            } else {
                window.open(downloadlink, '_blank');
            }

            setLoading(false);

        } catch (error) {
            console.error('Error downloading Excel file:', error);
            setLoading(false);
        }
    };

    return (
        <Button onClick={downloadExcelFile} isLoading={isLoading} colorScheme='teal' variant='solid' size='md'
            m={4} maxW='200px'
        >
            Download Excel
        </Button>
    )
}

export default ExcelDownload