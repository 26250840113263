import React from 'react'
import { VStack, HStack, Spacer, Text } from '@chakra-ui/react'
import ReactSelect from 'react-select'

// const range_labels = ['low', 'average', 'medium', 'high', 'luxury']
const range_labels = [
    { value: 1, label: 'low' },
    { value: 2, label: 'average' },
    { value: 3, label: 'medium' },
    { value: 4, label: 'high' },
    { value: 5, label: 'luxury' },
]

const StoreBudget = ({ form, viewOnly }) => {
    return (
        <VStack space={3} w='100%' alignItems='flex-start'>

            <HStack alignItems={'center'} w='100%'>
                <Text fontWeight={'semibold'} fontSize='md' >
                    Store Budget
                </Text>
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            form.setValue('range_type', form.watch('default.range_type'))
                        }}>
                        Reset Store Budget
                    </Text>}
            </HStack>

            <ReactSelect
                styles={{ width: '100%', flex: 1 }}
                options={range_labels} isMulti
                isDisabled={viewOnly}
                value={form.watch('range_type')?.map(item => range_labels.find(obj => obj.value === item))}
                onChange={(val) => {
                    form.setValue('range_type', val?.map(item => item.value))
                }}
            />

        </VStack>
    )
}

export default StoreBudget