import { Button } from "native-base";
export const columns = (navigate) => [
    {
        name: 'Code',
        selector: (row) => row.code,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Perks',
        selector: (row) => row.perks.join(', '),
        sortable: true,
        width: '30%',
    },
    {
        name: 'Price',
        selector: (row) => `₹ ${row.price}`,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Duration',
        selector: (row) => `${row.duration} days`,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Status',
        selector: (row) => row.status,
        sortable: true,
        width: '10%',
    },
    {
        name: 'Active users',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/subscriptions/user/users/${row._id}`)}>
            Users</Button>,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/subscriptions/user/edit/${row._id}`)}>
            Edit</Button>,
        sortable: false,
    },
]


export const staticData = {
    status: ["Active", "Inactive"].map(item => ({ label: item, value: item })),
    yes_no: ["Yes", "No"].map(item => ({ label: item, value: (item === 'Yes').toString() })),
}


export const properties = [
    {
        label: 'Enquiry Limit', value: 'enquiry_limit', list: null,
        desc: 'Enquiry Limit is the number of enquiries a user can send in a package.',
    },
    {
        label: 'Enquiry Gap', value: 'enquiry_gap', list: null,
        desc: 'Enquiry Gap is the number of days between two enquiries.',
    },
    {
        label: 'Daily Limit', value: 'daily_limit', list: null,
        desc: 'Daily Limit is the number of enquiries a user can send in a day.',
    },
    {
        label: 'Rollover Limit', value: 'rollover_limit', list: null,
        desc: 'Rollover Limit is the number of times use can extend enquiry rounds.',
    },
    {
        label: 'Price Per Response', value: 'price_per_response', list: null,
        desc: 'Price Per Response is the option to charge per response.',
    },
    {
        label: 'Serial No Identification', value: 'serial_no_identification', list: staticData.yes_no,
        desc: 'Serial No Identification is the option to identify the particular cloth.',
    },
    {
        label: 'Include Checklist', value: 'include_checklist', list: staticData.yes_no,
        desc: 'Include Checklist is the option to include checklist in the enquiry.',
    },
    {
        label: 'Store Selection', value: 'store_selection', list: staticData.yes_no,
        desc: 'Store Selection is the option to select the store.',
    },
    {
        label: 'Custom Area', value: 'custom_area', list: staticData.yes_no,
        desc: 'Custom Area is the option to select the custom location.',
    },
    {
        label: 'Best Deal', value: 'best_deal', list: staticData.yes_no,
        desc: 'Provide lowest price from all the stores.',
    },
]