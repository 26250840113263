import React from 'react'
import { VStack } from 'native-base';
import { Heading } from 'native-base';

const NotFound = () => {
    return (
        <VStack alignItems='center'>
            <Heading mb='4' size='xl'>
                Page not found
            </Heading>
            <Heading mb='4' size='xl'>
                or
            </Heading>
            <Heading mb='4' size='xl'>
                You are not authorized to access this page
            </Heading>
        </VStack>
    )
}

export default NotFound