import React, { useEffect } from 'react'
import { VStack } from 'native-base'
import UpdateTile from './UpdateTile'
import useFetch from '../../hook/useFetch'
import useAppUpdator from './useAppUpdator'
import { Box, HStack, Text } from '@chakra-ui/react'
import { getRealtimeAppUpdates } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'

const tabs = [
    { label: 'All', value: 'all', },
    { label: 'Offers', value: 'offer', },
    { label: 'Stores', value: 'store', },
    { label: 'Login codes', value: 'logincode', },
    { label: 'Styles', value: 'style', },
    { label: 'Ava. Enq', value: 'enq1', },
    { label: 'Spe. Enq', value: 'enq2', },
    { label: 'Style Enq', value: 'enq3', },
]

const AppUpdates = () => {
    const [data, setData] = React.useState([])
    const [active, setActive] = React.useState('all')

    const { refetch } = useFetch({
        service: getRealtimeAppUpdates,
        onSuccess: (res) => setData(res)
    })

    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, 1000 * 60 * 10); // 10 min
        return () => clearInterval(interval);
    }, []);


    useAppUpdator({ setData })

    const list = data.filter(item => active === 'all' ? item : item.socket_type.includes(active))

    return (
        <VStack>
            <CompHeader title={`App Updates (${list.length} logs)`} />

            <HStack alignItems={'center'} mb={5}>
                {tabs.map((item) =>
                    <Box onClick={() => setActive(item.value)}
                        cursor="pointer" key={item} p={2} borderRadius={10}
                        bg={active === item.value ? 'blue.500' : 'gray.100'}
                        color={active === item.value ? 'white' : 'black'}
                        _hover={{ bg: 'blue.500', color: 'white' }} >
                        <Text key={item.value} fontSize="sm" fontWeight="bold">
                            {item.label}
                        </Text>
                    </Box>
                )}
            </HStack>

            {list.map((item) => <UpdateTile data={item} key={item._id} />)}
        </VStack>
    )
}

export default AppUpdates