import { formatDateTimes } from '../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Activity',
        selector: (row) => row.message,
        sortable: true,
    },
    {
        name: 'Time',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    }
]