import { createStoreSubscriptions, deleteStoreSubscriptionsById, editStoreSubscriptionsById } from '../../apis/admin'
import { Box, Button, HStack, Spacer, Text, VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { InputField } from '../../commons/InputField'
import SelectField from '../../commons/SelectField'
import { useNavigate } from 'react-router-dom'
import useSubmit from '../../hook/useSubmit'
import { useForm } from 'react-hook-form'
import React, { useMemo, useState } from 'react'
import { Divider } from '@chakra-ui/react'
import { properties } from './static'

const defaultValues = {
    code: '', name: '', perks: [''], price: '', duration: '', status: '',
    properties: {
        home_page: 'false',
        product_upload_count: 10,
        better_listing: 'false',
        custom_offers_approval_faster: 'false',
        enquiries_count: 10,
    }
}
const staticData = { status: ["Active", "Inactive"] }

const CreateStoreSubscriptions = ({ data }) => {
    if (data) {
        Object.keys(data?.properties).map((obj) => {
            if (typeof data?.properties[obj] === 'boolean') {
                data.properties[obj] = data.properties[obj].toString()
            }
        })
    }
    const form = useForm({ defaultValues: data ? data : defaultValues })
    const state = form.getValues()
    useMemo(() => { }, [form.watch()])
    const navigate = useNavigate()
    const [showDesc, setShowDesc] = useState(false)

    const { submit, isLoading } = useSubmit({
        service: data ? editStoreSubscriptionsById : createStoreSubscriptions,
        onSuccess: () => navigate(-1),
        id: data?._id,
    })

    const create = () => {
        const state = { ...form.getValues() }
        state.perks = state.perks.filter(perk => perk !== '')
        submit(state)
    }

    const Delete = useSubmit({
        service: deleteStoreSubscriptionsById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    return (
        <Box>
            {!data && <CompHeader title='Create Subscriptions' backButton />}
            <VStack w='100%' space='2'>
                <InputField tw='200' form={form} title='Code' id='code' />
                <InputField tw='200' form={form} title='Name' id='name' />

                <Divider />
                <HStack>
                    <Text fontSize={'xl'} fontWeight='600'>Properties</Text>
                    <Spacer />
                    <Button size={'sm'} variant={'link'} onPress={() => setShowDesc(!showDesc)}>
                        <Text color={!showDesc ? 'blue.400' : "gray.500"} > {showDesc ? 'Hide' : 'Show'} Description </Text>
                    </Button>
                </HStack>

                {properties.map(({ label, value, desc, list }) => {

                    const props = {
                        tw: '200',
                        desc,
                        form,
                        title: label,
                        id: `properties.${value}`,
                        showDesc,
                    }

                    if (list)
                        return <SelectField {...props} list={list} />

                    return <InputField {...props} />

                })}


                <Divider />

                <Text fontSize={'xl'} fontWeight='600'>Perks</Text>
                <VStack space='2'>
                    {state.perks.map((perk, index) =>
                        <VStack space='0'>
                            <InputField tw='200' key={`index${index}`} form={form} title={`Perk ${index + 1}`} id={`perks[${index}]`} />
                            <Button size={'sm'}
                                w='18'
                                mt='-8'
                                mr='4'
                                _text={{ fontSize: 'xs', color: 'red.400' }}
                                alignSelf='flex-end'
                                onPress={() => form.setValue(`perks`, state.perks.filter((_, i) => i !== index))}
                                variant={'link'}
                            >remove</Button>
                        </VStack>
                    )}
                    <Button size={'sm'}
                        onPress={() => form.setValue(`perks`, [...state.perks, ''])}
                        alignSelf='flex-end' variant={'link'}>Add More</Button>
                </VStack>

                <Divider />

                <InputField tw='200' form={form} title='Price' id='price' />
                <InputField tw='200' form={form} title='Duration(days)' id='duration' />
                <SelectField tw='200' form={form}
                    list={staticData.status.map(item => ({ label: item, value: item }))}
                    title='Status' id='status' />
                <HStack mt='2'>
                    <Button size='sm' isLoading={isLoading} onPress={create} >
                        {data ? 'Save' : 'Create subscription'}
                    </Button>
                    <Spacer />
                    {data && <Button m='auto'
                        colorScheme={'red'}
                        isLoading={Delete.isLoading}
                        onPress={Delete.submit} size='sm' >
                        Delete subscription
                    </Button>}
                </HStack>
            </VStack>
        </Box>
    )
}

export default CreateStoreSubscriptions