import React from 'react'
import { Box } from 'native-base'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import { getStoreSubscriptions, getUserSubscriptions } from '../../apis/admin'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'

const StoreSubscriptions = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getStoreSubscriptions',
        service: getStoreSubscriptions,
    })

    if (isLoading) return <Loading />

    return (
        <Box>
            <CompHeader
                title='Store Subscriptions'
                buttonTitle='Add New Store Subscription'
                onPress={() => navigate('/admin/subscriptions/store/new')}
            />
            <DataTable
                showSearch={false}
                tableData={{ columns: columns(navigate), data }}
            />
        </Box>
    )
}


export default StoreSubscriptions