import { Button, HStack, Input, Spacer, Text, VStack } from '@chakra-ui/react';
import SelectField from '../../commons/SelectField';
import { InputField } from '../../commons/InputField';
import { colors } from '../../theme/colors';
import React from 'react';
import { createOfferlist } from './static';

const CreateOfferBox = ({ form, offer, index, templates, products, genders }) => {
    const i = index
    const offers = form.watch('offers')
    return (
        <VStack w='100%' p='2' borderWidth={1} borderRadius={'lg'}
            borderColor={colors.border} spacing='2' alignItems={'flex-start'}
        >
            <HStack w='100%'>
                <Text fontSize="md" fontWeight='bold' mb='2'>Offer {i + 1} Details</Text>
                <Spacer />
                <Button variant={'solid'} bg='red.400'
                    textColor={'white'} alignSelf={'flex-end'}
                    size='sm' bgColor='rosybrown'
                    onClick={() => form.setValue('offers', [...offers, offer])}>
                    Duplicate</Button>
                {i !== 0 && <Button variant={'solid'} bg='red.400'
                    textColor={'white'} alignSelf={'flex-end'}
                    size='sm' bgColor='red'
                    onClick={() => form.setValue('offers', [...offers.filter((item, j) => i !== j)])}>
                    Remove</Button>}
            </HStack>

            {createOfferlist({ form, i, templates, products, genders, offers, offer }).map(item => {
                const Comp = item.type === 'select' ? SelectField : InputField
                return <Comp tw={100} form={form} {...item} />
            })}
        </VStack>
    )
}

export default CreateOfferBox