const getNumberStringFromNumber = (number) => {
    switch (number) {
        case 1: return '1st'
        case 2: return '2nd'
        case 3: return '3rd'
        default: return `${number}th`
    }
}


export const isValid = (value) => {
    const filters = value?.filtersData || []
    const allRequiredFiltersSelected = (() => {
        const selectedFilters = value?.filters?.filter(({ title }) => filters?.includes(title))
        const emptyFilters = selectedFilters?.filter(({ keys }) => keys.length === 0)

        if (emptyFilters?.length === 0 && selectedFilters?.length === filters?.length)
            return true
        return false
    })()
    let errorStr = ''

    switch (true) {
        case !value.product:
            errorStr = 'Item is required'
            break;
        case !value.gender:
            errorStr = 'Gender is required'
            break;
        case !value.wear:
            errorStr = 'Wear is required'
            break;
        case !value.price:
            errorStr = 'Price is required'
            break;
        case !allRequiredFiltersSelected:
            errorStr = 'Please select all the required filters'
            break;
        default:
            return '';
    }


    return errorStr
}