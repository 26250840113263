import React from 'react'
import { VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useParams } from 'react-router-dom'
import { getmodbyid } from '../../apis/admin'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import CreateMod from './CreateMods'


const EditMod = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `mod_${id}`,
        service: getmodbyid,
        id
    })

    return (
        <VStack p='3' space='2' w={'100%'}>
            <CompHeader title='Edit Moderator' backButton />
            {isLoading ? <Loading /> : <CreateMod data={data} />}
        </VStack>
    )
}

export default EditMod