import { Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { IMAGE_BASE_URL } from '../../../helper/variables'

const MallCard = ({ item }) => {
    return (
        <VStack space='2' w={'fit-content'} bg='#cfcfcf4D'
            py='2' px='2' borderRadius='md' >
            <Image w={'120'} h={'32'} borderRadius='md' objectFit={'contain'}
                src={`${IMAGE_BASE_URL}${item.image}`} alt="image base" />
            <Text textAlign={'center'} fontSize='sm' fontWeight='normal'>{item.mall_name}</Text>
        </VStack>
    )
}

export default MallCard