import { Button, HStack } from '@chakra-ui/react';
import { Icon, Text, VStack } from '@chakra-ui/react';
import { FiPhoneIncoming, FiPhoneOutgoing } from "react-icons/fi";
import { formatDateTimes, formatSeconds, formatTimestamp } from '../../utils/moment';
import { capitalize, formatNumber } from '../../helper/stringOperations';

export const columns = ({ navigate, setID }) => [
    {
        name: 'User',
        width: '15%',
        cell: (row) =>
            <HStack spacing={1} alignItems={'center'} >
                <Icon mr="4" fontSize="16" as={row.initiator === 'user' ? FiPhoneOutgoing : FiPhoneIncoming} />
                <VStack alignItems={'flex-start'}>
                    <Text>
                        {row?.user?.name}
                    </Text>
                    <Text>
                        {formatNumber(row?.user?.phone_number)}
                    </Text>
                </VStack>
            </HStack>
        ,
        sortable: true,
    },
    {
        name: 'Store Auth',
        width: '15%',
        cell: (row) =>
            <HStack spacing={1} alignItems={'center'} >
                <Icon mr="4" fontSize="16" as={row.initiator === 'store' ? FiPhoneOutgoing : FiPhoneIncoming} />
                <VStack alignItems={'flex-start'}>
                    <Text>
                        {row?.store_auth?.name}
                    </Text>
                    <Text>
                        {formatNumber(row?.store_auth?.phone_number)}
                    </Text>
                </VStack>
            </HStack>,
        sortable: true,
    },
    {
        name: 'Initiator',
        selector: (row) => row?.initiator,
        sortable: true,
    },
    {
        name: 'Store',
        width: '15%',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Button variant='link' color={'blue.500'} outline={'none'} border={'none'}
                    onClick={() => window.open(`/admin/reviewStore/${row.store_id._id}`)} >
                    {row.store.store_name}
                </Button>
                <Text
                    fontSize={'10'}
                >
                    {row?.store?.store_address?.address}
                </Text>
            </VStack>,
        sortable: true,
    },
    {
        name: 'Type',
        selector: (row) => row?.type,
        sortable: true,
    },
    {
        name: 'Status',
        cell: (row) => row.status === 'answered' ?
            <Text color={'green.500'}>{capitalize(row.status)}</Text>
            :
            <Text color={'red.500'}>{capitalize(row.status)}</Text>,
        sortable: true,
    },
    {
        name: 'Numbers',
        width: '15%',
        cell: (row) => <VStack alignItems={'flex-start'}>
            <Text>
                <Icon mr="4" fontSize="16" as={FiPhoneOutgoing} />
                {formatNumber(row.from)}
            </Text>
            <Text>
                <Icon mr="4" fontSize="16" as={FiPhoneIncoming} />
                {formatNumber(row.to)}
            </Text>
        </VStack>,
        sortable: true,
    },
    {
        name: 'Date',
        width: '8%',
        cell: (row) => <Text>
            {formatDateTimes(row?.createdAt, 3)}
        </Text>,
        sortable: true,
    },
    {
        name: 'Events (from)',
        width: '14%',
        cell: (row) => <VStack alignItems={'flex-start'}>
            {row.events
                .filter((e) => e.leg === 'a')
                .map((e) =>
                    <Text>
                        {capitalize(e.status)} - {formatTimestamp(e.time, 10)}
                    </Text>
                )}
        </VStack>,
        sortable: true,
    },
    {
        name: 'Events (to)',
        width: '14%',
        cell: (row) => <VStack alignItems={'flex-start'}>
            {row.events
                .filter((e) => e.leg === 'b')
                .map((e) =>
                    <Text>
                        {capitalize(e.status)} - {formatTimestamp(e.time, 10)}
                    </Text>
                )}
        </VStack>,
        sortable: true,
    },
    {
        name: 'CDR (From)',
        width: '20%',
        cell: (row) => <VStack alignItems={'flex-start'}>
            {row.cdr
                .filter((e) => e.leg === 'a')
                .map((e) =>
                    <VStack alignItems={'flex-start'}>
                        <Text>
                            Status:{e.status} -
                            <Text as={'span'} fontWeight={'bold'}>
                                ({formatSeconds(e.duration)})
                            </Text>
                        </Text>
                        {e?.answer_time &&
                            <Text>
                                Answer Time: {formatTimestamp(e?.answer_time, 10)}
                            </Text>
                        }
                        {e?.start_time &&
                            <Text>
                                Start Time: {formatTimestamp(e?.start_time, 10)}
                            </Text>
                        }
                        {e?.end_time &&
                            <Text>
                                End Time: {formatTimestamp(e?.end_time, 10)}
                            </Text>
                        }
                        {e?.call_rate &&
                            <Text>
                                Call Rate: {parseFloat(e?.call_rate)?.toFixed(2)}
                            </Text>}
                    </VStack>
                )}
        </VStack>,
        sortable: false,
    },
    {
        name: 'CDR (To)',
        width: '20%',
        cell: (row) => <VStack alignItems={'flex-start'}>
            {row.cdr
                .filter((e) => e.leg === 'b')
                .map((e) =>
                    <VStack alignItems={'flex-start'}>
                        <Text>
                            Status:{e.status} -
                            <Text as={'span'} fontWeight={'bold'}>
                                ({formatSeconds(e.duration)})
                            </Text>
                        </Text>
                        {e?.answer_time &&
                            <Text>
                                Answer Time: {formatTimestamp(e?.answer_time, 10)}
                            </Text>
                        }
                        {e?.start_time &&
                            <Text>
                                Start Time: {formatTimestamp(e?.start_time, 10)}
                            </Text>
                        }
                        {e?.end_time &&
                            <Text>
                                End Time: {formatTimestamp(e?.end_time, 10)}
                            </Text>
                        }
                        {e?.call_rate &&
                            <Text>
                                Call Rate: {parseFloat(e?.call_rate)?.toFixed(2)}
                            </Text>}
                    </VStack>
                )}
        </VStack>,
        sortable: false,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button size='sm' variant={'link'}
            onClick={() => navigate(`/admin/calls/${row._id}`)}>
            View
        </Button>,
        sortable: false,
    },
]