import { Button } from 'native-base';
import { formatDateTimes } from '../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Reason',
        selector: (row) => row.reason?.reason,
        sortable: true,
    },
    {
        name: 'Message',
        selector: (row) => row.message,
        sortable: true,
    },
    {
        name: 'Store',
        selector: (row) => row.store?.store_name,
        sortable: true,
    },
    {
        name: 'Brand',
        selector: (row) => row.brand?.brand,
        sortable: true,
    },
    {
        name: 'Product',
        selector: (row) => row.product?.name,
        sortable: true,
    },
    {
        name: 'User',
        selector: (row) => row.user?.name,
        sortable: true,
    },
    {
        name: 'Offer',
        selector: (row) => row.offer?.offer,
        sortable: true,
    },
    {
        name: 'Status',
        selector: (row) => row.status,
        sortable: true,
    },
    {
        name: 'Last update',
        selector: (row) => formatDateTimes(row.updatedAt, 4),
        sortable: true,
    },
    {
        name: 'Started',
        selector: (row) => formatDateTimes(row.createdAt, 4),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/flags/details/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
]