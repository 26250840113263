import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import AddOfferTemplate from './AddOfferTemplate';

const CreateOfferTemplate = () => {
    return (
        <VStack p='3' space='2' w={'100%'} maxW={500}>
            <CompHeader title='Create Offer Template' backButton />
            <AddOfferTemplate />
        </VStack>
    )
}

export default CreateOfferTemplate