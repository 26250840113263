import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useNavigate } from 'react-router-dom';
import AdminOffersTabs from './AdminOffersTabs';

const AdminOffers = () => {
    const navigate = useNavigate()
    return (
        <VStack>
            <CompHeader title="Admin Offers" buttonTitle='Add Offer'
                onPress={() => navigate('/admin/system_offers/create')} />
            <AdminOffersTabs />
        </VStack>
    )
}

export default AdminOffers