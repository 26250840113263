import React, { useState } from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { VStack } from 'native-base'
import { useForm } from 'react-hook-form'
import SelectField from '../../commons/SelectField'
import { InputField } from '../../commons/InputField'
import { Button, HStack, Spacer, Text } from '@chakra-ui/react'
import { createLinkShortener, deleteLinkShortenerById, editLinkShortenerbyid } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import useSubmit from '../../hook/useSubmit'
import Model from '../../components/compo/UI/Model';
import Tile from '../../commons/Tile';
import { copyToClipboard } from '../../helper/applink'


const defaultValues = {
    code: '',
    high_level: 'false',
    link: '',
}
const types = [
    { label: 'Offingo Business', value: 'business' },
    { label: 'Offingo', value: 'user' },
]



const AddLinkShortener = ({ data }) => {
    const form = useForm({ defaultValues: data || defaultValues })
    const [finalLinkData, setFinalLinkData] = useState(false)
    const navigate = useNavigate()


    const { submit, isLoading } = useSubmit({
        service: data ? editLinkShortenerbyid : createLinkShortener,
        id: data?._id,
        onSuccess: (res) => {
            setFinalLinkData(res?.data || res)
        }
    })

    const Delete = useSubmit({
        service: deleteLinkShortenerById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const save = () => {
        const state = { ...form.getValues() }
        const data = {
            ...state,
            high_level: state.high_level === 'true',
        }
        submit(data)
    }


    const list = [
        { title: 'Link', placeholder: 'Link', id: 'link' },
        { title: 'Code', placeholder: 'Code(optional)', id: 'code' },
        { title: 'High Level', placeholder: 'High Level(optional)', id: 'high_level', list: ['true', 'false'].map(value => ({ label: value.toString(), value })) },
    ]

    return (
        <VStack p='3' space='2' >
            <CompHeader title="Create Link Shortener" />

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}

            <SelectField form={form} title='App' id='type' list={types} />
            <HStack>
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={save}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update Link Shortener' : "Add Link Shortener"}
                </Button>
                <Spacer />
                {data && <Button m='auto'
                    disabled variant={'solid'}
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Mall
                </Button>}
            </HStack>
            <Model
                title={'Link Shortener'}
                show={finalLinkData}
                close={() => navigate(-1)}
                body={
                    <VStack alignItems={'flex-start'} bg='white' space='2' p='2' borderRadius={'md'} >
                        <Tile fw='500' label='Short Link' value={finalLinkData?.final_link}
                            valueComponent={
                                <Text cursor='poniter' color="blue.500" onClick={() => { copyToClipboard(finalLinkData?.final_link) }} >
                                    {finalLinkData?.final_link}
                                </Text>
                            }
                        />
                        <Tile fw='500' label='Actual Link' value={finalLinkData?.link}
                            valueComponent={
                                <Text cursor='poniter' color="blue.500" onClick={() => { copyToClipboard(finalLinkData?.link) }} >
                                    {finalLinkData?.link}
                                </Text>
                            }
                        />
                        <Tile fw='500' label='Code Link' value={finalLinkData?.code} />
                        <Tile fw='500' label='High Level' value={finalLinkData?.high_level ? 'true' : 'false'} />
                        <Button size={'sm'} onClick={() => navigate(-1)}>close</Button>
                    </VStack>
                }
            />
        </VStack>
    )
}

export default AddLinkShortener