import { VStack } from 'native-base'
import React from 'react'
import { useParams } from 'react-router-dom'
import { getStoreAuthById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import useFetch from '../../hook/useFetch'

const StoreAuthEdit = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: 'getStoreAuthById',
        service: getStoreAuthById,
        id,
    })

    return (
        <VStack>
            <CompHeader title='Edit Subscription' backButton />
            {/* {isLoading ? <Loading /> :
                <StoreAuthories auth={[data]} />} */}
        </VStack>
    )
}

export default StoreAuthEdit