import React from 'react'
import { VStack } from '@chakra-ui/react'
import ModCallsTabs from './ModCallsTabs'
import { userColumns } from './UserStatic'
import { storeColumns } from './StoreStatic'
import useFetch from '../../../hook/useFetch'
import { getURLQuery } from '../../../helper/url'
import { getModCalls } from '../../../apis/admin'
import DataTable from '../../../commons/DataTable/DataTable'
import CompHeader from '../../../commons/CompHeader/CompHeader'
import { useLocation, useParams } from 'react-router-dom'
import { Text } from 'native-base'
import ModCallCard from './ModCallCard'
import ModStoreCallCard from './ModStoreCallCard';
import DateRangeSelector from '../../../commons/DateRangeSelector'
import { getPastDate } from '../../../utils/moment'
import Loading from '../../../commons/Loading'
import ModCallCard2 from './ModCallCard2'
import ModStoreCallCard2 from './ModStoreCallCard2'
import usePaginationNav from '../../../hook/usePaginationNav'

const ModsCalls = () => {
    const { id } = useParams();
    const [range, setRange] = React.useState([getPastDate(10), new Date()])
    const { search } = useLocation();
    const type = getURLQuery(search, 'type') || 'user';

    const { data, isLoading } = useFetch({
        name: `getModCalls_${id}_${type}`,
        service: getModCalls,
        id,
        params: { type, date1: range[0], date2: range[1] },
        conditionalRefetch: [type, range]
    })


    const { currentData, Nav } = usePaginationNav({ data, item_per_page: 10, defaultPage: 1 })

    return (
        <VStack w='100%' alignItems='flex-start'>
            <CompHeader title='Moderators Calls' backButton />
            <DateRangeSelector range={range} setRange={setRange} />
            <ModCallsTabs id={id} />
            <Nav />
            <VStack w='100%' h='100%' >
                {
                    isLoading ?
                        <Loading />
                        :
                        currentData?.length > 0 ?
                            <VStack w='100%'  >
                                {currentData?.map((item, index) => (
                                    type === 'user' ?
                                        <ModCallCard2 key={index} data={item} index={index} />
                                        :
                                        <ModStoreCallCard2 key={index} data={item} index={index} />
                                ))}
                            </VStack>
                            :
                            <VStack
                                w='100%'
                                h='100%'
                                alignItems='center'
                                justifyContent='center'
                                bg='gray.200'
                                p={5}
                                borderRadius={10}
                            >
                                <Text
                                    fontSize={20}
                                    fontWeight='bold'
                                    color='gray.500'
                                >
                                    No Calls
                                </Text>
                            </VStack>
                }
            </VStack>
            <Nav />
        </VStack>
    )
}

export default ModsCalls