import axios from "axios";
import { baseurl } from "./url";
const API = axios.create({ baseURL: `${baseurl}` });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("token")) {
        req.headers.Authorization = localStorage.getItem("token");
    }
    return req;
});

export const getStates = () => API.get("/states");
export const getCity = ({ id }) => API.get(`/cities?state=${id}`);
export const getAllCity = (stateName) => API.get("/allCities");
