import { HStack, Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../helper/url'
import { colors } from '../../theme/colors'

const list = [
    ['All', '/admin/flags', 'all'],
    ['CBS', '/admin/flags?type=cbs_wrong_info', 'cbs_wrong_info'],
    ['Wrong Offer', '/admin/flags?type=offer_wrong_info', 'offer_wrong_info'],
    ['User', '/admin/flags?type=user', 'user'],
    ['Brand', '/admin/flags?type=brand', 'brand'],
    ['Offer', '/admin/flags?type=offer', 'offer'],
    ['Product', '/admin/flags?type=product', 'product'],
]

const FlagsTabs = ({ as }) => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const status = getURLQuery(search, 'verified') || 'all';

    return (
        <HStack mb='2' space='4'>
            {list.map(([name, link, active]) =>
                <Text key={link} fontWeight='600' fontSize='lg'
                    color={as ? as === active ? colors.dark : colors.secondary : status === active ? colors.dark : colors.secondary}
                    onPress={() => navigate(link)}>{name}</Text>
            )}
        </HStack>
    )
}

export default FlagsTabs