import { Button, HStack, Image, Spacer, Text, VStack } from 'native-base'
import React from 'react'
import { useParams } from 'react-router-dom'
import { editFlagsById, getFlagsById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import Tile from '../../commons/Tile'
import { IMAGE_BASE_URL } from '../../helper/variables'
import useFetch from '../../hook/useFetch'
import useSubmit from '../../hook/useSubmit'
import { colors } from '../../theme/colors'
import { formatDateTimes } from '../../utils/moment'

const contProps = { borderWidth: 0, bg: colors.light, p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1 }

const FlagsDetails = () => {
    const { id } = useParams()

    const { data, isLoading, refetch } = useFetch({
        name: `getFlagsById_${id}`,
        service: getFlagsById,
        id,
    })

    // const Remove = useSubmit({ service: deleteCBSEquiriesById, id, onSuccess: () => navigate('/admin/cbs') })
    const Edit = useSubmit({ service: editFlagsById, id, onSuccess: refetch })



    const { status, createdAt, updatedAt, store, user, offer, reason, message, _id } = data

    const last_data = user?.subscription?.end_date
    const expired = new Date(last_data) < new Date()

    if (isLoading) return <Loading />

    return (
        <VStack space='4'>
            <CompHeader title='Flags Details' backButton />

            <VStack {...contProps}>
                <Text fontWeight='600' mb='2'> Info </Text>
                <Tile fw='500' label='Report Id' value={_id} />
                <Tile fw='500' label='Reason' value={reason?.reason} />
                <Tile fw='500' label='Message' value={message} />
            </VStack>

            <VStack {...contProps}>
                <Text fontWeight='600' mb='2'> Timeline </Text>
                <Tile fw='500' label='Report Time' value={formatDateTimes(createdAt, 5)} />
                <Tile fw='500' label='Last update' value={formatDateTimes(updatedAt, 5)} />
                <Tile fw='500' label='Status' value={status} />
            </VStack>

            {user && <VStack {...contProps}>
                <Text fontWeight='600' mb='2'>User</Text>
                <Tile fw='500' label='User ID' value={user?._id} />
                <Tile fw='500' label='Name' value={user?.name} />
                <Tile fw='500' label='Phone Number' value={user?.phone_number} />
                {user?.subscription?.sub_id ? <>
                    <Tile fw='500' label='Subscription Name' value={`${user?.subscription?.sub_id?.name} (${user?.subscription?.sub_id?.code})`} />
                    <Tile fw='500' label='Subscription Duration' value={`${user?.subscription?.sub_id?.duration} days`} />
                    <Tile fw='500' label='Subscription Price' value={user?.subscription?.sub_id?.price} />
                    <Tile fw='500' label='Subscription Status' value={expired ? 'Expired ' : 'Active'} />
                </> : null}
            </VStack>}

            {store && <HStack {...contProps}>
                <VStack>
                    <Text fontWeight='600' mb='2'>Store </Text>
                    <Tile fw='500' label='Store ID' value={store._id} />
                    <Tile fw='500' label='Name' value={store.store_name} />
                    <Tile fw='500' label='GST' value={store.gst_number} />
                    <Tile fw='500' label='Premium' value={store.premium ? "Yes" : "No"} />
                    <Tile fw='500' label='Pre Register' value={store.pre_register ? "Yes" : "No"} />
                </VStack>
                <Spacer />
                <Image h='100' w='200' resizeMode='contain' source={`${IMAGE_BASE_URL}${store.store_images?.[0]}`} />
            </HStack>
            }
            {offer && <VStack {...contProps}>
                <Text fontWeight='600' mb='2'> Offer </Text>
                <Tile fw='500' label='Offer Id' value={offer?._id} />
                <Tile fw='500' label='Offer' value={offer?.offer} />
                <Tile fw='500' label='Start Date' value={formatDateTimes(offer?.start_date, 5)} />
                <Tile fw='500' label='End Date' value={formatDateTimes(offer?.end_date, 5)} />
                <Tile fw='500' label='Template Id' value={offer?.template_id} />
            </VStack>
            }

            <VStack {...contProps}>
                <Text fontWeight='600' mb='2'>Actions</Text>
                <HStack space={4}>
                    <Button
                        //  isLoading={Remove.isLoading} onPress={Remove.submit} 
                        w='300' size='sm' colorScheme={'warmGray'}>Call Store</Button>
                    <Button
                        disabled={status === 'rejected'}
                        isLoading={Edit.isLoading} onPress={() => Edit.submit({ status: 'rejected' })}
                        w='300' size='sm' colorScheme={'error'}>Reject</Button>
                    <Button
                        disabled={status === 'resolved'}
                        isLoading={Edit.isLoading} onPress={() => Edit.submit({ status: 'resolved' })}
                        w='300' size='sm' colorScheme={'green'}>Resolved</Button>
                </HStack>
            </VStack>

        </VStack>
    )
}

export default FlagsDetails