import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { getMarkets } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const Markets = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'markets',
        service: getMarkets,
        pagination: true,
    })

    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title='Markets' buttonTitle='Create New Market'
                onPress={() => navigate(`/admin/markets/create`)}
            />

            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Markets