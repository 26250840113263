import React from 'react'
import { Button, Spacer, Text, VStack, HStack } from '@chakra-ui/react'
import { colors } from '../../theme/colors'
import useSubmit from '../../hook/useSubmit';
import { toggleLocationStatus } from '../../apis/admin';

const Cities = ({ state, refetch }) => {
    const cities = state?.cities
    const stateActive = state?.status === 'active'

    const { submit, isLoading } = useSubmit({
        service: toggleLocationStatus,
        onSuccess: refetch
    })

    if (!state) {
        return (
            <VStack bg='white' w={'60vw'} flex={1} h={'100%'}
                alignItems={'center'} justifyContent={'center'}
            >
                <Text bg={colors.light} p={6} fontWeight={'semibold'} fontSize={'2xl'}
                    borderRadius={'lg'} textAlign={'center'}
                >
                    Select a state to view cities
                </Text>
            </VStack>
        )
    }

    return (
        <VStack bg='white' w={'60vw'} flex={1} h={'100%'} alignItems={'flex-start'} overflowX={'scroll'} >
            <HStack px={4} py={2} borderBottomWidth={4} w='100%' fontSize='xl' borderColor={'gray.200'}>
                <Text>
                    {state.name} - Cities({cities?.length}) -
                    <Text as='span' ml='1' fontWeight='bold' color={stateActive ? colors.success : colors.error}>
                        {stateActive ? 'Active' : 'Inactive'}
                    </Text>
                </Text>
                <Spacer />
                <Button variant='link' isLoading={isLoading}
                    onClick={() => submit({ type: 'state', id: state._id, status: stateActive ? 'inactive' : 'active' })}
                    color={stateActive ? colors.error : colors.success}
                >
                    {stateActive ? 'Deactivate' : 'Activate'} State
                </Button>
            </HStack>
            <VStack spacing={0} flex={1} h={'100%'} w='100%' alignItems={'flex-start'} overflowX={'scroll'}
                opacity={stateActive ? 1 : 0.5}
                cursor={stateActive ? 'auto' : 'not-allowed'}
            >
                {cities.map(({ name, status, _id }) => {
                    return (
                        <HStack w='100%' alignItems={'flex-start'} p={2} px={4}
                            borderBottomWidth={1} borderBottomColor={'gray.200'}
                            spacing={0} cursor={'pointer'}
                        >
                            <Text fontSize='sm'>
                                {name}-
                                <Text as='span' ml='1' fontWeight='bold' color={status === 'active' ? colors.success : colors.error}>
                                    {status === 'active' ? 'Active' : 'Inactive'}
                                </Text>
                            </Text>
                            <Spacer />
                            <Button variant='link' isLoading={isLoading}
                                disabled={!stateActive}
                                onClick={() => submit({ type: 'city', id: _id, status: status === 'active' ? 'inactive' : 'active' })}
                                color={status === 'active' ? colors.error : colors.success}
                            >
                                {status === 'active' ? 'Deactivate' : 'Activate'} City
                            </Button>
                        </HStack>
                    )
                })}
            </VStack>
        </VStack>
    )
}

export default Cities