import React from 'react'
import { useParams } from 'react-router-dom'
import { getFlagReasonsById } from '../../apis/admin'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading';
import CreateFlagsReasons from './CreateFlagsReasons'

const EditFlagsReasons = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `getFlagReasonsById_${id}`,
        service: getFlagReasonsById,
        id
    })

    if (isLoading) return <Loading />

    return <CreateFlagsReasons data={data} />
}

export default EditFlagsReasons