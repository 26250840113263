import React from 'react'
import useFetch from '../../../hook/useFetch'
import { colors } from '../../../theme/colors'
import { getstores } from '../../../apis/store'
import { getStoreAuthById } from '../../../apis/admin'
import SelectField from '../../../commons/SelectField'
import { formatDateTimes } from '../../../utils/moment'
import { InputField } from '../../../commons/InputField'
import { Divider, HStack, Text, VStack } from '@chakra-ui/react'

const storeRoles = [
    ['STORE OWNER', "Store Owner",],
    ['STORE MANAGER', "Store Manager",],
    ['STORE REP', "Store Representative",]
].map(([value, label]) => ({ value, label }))

const SelectStore = ({ form }) => {

    const stores = useFetch({
        name: 'getstores',
        service: getstores,
        params: { limit: 0 },
        dataOnly: true,
    })

    const store = form.watch('store_id')
    const store_name = form.watch('store.name')

    const { data: storeAuths } = useFetch({
        service: getStoreAuthById,
        id: store?.value,
        name: `getStoreAuthById_${form.watch('store')}`,
        conditionalRefetch: [store?.value],
        condition: !!store
    })

    return (
        <VStack w='100%' alignItems={'flex-start'} spacing={2} p={4} bg={colors.light} borderRadius={'md'} >
            <Text fontSize='xl' fontWeight='bold' color='black' mb={2} >
                Select Store
            </Text>

            <SelectField form={form} reactSelect isMulti={false} id={'store_id'}
                title={`Store (${stores?.length})`}
                list={stores?.map(item =>
                ({
                    label: `${item.store_name} || ${item?.store_address?.address} || ${formatDateTimes(item.createdAt, 4)}`,
                    value: item._id,
                }))}
            />

            <HStack w='100%' alignItems='center' my={5} alignSelf={'center'} justifyContent={'center'}>
                <Divider borderWidth={1} w={100} borderColor={colors.secondary} />
                <Text fontSize='md' fontWeight='bold' color='black'>
                    OR
                </Text>
                <Divider borderWidth={1} w={100} borderColor={colors.secondary} />
            </HStack>

            <VStack w='100%' alignItems={'flex-start'} spacing={2} >
                <InputField form={form} title='Store Name' id='store.name' autoCapatilize />
                <InputField form={form} title='Store Address' id='store.address' />
                <InputField form={form} title='Point Location' id='store.point_location' />
            </VStack>

            {(store || store_name) &&
                <>
                    <Divider borderWidth={1} w='100%' borderColor={colors.secondary} my={5} />
                    <VStack spacing={2} w='100%' alignItems={'flex-start'}>
                        <Text fontSize='xl' fontWeight='bold' color='black' mb={2} >
                            Store Contact
                        </Text>

                        <SelectField form={form} reactSelect isMulti={false} id={'auth_id'}
                            title={`Store Auths (${storeAuths?.length})`}
                            list={storeAuths?.map(item =>
                            ({
                                label: `${item.name} || ${item?.phone_number} (${item.role})`,
                                value: item._id,
                            }))}
                        />

                        <HStack w='100%' alignItems='center' my={5} alignSelf={'center'} justifyContent={'center'}>
                            <Divider borderWidth={1} w={100} bg={colors.secondary} borderColor={colors.secondary} />
                            <Text fontSize='md' fontWeight='bold' color='black'>
                                OR
                            </Text>
                            <Divider borderWidth={1} w={100} bg={colors.secondary} borderColor={colors.secondary} />
                        </HStack>

                        <VStack w='100%' alignItems={'flex-start'} spacing={2} >
                            <InputField form={form} title='Store Auth Name' id='auth.name' autoCapatilize />
                            <InputField form={form} title='Store Auth Number' id='auth.phone_number' />
                            <SelectField form={form} title='Store Auth Role' id='auth.role' list={storeRoles} />
                        </VStack>
                    </VStack>
                </>}
        </VStack>
    )
}

export default SelectStore