import React from 'react';
import { Spacer } from 'native-base';
import CouponCards from './CouponCards';
import { useForm } from 'react-hook-form';
import useSubmit from '../../../hook/useSubmit';
import { assignCouponsToUser } from '../../../apis/admin';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';

const formatNumber = (no) => {
    if (no.includes('0161'))
        return `0161-${no.slice(4, 7)}-${no.slice(7, 11)}`

    if (no.includes('161'))
        return `0161-${no.slice(3, 7)}-${no.slice(7, 11)}`

    return `${no.slice(0, 3)}-${no.slice(3, 6)}-${no.slice(6, 10)}`
}

const GiveCoupon = ({ user, setRefresh }) => {
    const form = useForm({ defaultValues: { user: '', coupons: [] } })

    const state = form.watch()
    const { submit, isLoading } = useSubmit({
        service: assignCouponsToUser,
        onSuccess: () => {
            form.reset();
            setRefresh(d => !d)
        }
    })

    const save = () => {
        const data = {
            ...form.getValues(),
            user: user._id,
            phone_number: user.phone_number,
        }

        submit(data)
    }

    return (
        <VStack px={4} alignItems={'flex-start'} w='100%'>

            <HStack alignContent={'flex-start'} sapcing={10} w='100%'>
                <Text fontSize={'2xl'} fontWeight='600'>
                    Assign Coupon
                </Text>
                <Spacer />
                <Text fontSize={'2xl'} fontWeight={'bold'}>
                    {user.name}
                </Text>
                <Text fontSize={'2xl'} fontWeight={'bold'}>
                    ({formatNumber(user.phone_number)})
                </Text>
            </HStack>

            <CouponCards form={form} user={user} />

            <Button onClick={save} isLoading={isLoading} colorScheme={'red'}
                disabled={!state.coupons.length} w='100%' mt={5}
                maxW={500}
            >
                Give {state.coupons.length} coupon{state.coupons.length > 1 ? 's' : ''} to {user.name}
            </Button>

        </VStack>
    )
}

export default GiveCoupon