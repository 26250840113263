import React from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import CompHeader from '../../commons/CompHeader/CompHeader';
import States from './States';
import useFetch from '../../hook/useFetch';
import { getLocations } from '../../apis/admin';
import Cities from './Cities';
import Loading from '../../commons/Loading';


const ActiveLocations = () => {
    const [state, setState] = React.useState('')

    const { data, isLoading, refetch } = useFetch({
        service: getLocations,
        name: 'ActiveLocations'
    })

    if (isLoading) return <Loading />

    return (
        <VStack w='100%' alignItems={'flex-start'}>
            <CompHeader title="Active Locations" />
            <HStack bg='white' borderRadius={'lg'} h='70vh' spacing={0}>
                <States data={data} setState={setState} state={state} />
                <Cities refetch={refetch} state={data.find(({ _id }) => _id === state)} />
            </HStack>
        </VStack>
    )
}

export default ActiveLocations