import { Button } from 'native-base';
export const columns = (navigate) => [
    {
        name: 'Order',
        selector: (row) => row.order,
        sortable: true,
    },
    {
        name: 'Reason',
        selector: (row) => row.reason,
        sortable: true,
    },
    {
        name: 'Description',
        selector: (row) => row.description,
        sortable: true,
    },
    {
        name: 'Tags',
        selector: (row) => row.tags.join(','),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/flags/reasons/edit/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
]