import React from 'react'
import { VStack } from '@chakra-ui/react'
import CompHeader from '../../../commons/CompHeader/CompHeader'
import useFetch from '../../../hook/useFetch'
import { getGrabbedCoupons } from '../../../apis/admin'
import DataTable from '../../../commons/DataTable/DataTable'
import { columns } from './static'

const GrabbedCoupons = () => {

    const { data, isLoading } = useFetch({
        service: getGrabbedCoupons,
        name: 'Grabbed Coupons',
        pagination: true
    })


    return (
        <VStack w='100%' alignItems='flex-start'>
            <CompHeader title="Grabbed Coupons" />

            <DataTable tableData={{ columns: columns(), data, isLoading, }} pagination={true} />

        </VStack>
    )
}

export default GrabbedCoupons