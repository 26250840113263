import React from 'react'
import StoreMall from './StoreMall'
import StoreChain from './StoreChain'
import StoreBrands from './StoreBrands'
import StoreBudget from './StoreBudget'
import StoreAddress from './StoreAddress'
import StoreCoordinates from './StoreCoordinates'
import SelectField from '../../../commons/SelectField'
import { formatDateTimes } from '../../../utils/moment'
import { InputField } from '../../../commons/InputField'
import { Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import { brandTypes, offerTypes, offerTypesChain, storeStructure, storeTypes } from '../../AdminOffers/static'
import StoreTiming from './StoreTiming'
import StoreQr from './StoreQr'
import StoreKeywords from './StoreKeywords'

const StoreForm = ({ form, viewOnly }) => {
    const state = form.watch()
    const { type, structure } = state
    // useEffect(() => {
    //     if (type === 'exclusive') {
    //         form.setValue('chain', '')
    //         form.setValue('offer_type', '')
    //     }
    // if (storeCity) form.setValue('store_address.mall', '')
    // if (storeState) {
    //     form.setValue('store_address.city', '')
    //     form.setValue('store_address.mall', '')
    // }
    // }, [form.watch('type'), form.watch('state'), form.watch('city')])

    const list = [
        { title: 'Store Name', id: 'store_name' },
        // { title: 'Store ID', id: '_id', disabled: true },
        { title: 'Code', id: 'code', disabled: true },
        { title: 'QR', id: 'qr', disabled: true },
        { title: 'GST/Pan', id: 'gst_number' },
        { title: 'Clothing Type', id: 'clothing_type', disabled: true },
        { title: 'Store Type', id: 'type', list: storeTypes },
        { title: 'Brand Type', id: 'brand_type', list: brandTypes },
        { title: 'Offer Type', id: 'offer_type', list: type === 'exclusive' ? offerTypes : offerTypesChain },
        { title: 'Store Structure', id: 'structure', list: storeStructure },
        // { title: 'Chain Type', id: 'chain_type', list: chainTypes },
        { title: 'Points', id: 'points', disabled: !true },
        { title: 'Slug', id: 'slug', disabled: true },
        { title: 'Premium', id: 'premium', disabled: true },
        { title: 'Claimed', id: 'claimed', disabled: !true, list: [{ value: true, label: 'true' }, { value: false, label: 'false' }] },
        { title: 'Banned', id: 'banned', disabled: true },
        { title: 'Pre Register', id: 'pre_register', disabled: true },
        { title: 'Created At', value: formatDateTimes(form.watch('createdAt'), 6), disabled: true },
        { title: 'Verified', id: 'verified', list: [{ value: true, label: 'true' }, { value: false, label: 'false' }] },
    ].map(item => ({ ...item, disabled: viewOnly || item.disabled, }))

    return (
        <VStack p={2} flex={1} pointerEvents={viewOnly ? 'none' : 'auto'} pb={100}>
            <HStack space={2} w='100%' borderBottomWidth={2} >
                <Text fontWeight={'semibold'} fontSize={'lg'} >
                    {viewOnly ? 'Store Details (Current)' : 'Store Details (To be updated)'}
                </Text>
                <Spacer />
                {viewOnly ? <></> :
                    <Text fontWeight={'semibold'} fontSize='sm' color='red.500' cursor='pointer'
                        onClick={() => {
                            const state = form.watch('default')
                            form.reset({ ...state, default: state, viewOnly })
                        }}>
                        Reset Form
                    </Text>}
            </HStack>

            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}
            <Divider borderColor="gray.900" />

            <StoreAddress form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            <StoreQr form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            <StoreKeywords form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            <StoreCoordinates form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            <StoreMall form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            {structure === 'chain' &&
                <>
                    <StoreChain form={form} viewOnly={viewOnly} />
                    <Divider borderColor="gray.900" />
                </>
            }

            <StoreBrands form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            <StoreBudget form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

            <StoreTiming form={form} viewOnly={viewOnly} />
            <Divider borderColor="gray.900" />

        </VStack>
    )
}

export default StoreForm