import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const GetDevData = ({ data }) => {

    const handleClick = (type) => {
        const list = data.map((item) => `${item.qr},${item.serial_no}`).join('\n')
        navigator.clipboard.writeText(list)
    }

    return (
        <VStack w='100%' alignItems={'flex-start'} spacing={4} bg={'white'} p={4} borderRadius={'lg'}>
            <Text fontSize='xl' fontWeight='bold' >
                Dev Area
            </Text>

            <HStack spacing={4} alignItems={'center'} >
                <Button variant='solid' color={'red'} onClick={() => handleClick('qrs')} >
                    Copy Dev Data
                </Button>

            </HStack>

        </VStack>
    )
}

export default GetDevData