import React, { } from 'react'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import { VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import { getoffertemplate } from '../../apis/admin'
import Loading from '../../commons/Loading';

const OfferTemplate = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({ service: getoffertemplate, name: 'OfferTemplate' })

    return (
        <VStack>
            <CompHeader title='Offer Templates'
                buttonTitle='Create New Template'
                onPress={() => navigate(`/admin/offerTemplate/create`)}
            />
            {isLoading ? <Loading /> :
                <DataTable tableData={{ columns: columns(navigate), data }} />}
        </VStack>
    )
}

export default OfferTemplate