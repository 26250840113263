import React from 'react'
import { VStack } from '@chakra-ui/react'
import CompHeader from '../../../commons/CompHeader/CompHeader'
import AddRestaurantform from './AddRestaurantform'

const CreateRestaurant = () => {



    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title="Create Restaurant" />
            <AddRestaurantform />
        </VStack>
    )
}

export default CreateRestaurant