import { VStack } from 'native-base'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { useForm } from 'react-hook-form'
import SelectField from '../../commons/SelectField'
import { InputField } from '../../commons/InputField'
import { Button, HStack, Spacer } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { createOnboader, deleteOnboardersById, editOnboardersById } from '../../apis/admin'
import useSubmit from '../../hook/useSubmit'

const defaultValues = {
    name: '',
    email: '',
    phone_number: '',
}

const CreateOnboarders = ({ data }) => {
    const form = useForm({ defaultValues: data || defaultValues })
    const navigate = useNavigate()

    const list = [
        { title: 'User id', id: '_id', disabled: true, show: !!data },
        { title: 'Name', id: 'name' },
        { title: 'Phone number', id: 'phone_number', type: 'number', maxLength: 10, max: 10 },
    ]

    const { submit, isLoading } = useSubmit({
        service: data ? editOnboardersById : createOnboader,
        id: data?._id,
        onSuccess: () => navigate(-1),
    })

    const Delete = useSubmit({
        service: deleteOnboardersById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    return (
        <VStack space={5}>
            <CompHeader title={data ? 'Edit Onboader' : 'Create Onboader'} backButton />
            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={120} key={index} form={form} {...item} />
            })}

            <HStack>
                <Button m='auto'
                    colorScheme={data ? 'green' : 'blue'}
                    variant={'solid'}
                    onClick={() => submit(form.getValues())}
                    isLoading={isLoading}
                    size='sm'
                >{data ? 'Update Onboader' : "Add Onboader"}</Button>
                <Spacer />
                {data && <Button m='auto'
                    disabled variant={'solid'}
                    isLoading={Delete.isLoading}
                    onClick={Delete.submit} size='sm' >
                    Delete Onboader
                </Button>}
            </HStack>

        </VStack>
    )
}

export default CreateOnboarders