import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getRestaurants } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'

const Restaurants = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useFetch({ name: 'getRestaurants', service: getRestaurants, pagination: true })

    return (
        <VStack w="100%" alignItems={'flex-start'}>
            <CompHeader title="Restaurants" buttonTitle='Add Restaurant' buttonLink={'create'} />
            <DataTable title={`${data?.total || ''} Restaurants`}
                tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default Restaurants