import React from 'react'
import UserCallingCard from './UserCallingCard'
import toast from 'react-hot-toast'
import useSubmit from '../../hook/useSubmit'
import { markUserAsDone } from '../../apis/admin'
import Model from '../../components/compo/UI/Model'
import UserCalls from '../Users/UserCalls/UserCalls'
import { HStack, Text, VStack } from '@chakra-ui/react'

const CallingBatch = ({ data, refetch, expanded, setExpanded }) => {
    const [call, setCall] = React.useState(false)

    const copyData = () => {
        const list = data.users.map((item, index) => {
            return `${index + 1}. ${item.name} - ${item.phone_number}`
        })
        const text = list.join('\n \n')
        navigator.clipboard.writeText(text)
        toast('Copied to clipboard', { icon: '📋' })
    }

    const { submit } = useSubmit({
        service: markUserAsDone,
        onSuccess: () => {
            refetch()
            setCall(false)
        }
    })

    if (!expanded) {
        return (
            <VStack w={'100%'} bg={'white'} p={4} borderRadius={'md'} alignItems={'flex-start'}
                onClick={() => setExpanded(data._id)} >
                <HStack w={'100%'} justifyContent={'space-between'} >
                    <Text fontSize={'lg'} >
                        <Text as='span' fontWeight={'bold'}>
                            Batch Code{" "}
                        </Text>
                        {data.code}
                    </Text>

                    <Text>
                        {data.called_users.length} / {data.users.length}
                    </Text>
                </HStack>
            </VStack>
        )
    }


    return (
        <VStack w={'100%'} bg={'white'} p={4} borderRadius={'md'} alignItems={'flex-start'} >

            <HStack w={'100%'} justifyContent={'space-between'} >
                <Text fontSize={'lg'} >
                    <Text as='span' fontWeight={'bold'} onClick={() => setExpanded(false)} cursor={'pointer'} >
                        Batch Code{" "}
                    </Text>
                    {data.code}
                </Text>

                <Text cursor={'pointer'} color={'blue.500'} onClick={copyData} >
                    Copy Data
                </Text>
            </HStack>

            {data.users.map((item, index) =>
                <UserCallingCard key={item._id} data={item}
                    isDone={data.called_users.includes(item._id)}
                    index={index}
                    setCall={setCall}
                    markUserAsDone={() => submit({ user_id: item._id, batch_id: data._id })}
                />
            )}

            {call && <Model size='full' title={'Calls'} show={!!call}
                close={() => {
                    setCall(false)
                    refetch()
                }}
                body={<UserCalls
                    user={call}
                    close={() => {
                        setCall(false);
                        refetch()
                    }}
                    onSuccess={() => {
                        submit({ user_id: call._id, batch_id: data._id })
                    }}
                />}
            />}
        </VStack>
    )
}

export default CallingBatch