import React from 'react'
import useFetch from '../../hook/useFetch'
import { useForm } from 'react-hook-form';
import StoreAuthCard from './StoreAuthCard';
import Loading from '../../commons/Loading';
import FilterSelection from './FilterSelection';
import { Box, Button, Text, VStack } from '@chakra-ui/react'
import { getStoreWithAdvanceFilters, } from '../../apis/admin';
import CompHeader from '../../commons/CompHeader/CompHeader';
import FiltersTiles from '../../commons/FiltersTiles';
import toast from 'react-hot-toast';
import Maps from '../../components/commons/Map/Maps';

const defaultValues = { brand: "", from_date: new Date(), claimed: true, pre_register: false, type: "", state: [], city: [], mall: "", offer_type: "", chain: "", stores: [], extra: { existInChain: false, } }

const StoreOnMap = () => {

    const form = useForm({ defaultValues })

    const params = () => {
        const data = form.watch()
        const { state, city } = data
        return {
            ...data,
            state: state.map(i => i?.value),
            city: city.map(i => i?.value),
        }
    }

    const { data, isLoading, refetch, isRefetching } = useFetch({
        name: `getStoreWithAdvanceFilters`,
        service: getStoreWithAdvanceFilters,
        params: params(),
    })

    console.log('data', data)

    const positions = (() => {
        if (isLoading) return
        return data.map(item => {
            return {
                position: item.geo_location.coordinates,
                label: item.store_name
            }
        })
    })()

    return (
        <VStack w='100%' space='2' alignItems={'flex-start'} >
            <CompHeader title='Store Map' />

            <VStack w='100%' space='2' alignItems={'flex-start'} >
                <FilterSelection form={form} />
                <FiltersTiles form={form} defaultValues={defaultValues} />
                <Button onClick={() => {
                    const query = JSON.stringify(params())
                    window.history.pushState({}, '', `/admin/store_with_no_offers?query=${query}`)
                    refetch()
                }} isLoading={isLoading || isRefetching}>
                    Get Stores
                </Button>
            </VStack>


            <Box>
                <Maps positions={positions} center={[0, 1]} />
            </Box>

            <Text>
                {isLoading ?
                    <Loading />
                    :
                    <Text fontSize='xl' fontWeight='bold' color='gray.500' alignSelf='flex-start' mt='4' >
                        {data?.length} Stores
                    </Text>
                }
            </Text>

        </VStack>
    )
}

export default StoreOnMap