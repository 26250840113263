import { VStack } from 'native-base'
import React from 'react'
import { useParams } from 'react-router-dom'
import { getOfferTemplateListById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import AddOfferTemplateList from './AddOfferTemplateList'

const EditOfferTemplateList = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `list_${id}`,
        service: getOfferTemplateListById,
        props: { id },
    })

    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2'>
            <CompHeader title='Edit Offer Template List' backButton />
            {data && <AddOfferTemplateList data={data} />}
        </VStack>
    )
}

export default EditOfferTemplateList