import React from 'react'
import { colors } from '../../theme/colors'
import { Text, VStack } from '@chakra-ui/react'

const States = ({ data, setState, state }) => {
    const _state = data.sort((a, b) => {
        const aActiveCities = a.cities?.filter(city => city.status === 'active')
        const bActiveCities = b.cities?.filter(city => city.status === 'active')
        return bActiveCities?.length - aActiveCities?.length
    })

    return (
        <VStack spacing={0} borderRightWidth={4} borderColor={'gray.200'} bg='white' flex={1} h={'100%'} w={270} maxW={270} alignItems={'flex-start'}  >
            <Text px={4} py={2} borderBottomWidth={4} borderColor={'gray.200'} w='100%' fontSize='xl'>States({data.length})</Text>
            <VStack spacing={0} flex={1} h={'100%'} w='100%' alignItems={'flex-start'} overflowX={'scroll'} >
                {_state.map(({ name, cities, status, _id }) => {
                    const activeCities = cities?.filter(city => city.status === 'active')
                    const active = status === 'active'
                    return (
                        <VStack w='100%' alignItems={'flex-start'} p={2}
                            borderBottomWidth={1} borderBottomColor={'gray.200'}
                            onClick={() => setState(state === _id ? '' : _id)} key={_id}
                            bg={state === _id ? colors.input : 'white'}
                            spacing={0} cursor={'pointer'}>
                            <Text fontSize='sm' color={active ? colors.success : colors.error} >{name}</Text>
                            <Text fontSize='xs' color={colors.secondary}>
                                <Text mr={1} as='span' fontWeight='bold'>{activeCities?.length}</Text>
                                of
                                <Text mx={1} as='span' fontWeight='bold'>{cities?.length}</Text>
                                cities active
                            </Text>
                        </VStack>
                    )
                })}
            </VStack>
        </VStack>
    )
}

export default States