import { VStack } from 'native-base'
import React from 'react'
import { useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { getwearbyid } from '../../apis/admin';
import AddWear from './AddWear'

const EditWear = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({ name: `brand_${id}`, service: getwearbyid, props: { id }, })
    if (isLoading) return <Loading />

    return (
        <VStack p='3' space='2'>
            <CompHeader title='Edit Wear' backButton />
            <AddWear data={data} />
        </VStack>
    )
}

export default EditWear