import { VStack } from 'native-base'
import React from 'react'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static';
import { useNavigate } from 'react-router-dom';

const LinkedStoresList = ({ data = [], type }) => {
    const navigate = useNavigate()
    return (
        <VStack w="100%">
            <DataTable title='Linked Stores'
                tableData={{ columns: columns(navigate), data }}
            />
        </VStack>
    )
}

export default LinkedStoresList