import React from 'react'
import { Box } from 'native-base'
import { useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import { columns } from './static'
import { getUserSubscriptions } from '../../apis/admin'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'

const Subscriptions = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'subscriptions',
        service: getUserSubscriptions,
    })

    if (isLoading) return <Loading />

    return (
        <Box>
            <CompHeader title='Subscriptions'
                buttonTitle='Add New Subscription'
                onPress={() => navigate('/admin/subscriptions/user/new')}
            />
            <DataTable showSearch={false} tableData={{ columns: columns(navigate), data }} />
        </Box>
    )
}

export default Subscriptions