import React from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useEffect } from 'react';

const CModal = ({ title, body, w, h, show = true, close = () => { }, ...other }) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => { document.body.style.overflow = 'unset' }
    }, [show])


    const props = {
        ...(w && { w }),
        ...(h && { h }),
    }

    return (
        <Modal isOpen={show} onClose={close}   {...other} {...props}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {body}
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}
export default CModal