import axios from "axios";
import { baseurl } from "./url";
const API = axios.create({ baseURL: `${baseurl}/admin/wa_services` });

API.interceptors.request.use((req) => {
    if (localStorage.getItem("token")) {
        req.headers.Authorization = localStorage.getItem("token");
    }
    return req;
})

export const sendWaMessageToUser = ({ data }) => API.post(`/services/send_message_to_user`, data);
export const sendWaMessageToStores = ({ data }) => API.post(`/services/send_message_to_store`, data);
export const getWaCampaigns = ({ params }) => API.get(`/services/get_campaigns`, { params });

export const getWaMessages = ({ params }) => API.get(`/messages`, { params });