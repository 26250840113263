import haversineDistance from "haversine-distance"
import { colors } from "../theme/colors"
export const IMAGE_BASE_URL = 'https://sharp-images.s3.ap-south-1.amazonaws.com/'
export const VIDEO_BASE_URL = 'https://offingo-reels.s3.ap-south-1.amazonaws.com/'

export const CBS_DISTANCE = [0.5, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096]

export const contProps = { borderWidth: 0, bg: colors.light, p: 4, borderRadius: 'lg', borderColor: 'gray.300', space: 1, alignItems: 'flex-start', w: '100%' }
export const contPropsTitle = { fontSize: 'xl', fontWeight: 'bold', color: colors.dark }

export const getCoordsDis = (coords1, coords2) => {

    const lat1 = coords1?.[0]
    const lon1 = coords1?.[1]
    const lat2 = coords2?.[0]
    const lon2 = coords2?.[1]

    coords1 = {
        latitude: lat1 > lon1 ? lon1 : lat1,
        longitude: lat1 > lon1 ? lat1 : lon1
    }
    coords2 = {
        latitude: lat2 > lon2 ? lon2 : lat2,
        longitude: lat2 > lon2 ? lat2 : lon2
    }

    return haversineDistance(coords1, coords2)
}
export const KmView = ({ store, coordinates }) => {
    const _coordinates = store?.geo_location?.coordinates
    const distance = getCoordsDis(coordinates, _coordinates)

    if (distance < 1000)
        return `${Math.round(distance)}m`;

    return `${Math.round(distance / 1000)}Km`;
}
