import React from 'react'
import { columns } from './static'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getConnections } from '../../../apis/admin'
import DataTable from '../../../commons/DataTable/DataTable'
import CompHeader from '../../../commons/CompHeader/CompHeader'

const ConnOffingo = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        service: getConnections,
        name: 'getConnections',
        params: { active: false, app: 'offingo' },
        pagination: true,
    })

    return (
        <VStack w='100%' alignItems={'flex-start'}>
            <CompHeader title={'Offingo Connections'} />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default ConnOffingo