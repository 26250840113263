import React from 'react'
import useSubmit from '../../hook/useSubmit'
import { acquireBatch } from '../../apis/admin'
import { formatDateTimes } from '../../utils/moment'
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const BatchCard = ({ data, index, refetch }) => {
    const navigate = useNavigate()

    const { submit, isLoading } = useSubmit({
        service: acquireBatch,
        onSuccess: () => {
            navigate('/admin/user_calling_my_batches')
        }
    })

    return (
        <HStack w='100%' alignItems={'flex-start'} borderWidth={1} p={4} borderRadius={5}
            borderColor={'gray.200'} bg={'white'} shadow='md' spacing={5}>

            <VStack>
                <Text fontSize='lg' fontWeight='bold' flex={1}>
                    Batch - {index + 1}({data?.code})
                </Text>
            </VStack>

            <Text fontSize='lg' fontWeight='normal' flex={1}>
                Users Count - {data?.users?.length}
            </Text>

            <Text fontSize='lg' fontWeight='normal' flex={1}>
                <Text as='span' fontWeight={'bold'}>From</Text>  - {formatDateTimes(data?.from, 4)}
            </Text>
            <Text fontSize='lg' fontWeight='normal' flex={1}>
                <Text as='span' fontWeight={'bold'}>To</Text> - {formatDateTimes(data?.to, 4)}
            </Text>

            {/* <Text fontSize='lg' fontWeight='normal' flex={1}>
                Created At - {formatDateTimes(data?.createdAt, 4)}
            </Text> */}

            {data?.mod ?
                <Text fontSize='lg' fontWeight='normal' flex={1}>
                    Acquire By - {data?.mod?.name}
                </Text>
                :
                <Button onClick={() => submit({ batch: data?._id })} colorScheme='blue'
                    size='sm' variant={'solid'} isLoading={isLoading}>
                    Acquire Batch
                </Button>
            }
        </HStack>
    )
}

export default BatchCard