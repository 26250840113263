import React from 'react'
import { useForm } from 'react-hook-form'
import useSubmit from '../../hook/useSubmit'
import { createNewStore } from '../../apis/store'
import SelectField from '../../commons/SelectField'
import { formatDateTimes } from '../../utils/moment'
import { InputField } from '../../commons/InputField'
import { Button, Divider, VStack } from '@chakra-ui/react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { brandTypes, offerTypes, offerTypesChain, storeStructure, storeTypes } from '../AdminOffers/static'
import { useNavigate } from 'react-router-dom'


const defaultValues = {
    geo_location: {
        type: "Point",
        coordinates: [30.882839566104987, 75.78172118321261]
    },
    store_address: {
        state: "6256721c3cf4652648f2c003",
        city: "626103a8f539fd37523c7aa5",
        address: "address",
        // coordinates_address: "",
        // mall_id: "",
    },
    clothing_provided: {
        readymade: {
            branded: false,
            non_branded: false
        },
        stitched: {
            branded: false,
            non_branded: false
        }
    },
    points: 3,
    range_type: [0, 1],
    non_working: [],
    store_images: [],
    gst_number: "",
    claimed: false,
    pre_register: true,
    premium: false,
    verified: false,
    banned: false,
    store_name: "",
    type: "",
    offer_type: "",
    structure: "",
    clothing_type: "readymade",
    brands: [],
    working_hours: {
        from: "\"2021-02-01T05:30:00.000Z\"",
        to: "\"2021-02-01T15:30:00.000Z\""
    },
}

const CreateNewStore = () => {
    const form = useForm({ defaultValues })
    const state = form.watch()
    const { type, structure } = state

    const list = [
        { title: 'Store Name', id: 'store_name' },
        { title: 'Store Type', id: 'type', list: storeTypes },
        { title: 'Brand Type', id: 'brand_type', list: brandTypes },
        { title: 'Offer Type', id: 'offer_type', list: type === 'exclusive' ? offerTypes : offerTypesChain },
        { title: 'Store Structure', id: 'structure', list: storeStructure },
    ]

    const navigate = useNavigate()
    const { submit, isLoading } = useSubmit({
        name: 'createNewStore',
        service: createNewStore,
        onSuccess: (res) => {
            navigate(`/admin/reviewStore/${res?._id}`)
        }
    })

    const create = () => {
        const state = form.watch()
        submit(state)
    }

    return (
        <VStack w={'100%'} alignItems={'flex-start'} bg={'white'} p={'4'} mb={'4'}>
            <CompHeader title='Create New Store' />
            {list.map((item, index) => {
                const Comp = item.list ? SelectField : InputField
                return <Comp tw={90} key={index} form={form} {...item} />
            })}

            <Button isLoading={isLoading} colorScheme="teal" variant="solid" onClick={create}>
                New Store
            </Button>
        </VStack>
    )
}

export default CreateNewStore