import Filters from './Filters'
import AllStores from './AllStores';
import { useForm } from 'react-hook-form';
import Loading from '../../commons/Loading';
import React, { useEffect, useState } from 'react'
import { getFilteredStores } from '../../apis/store';
import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import useSubmit from '../../hook/useSubmit';

const Shops = () => {
    const form = useForm({ defaultValues: { active: [] } })
    const [data, setData] = useState([])
    const active = form.watch('active')
    const setActive = (e) => form.setValue('active', e)

    const { submit, isLoading } = useSubmit({
        service: getFilteredStores,
        onSuccess: (data) => setData(data)
    })

    useEffect(() => { submit({ q: {} }) }, [])

    return (
        <VStack w='100vw' maxW={'100vw'} alignItems={'flex-start'} px={2}>

            <Text fontWeight={'bold'} fontSize={'2xl'}>
                Shops ({data.length})
            </Text>

            <HStack w={'100%'} alignItems={'flex-end'} spacing={10}>
                <Filters active={active} setActive={setActive} />
                <Button onClick={() => {
                    const active = form.watch('active')
                    let obj = {}
                    active.forEach((item) => obj = { ...obj, ...item.q })
                    submit({ q: obj })
                }} w='50'
                    colorScheme={'blue'} size={'sm'} isLoading={isLoading} >
                    Apply
                </Button>
            </HStack>

            {isLoading ?
                <VStack pt={10} w={'100%'}>
                    <Loading />
                </VStack>
                :
                <AllStores data={data} isLoading={isLoading} />
            }
        </VStack>
    )
}

export default Shops