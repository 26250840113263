import { Button } from "native-base"
import { Text, VStack } from "@chakra-ui/react"
import { formatDateTimes } from "../../utils/moment"
import { getDistancefromLocal } from "../../helper/coords"

export const columns = () => [
    {
        name: 'User',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Text
                    onClick={() => window.open(`/admin/users/edit/${row?.user?._id}`, '_blank')}
                    style={{ cursor: 'pointer' }}
                    fontWeight={'bold'}
                    fontSize={'md'}
                    mb={"1"}
                >
                    {row?.user?.name}
                </Text>
                <Text>
                    {row?.user?.phone_number}
                </Text>
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Store',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Text
                    onClick={() => window.open(`/admin/reviewStore/${row?.store?._id}`, '_blank')}
                    style={{ cursor: 'pointer' }}
                    fontWeight={'bold'}
                    fontSize={'md'}
                    mb={"1"}
                >
                    {row?.store.store_name}
                </Text>
                <Text>
                    {row?.store?.store_address?.address}
                </Text>
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Store Coupon',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Text
                    onClick={() => window.open(`/admin/coupons/edit/${row?.coupon?._id}`, '_blank')}
                    style={{ cursor: 'pointer' }}
                    fontWeight={'bold'}
                    fontSize={'md'}
                    mb={"1"}
                >
                    {row?.coupon?.name}
                </Text>
                <Text>
                    Redeem At : {formatDateTimes(row?.createdAt, 7)}
                </Text>
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Restuarant',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Text
                    onClick={() => window.open(`/admin/restaurants/edit/${row?.restaurant?._id}`, '_blank')}
                    style={{ cursor: 'pointer' }}
                    fontWeight={'bold'}
                    fontSize={'md'}
                    mb={"1"}
                >
                    {row?.restaurant?.name}
                </Text>
                <Text>
                    {row?.restaurant?.address?.address}
                </Text>
            </VStack>
        ,
        sortable: true,
    },
    {
        name: 'Food Coupon',
        cell: (row) =>
            <VStack spacing={0} alignItems={'flex-start'} >
                <Text
                    onClick={() => window.open(`/admin/food_coupons/edit/${row?.food_coupon?._id}`, '_blank')}
                    style={{ cursor: 'pointer' }}
                    fontWeight={'bold'}
                    fontSize={'md'}
                    mb={"1"}
                >
                    {row?.food_coupon?.name}
                </Text>
                <Text>
                    Selected At : {formatDateTimes(row?.updatedAt, 7)}
                </Text>
            </VStack>
        ,
        sortable: true,
    },
] 