import { Button, Checkbox, HStack, Spacer, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import useSubmit from '../../../hook/useSubmit'
import { toggleProductsWearsById } from '../../../apis/admin'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { LoaderIcon } from 'react-hot-toast'
import CModal from '../../../commons/CModal'
import { colors } from '../../../theme/colors'
import EditProductFilter from './EditProductFilter'

const ProductWearCard = ({ wear, isActive, refetch, gender, editWear }) => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const name = searchParams.get('name')
    const [filters, setFilters] = React.useState(false)
    const navigate = useNavigate()
    const Toggle = useSubmit({
        service: toggleProductsWearsById,
        onSuccess: refetch,
        id
    })

    return (
        <HStack borderWidth={1} px={4} py={2} w={200} borderRadius={'md'}
            borderColor={editWear && isActive ? 'lightblue' : colors.gray}
        >
            {editWear && (Toggle.isLoading ? <LoaderIcon />
                :
                <Checkbox isChecked={isActive}
                    colorScheme='blue'
                    onChange={() => Toggle.submit({ gender: gender._id, wear: wear._id })} />
            )}

            <Text fontSize={'md'} fontWeight={'normal'}>{wear.wear}</Text>

            <Spacer />

            {(!editWear && isActive) && (
                <HStack>
                    <Text color={colors.info} cursor={'pointer'}
                        onClick={() => {
                            const q = new URLSearchParams({
                                gender: gender._id,
                                wear: wear._id,
                                id, name,
                                title: `${name} > ${gender.type} > ${wear.wear} > Filters`
                            }).toString()
                            navigate(`?${q}`)
                        }} >
                        Filters
                    </Text>
                </HStack>
            )}

            {filters && (
                <CModal
                    // title={ }
                    show={filters}
                    close={() => setFilters(false)}
                    body={<EditProductFilter gender={gender._id} wear={wear._id} id={id} />}
                    size='full'
                    scrollBehavior={'inside'}
                />
            )}

        </HStack>
    )
}

export default ProductWearCard