import { Button, HStack, VStack } from 'native-base'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, } from 'react-router-dom'
import { createFlagReason, deleteFlagReasonsById, editFlagReasonsById } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import { InputField } from '../../commons/InputField'
import useSubmit from '../../hook/useSubmit'
import SelectField from '../../commons/SelectField'


const defaultValues = { reason: '', description: "", tags: [], order: 0 }

const reasonTags = [
    { label: 'Store', value: 'store' },
    { label: 'Offer', value: 'offer' },
    { label: 'Product', value: 'product' },
    { label: 'User', value: 'user' }
]

const CreateFlagsReasons = ({ data }) => {
    const form = useForm({
        defaultValues: data ?
            {
                ...data,
                tags: data.tags.map(tag => ({ value: tag, label: reasonTags.find(t => t.value === tag)?.label }))
            }
            : defaultValues
    })

    const navigate = useNavigate()

    const { submit, isLoading } = useSubmit({
        service: data ? editFlagReasonsById : createFlagReason,
        id: data?._id || null,
        onSuccess: () => navigate(-1),
    })

    const DELETE = useSubmit({
        service: deleteFlagReasonsById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    useMemo(() => { }, [form.watch()])

    return (
        <VStack space='4'>
            <CompHeader title={data ? "Edit Flag Reason" : 'Create Flags Reason'} backButton />
            <VStack space='4'>
                <InputField form={form} title='Order' id='order' />
                <InputField form={form} title='Reason' id='reason' />
                <InputField form={form} title='Description' id='description' />
                <SelectField multi form={form} title='Tags' id='tags' list={reasonTags} />
                <HStack space='2'>
                    <Button
                        onPress={() => submit({ ...form.getValues(), tags: form.getValues().tags.map(tag => tag.value) })}
                        isLoading={isLoading}
                        colorScheme='primary'
                        size='sm' variant='solid' >
                        {data ? 'Update' : 'Create'}
                    </Button>
                    {data && <Button
                        onPress={DELETE.submit}
                        isLoading={DELETE.isLoading}
                        colorScheme='danger'
                        size='sm'
                        variant='solid'
                    >
                        Delete
                    </Button>}
                </HStack>
            </VStack>
        </VStack>
    )
}

export default CreateFlagsReasons