import React, { useState, useRef } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import imageCompression from 'browser-image-compression'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import { colors } from '../../../theme/colors'
import { Box, Stack, Text } from 'native-base'
import 'react-image-crop/dist/ReactCrop.css'
import { MdCancel } from 'react-icons/md'
import { FiCamera } from 'react-icons/fi'
import box from "./box.svg"

const compressionOptions = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true, }

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      { unit: '%', width: 90, },
      aspect, mediaWidth, mediaHeight,
    ), mediaWidth, mediaHeight)
}

export default function CropArea({
  defaultImage,
  removeImage,
  setImage,
  circularCrop = false,
  aspect = null,
  rotate = 0,
  scale = 1,
  viewFinalImage = false,
  width = 150,
  height = 150,
}) {

  const [padding, setPadding] = useState(10)
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState({
    "unit": "px",
    "x": 13.78622817993164,
    "y": 14.479049682617188,
    "width": 394.3444480895996,
    "height": 376.4160919189453
  })
  const [completedCrop, setCompletedCrop] = useState()

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let _file = null
      const image = e.target.files[0]
      await imageCompression(image, compressionOptions)
        .then(function (_res) { _file = new File([_res], _res.name) })
        .catch(function (error) { console.error(error.message) })
      // setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(_file)
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate, setImage)
      }
    }, 100, [completedCrop, scale, rotate])

  return (
    <div className="App">
      <div className="Crop-Controls">
        {!imgSrc && (defaultImage ?
          <Box width={'150px'}>
            <img src={defaultImage} width={width} height={height} />
            <MdCancel onClick={() => {
              removeImage()
            }} style={{
              cursor: "pointer",
              position: "absolute", right: 10, top: 10,
              zIndex: 1, color: colors.secondary,
            }} />
          </Box>
          :
          <>
            <input type="file" onChange={onSelectFile}
              ref={imgRef} style={{ display: "none" }} />
            <Stack
              cursor={"pointer"}
              onClick={() => imgRef.current.click()}
              h={"150px"}
              w={"150px"}
              alignItems="center"
              justifyContent={"center"}
              backgroundImage={`url(${box})`}
              backgroundSize={"cover"}
            >
              <FiCamera color={colors.secondary} />
              <Text color={colors.secondary} fontWeight={500}>
                Upload Images
              </Text>
            </Stack>
          </>)
        }
      </div>
      {!!imgSrc && (
        <ReactCrop crop={crop} aspect={aspect}
          style={{ width: '100px', objectFit: 'contain', padding: `${padding}px` }}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          circularCrop={circularCrop}
        >
          <img ref={imgRef} src={imgSrc}
            alt="Crop me"
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {/* range for padding */}
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
        <input type="range" min="0" max="100" value={padding} onChange={(e) => setPadding(e.target.value)} />
        <span style={{ marginLeft: 10 }}>{padding}</span>
      </div> */}

      <div>
        {!!completedCrop && !viewFinalImage && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid lightgrey',
              objectFit: 'contain',
              width, height,
              borderRadius: circularCrop ? '50%' : '0%',
            }}
          />
        )}
      </div>
    </div>
  )
}
