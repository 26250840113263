import { Button, HStack, Input, Spacer, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import useFetch from '../../../hook/useFetch'
import { getCity, getStates } from '../../../apis/misc'
import { IMAGE_BASE_URL, contProps, contPropsTitle } from '../../../helper/variables'
import { createMarket, deleteMarketById, editMarketById } from '../../../apis/admin'
import { useNavigate } from 'react-router-dom'
import useSubmit from '../../../hook/useSubmit'
import MarketImages from './MarketImages'
import Maps from '../../../components/commons/Map/Maps'
import SelectField from '../../../commons/SelectField'
import { InputField } from '../../../commons/InputField'
import { multipledataformater } from '../../../helper/multipledataformater'

const staticData = { status: [["Active", "Verified"], ["Pending", "Not-Verified"]] }

const MarketDetails = ({ form, data }) => {
    const [images, setImages] = useState(null)
    const state = form.watch()

    const navigate = useNavigate()

    const states = useFetch({ name: 'states', service: getStates, dataOnly: true, converterLabel: 'name' })
    const cities = useFetch({
        name: `cities_${form.getValues()?.address?.state}`,
        service: getCity, dataOnly: true,
        conditionalRefetch: form.getValues()?.address?.state,
        id: form.getValues()?.address?.state,
        condition: !!form.getValues()?.address?.state,
        converterLabel: 'name'
    })

    React.useEffect(() => {
        if (!data) return
        setImages(images ? images : (data ? `${IMAGE_BASE_URL}${data.image}` : images))
    }, [data])

    const { submit, isLoading } = useSubmit({
        service: data ? editMarketById : createMarket,
        id: data?._id,
        onSuccess: (res) => {
            console.log(res)
            if (data) {
                navigate(-1)
            } else {
                navigate(`/admin/markets/edit/${res?._id}`)
            }
        },
    })

    const Delete = useSubmit({
        service: deleteMarketById,
        id: data?._id,
        onSuccess: () => navigate(-1),
        alert: true,
    })

    const list = [
        { title: 'Market id', id: '_id', disabled: true, show: !!data },
        { title: 'Market Name', id: 'name' },
        { title: 'Phone Number', id: 'phone_number' },
        {
            title: 'Google Maps Coords', Comp:
                <VStack w="100%" alignItems="flex-start" >
                    <Text fontSize="sm" color="gray.500" >
                        Enter the coordinates in the format: latitude,longitude
                    </Text>
                    <Input
                        placeholder='latitude,longitude'
                        onChange={(e) => {
                            form.setValue('geo_location.coordinates', { latitude: e.target.value.split(',')[0], longitude: e.target.value.split(',')[1] })
                        }}
                    />
                </VStack>
        },
        { title: 'Latitude', id: 'geo_location.coordinates.latitude' },
        { title: 'Longtitude', id: 'geo_location.coordinates.longitude' },
        { title: 'Address', id: 'address.address' },
        { title: 'Locality', id: 'address.locality' },
        { title: 'Is Top', id: 'is_top', list: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }] },
        { title: 'Status', id: 'status', list: staticData.status.map(([value, label]) => ({ label, value })) },
        { title: 'State', id: 'address.state', list: states },
        { title: 'City', id: 'address.city', list: cities },
        { title: 'Pincode', id: 'address.pincode' },
    ]

    const save = () => {
        const state = { ...form.getValues() }

        const formdata = multipledataformater({
            state,
            images: state.images.filter(image => typeof image !== 'string' && image !== '')
        })
        // return
        submit(formdata)
    }

    return (
        <VStack w="100%" alignItems="flex-start" >
            <MarketImages form={form} />
            <VStack {...contProps} >
                <Text {...contPropsTitle}>
                    Market Details
                </Text>
                {/* <Maps coordinates={state?.geo_location?.coordinates}
                    setCoordinates={(val) => form.setValue('geo_location.coordinates', val)}
                /> */}
                {list.map((item, index) => {
                    if (item?.Comp) {
                        return item.Comp
                    }
                    const Comp = item?.Comp || item.list ? SelectField : InputField
                    return <Comp tw={120} key={index} form={form} {...item} />
                })}

                <HStack>
                    <Button m='auto'
                        colorScheme={data ? 'green' : 'blue'}
                        variant={'solid'}
                        onClick={save}
                        isLoading={isLoading}
                        size='sm'
                    >{data ? 'Update Market' : "Add Market"}</Button>
                    <Spacer />
                    {data && <Button m='auto'
                        disabled variant={'solid'}
                        isLoading={Delete.isLoading}
                        onClick={Delete.submit} size='sm' >
                        Delete Market
                    </Button>}
                </HStack>
            </VStack>
        </VStack>
    )
}

export default MarketDetails