import React from 'react'
import { VStack } from '@chakra-ui/react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { getMyUserCallingBatches } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import CallingBatch from './CallingBatch'

const MyUserCallingBatches = () => {
    const [expanded, setExpanded] = React.useState(false)

    const { data, isLoading, refetch } = useFetch({
        service: getMyUserCallingBatches,
    })

    if (isLoading) return <Loading />

    return (
        <VStack w='100%' alignItems={'flex-start'} >
            <CompHeader title={`My User Calling Batches`} />
            {data.map((item, index) =>
                <CallingBatch key={item._id} data={item} index={index} refetch={refetch}
                    expanded={expanded === item._id}
                    setExpanded={setExpanded}
                />
            )}
        </VStack>
    )
}

export default MyUserCallingBatches