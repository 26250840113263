import React from 'react'
import { columns } from './static'
import useFetch from '../../hook/useFetch'
import { useNavigate } from 'react-router-dom'
import { getLatestStyles } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DateRangeSelector from '../../commons/DateRangeSelector'
import { getPastDate } from '../../utils/moment'
import { Button, VStack } from '@chakra-ui/react'

const StoreUploads = () => {
    const navigate = useNavigate()
    const [params, setParams] = React.useState({
        range: [getPastDate(1000), new Date()],
        havingMultipleImages: false
    })

    const { data, isLoading } = useFetch({
        name: 'getLatestStyles',
        service: getLatestStyles,
        pagination: true,
        params,
        conditionalRefetch: [params],
    })

    return (
        <VStack w={'100%'} alignItems={'flex-start'}>
            <CompHeader title='Store Uploads' />
            <DateRangeSelector range={params.range} setRange={(range) => setParams({ ...params, range })} />
            <VStack w={'100%'} alignItems={'flex-start'} p={4} bg={'white'} borderRadius={8} boxShadow={'md'}>
                <Button size='sm' onClick={() => setParams({ ...params, havingMultipleImages: !params.havingMultipleImages })}
                    bg={params.havingMultipleImages ? 'green.400' : 'gray.200'}
                >
                    Having Multiple Images
                </Button>
            </VStack>
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default StoreUploads