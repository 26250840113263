import { VStack } from 'native-base'
import React, { } from 'react'
import { useParams } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import Loading from '../../commons/Loading'
import useFetch from '../../hook/useFetch'
import { getUserSubscriptionsById } from '../../apis/admin';
import CreateSubscriptions from './CreateSubscriptions';

const EditSubscriptions = () => {
    const { id } = useParams()

    const { data, isLoading } = useFetch({
        name: `getsubscriptionsbyid_${id}`,
        service: getUserSubscriptionsById,
        id
    })

    return (
        <VStack p='3' space='2'>
            <CompHeader title='Edit Subscription' backButton />
            {isLoading ? <Loading /> : <CreateSubscriptions data={data} />}
        </VStack>
    )
}

export default EditSubscriptions