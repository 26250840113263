import React from 'react'
import { VStack } from 'native-base'
import CompHeader from '../../commons/CompHeader/CompHeader'
import AddMall from './AddMall';

const CreateMall = () => {
    return (
        <VStack p='3' space='2' w={'100%'} maxW={500}>
            <CompHeader title='Create New Mall' backButton />
            <AddMall />
        </VStack>
    )
}

export default CreateMall