import { Button, Text, VStack } from '@chakra-ui/react';
import { formatDateTimes } from '../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onClick={() => navigate(`/admin/offers/edit/${row._id}`)}>
            View</Button>,
        sortable: false,
    },
    {
        name: 'Offer',
        selector: (row) => row.offer,
        sortable: true,
    },
    {
        name: 'Store',
        cell: (row) =>
            <VStack
                spacing={0}
                alignItems={'flex-start'}
            >
                <Button variant='link' color={'blue.500'} outline={'none'} border={'none'}
                    onClick={() => window.open(`/admin/reviewStore/${row.store_id._id}`)}
                >
                    {row.store_id.store_name}
                </Button>
                <Text
                    fontSize={'10'}
                >
                    {row?.store_id?.store_address?.address}
                </Text>
            </VStack>,
        sortable: true,
    },
    {
        name: 'Start Date',
        selector: (row) => formatDateTimes(row.start_date, 3),
        sortable: true,
    },
    {
        name: 'End Date',
        selector: (row) => formatDateTimes(row.end_date, 3),
        sortable: true,
    },
    {
        name: 'CreatedAt',
        selector: (row) => formatDateTimes(row.createdAt, 3),
        sortable: true,
    },
    {
        name: 'Offer Applicable',
        selector: (row) => row.offer_applicable.map(item => item.type).join(", "),
        sortable: true,
    },
]