import { HStack, Text } from 'native-base'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getURLQuery } from '../../helper/url'
import { colors } from '../../theme/colors'

const list = [
    ['Created', '/admin/system_offers/created', 'created'],
    ['Suggested', '/admin/system_offers/suggested', 'suggested'],
]

const AdminOffersTabs = ({ as }) => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const status = getURLQuery(search, 'verified');

    return (
        <HStack mb='2' space='4'>
            {list.map(([name, link, active]) =>
                <Text key={link} fontWeight='600' fontSize='lg'
                    color={as ? as === active ? colors.dark : colors.secondary : status === active ? colors.dark : colors.secondary}
                    onPress={() => navigate(link)}>{name}</Text>
            )}
        </HStack>
    )
}

export default AdminOffersTabs