import { Button } from "native-base";
import { getAppLinks } from "../../helper/applink";
import { Text } from "@chakra-ui/react";

export const columns = (navigate, getDashboardLink) => [
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button
            size='sm' variant={'link'}
            onPress={() => navigate(`/admin/reviewStore/${row._id}`)}
        >
            Review</Button>,
        sortable: false,
    },
    {
        name: 'Name',
        selector: (row) => row.store_name,
        sortable: true,
    },
    {
        name: 'State',
        selector: (row) => row.store_address.state?.name,
        sortable: true,
    },
    {
        name: 'City',
        selector: (row) => row.store_address.city?.name,
        sortable: true,
    },
    {
        name: 'Status',
        width: '250px',
        button: true,
        cell: (row) => <Text flex={1}>
            <Text as='span' color={row?.verified ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Verified-{row?.store?.verified ? 'Y' : 'N'}/
            </Text>
            <Text as='span' color={row?.claimed ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Claimed-{row?.store?.claimed ? 'Y' : 'N'}/
            </Text>
            <Text as='span' color={row?.pre_register ? 'green.500' : 'red.500'} fontWeight={'semibold'} >
                Pre-Register-{row?.pre_register ? 'Y' : 'N'}
            </Text>
        </Text>,
        sortable: false,
    },
    {
        name: 'App Link',
        button: true,
        cell: (row) => <Button
            color='blue.400'
            size='sm' variant={'link'}
            onPress={() => ['exclusive', 'non-branded'].includes(row.type) ? getAppLinks(`/store/${row._id}`) : getAppLinks(`/mutliBrandStore/${row._id}`)
            } >
            copy</Button>,
        sortable: false,
    },
] 