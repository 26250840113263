import { VStack } from '@chakra-ui/react'
import React from 'react'
import CompHeader from '../../commons/CompHeader/CompHeader'
import useFetch from '../../hook/useFetch'
import { getCalls } from '../../apis/admin'
import { useNavigate } from 'react-router-dom'
import { columns } from './static'
import DataTable from '../../commons/DataTable/DataTable'
import DateRangeSelector from '../../commons/DateRangeSelector'
import { getPastDate } from '../../utils/moment'

const Calls = () => {
    const navigate = useNavigate()
    const [range, setRange] = React.useState([getPastDate(10), new Date()])

    const { data, isLoading } = useFetch({
        name: 'calls',
        service: getCalls,
        pagination: true,
        params: {
            date1: range[0],
            date2: range[1]
        },
        conditionalRefetch: [range]
    })

    return (
        <VStack w="100%" alignItems={'flex-start'} >
            <CompHeader title='Calls' />

            <DateRangeSelector range={range} setRange={setRange} />

            <DataTable tableData={{ columns: columns({ navigate }), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Calls