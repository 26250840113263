import React from 'react'
import useFetch from '../../hook/useFetch'
import Loading from '../../commons/Loading'
import { colors } from '../../theme/colors'
import { getPastDate } from '../../utils/moment'
import { getWaMessageActions } from '../../apis/admin'
import { HStack, Text, VStack } from '@chakra-ui/react'
import WaMessageActionsCard from './WaMessageActionsCard'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DateRangeSelector from '../../commons/DateRangeSelector'

const WaMessageActions = () => {
    const [params, setParams] = React.useState({
        q: 'button=',
        range: [getPastDate(30), new Date()]
    })

    const [filters, setFilters] = React.useState(null)

    const { data, isLoading } = useFetch({
        name: 'getWaMessageActions',
        service: getWaMessageActions,
        params,
        conditionalRefetch: [params],
        onSuccess: (data) => {
            if (!filters) setFilters(data.filters)
        }
    })


    if (isLoading) return <Loading />

    return (
        <VStack alignItems={'flex-start'} w={'100%'} >

            <CompHeader title={'Message Actions'} />

            <VStack w={'100%'} alignItems={'flex-start'} p={4} bg={'white'} borderRadius={8} boxShadow={'md'}>
                {filters?.map(item =>
                    <HStack key={item.id} w={'100%'} alignItems={'center'} spacing={2}>
                        <Text color={'gray.500'} fontSize={'sm'} fontWeight={'bold'} >
                            {item.name}:
                        </Text>
                        <HStack alignItems={'center'}>
                            {item.values.map(value => {
                                const active = params.q.includes(`${item.id}=${value}`)
                                return (<Text key={value} color={'black'} fontSize={'sm'} fontWeight={'bold'}
                                    p={2} borderRadius={4} bg={active ? colors.success : colors.input}
                                    cursor={'pointer'}
                                    onClick={() => {
                                        const q = `${item.id}=${value}`
                                        if (active) setParams({ q: params.q.replace(q, '') })
                                        else setParams({ q })
                                    }}
                                >
                                    {value}
                                </Text>)
                            })}
                        </HStack>
                    </HStack>
                )}
            </VStack>

            <DateRangeSelector range={params.range} setRange={(range) => setParams({ ...params, range })} />

            <Text fontSize={'lg'} color={'gray.500'} fontWeight={'bold'} w={'100%'} pr={2} >
                Total Message Actions: {data.total}
            </Text>

            <VStack w={'100%'} >
                {data?.data?.map(item => <WaMessageActionsCard key={item.id} data={item} />)}
            </VStack>

        </VStack>
    )
}

export default WaMessageActions