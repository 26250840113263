import React from 'react'
import OfferTabs from './OfferTabs'
import { columns } from './static';
import { VStack } from 'native-base'
import useFetch from '../../hook/useFetch'
import { getOffers } from '../../apis/admin'
import { getURLQuery } from '../../helper/url'
import DataTable from '../../commons/DataTable/DataTable'
import { useLocation, useNavigate } from 'react-router-dom'
import CompHeader from '../../commons/CompHeader/CompHeader'
import ExcelDownload from '../../components/ExcelDownload/ExcelDownload';

const Offers = () => {
    const navigate = useNavigate()
    const { search } = useLocation();
    const verified = getURLQuery(search, 'verified') || 'all';

    const { data, isLoading } = useFetch({
        name: 'getOffers',
        service: getOffers,
        pagination: true,
        params: { verified },
        conditionalRefetch: [verified],
        pagination: true
    })

    return (
        <VStack>
            <CompHeader title='Offers' />
            <OfferTabs />
            <ExcelDownload service={getOffers} params={{ verified }} />
            <DataTable tableData={{ columns: columns(navigate), data, pagination: true, isLoading }} />
        </VStack>
    )
}

export default Offers