import React from 'react'
import { columns } from './static'
import useSubmit from '../../hook/useSubmit'
import { removeAdminAlertUser } from '../../apis/admin'
import DataTable from '../../commons/DataTable/DataTable'

const List = ({ data, refetch }) => {

    const { submit: remove, isLoading } = useSubmit({
        service: removeAdminAlertUser,
        onSuccess: () => refetch()
    })

    return (
        <DataTable tableData={{ columns: columns({ remove }), data, isLoading, pagination: true }} showSearch={false} pagination={false} title={'Admin Alerts'} />
    )
}

export default List