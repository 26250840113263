
export const multipledataformater = ({ state, images }) => {
    const formdata = new FormData();
    Object.keys(state).map((obj) => {
        if (Array.isArray(state[obj])) {
            state[obj].map((item) => {
                if (typeof item === "object") {
                    return formdata.append(obj, JSON.stringify(item));
                }
                formdata.append(obj, item);
            });
        } else if (!Array.isArray(state[obj]) && typeof state[obj] === "object") {
            formdata.append(obj, JSON.stringify(state[obj]));
        }
        if (typeof state[obj] === "string") {
            formdata.append(obj, state[obj]);
        }
        if (typeof state[obj] === "boolean") {
            formdata.append(obj, state[obj]);
        }
    });

    if (images?.length > 0) {
        images.map((image) => formdata.append("images", image))
    }

    return formdata;
}
