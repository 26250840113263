import React from 'react'
import Tile from '../../../commons/Tile'
import useFetch from '../../../hook/useFetch'
import Loading from '../../../commons/Loading'
import { HStack, Text, VStack } from '@chakra-ui/react'
import { getUserReviewAnalysics } from '../../../apis/admin'
import { getPastDate } from '../../../utils/moment'
import DateRangeSelector from '../../../commons/DateRangeSelector'

const TagTile = ({ title, list }) => {
    return (
        <HStack width='100%' alignItems='flex-start' flex={1} >
            <Text fontSize={'md'} fontWeight={'semibold'}>
                {title}:
            </Text>
            <VStack width='100%' alignItems={'flex-start'} >
                {list.map(item =>
                    <Tile titleWidth='20vw' key={item.string} label={item.string} value={item.count} />
                )}
            </VStack>
        </HStack>
    )
}

const UserCallAnalysis = () => {
    const [range, setRange] = React.useState([getPastDate(1000), new Date()])

    const { data, isLoading } = useFetch({
        name: 'getUserReviewAnalysics',
        service: getUserReviewAnalysics,
        params: {
            date1: range[0],
            date2: range[1]
        },
        conditionalRefetch: [range]
    })

    return (
        <VStack width='100%' alignItems='flex-start' bg='white' p='4' borderRadius='10' boxShadow='md' >
            <Text fontSize='xl' fontWeight='bold' color='gray.500' alignSelf='flex-start' >
                User Call Analysis
            </Text>

            <DateRangeSelector range={range} setRange={setRange} />

            {isLoading ? <Loading /> :
                <>
                    <Tile label='Total User Calls' value={data?.total_calls} />
                    <Tile label='Total User Reached' value={data?.user_calls} />

                    <HStack width='100%' alignItems='flex-start' justifyContent={'space-between'} >
                        <VStack flex={1}>
                            <TagTile title='Purpose' list={data.purposes} />
                            <TagTile title='Tags' list={data.tags} />
                        </VStack>
                        <TagTile title='Comments' list={data.comments} />
                    </HStack>
                </>}

        </VStack>
    )
}

export default UserCallAnalysis