import React, { memo, useCallback } from 'react'
import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react'

const usePaginationNav = ({
    data = [],
    item_per_page = 10,
    defaultPage = 1
}) => {
    const [page, setPage] = React.useState(defaultPage)
    const [limit, setLimit] = React.useState(item_per_page)

    const totalPage = Math.ceil(data.length / limit)
    const start = (page - 1) * limit
    const end = page * limit
    const currentData = data.slice(start, end)

    const nextPage = useCallback((page, totalPage) => {
        if (page >= totalPage) return
        setPage(page + 1)
    }, [])

    const prevPage = useCallback((page) => {
        if (page === 1) return
        setPage(page - 1)
    }, [])

    const goToPage = useCallback((page, totalPage) => {
        if (page < 1 || page > totalPage) return
        setPage(page)
    }, [])

    const Nav = memo(() => {
        if (totalPage === 0) return null
        return (
            <VStack w={'100%'} alignItems={'center'} spacing={2} bg={'white'} p={4}
                borderRadius={10} borderWidth={2} borderColor={'gray.200'}
            >
                <HStack spacing={2} justifyContent={'center'} w={'100%'} alignItems={'center'}>
                    <Input
                        width={150}
                        type='number'
                        placeholder={`per page(${limit})`}
                        onChange={(e) => {
                            setTimeout(() => {
                                setLimit(Number(e.target.value))
                                setPage(1)
                            }, 500)
                        }}
                    />
                    <Button onClick={() => prevPage(page)} disabled={page === 1} >
                        {`< Prev `}
                    </Button>
                    <Button onClick={() => nextPage(page, totalPage)} disabled={page === totalPage} >
                        {`Next >`}
                    </Button>
                    <Input
                        width={150}
                        type='number'
                        placeholder={`Go to page(${page})`}
                        onChange={(e) => {
                            setTimeout(() => {
                                goToPage(Number(e.target.value), totalPage)
                            }, 500)
                        }}
                    />
                </HStack>
                <HStack spacing={2} justifyContent={'center'} w={'100%'} alignItems={'center'} overflowX={'scroll'}>
                    <Text cursor={'pointer'} color={page === 1 ? 'blue.500' : 'black'} >
                        {totalPage === 0 ? 0 : page}/{totalPage}
                    </Text>
                </HStack>
            </VStack>
        )
    }, [totalPage, page])

    return {
        currentData,
        nextPage,
        prevPage,
        goToPage,
        page,
        limit,
        totalPage,
        Nav
    }

}

export default usePaginationNav