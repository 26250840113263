import { useEffect } from 'react';
import RulesSummary from './RulesSummary';
import { useForm } from 'react-hook-form';
import useFetch from '../../../hook/useFetch';
import Loading from '../../../commons/Loading';
import useSubmit from '../../../hook/useSubmit';
import React, { useMemo, useState } from 'react'
import { Spacer, Text, VStack } from 'native-base'
import { useSearchParams } from 'react-router-dom';
import RulesSelectionCard from './RulesSelectionCard';
import CompHeader from '../../../commons/CompHeader/CompHeader';
import RulesSelectionExceptCard from './RulesSelectionExceptCard';
import { arrayIntersection } from '../../../helper/stringOperations';
import { HStack, Input, Button, SimpleGrid } from '@chakra-ui/react';
import { editKeyRules, getPopulatedProductFiltersById } from '../../../apis/admin';
import RulesSelectionRelationCard from './RulesSelectionRelationCard';

const EditRules = () => {
    const [searchParams] = useSearchParams()
    const gender = searchParams?.get('gender')
    const wear = searchParams?.get('wear')
    const product = searchParams?.get('id')
    const type = searchParams?.get('type')
    const type_id = searchParams?.get('type_id')
    const pageTitle = searchParams?.get('title')
    const parent_id = searchParams?.get('parent')
    const child_id = searchParams?.get('child')
    const hide = true
    const [search, setSearch] = useState('')
    const form = useForm({ defaultValues: { titles: [], keys: [], except: [], hides: [], show: null, isExcept: false, isRelation: false, product, gender, wear } })
    const { titles, keys, isExcept, except, relations, show, isRelation } = form.getValues()
    const title = type === 'title'

    const { data, isLoading, refetch } = useFetch({ name: `product_filters_populated_${product}`, service: getPopulatedProductFiltersById, params: { gender, wear }, id: product })
    const parentObj = data.find(({ title }) => title._id === parent_id)
    const childObj = title ? null : parentObj?.keys?.find(({ _id }) => _id === child_id)
    const rules = childObj?.rules || parentObj?.title?.rules || []

    const titleKeys = data
        ?.filter(({ keys }) => keys?.length > 0)
        ?.map(({ title, keys: _keys }) => ({
            ...title,
            hasRules: isExcept ?
                except?.map(({ title }) => title)?.includes(title._id)
                : isRelation ? relations?.map(({ title }) => title)?.includes(title._id)
                    : titles?.includes(title._id) || arrayIntersection(keys, _keys.map(({ _id }) => _id))?.length > 0,
        }))
        .sort((a, b) => { if (a.title < b.title) { return -1 } if (a.title > b.title) { return 1 } return 0 })
        .sort((a, b) => { if (a.hasRules) { return -1 } if (b.hasRules) { return 1 } return 0 })
        .filter(({ _id }) => _id !== parent_id)

    useEffect(() => {
        if (rules.length === 0) return
        form.setValue('titles', rules?.titles || [])
        form.setValue('keys', rules?.keys || [])
        form.setValue('except', rules?.except || [])
        form.setValue('relations', rules?.relations || [])
        form.setValue('hides', rules?.hides || [])
    }, [rules])

    const RulesCompo = useMemo(() => {
        if (!show) return null

        if (isRelation)
            return <RulesSelectionRelationCard data={data?.find(({ title }) => title._id === show)} form={form} />

        if (isExcept)
            return <RulesSelectionExceptCard data={data?.find(({ title }) => title._id === show)} form={form} />

        return <RulesSelectionCard data={data?.find(({ title }) => title._id === show)} form={form} />
    }, [form.watch()])

    const Save = useSubmit({ service: editKeyRules, props: { type, type_id }, onSuccess: refetch })
    const save = () => Save.submit({ ...form.getValues(), rule_type: hide ? 'hide' : 'show' })

    const reset = () => {
        form.setValue('titles', [])
        form.setValue('keys', [])
        form.setValue('except', [])
        form.setValue('hides', [])
        form.setValue('isExcept', false)
    }

    if (isLoading) return <Loading />

    return (
        <VStack>
            <CompHeader title={pageTitle} />

            <HStack mb={10}>
                {isExcept ?
                    <Text fontWeight={'bold'} fontSize='md'>Select Filter Values to show when {childObj?.label || parentObj?.title?.title} {type} is selected </Text>
                    :
                    isRelation ?
                        <Text fontWeight={'bold'} fontSize='md'>Add Relation for {childObj?.label || parentObj?.title?.title} {type}</Text>
                        :
                        <Text fontWeight={'bold'} fontSize='md'>Select When to {hide ? 'hide' : 'show'} {childObj?.label || parentObj?.title?.title} {type}</Text>
                }
                <Spacer />

                <Button onClick={() => {
                    if (!isRelation)
                        form.setValue('isExcept', false)
                    form.setValue('isRelation', !isRelation)
                }}
                    mr={4} variant='outline' colorScheme='gray'>Relation: {isRelation ? 'On' : 'Off'} </Button>

                <Button onClick={() => {
                    if (!isExcept)
                        form.setValue('isRelation', false)
                    form.setValue('isExcept', !isExcept)
                }}
                    mr={4} variant='outline' colorScheme='gray'>Expect: {isExcept ? 'On' : 'Off'} </Button>

                <Button onClick={reset} mr={4} variant='outline' colorScheme='red' >Reset</Button>
                <Button onClick={save} variant='solid' colorScheme='blue' isLoading={Save.isLoading}>Save</Button>
            </HStack>

            {product &&
                <>
                    <VStack w={'100%'} bg='white' p='4' borderRadius={'lg'} mb='4' space={2}>
                        <Text fontWeight={'bold'} fontSize='md'>Filters ({titleKeys.length})</Text>

                        <Input value={search} bg={'white'} borderWidth={0} outline={0}
                            onChange={e => setSearch(e.target.value)} placeholder='Search filters' />

                        <SimpleGrid columns={[3, 7]} spacing='2' mb='2'>
                            {titleKeys.filter(({ title }) => title.toLowerCase().startsWith(search.toLowerCase())).map(({ title, hasRules, _id }) =>
                                <Button variant={show === _id ? 'solid' : 'outline'}
                                    colorScheme={hasRules ? 'green' : "blue"}
                                    onClick={() => show === _id ? form.setValue('show', null) : form.setValue('show', _id)}
                                >
                                    {title}
                                </Button>
                            )}
                        </SimpleGrid>

                    </VStack>
                    {RulesCompo}
                    <RulesSummary form={form} data={data} parentObj={parentObj} childObj={childObj} />
                </>
            }
        </VStack>
    )
}

export default EditRules