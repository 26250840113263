import { Button } from '@chakra-ui/react';
import { formatDateTimes } from '../../../../utils/moment';

export const columns = (navigate) => [
    {
        name: 'Offer',
        selector: (row) => row.offer,
        sortable: true,
    },
    {
        name: 'Brand',
        selector: (row) => row.brands.map(item => item.brand).join(", "),
        sortable: true,
    },
    {
        name: 'Start Date',
        selector: (row) => formatDateTimes(row.start_date, 3),
        sortable: true,
    },
    {
        name: 'End Date',
        selector: (row) => formatDateTimes(row.end_date, 3),
        sortable: true,
    },
    {
        name: 'CreatedAt',
        selector: (row) => formatDateTimes(row.createdAt, 3),
        sortable: true,
    },
    {
        name: 'Offer Applicable',
        selector: (row) => row.offer_applicable.map(item => item.type).join(", "),
        sortable: true,
    },
    {
        name: 'Actions',
        button: true,
        cell: (row) => <Button size='sm' variant={'link'}
            onClick={() => navigate(`/admin/offers/edit/${row._id}`)} >
            edit
        </Button>,
        sortable: false,
    },
] 