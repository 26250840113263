import React from "react";
import ReactSelect from "react-select";
import { Pressable } from "native-base";
import { colors } from "../../../../theme/colors";
import { contProps } from "../../../../helper/variables";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

const FilterCard = ({ selection, changeSelection, selectedKeys, filters = [], showTitle = true, title, multiple }) => {
    return (
        filters.length ?
            <VStack w={'100%'} alignItems={'flex-start'}  >
                {title ? <Text color={colors.secondary} fontSize='lg' fontWeight={'semibold'}>
                    {title}
                </Text> : null}

                {filters.map(item => {
                    const { title, keys } = item
                    const type = title?.type || 'select'
                    const titleSelection = selection?.find(obj => obj.title === title._id)?.keys || []

                    return (
                        <VStack {...contProps} p={2}>
                            {showTitle ?
                                <HStack alignItems={'center'} spacing={0}>
                                    <VStack w={'100%'} alignItems={'flex-start'} spacing={0} >
                                        <Text fontSize={'sm'} fontWeight='semibold' >
                                            {item.title.title}{item.title?.required && "*"}
                                        </Text>
                                        <HStack space={1} alignItems={'center'} w={'100%'}>
                                            {keys?.[0]?.label?.split('-#')?.[1] ?
                                                keys?.filter(({ _id }) => selectedKeys.includes(_id)).map(({ label }, index) =>
                                                    <Text color={`#${label?.split('-#')?.[1]}`} fontSize={'xs'}>
                                                        {label?.split('-#')?.[0]}{index !== keys?.filter(({ _id }) => selectedKeys.includes(_id)).length - 1 && ','}
                                                    </Text>
                                                )
                                                : <></>}
                                        </HStack>
                                    </VStack>
                                </HStack> : null}

                            {['select', 'autocomplete'].includes(type) ?
                                <Box w={'100%'}>
                                    <ReactSelect
                                        value={titleSelection.map(obj => ({ label: keys.find(({ _id }) => _id === obj)?.label, value: obj }))}
                                        onChange={e => {
                                            if (!Array.isArray(e)) e = [e]
                                            e = e.map(({ value }) => value)
                                            const index = selection?.findIndex(obj => obj.title === title._id)
                                            if (index === -1)
                                                selection.push({ title: title?._id, keys: e })
                                            else selection[index].keys = e
                                            changeSelection(selection)
                                        }}
                                        isMulti={multiple}
                                        options={keys.map(({ label, _id }) => ({ label, value: _id }))}
                                        placeholder={`${title.title}${title.required ? '*' : ''}`}
                                    />
                                </Box>
                                :
                                <HStack spacing={2} alignItems={'center'} >
                                    {keys.map(({ label, _id }) =>
                                        <Pressable onPress={() => {
                                            const index = selection?.findIndex(obj => obj.title === title._id)
                                            if (index === -1)
                                                selection.push({ title: title?._id, keys: [_id] })
                                            else {
                                                const keys = selection?.[index]?.keys || []
                                                selection[index].keys = keys.includes(_id) ? keys.filter(obj => obj !== _id) : [...keys, _id]
                                            }
                                            changeSelection(selection)
                                        }}>
                                            {type === 'color' ?
                                                <Box w={5} h={5} rounded={100} bg={label?.split('-')[1]}
                                                    borderWidth={selectedKeys.includes(_id) ? 2 : 0.5}
                                                    borderColor={selectedKeys.includes(_id) ? colors.primary : 'gray.400'}
                                                />
                                                :
                                                <Text p='2' mb='.5' w={type === 'age' ? 30 : 16} textAlign='center'
                                                    textTransform={'uppercase'} borderWidth={1} borderRadius={'md'}
                                                    borderColor={selectedKeys.includes(_id) ? colors.primary : 'gray.400'}>
                                                    {label}
                                                </Text>
                                            }
                                        </Pressable>
                                    )}
                                </HStack>
                            }
                        </VStack>
                    )
                })}
            </VStack> : null
    )
}

export default FilterCard