import { Button, Image, Text } from 'native-base';
import { IMAGE_BASE_URL } from '../../helper/variables';
import { getAppLinks, copyToClipboard } from '../../helper/applink';
import { formatDateTimes } from '../../utils/moment';

export const columns = (navigate) => [
    // {
    //     name: 'Logo',
    //     cell: (row) => <Image cursor='pointer' src={IMAGE_BASE_URL + row.logo} h='50' w='50' />,
    //     sortable: true,
    // },
    {
        name: 'Title',
        selector: (row) => row.title,
        sortable: true,
    },
    {
        name: 'Description',
        selector: (row) => row.description,
        sortable: true,
    },
    {
        name: 'Sent at',
        selector: (row) => formatDateTimes(row.createdAt, 5),
        sortable: true,
    },
    {
        name: 'Link',
        button: true,
        width: '25%',
        cell: (row) =>
            <Text color='blue.400' variant={'link'}
                onPress={() => copyToClipboard(row?.payload?.url)}>
                {row?.payload?.url}</Text>,
        sortable: false,
    },
    // {
    //     name: 'Actions',
    //     button: true,
    //     cell: (row) => <Button
    //         size='sm' variant={'link'}
    //         onPress={() => navigate(`/admin/chains/edit/${row._id}`)}>
    //         View</Button>,
    //     sortable: false,
    // },
]