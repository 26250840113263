import React, { useState, useRef, useEffect } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import { Stack, Button } from '@chakra-ui/react'
import 'react-image-crop/dist/ReactCrop.css'
import Model from '../UI/Model';


const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      { unit: '%', width: 90, },
      aspect, mediaWidth, mediaHeight,
    ), mediaWidth, mediaHeight)
}

export default function CropAreaSingle({
  circularCrop = false,
  rotate = 0,
  aspect = 1,
  scale = 1,
  image,
  setImage,
  close
}) {

  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [final, setFinal] = useState(null)
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState(undefined)
  const [completedCrop, setCompletedCrop] = useState()

  useEffect(() => {
    if (!image) return
    let _file = image
    setCrop(undefined)
    const reader = new FileReader()
    reader.addEventListener('load', () =>
      setImgSrc(reader.result?.toString() || ''),
    )
    reader?.readAsDataURL(_file)
  }, [image])

  const okAction = () => {
    if (final) {
      setImage(final)
      close()
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate, setFinal)
      }
    }, 100, [completedCrop, scale, rotate])

  return (
    <Stack className="App">
      <Model
        title='Crop Image'
        show={true}
        // close={close}
        body={
          <Stack p='4' >
            <ReactCrop crop={crop} aspect={aspect}
              style={{
                borderRadius: '10px',
                width: '500px', objectFit: 'contain'
              }}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              circularCrop={circularCrop}
            >
              <img ref={imgRef} src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                alt="Crop me" onLoad={onImageLoad} />
            </ReactCrop>

            <Button onClick={okAction} alignSelf={'flex-end'}
              colorScheme='red' w='20' variant={'solid'} > OK </Button>

          </Stack>
        }
      />
      <Stack display={'none'}>
        {!!imgSrc && !!image && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '2px solid lightgrey',
              objectFit: 'contain',
              width: 240, height: 240,
              borderRadius: circularCrop ? '50%' : '0%',
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
