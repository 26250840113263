import React from 'react'
import { VStack } from 'native-base'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFlags } from '../../apis/admin'
import CompHeader from '../../commons/CompHeader/CompHeader'
import DataTable from '../../commons/DataTable/DataTable'
import useFetch from '../../hook/useFetch'
import { columns } from './static';
import { getURLQuery } from '../../helper/url'
import FlagsTabs from './FlagTabs'

const Flags = () => {
    const navigate = useNavigate()
    const { search } = useLocation();
    const type = getURLQuery(search, 'type') || 'all';

    const { data, isLoading } = useFetch({
        name: 'getFlags',
        params: { type },
        service: getFlags, pagination: true
    })

    return (
        <VStack space='4'>
            <CompHeader title='Flags' />
            <FlagsTabs />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default Flags