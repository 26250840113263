import React from 'react'
import { columns } from './static';
import { VStack } from 'native-base';
import useFetch from '../../hook/useFetch';
import { useNavigate } from 'react-router-dom';
import { getLinkShortener } from '../../apis/admin';
import DataTable from '../../commons/DataTable/DataTable';
import CompHeader from '../../commons/CompHeader/CompHeader';

const LinkShortener = () => {
    const navigate = useNavigate()

    const { data, isLoading } = useFetch({
        name: 'getLinkShortener',
        service: getLinkShortener,
        pagination: true,
    })

    return (
        <VStack>
            <CompHeader title="Link Shortener" buttonTitle='Create Link' onPress={() => navigate(`/admin/link_shortener/create`)} />
            <DataTable tableData={{ columns: columns(navigate), data, isLoading, pagination: true }} />
        </VStack>
    )
}

export default LinkShortener