import React from 'react'
import DatePicker from 'react-datepicker';
import { getDayDiff, getPastDate } from '../../../utils/moment';
import { HStack, Box, Input, VStack, Text } from '@chakra-ui/react';
import { colors } from '../../../theme/colors';

const DateRangeSelector = ({
    range = [getPastDate(30), new Date()],
    setRange = () => { }
}) => {
    return (
        <VStack w={'100%'} alignItems={'flex-start'} >
            <Text fontSize={'lg'} fontWeight={'bold'}>
                Range ( {getDayDiff(range[0], range[1])} days )
            </Text>
            <HStack w='100%' alignItems={'center'} >
                <Box zIndex='1' flex={1}>
                    <Text fontWeight={'semibold'}>From</Text>
                    <DatePicker
                        selected={range[0]}
                        onChange={(date) => setRange([date, range[1]])}
                        maxDate={range[1]}
                        dateFormat={'dd/MM/yyyy'}
                        customInput={
                            <Input
                                variant={"outline"}
                                size="lg"
                                id="offer"
                                placeholder="From"
                                bg={colors.inputBg}
                            />
                        }
                    />
                </Box>
                <Box zIndex='1' flex={1}>
                    <Text fontWeight={'semibold'}>To</Text>
                    <DatePicker
                        dropdownMode
                        minDate={range[0]}
                        maxDate={new Date()}
                        selected={range[1]}
                        dateFormat={'dd/MM/yyyy'}
                        onChange={(date) => setRange([range[0], date])}
                        customInput={
                            <Input
                                variant={"outline"}
                                size="lg"
                                id="offer"
                                placeholder="From"
                                bg={colors.inputBg}
                            />
                        }
                    />
                </Box>
            </HStack>
        </VStack>
    )
}

export default DateRangeSelector